.banner_main_wraper {
    border: 1px solid black;
    border-radius: 15px;
    background-image: url("../../../../../assets/image/bannerbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

}

.banner_heading {
    width: 35%;
    padding-left: 20px;
    line-height: 70px;
    white-space: nowrap;

}

.banner_heading h1 {
    font-size: 70px;
    background: linear-gradient(#fbed00, #febb02);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    -webkit-filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.6));
}

.banner_para {
    color: white;
    font-size: 23px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: 20%;


}

.banner_img {
    position: relative;

}

.banner_img h1 {
    color: white;
    position: absolute;
    top: 0;
    left: 200px;
    font-size: 50px;
}

.banner_img h2 {
    color: white;
    position: absolute;
    top: 60px;
    left: 243px;
    font-size: 30px;
}

.banner_img h3 {
    color: white;
    position: absolute;
    top: -33px;
    left: 350px;
    font-size: 140px;
}

.banner_img h4 {
    color: white;
    position: absolute;
    bottom: 10px;
    right: 160px;
    font-size: 30px;
}


@media screen and (max-width:1882px) {

    .banner_para {
        font-size: 21px;
    }

    .banner_heading h1 {
        font-size: 65px;
        line-height: 65px;
    }


}

@media screen and (max-width:1805px) {

    .banner_para {
        font-size: 21px;
    }

    .banner_heading h1 {
        font-size: 65px;
        line-height: 65px;
    }

    .banner_img img {
        width: 98%;
    }

}

@media screen and (max-width:1780px) {

    .banner_para {
        font-size: 21px;
    }

    .banner_heading h1 {
        font-size: 60px;
        line-height: 60px;
    }

    .banner_img img {
        width: 96%;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 200px;
        font-size: 48px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 54px;
        left: 240px;
        font-size: 30px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -33px;
        left: 350px;
        font-size: 135px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 10px;
        right: 180px;
        font-size: 30px;
    }

}

@media screen and (max-width:1702px) {

    .banner_para {
        font-size: 21px;
        width: 30%;
    }

    .banner_heading h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .banner_img img {
        width: 94%;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 180px;
        font-size: 44px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 43px;
        left: 210px;
        font-size: 28px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -33px;
        left: 300px;
        font-size: 120px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 10px;
        right: 160px;
        font-size: 28px;
    }

}

@media screen and (max-width:1626px) {

    .banner_para {
        font-size: 20px;
    }

    .banner_heading {
        width: 40%;
    }

    .banner_heading h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .banner_img img {
        width: 92%;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 160px;
        font-size: 38px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 41px;
        left: 185px;
        font-size: 26px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -22px;
        left: 260px;
        font-size: 100px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 10px;
        right: 170px;
        font-size: 26px;
    }

}

@media screen and (max-width:1533px) {

    .banner_para {
        font-size: 21px;
        margin-left: 20px;
    }

    .banner_heading h1 {
        font-size: 45px;
        line-height: 45px;
    }

    .banner_img img {
        width: 90%;
        margin-left: 50px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 190px;
        font-size: 34px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 36px;
        left: 200px;
        font-size: 26px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -19px;
        left: 280px;
        font-size: 90px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 10px;
        right: 170px;
        font-size: 24px;
    }


}

@media screen and (max-width:1446px) {

    .banner_para {
        font-size: 20px;
    }

    .banner_heading h1 {
        font-size: 40px;
        line-height: 40px;
    }

    .banner_img img {
        width: 92%;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 190px;
        font-size: 32px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 36px;
        left: 200px;
        font-size: 24px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -19px;
        left: 270px;
        font-size: 94px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 8px;
        right: 140px;
        font-size: 23px;
    }

}

@media screen and (max-width:1352px) {

    .banner_para {
        font-size: 19px;
    }

    .banner_heading h1 {
        font-size: 38px;
        line-height: 35px;
    }

    .banner_img img {
        width: 94%;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 190px;
        font-size: 29px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 32px;
        left: 205px;
        font-size: 21px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -23px;
        left: 270px;
        font-size: 94px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 6px;
        right: 110px;
        font-size: 24px;
    }

}

@media screen and (max-width:1290px) {

    .banner_para {
        font-size: 19px;
        width: 35%;
        margin-left: 50px;
    }

    .banner_heading {
        width: 40%;
    }

    .banner_heading h1 {
        font-size: 33px;
        line-height: 30px;
    }

    .banner_img img {
        width: 89%;
        margin-left: 50px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 180px;
        font-size: 29px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 32px;
        left: 195px;
        font-size: 21px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -19px;
        left: 260px;
        font-size: 88px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 6px;
        right: 120px;
        font-size: 22px;
    }

}

@media screen and (max-width:1258px) {

    .banner_para {
        font-size: 18px;
        width: 50%;
    }

    .banner_heading {
        width: 45%;
        white-space: nowrap;
    }

    .banner_heading h1 {
        font-size: 34px;
        line-height: 30px;
    }

    .banner_img img {
        width: 95%;
        margin-left: 10px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 130px;
        font-size: 25px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 25px;
        left: 140px;
        font-size: 19px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -19px;
        left: 200px;
        font-size: 78px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        bottom: 6px;
        right: 100px;
        font-size: 19px;
    }

}

@media screen and (max-width:1199px) {

    .banner_main_wraper {
        display: flex;
        flex-direction: column;
        border-radius: 35px;

    }

    .banner_para {
        width: 100%;
        padding:0 30px 0 40px;
        margin: 50px 0 50px 0;
        font-size: 30px;


    }

    .banner_heading {
        width: 100%;

        text-align: center;
        margin: 0;

    }

    .banner_heading h1 {
        font-size: 80px;
        line-height: 70px;

    }

    .banner_img {
        min-height: 225px;
        width: 100%;
        border-radius: 20px;
        background: linear-gradient(rgb(255, 138, 0), rgb(255, 105, 0));
        display: flex;
        justify-content: center;
    }
    

    .banner_img img {
        display: none;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 300px;
        font-size: 60px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 60px;
        left: 340px;
        font-size: 40px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -35px;
        left: 460px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 300px;
        font-size: 40px;
    }
}

@media screen and (max-width:1099px) {

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 230px;
        font-size: 60px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 60px;
        left: 270px;
        font-size: 40px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -35px;
        left: 400px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 250px;
        font-size: 40px;
    }
}

@media screen and (max-width:999px) {

    .banner_main_wraper {
        display: flex;
        flex-direction: column;
        

    }

    .banner_para {
        font-size: 30px;
        padding-left: 100px;
    }

    .banner_heading {
        text-align: center;
       margin-left: -20px;

    }

    .banner_heading h1 {
        font-size: 70px;
        line-height: 70px;

    }

    .banner_img {
        min-height: 220px;
        width: 100%;
        border: 2px solid black;
        background: linear-gradient(rgb(255, 138, 0), rgb(255, 105, 0));

    }

    .banner_img img {
        display: none;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 150px;
        font-size: 60px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 60px;
        left: 190px;
        font-size: 40px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -33px;
        left: 310px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 250px;
        font-size: 35px;
        white-space: nowrap;
    }
}





@media screen and (max-width:899px) {

    .banner_main_wraper {
        display: flex;
        flex-direction: column;

    }

    .banner_para {
        
        font-size: 30px;


    }

    .banner_heading {
   
        margin-left:0px;

    }

    .banner_heading h1 {
        font-size: 60px;
        line-height: 60px;

    }

    .banner_img {
        min-height: 220px;
        width: 100%;
        border: 2px solid black;
        background: linear-gradient(rgb(255, 138, 0), rgb(255, 105, 0));

    }

    .banner_img img {
        display: none;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 150px;
        font-size: 60px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 60px;
        left: 190px;
        font-size: 40px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -33px;
        left: 310px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 250px;
        font-size: 35px;
    }
}


@media screen and (max-width:850px) {



    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 100px;
        font-size: 50px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 50px;
        left: 130px;
        font-size: 35px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -38px;
        left: 230px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 180px;
        font-size: 35px;
    }
}

@media screen and (max-width:699px){

    .banner_heading{
        margin: 0;
        padding: 0;
    } 

   
    .banner_para{
        padding: 0 35px 0 35px;
        
    }  

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 50px;
        font-size: 40px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 33px;
        left: 55px;
        font-size: 35px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -38px;
        left: 150px;
        font-size: 160px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 150px;
        left: 100px;
        font-size: 35px;
    }

}

@media screen and (max-width:599px){

    .banner_heading{
        margin: 0;
        padding: 0;
    } 
    .banner_heading h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .banner_para{
      padding: 0px;
      padding-left: 30px;
        font-size: 25px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 40px;
        font-size: 30px;
    }
    .banner_img{
        min-height: 165px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 28px;
        left: 45px;
        font-size: 25px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -38px;
        left: 115px;
        font-size: 130px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 110px;
        left: 50px;
        font-size: 30px;
    }

}

@media screen and (max-width:499px){

    .banner_heading{
        margin: 0;
        padding: 0;
    } 
    .banner_heading h1 {
        font-size: 40px;
        line-height: 40px;
    }

    .banner_para{
        padding-right: 20px;
        font-size: 22px;
    }
    .banner_img{
        min-height: 150px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 40px;
        font-size: 26px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 28px;
        left: 55px;
        font-size: 18px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -30px;
        left: 105px;
        font-size: 110px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 95px;
        left: 50px;
        font-size: 25px;
    }

}


@media screen and (max-width:450px){

    .banner_heading{
        margin: 0;
        padding: 0;
    } 
    .banner_heading h1 {
        font-size: 35px;
        line-height: 30px;
    }

    .banner_para{
        padding-right: 20px;
        font-size: 20px;
    }

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 10px;
        left: 20px;
        font-size: 24px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 32px;
        left: 30px;
        font-size: 18px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -20px;
        left: 80px;
        font-size: 100px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 95px;
        left: 50px;
        font-size: 20px;
    }

}

@media screen and (max-width:385px){

  

    .banner_img h1 {
        color: white;
        position: absolute;
        top: 10px;
        left: 20px;
        font-size: 24px;
    }

    .banner_img h2 {
        color: white;
        position: absolute;
        top: 32px;
        left: 30px;
        font-size: 18px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -12px;
        left: 80px;
        font-size: 85px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 85px;
        left: 30px;
        font-size: 20px;
    }

}

@media screen and (max-width:360px){

  

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 85px;
        left: 20px;
        font-size: 20px;
    }

}

@media screen and (max-width:345px){
    .banner_img{
        min-height: 120px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -12px;
        left: 80px;
        font-size: 78px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 75px;
        left: 10px;
        font-size: 20px;
    }

}


@media screen and (max-width:330px){
    .banner_img{
        min-height: 120px;
    }
    .banner_heading h1{
        font-size: 26px;
    }

    .banner_img h3 {
        color: white;
        position: absolute;
        top: -12px;
        left: 80px;
        font-size: 70px;
    }

    .banner_img h4 {
        color: white;
        position: absolute;
        top: 75px;
        left: 10px;
        font-size: 18px;
    }

}