.ongoing_back_wrap {}

.ongoing_main_wrap {
    background-image: url("../../../../../assets/image/ongoingimg.png");
    background-repeat: no-repeat;
    background-position: left -100px bottom -50px;
    position: relative;
    padding-left: 750px;
}

.ongoing_text_wrap {}

.ongoing_text_heading {
    font-size: 26px;
}

.ongoing_text_para {
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
    padding-right: 40px;
}

.ongoing_text_para p:nth-child(2) {
    margin-top: 20px;
}

.ongoing_list_wrap {
    padding: 0 60px 10px 80px;
}

.ongoing_list_wrap h3 {
    color: #0b57a5;
    font-size: 36px;
    font-weight: 600;
}

.ongoing_list_wrap p {
    font-size: 23px;
    line-height: 26px;
    font-weight: 500;
}

.ongoing_list_wrap ul {
    list-style: none;


}

.ongoing_list_wrap ul li {
    font-size: 23px;
    background-image: url("../../../../../assets//image/circle.png");
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 43px;
    line-height: 26px;
    margin-top: 15px;
    font-weight: 500;
}

.ongoing_list_title {
    font-weight: bold;
    font-size: 23px;

}

.text_org {
    color: rgb(252, 130, 45);
}

@media screen and (max-width:1699px) {

    .ongoing_main_wrap {
      
        background-position: left -200px bottom -50px;
        padding-left: 660px;
    }

    .ongoing_text_heading {
        font-size: 23px;
    }

    .ongoing_text_para {
        font-size: 21px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 34px;

    }

    .ongoing_list_wrap p {
        font-size: 22px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 22px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 22px;

    }

}

@media screen and (max-width:1599px) {

    .ongoing_main_wrap {
      
        background-position: left -200px bottom -50px;
        padding-left: 660px;
    }

    .ongoing_text_heading {
        font-size: 23px;
    }

    .ongoing_text_para {
        font-size: 21px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 34px;

    }

    .ongoing_list_wrap p {
        font-size: 22px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 22px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 22px;

    }

}

@media screen and (max-width:1500px) {

    .ongoing_main_wrap {
        background-position: left -230px bottom -50px;
        padding-left: 620px;
    }

    .ongoing_text_heading {
        font-size: 21px;
    }

    .ongoing_text_para {
        font-size: 19*px;
        line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 32px;

    }

    .ongoing_list_wrap p {
        font-size: 21px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 21px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 21px;

    }

}

@media screen and (max-width:1420px) {

    .ongoing_main_wrap {
     
        padding-left: 600px;
        background-position: left -250px bottom;
    }

    .ongoing_text_heading {
        font-size: 20px;
    }

    .ongoing_text_para {
        font-size: 19px;
        line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 32px;

    }

    .ongoing_list_wrap p {
        font-size: 21px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 21px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 21px;

    }

}


@media screen and (max-width:1350px) {

    .ongoing_main_wrap {
     
        padding-left: 600px;
        background-position: left -250px bottom;
    }

    .ongoing_text_heading {
        font-size: 20px;
    }

    .ongoing_text_para {
        font-size: 19px;
        line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 32px;

    }

    .ongoing_list_wrap p {
        font-size: 21px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 21px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 21px;

    }

}


@media screen and (max-width:1299px) {

    .ongoing_main_wrap {
     
        padding-left: 520px;
        background-position: left -300px bottom;
    }

    .ongoing_text_heading {
        font-size: 19px;
    }

    .ongoing_text_para {
        font-size: 18px;
        line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 30px;

    }

    .ongoing_list_wrap p {
        font-size: 20px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 20px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 21px;

    }

}

@media screen and (max-width:1199px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 760px;
        background-position: center top;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 26px;
    }

    .ongoing_text_para {
        font-size: 21px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 36px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 23px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 23px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 23px;
    }

}

@media screen and (max-width:899px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 700px;
        background-position: center top;
        background-size: 900px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 24px;
    }

    .ongoing_text_para {
        font-size: 20px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 34px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 22px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 22px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 22px;
    }

}

@media screen and (max-width:803px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 620px;
        background-position: center top;
        background-size: 800px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 22px;
    }

    .ongoing_text_para {
        font-size: 19px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 32px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 21px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 21px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 20px;
    }

}

@media screen and (max-width:750px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 620px;
        background-position: center top;
        background-size: 800px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 20px;
    }

    .ongoing_text_para {
        font-size: 19px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 32px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 21px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 21px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 20px;
    }

}

@media screen and (max-width:699px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 620px;
        background-position: center top;
 
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 17px;
    }

    .ongoing_text_para {
        font-size: 18px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 30px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 20px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 20px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 20px;
    }

}

@media screen and (max-width:599px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 600px;
        background-size: 800px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 16px;
    }

    .ongoing_text_para {
        font-size: 16px;
    line-height: 26px;

    }

    .ongoing_list_wrap h3 {

        font-size: 28px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 18px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 18px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 18px;
    }

}

@media screen and (max-width:570px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 550px;
        background-size: 700px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 15px;
    }

    .ongoing_text_para {
        font-size: 15px;
    line-height: 20px;

    }

    .ongoing_list_wrap h3 {

        font-size: 26px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 16px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 16px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 16px;
    }

}

@media screen and (max-width:530px) {
    .ongoing_text_heading {
        font-size: 14px;
    }

}


@media screen and (max-width:499px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 480px;
        background-size: 600px;
    }
    .ongoing_text_wrap{
        padding: 0 20px 0 20px;
    }

    .ongoing_text_heading {
        font-size: 13px;
    }

    .ongoing_text_para {
        font-size: 15px;
    line-height: 20px;

    }

    .ongoing_list_wrap h3 {

        font-size: 26px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 16px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 16px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 16px;
    }

}

@media screen and (max-width:465px) {
    .ongoing_text_heading {
        font-size: 12px;
    }
}


@media screen and (max-width:435px) {

    .ongoing_main_wrap {
     
        padding: 0;
        padding-top: 480px;
        background-size: 600px;
    }
    .ongoing_text_wrap{
        padding: 0;
    }

    .ongoing_text_heading {
        font-size: 12px;
        margin: 0;
        padding: 0;
    }

    .ongoing_text_para {
        font-size: 13px;
    line-height: 20px;

    }

    .ongoing_list_wrap h3 {

        font-size: 24px;

    }
    .ongoing_list_wrap {
        padding: 0;
    }

    .ongoing_list_wrap p {
        font-size: 14px;
    line-height: 26px;

    }

   
    .ongoing_list_wrap ul li {
        font-size: 14px;

    }

    .ongoing_list_title {
        font-weight: bold;
        font-size: 14px;
    }

}

@media screen and (max-width:399px) {



    .ongoing_text_heading {
        font-size: 11px;
        margin: 0;
        padding: 0;
    }

   

}

@media screen and (max-width:368px) {



    .ongoing_text_heading {
        font-size: 10px;
        margin: 0;
        padding: 0;
    }

   

}