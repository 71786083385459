.form_container {
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.form_container h3 {
  background-color: #f2cf66;
  color: #333;
  width: 70%;
  /* margin-top: 5%; */
  /* margin-bottom: 10px; */
  padding: 15px;
  font-size: 22px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
margin: 10px auto;
}


.cif_form  .previewfield {
  text-align: start !important;
  display: block !important;
}
.form_contain_wraper{
  width: 100%;
}
.cif_form .previewfield .css-1nrlq1o-MuiFormControl-root{
  margin: 15px 0 ;
}
.cif_form  .previewfield textarea{
  height: 60px !important;
}
.adminformbody .form_contain_wraper .cif_form {
  width: 90%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: scroll;
  overflow-x: hidden;
}



.adminformbody .cif_contact_form {
  width: auto!important;
  margin: 0 auto;
  max-height:inherit!important;
  overflow-y: hidden!important;
  overflow-x: hidden;
}



.adminformbody .cif_contact_form  .formInline .adminformbody .cif_contact_form  .MuiFormControl-root.MuiTextField-root{background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc;font-size: 14px;   color: #333;   font-family: "Poppins", sans-serif;}
.adminformbody .cif_contact_form  .formInline .adminformbody .cif_contact_form  textarea {background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc!important;height:100px!important; padding: 10px; resize: none; outline: none!important; font-size: 14px; color: #333;    font-family: "Poppins", sans-serif; }

.adminformbody .cif_contact_form  .formInline .color, .adminformbody .cif_contact_form  .formInline .color > div {margin-top: 8px;}
.adminformbody .cif_contact_form  .formInline .dynamicFormField .MuiFormControl-root.MuiTextField-root input,.adminformbody .cif_contact_form  .formInline .dynamicFormField .MuiSelect-select{padding:12px 14px;   font-family: "Poppins", sans-serif;  font-size: 14px; color: #333; }
.adminformbody .cif_contact_form .formInline .dynamicFormField .MuiFormControl-root .MuiInputLabel-root{top:-4px;  color: #a9a9a9; margin: 0;}
.adminformbody .cif_contact_form  .formInline .dynamicFormField .MuiSelect-select{background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc; font-size: 14px; min-height: 32px;     padding: 6px 14px!important;  border-radius: 0px;  color: #333;   font-family: "Poppins", sans-serif;}


.adminformbody .cif_contact_form  .previewfield .css-1nrlq1o-MuiFormControl-root {
  margin:5px 0 10px 0;
}
.adminformbody .cif_contact_form  .MuiFormLabel-filled{display: none;}
.adminformbody .cif_contact_form  label.Mui-focused{ display: none;}
.adminformbody .cif_contact_form legend{display: none;}
.adminformbody .cif_contact_form fieldset{top: 0 !important;}

.adminformbody .cif_contact_form .MuiFormControlLabel-label{ font-size: 16px;}

.adminformbody .cif_contact_form .previewfield textarea {
  height: 60px !important;
  resize: none;
  padding: 10px 14px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333; border:solid 1px #ccc!important; border-radius: 5px!important; outline: none!important;
}
.adminformbody .cif_contact_form .text_area div label{ display: none;}

.form_contain_wraper .chip_block{
  display: block;
  margin: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color:inherit !important;
  padding: 0px;
  font-size: 22px;
  border-radius: 0px;
  max-width: 100%;
  overflow:hidden;
  border-bottom: none;
} 
 /* .folder_view_con span{
  font-size: 22px;
} */
.load_button{
  text-align: center;

  margin-top: 10px;

}