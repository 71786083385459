.promis_main_wrap{
    position: relative;
    background-image: url("../../../../../assets//image/promisbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-right: 600px;
    border: 1px solid black;
    border-radius: 10px;
}
.tag_img{
    margin-left: -21px;
}
.tag_text{
    color: white;
    font-size: 54px;
    position: absolute;
    top: 30px;
    left: 40px;
}
.promis_text_wrap{
    padding: 0 30px 20px 40px;
}

.promis_text_wrap p{
    font-size: 25px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.promis_text_wrap h3{
    font-size: 41px;
    line-height: 40px;
    background: linear-gradient(#fbed00, #ca9607 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    


}



.promis_img{
    position: absolute;
    bottom: 0;
    right: 0px;
}

@media screen and (max-width:1899px) {

    .promis_text_wrap h3{
    font-size: 40px;
    line-height: 40px;
   
    


}
    
}

@media screen and (max-width:1820px) {

    .promis_text_wrap h3{
    font-size: 39px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 23px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.tag_text{
   
    font-size: 53px;
   
    
}
}

@media screen and (max-width:1799px) {

    .promis_text_wrap h3{
    font-size: 38px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 22px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.tag_text{
   
    font-size: 52px;
   
    
}
}


@media screen and (max-width:1755px) {



    .promis_text_wrap h3{
    font-size: 35px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 21px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.tag_text{
   
    font-size: 51px;
   
    
}

}

@media screen and (max-width:1699px) {



    .promis_text_wrap h3{
    font-size: 32px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 21px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.tag_text{
   
    font-size: 51px;
   
    
}

}

@media screen and (max-width:1699px) {



    .promis_text_wrap h3{
    font-size: 30px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 20px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.tag_text{
   
    font-size: 48px;
   
    
}

}

@media screen and (max-width:1199px) {

.promis_main_wrap{
    margin: 0;
    padding: 0;
}

    .promis_text_wrap h3{
    font-size: 35px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 24px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.promis_img{
    display: none;
}

.tag_text{
   
    font-size: 48px;
   
    
}

}

@media screen and (max-width:599px) {

.promis_main_wrap{
    margin: 0;
    padding: 0;
}
.tag_img{
    height: 90px;
    margin-left: -18px;
}
.tag_text{
    
    top: 20px;
    left: 30px;
}

    .promis_text_wrap h3{
    font-size: 35px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 24px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.promis_img{
    display: none;
}



}

@media screen and (max-width:499px) {

.promis_main_wrap{
    margin: 0;
    padding: 0;
}
.tag_img{
    height: 70px;
    margin-left: -15px;
}
.tag_text{
    
    top: 15px;
    left: 20px;
    font-size: 38px;
}

    .promis_text_wrap h3{
    font-size: 35px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 24px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.promis_img{
    display: none;
}



}


@media screen and (max-width:399px) {

.promis_main_wrap{
    margin: 0;
    padding: 0;
}
.tag_img{
    height: 60px;
    margin-left: -15px;
}
.tag_text{
    
    top: 10px;
    left: 10px;
    font-size: 34px;
}

    .promis_text_wrap h3{
    font-size: 33px;
    line-height: 40px;
}

.promis_text_wrap p{
    font-size: 22px;
    color: white;
    font-weight: 500;
    margin: 10px 0 15px 0;

}

.promis_img{
    display: none;
}



}