.NDAContract_mainwrpr {font-family: "Tinos", serif;}
.NDAContract_commnwidth {width: 866px;  margin: 50px auto;}
.NDAContract_header {  text-align: center;}
.NDAContract_header h3 {  text-decoration: underline;  font-weight: bold;margin-bottom: 10px;font-size: 45px;}
.NDAContract_mainwrpr p {  line-height: 18px;  margin-bottom: 5px;margin-right: 10px    ;}
.NDA1stSection {  margin-bottom: 30px;}
.NDA1stSection p {  text-indent: 70px;}
.NDA2ndSectionColumn,.NDA3rdSectionColumn,.NDA5thSectionForm, .NDA6thSectionForm {  display: flex;  justify-content: space-between;}
.NDA2ndSectionColumn .NDA2ndSec-left,.NDA3rdSectionColumn .NDA3rdSec-left {  width: 49.5%;  padding-right: 5px;}
.NDA2ndSectionColumn .NDA2ndSec-right,.NDA3rdSectionColumn .NDA3rdSec-right {  width: 48.5%;}
.commnSpan p span {  margin-right: 50px;}
.NDA2ndSec-left,.NDA3rdSec-left {  border-right: 1px solid #000;}
.NDA3rdSectionColumn {  margin-top: 30px;}
.NDA4thSectionColumn p {  text-align: center;  margin: 30px 0;}
.NDA4thSectionColumn p span {  font-style: italic;}
.NDA5thSectionForm{margin-bottom: 30px;}
.NDA5thSectionForm .NDA5thSec-right form input, .NDA6thSectionForm .NDA6thSec_left form input, .NDA6thSectionForm .NDA6thSec_right form input {
    border: none;  border-bottom: 1px solid #000;  font-size: 16px;  outline: none;
}
.NDA6thSectionForm .NDA6thSec_left form input, .NDA6thSectionForm .NDA6thSec_right form input{padding-left: 3px;}
.NDA6thSectionForm .NDA6thSec_left form input:nth-of-type(6), .NDA6thSectionForm .NDA6thSec_right form input:nth-of-type(6) {width: 100%;}


.NDA6thSectionForm .NDA6thSec_left .NDA6th_Form,
.NDA6thSectionForm .NDA6thSec_right .NDA6th_Form,
.NDA5thSectionForm .NDA5thSec-right .NDA6th_Form {display: flex; width: 100%;margin: 10px 0;}
.NDA6thSec_left {width: 42%;}
.NDA6th_Form .MuiFormControl-root {width: 100%;}
.NDA6thSec_right {width: 50%;}
.NDA6thSectionForm .NDA6thSec_left .NDA6th_Form .uiFilledInput-root:hover, 
.NDA6thSectionForm .NDA6thSec_left .NDA6th_Form .MuiFilledInput-root, 
.NDA6thSectionForm .NDA6thSec_right .NDA6th_Form .uiFilledInput-root:hover,
.NDA6thSectionForm .NDA6thSec_right .NDA6th_Form .MuiFilledInput-root,
.NDA5thSectionForm .NDA5thSec-right .NDA6th_Form .uiFilledInput-root:hover,
.NDA5thSectionForm .NDA5thSec-right .NDA6th_Form .MuiFilledInput-root
{background-color: transparent;}
.NDA6thSectionForm .NDA6thSec_left .NDA6th_Form input,
.NDA6thSectionForm .NDA6thSec_right .NDA6th_Form input,
.NDA5thSectionForm .NDA5thSec-right .NDA6th_Form input
{padding: 0; padding-bottom: 1px;padding-left: 3px;font-family: "Tinos", serif !important;}

.visibility_cls{
    visibility: hidden;
}
.SD_nda_para p {margin: 18px 0;}

@media only screen and (max-width: 850px){.NDAContract_commnwidth {width: 96%}}
@media only screen and (max-width: 580px){
    .NDA5thSectionForm{align-items: center;}
    .NDA5thSectionForm, .NDA6thSectionForm{flex-direction: column;}
    .NDA6thSectionForm .NDA6thSec_left{margin-bottom: 30px ;border-bottom-style: double;padding-bottom: 30px;display: none;}
    .NDA6thSectionForm .NDA6thSec_left form input:nth-of-type(6), .NDA6thSectionForm .NDA6thSec_right form input:nth-of-type(6) {width: 90%;}
    .NDA6thSectionForm .NDA6thSec_left form input, .NDA6thSectionForm .NDA6thSec_right form input{width: 80%;}
    .NDA6thSec_right {width: 100%;}
}


@media print {
    /* if modal-body is your scrollable class */
    .NDAContract_mainwrpr {
        width: 100%;
        visibility: visible !important;
        overflow: visible !important;
    }

    .NDAContract_mainwrp .NDAContract_commnwidth{
        width: 100%;
        padding: 5px;
        margin: 250px auto;
        visibility: visible !important;
        overflow: visible !important;
    }

    .NDAContract_commnwidth .downloadBtn{
        display: none;
    }


 }