.heading_subscrptn, .checkBox_subscrptn{
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    margin-bottom: 15px;
}
.checkBox_subscrptn .MuiFormControl-root .MuiSvgIcon-root{color: #ffffff;}
.checkBox_subscrptn .MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root{
    font-size: 18px;
    font-weight: bold;
}