 .testi_main_wrap {
     background: linear-gradient(to right bottom, rgb(239, 254, 253), rgb(164, 168, 168));
     padding: 18px;
     box-shadow: 0px 0px 3px 3px rgba(128, 128, 128, 0.3);
     margin-left: 30px;
     margin-right: 30px;
     z-index: 1;
     border: 1px solid rgb(241, 241, 241);
 }

 .testi_main_wrap .testi_main_card_wrap {
     background: url("../../../../../assets/image/audting1.png");
     background-repeat: no-repeat;
     background-size: cover;
     padding: 27px;
     position: relative;
     z-index: 2;
 }

 .testi_main_card_wrap .testi_card_wrap {
     display: flex;
     justify-content: space-between;
     width: 86%;
     z-index: 3;

 }

 .testi_card_wrap .glow {
     width: 95%;
 }

 .card-title {
     font-size: 32px;
     font-weight: 700;
 }

 .card_first_text {
     font-size: 23px;
     font-weight: 600;
     line-height: 25px;
     margin: 15px 15px 13px 15px;
 }

 .card_secnd_text {
     font-size: 23px;
     font-weight: 600;
     margin: 2px 10px 10px 13px;
     line-height: 25px;
 }

 .main_card1 {
     border: 1px solid rgb(5, 162, 238);
     box-sizing: border-box;
     width: 23.5%;
     min-height: 435px;
     box-sizing: border-box;
     border-radius: 20px;
     background: url("../../../../../assets//image/card1bg.png");
     background-size: cover;
     background-repeat: no-repeat;
     padding: 15px;
     color: white;
     text-align: center;
 }

 .card1 {
     border: 1px solid rgb(5, 162, 238);
     border-radius: 20px;
     padding: 10px;
     height: 100%;
 }

 .card_logo1 {
     margin-top: 8px;
 }

 .card_title1 {
     background: url("../../../../../assets/image/titlebg.png");
     background-repeat: no-repeat;
     background-size: cover;
     text-align: center;
 }




 .main_card2 {
     border: 1px solid rgb(64, 188, 255);
     box-sizing: border-box;
     width: 23.5%;
     min-height: 435px;
     box-sizing: border-box;
     border-radius: 20px;
     background: url("../../../../../assets//image/card2bg.png");
     background-size: cover;
     background-repeat: no-repeat;
     padding: 15px;
     color: white;
     text-align: center;
 }

 .card2 {
     border: 1px solid rgb(64, 188, 255);
     border-radius: 20px;
     padding: 10px;
     height: 100%;
 }

 .card_logo2 {
     margin-top: 8px;
     margin-bottom: 8px;
 }

 .card_title2 {
     background: url("../../../../../assets/image/titlebg2.png");
     background-repeat: no-repeat;
     background-size: cover;
     text-align: center;
 }




 .main_card3 {
     border: 1px solid rgb(14, 220, 222);
     box-sizing: border-box;
     width: 23.5%;
     min-height: 435px;
     box-sizing: border-box;
     border-radius: 20px;
     background: url("../../../../../assets//image/card3bg.png");
     background-size: cover;
     background-repeat: no-repeat;
     padding: 15px;
     color: white;
     text-align: center;
 }

 .card3 {
     border: 1px solid rgb(14, 220, 222);
     border-radius: 20px;
     padding: 10px;
     height: 100%;
 }

 .card_logo3 {
     margin-top: 8px;
 }

 .card_title3 {
     background: url("../../../../../assets/image/titlebg3.png");
     background-repeat: no-repeat;
     background-size: cover;
     text-align: center;
     margin-top: -1px;
 }



 .main_card4 {
     border: 1px solid rgb(34, 242, 209);
     box-sizing: border-box;
     width: 23.5%;
     min-height: 435px;
     box-sizing: border-box;
     border-radius: 20px;
     background: url("../../../../../assets//image/card4bg.png");
     background-size: cover;
     background-repeat: no-repeat;
     padding: 15px;
     color: white;
     text-align: center;
 }

 .card4 {
     border: 1px solid rgb(34, 242, 209);
     border-radius: 20px;
     padding: 10px;
     height: 100%;
 }

 .card_logo4 {
     margin-top: 13px;

 }

 .card_title4 {
     background: url("../../../../../assets/image/titlebg4.png");
     background-repeat: no-repeat;
     background-size: cover;
     text-align: center;
     margin-top: 30px;

 }



 .main_image {
     position: absolute;
     bottom: 0;
     right: -20px;
     z-index: -1;
 }

 @media screen and (max-width:1960px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -30px 0;
     }

 }

 @media screen and (max-width:1935px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -36px 0;
     }

 }

 @media screen and (max-width:1935px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -60px 0;
     }

 }

 @media screen and (max-width:1889px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -80px 0;
     }

 }

 @media screen and (max-width:1871px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -100px 0;
     }

 }

 @media screen and (max-width:1844px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -130px 0;
     }

     .card-title {
         font-size: 31px;
     }

     .card_first_text {
         font-size: 21px;
     }

     .card_secnd_text {
         font-size: 21px;
     }

 }

 @media screen and (max-width:1808px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -150px 0;
     }


     .card-title {
         font-size: 30;
     }

     .card_first_text {
         font-size: 20px;
     }

     .card_secnd_text {
         font-size: 20px;
     }

 }

 @media screen and (max-width:1780px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -200px 0;
     }


 }

 @media screen and (max-width:1756px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -190px 0;
     }


 }

 @media screen and (max-width:1702px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -230px 0;
     }

 }

 @media screen and (max-width:1675px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -260px 0;
     }

     .card-title {
         font-size: 28px;
     }

     .card_first_text {
         font-size: 18px;
     }

     .card_secnd_text {
         font-size: 18px;
     }


 }


 @media screen and (max-width:1605px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -300px 0;
     }

 }

 @media screen and (max-width:1550px) {

     .testi_main_wrap .testi_main_card_wrap {
         background-position: -380px 0;
     }

     .card_logo {
         height: 90px;
     }


     .card_logo4 img {
         height: 70px;
     }

     .card-title {
         font-size: 28px;
     }

     .card_first_text {
         font-size: 18px;
     }

     .card_secnd_text {
         font-size: 18px;
     }


 }



 @media screen and (max-width:1399px) {


     .testi_card_wrap {
         width: 100% !important;
         flex-wrap: wrap;

     }

     .main_card1 {
         width: 48.5%;
        
     }

     .main_card2 {
         width: 48.5%;
        
     }

     .main_card3 {
        width: 48.5%;
         margin-top: 20px;
     }

     .main_card4 {
        width: 48.5%;
         margin-top: 20px;
     }

     .main_image {
         display: none;
     }

     .card_logo {
         height: 120px;
     }


     .card_logo4 img {
         height: 100px;
     }

     .card-title {
         font-size: 32px;
     }

     .card_first_text {
         font-size: 22px;
     }

     .card_secnd_text {
         font-size: 22px;
     }


 }

 @media screen and (max-width:899px) {


     .testi_card_wrap {
         width: 100% !important;
         flex-wrap: wrap;

     }

     .main_card1 {
         width: 100%;
         
     }

     .main_card2 {
         width: 100%;
         margin-top: 20px;
         
     }

     .main_card3 {
         width: 100%;
         margin-top: 20px;
        
     }

     .main_card4 {
         width: 100%;
         margin-top: 20px;
        
     }

     .main_image {
         display: none;
     }

     .card_logo {
         height: 120px;
     }


     .card_logo4 img {
         height: 100px;
     }

     .card-title {
         font-size: 36px;
     }

     .card_first_text {
         font-size: 26px;
     }

     .card_secnd_text {
         font-size: 26px;
     }


 }
 @media screen and (max-width:799px){

    .testi_main_wrap {
        margin-left: 0px;
        margin-right: 0px;
       
    }
   
    .testi_main_wrap .testi_main_card_wrap {
      
        padding: 10px;
       
    }

 }


 @media screen and (max-width:599px) {
    
    .testi_main_wrap {
        margin-left: 0px;
        margin-right: 0px;
       
    }
   
    .testi_main_wrap .testi_main_card_wrap {
      
        padding: 10px;
       
    }

    .testi_card_wrap {
        width: 100% !important;
        flex-wrap: wrap;

    }

    .main_card1 {
        width: 100%;
       
    }

    .main_card2 {
        width: 100%;
        margin-top: 20px;
        
    }

    .main_card3 {
        width: 100%;
        margin-top: 20px;
       
    }

    .main_card4 {
        width: 100%;
        margin-top: 20px;
       
    }


    .card-title {
        font-size: 30px;
    }

    .card_first_text {
        font-size: 22px;
    }

    .card_secnd_text {
        font-size: 22px;
    }


}

@media screen and (max-width:530px) {

    .testi_main_wrap {
        margin-left: 0px;
        margin-right: 0px;
       
    }
   
    .testi_main_wrap .testi_main_card_wrap {
      
        padding: 10px;
       
    }
   


    .testi_card_wrap {
        width: 100% !important;
        flex-wrap: wrap;

    }

    .main_card1 {
        width: 100%;
        margin-top: 20px;
    }

    .main_card2 {
        width: 100%;
        margin-top: 20px;
        
    }

    .main_card3 {
        width: 100%;
        margin-top: 20px;
        
    }

    .main_card4 {
        width: 100%;
        margin-top: 20px;
        
    }


    .card-title {
        font-size: 30px;
    }

    .card_first_text {
        font-size: 20px;
    }

    .card_secnd_text {
        font-size: 20px;
    }


}

@media screen and (max-width:499px){
    .testi_main_wrap{
        padding: 0px;
    }
      

}

@media screen and (max-width:399px) {
    
.testi_main_wrap{
    padding: 0px;
}
   
    .testi_main_wrap .testi_main_card_wrap {
      
        padding: 0px;
       
    }

    .testi_card_wrap {
        width: 100% !important;
        flex-wrap: wrap;

    }

    .main_card1 {
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
    }

    .main_card2 {
        width: 100%;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .main_card3 {
        width: 100%;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .main_card4 {
        width: 100%;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
    }


    .card-title {
        font-size: 28px;
    }

    .card_first_text {
        font-size: 18px;
    }

    .card_secnd_text {
        font-size: 18px;
    }


}
