.LandingPage2Video .lpage_banner_sub_wrapper::after {content: none;}
.LandingPage2Video .l2_logowrapper {
    margin: 28px 0 20px;
}
.LandingPage2Video .l2_top_banner_right{margin: 25px 0 28px 0;}
.LandingPage2Video .l2_top_banner_arrowCon { margin-top: -20px;}
.LandingPage2Video .lpage_banner_sub_wrapper {width: 917px;}
.LandingPage2Video .l2_top_banner_text {width: auto; padding: 0; box-shadow: none; background: none; margin-bottom: 15px;}
.LandingPage2Video .l2_top_banner_text h2 {padding: 0; text-align: left;}
.Videolanding4 .lpage_banner_sub_wrapper { margin: 20px 0 0 0;}
 
@media (max-width:1640px){  
    .LandingPage2Video .lpage_banner_sub_wrapper {
        width: 841px;
    }
}

@media (max-width:1440px){
    .LandingPage2Video .l2_top_banner_arrowCon {
        margin-top: 0px;
    }
}

@media (max-width:1325px){
    .LandingPage2Video .l2form_box .l2form_style_heading h2 {
        font-size: 19px;
        line-height: 22px;
    }
    .LandingPage2Video .l2_top_banner_arrowCon {
        margin-top: 23px;
    }
    .LandingPage2Video .lpage_banner_sub_wrapper {
        width: 810px;
    }
}

@media (max-width:1199px){
    .LandingPage2Video .lpage_banner_sub_wrapper {
        width: auto;
    }
    .LandingPage2Video .l2_top_banner_right {
        margin: 30px 0 0px 0;
    }
}

@media (max-width:560px){
    .LandingPage2Video .l2_top_banner_arrowCon {
        margin-top: -12px;
    }
    .LandingPage2Video .l2_top_banner_right {
        margin: 25px 0 0px 0;
    }

}