.Videolanding3 .landing_page3_bannerblock .lpage_banner_titlewrapper h1{font-size:58px;line-height:58px}
.Videolanding3 .landing_page3_bannerblock .lpage_banner_sub_wrapper{position: relative; background: none; padding:0px;width:850px}


.Videolanding3 .lpage_logowrapper {
    margin-bottom: 20px;
}

.Videolanding3 .lpage_banner_sub_video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; 
  }
 
  .Videolanding3 .lpage_banner_sub_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .Videolanding3 .lpage_banner_sub_video img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }


  .Videolanding3 .lpage_banner_titlewrapper {
    margin-bottom: 20px;
}

.Videolanding3 .lpage_arrow_wrapper::after{ display: none;}

.Videolanding3 .landing_page3_bannerblock .lpage_banner_sub_wrapper::after{ display: none;}
.Videolanding3  .landing_page3_bannerblock .lpage_banner_textwrapper{background-image:url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/l_page3_banner_bg3.jpg);background-repeat:no-repeat;background-size:100% 100%;padding:20px;width:auto}
.Videolanding3  .landing_page3_bannerblock .lpage_banner_textwrapper h4{font-weight:var(--main-font-Bold);font-size:42px;line-height:45px;color:#000;text-shadow: 1px 1px 1px #f9f122;}
.Videolanding3  .landing_page3_bannerblock .lpage_banner_sub_wrapper h2{color:#fff;font-size:38px;line-height:43px;font-weight:var(--main-font-Medium);margin-bottom:20px}
.Videolanding3  .landing_page3_bannerblock .form_style .formfild input{padding:8px 14px}
.Videolanding3  .landing_page3_bannerblock .css-ahj2mt-MuiTypography-root{font-size:20px;font-family:"Poppins",sans-serif}
.Videolanding3  .landing_page3_bannerblock .form_text p{margin-top:22px;font-size:28px;line-height:28px;text-shadow: none;}
.Videolanding3  .landing_page3_bannerblock .form_style .MuiFormLabel-root{top:-8px;font-weight:var(--main-font-Medium)}
.Videolanding3  .landing_page3_bannerblock .formwrapper{background: rgb(4,48,79);
    background: linear-gradient(190deg, rgba(4,48,79,1) 0%, rgba(9,21,70,1) 100%);}
    .Videolanding3  .landing_page3_bannerblock .landing-banner-right{background-color:rgba(255,255,255,.3);padding:10px;border-radius:10px}
    .Videolanding3   .landing_page3_bannerblock{padding:40px 0}
    .landing_page3_bannerblock {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/landing3_video_bannerImg1.webp), 
    url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/landing3_video_bannerImg2.webp); */
    background-repeat: no-repeat;
    background-size: auto, cover;
    background-position: right 290px top, center center;
}
.Videolanding3  .landing_page3_bannerblock .form_title_wrapper{background:rgb(8,231,252);background:linear-gradient(180deg,rgba(8,231,252,1) 0%,rgba(39,134,234,1) 100%)}
.Videolanding3  .landing_page3_bannerblock .form_title_wrapper h2 span{color:#fff!important; background: none; -webkit-text-fill-color: inherit; filter: none!important; font-size:28px;line-height:30px; filter: drop-shadow(1px 1px 0px #08e6fc);}
.Videolanding3  .landing_page3_bannerblock .form_style .formfild input{background-color:#fff;color:#000}
.Videolanding3  .landing_page3_bannerblock .form_style .MuiOutlinedInput-notchedOutline{border:1px solid #557381!important}
.Videolanding3  .landing_page3_bannerblock .form_style .MuiFormLabel-root{color: #000 !important;}

.Videolanding3  .landingThree .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input{background-color:#fff;color:#000}

.Videolanding3  .l2submitbtnsection button{background: rgb(8,214,208);
    background: linear-gradient(190deg, rgba(8,214,208,1) 0%, rgba(2,202,244,1) 100%); color: #011c31 !important;}

.Videolanding3  .landing_page3_bannerblock .submitbtnsection button{background:rgb(255,138,6);background:linear-gradient(180deg,rgba(255,138,6,1) 0%,rgba(255,22,1,1) 100%);border:1px solid #ffcc52}
.Videolanding3   .landing_page3_bannerblock .landing-col-container{align-items: flex-start;}
/* .Videolanding3 .landing_page3_bannerblock .lpage_arrow_wrapper h2{font-size:52px;line-height:53px;font-weight:var(--main-font-SemiBold)}
.Videolanding3 .landing_page3_bannerblock .lpage_arrow_wrapper{background-image:url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/landing3_video_textbg.webp);padding:15px 35px; background-size: cover; border-radius: 10px; margin-top: 20px;} */

  .Videolanding3  .textwrapper p span{color: #009cff;}
.Videolanding3  .landing_page3_listwrapper h2{ color:#0b58a0;}
  .Videolanding3  .landing_page3_list p span{color: #009cff;}
.Videolanding3  .colnumber{ background-color:#041c52;border:12px solid #d5dadc;border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:0 0 8px rgba(0,0,0,.3);position:absolute;left:50%;top:-36px;transform:translateX(-50%)}
.Videolanding3 .colicon{  background-color:#041c52;border:12px solid #d5dadc;   }

.Videolanding3 .landing_page3_block4_sub_textwrapper h3{color:#0b58a0;}
.Videolanding3  .landing_page3_block4_sub_textwrapper h1{color:#0052e8;}
 .Videolanding3  .landing_page3_block4_sub_textwrapper p::after{content:'';height:16px;width:16px;background-color:#03aae8;border:1px solid #fff;}


  .Videolanding3  .landing_page3_listwrapper p::after{height:15px;width:15px;background-color:#03aae8;border:1px solid #fff;}
 .Videolanding3  .textwrapper h4{color:#0b58a0;}
 


 
 .Videolanding3  .block7-col h4{color:#000; background: rgb(8,231,252);
    background: linear-gradient(190deg, rgba(8,231,252,1) 0%, rgba(37,137,235,1) 100%);   }
 
    
     .Videolanding3   .landing_page3_block7_list h4{color:#fff;  background: rgb(8,231,252);
    background: linear-gradient(190deg, rgba(8,231,252,1) 0%, rgba(37,137,235,1) 100%);  }
 
     .Videolanding3 .landing_page3_block7_subwrapper .textwrapper::after{content:''; background:#000;background:linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 80%,rgba(0,171,243,1) 80%,rgba(0,171,243,1) 100%)}
 

      .Videolanding3  .textwrapper h3{font-size:40px;line-height:45px;color:#009cff;font-weight:var(--main-font-Bold);margin-bottom:10px}
 

     .Videolanding3 .landing_page3_block10_textwrapper p span{color: #009cff;}
     .Videolanding3 .landing_page3_block10_textwrapper p strong{color: #009cff ;font-weight: var(--main-font-SemiBold);}
 


 .Videolanding3  .LP3_block12_text_wrapper p span{color: #009cff;}
 
 
  .Videolanding3  .landing_page3_block13_text_wrapper h2{color: #009cff; }
 
 .Videolanding3  .landing_page3_block13_text_wrapper button{   background: rgb(8,213,244);
    background: linear-gradient(190deg, rgba(8,213,244,1) 0%, rgba(7,136,211,1) 100%);  }
     .landing_page3_block13  {background-image: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/landing_page3_block13_img1new.webp');background-repeat: no-repeat; background-size: 61%;background-position: bottom 36% right -134px;}
 
     .landing_page3_block15_wrapper h2{background: rgb(19,217,249);
    background: linear-gradient(190deg, rgba(19,217,249,1) 0%, rgba(24,172,227,1) 100%);color: transparent;  -webkit-background-clip: text; background-clip: text;filter: drop-shadow(0px 6px 5px rgba(0, 0, 0, 0.439));padding: 0 20px 0 0;}
 





.Videolanding3 .audit_font {
    color: #009afe;
}
.Videolanding3 .audit_footer_list ul li::before{background-color: #03abe9;}


 
.Videolanding3  .landing_page3_block13 span{color: #009afe;}
.Videolanding3  .landing_page3_block14_text span {
    color: #009afe;
}


.Videolanding3 .LP3_block12_sub_add2_top_right h1, .Videolanding3 .LP3_block12_sub_add2 h3, .Videolanding3 .LP3_block12_sub_add2_top_left h1, .Videolanding3 .LP3_block12_sub_add2_top_left h2{
    color: #041d4c;
    text-shadow: 1px 1px 0 #c1fafe;
}

.Videolanding3 .LP3_block12_h1_gradient h1{
    background: rgb(11,223,251);
    background: linear-gradient(180deg, rgba(11,223,251,1) 0%, rgba(27,177,243,1) 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
























@media only screen and (max-width: 1800.98px){
    .landing_page3_block13{background-size: 65%;background-position: bottom 57% right -148px;}
    .landing_page3_bannerblock{
        background-position: right 220px top, center center;
    }
}


 @media only screen and (max-width: 1740.98px) {
 

     /* .Videolanding3 .landing_page3_bannerblock .lpage_arrow_wrapper h2{ font-size: 40px; line-height: 42px;} */

        .landing_page3_bannerblock { 
        background-position: right 190px top, center center;
    }
 }

 
  
 @media only screen and (max-width: 1600.98px) {

    .landing_page3_bannerblock {
    background-position: right 130px top, center center;
}
.Videolanding3  .landing-banner-right {
    width: calc(35% - 83px);
    margin-left: 43px;
}
}

@media only screen and (max-width: 1540.98px) {
    .Videolanding3 .landing_page3_bannerblock .lpage_banner_titlewrapper h1 {
        font-size: 54px;
        line-height: 54px;
    }
    .landing_page3_block13{background-size: 68%;
                         background-position: bottom 65% right -148px;}
    .Videolanding3 .landing_page3_bannerblock .form_title_wrapper h2 span{ font-size: 25px; line-height: 28px;}

    /* .Videolanding3 .landing_page3_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 35px;
        line-height: 39px;
    } */
}

@media only screen and (max-width: 1440.98px) {

    .landing_page3_bannerblock {
       background-position: right 100px top, center center;
   }

   .Videolanding3 .landing_page3_bannerblock .lpage_banner_titlewrapper h1 {
    font-size: 46px;
    line-height: 46px;
}
.Videolanding3 .landing_page3_bannerblock .form_title_wrapper h2 span{ display: inline;}
.Videolanding3 .landing-banner-right {
    width: calc(35% - 62px);
    margin-left: 43px;
}
.Videolanding3 .landing_page3_bannerblock .form_title_wrapper h2 span{ font-size: 20px; line-height: 22px;}

.Videolanding3  .form_style .formfild {
    margin-bottom: 9px;
}
   }

   @media only screen and (max-width: 1380.98px) {
   .landing_page3_bannerblock {
    background-position: right 80px top, center center;
}

}
   @media only screen and (max-width: 1340.98px) {

    .landing_page3_bannerblock {
       background-position: right 70px top, center center;
   }

   .Videolanding3 .landing_page3_bannerblock .lpage_banner_sub_wrapper{ width: 780px;}
   .Videolanding3 .landing_page3_bannerblock .form_text p{ font-size: 22px;}
}

@media only screen and (max-width: 1300.98px) {

    .landing_page3_bannerblock {
       background-position: right 20px top, center center;
   }

}

@media only screen and (max-width: 1199.98px) {

    .landing_page3_bannerblock {
    background: rgb(226,240,218);
    background: linear-gradient(90deg,rgba(226,240,218,1) 0%,rgba(195,227,232,1) 50%,rgba(210,211,230,1) 100%);
}

.Videolanding3 .landing_page3_bannerblock .lpage_banner_sub_wrapper {
    width: auto;
}

.Videolanding3  .landing-banner-right {
    width: calc(100% - 0px);
    margin-left: 0;
}

.Videolanding3 .lpage_banner_titlewrapper{ text-align: center;}
/* .Videolanding3 .landing_page3_bannerblock .lpage_arrow_wrapper h2 { text-align: center;} */
}
