.adBlock1{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/lpage13_adBlock1_img2.webp') no-repeat; background-size: cover;}

.adBlock1_wrapper{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/lpage13_adBlock1_img1.webp') no-repeat left bottom; 
  width: 1733px; margin: 0 auto;}

.adBlock1_wrapperText{ padding: 60px 20px 60px 680px;}

.adBlock1_wrapperText h2{ margin: 0; padding: 0; font-size: 45px; line-height: 50px; color: #fff; text-transform: uppercase;   font-family: "Poppins", sans-serif;} 
.adBlock1_wrapperText h3{ margin: 20px 0 10px 0; padding: 25px 0; font-size: 35px; line-height: 35px; color: #e60828;  font-weight: 600;    font-family: "Poppins", sans-serif;}
  
.adBlock1_wrapperText p{ margin: 30px 0 0 0; padding: 0; font-size: 22px; line-height: 30px; color: #fff;    font-family: "Poppins", sans-serif;} 
  
.adBlock1_wrapperText p:first-of-type{ margin: 0;}

.adBlock1_wrapperText button{background: #c50823 !important; 
    border-radius: 0; border: none;   width: 100%; text-align: center;
    padding: 20px; 
    display: block; font-size: 35px; line-height: 35px; color: #fff; font-weight: 700;  margin-top: 50px;   font-family: "Poppins", sans-serif;}


.adBlock2{background: url('https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/lpage13_adBlock2_img1.webp') no-repeat;  background-size: cover;}    

.adBlock2_wrapper{  width: 1733px; margin: 0 auto;}

.adBlock2_text{ width: 1080px; height: 100%; display: flex; flex-wrap: wrap; align-items: center; min-height: 490px;   }

.adBlock2_text h2{ margin: 0; padding: 0; font-size: 56px; line-height: 56px; color: #fff; text-transform: uppercase; font-weight: bold; font-family: "Poppins", sans-serif;     text-shadow: 1px 1px 10px #042839;} 

.adBlock2_text_con{ position: relative;} 
.adBlock2_text_con button{ position: absolute; right: 0; bottom: -65px; background: #c50823 !important; 
    border-radius: 0; border: none;    text-align: center;
    padding: 20px;   font-size: 45px; line-height: 45px; color: #fff; font-weight: 700;    font-family: "Poppins", sans-serif;}


@media only screen and (max-width: 1840px){
  .adBlock1_wrapper { width: 96%;}
  .adBlock2_wrapper{  width: 96%;}
}

@media only screen and (max-width: 1680px){

.adBlock1_wrapperText{ padding: 50px 0px 50px 650px;}
.adBlock1_wrapperText h3{ margin: 20px 0 0 0;}

}

@media only screen and (max-width: 1620px){
    .adBlock2{ background-position: 60% top;}
    .adBlock2_text{ width: 60%;}
    .adBlock2_text_con button{ position: inherit; bottom: 0; margin-top: 20px;}
}

@media only screen and (max-width: 1520px){
    .adBlock1_wrapperText h3 {
        margin: 0;
    }

    .adBlock1_wrapperText button{ margin-top: 25px;}
}

@media only screen and (max-width: 1460px){
    .adBlock1_wrapperText {
        padding: 30px 0px 30px 620px;
    }
}

@media only screen and (max-width: 1380px){
    .adBlock1_wrapperText h2{font-size: 40px;  line-height: 45px;}
    .adBlock1_wrapperText h3{ font-size: 30px; line-height: 34px;}
    .adBlock1_wrapperText button{ font-size: 30px; line-height: 34px;}

    .adBlock2_text h2{font-size: 40px;  line-height: 45px;}
    .adBlock2_text_con button{ font-size: 30px; line-height: 34px;}
}

@media only screen and (max-width: 1199px){
    
    .adBlock1_wrapper{ background: none; width: auto; padding: 0 2%; background-color: rgba(0, 0, 0, 33%);}
    .adBlock1_wrapperText{ padding:30px 15px;}

    .adBlock1_wrapperText h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .adBlock1_wrapperText h3 {
        font-size: 26px;
        line-height: 30px; padding: 18px 0;
    }

    .adBlock1_wrapperText button {
        font-size: 26px;
        line-height: 30px;
    }

    .adBlock1_wrapperText p{ font-size: 18px; line-height: 25px;}

    .adBlock2{background: rgb(1,187,221);
        background: linear-gradient(180deg, rgba(1,187,221,1) 0%, rgba(48,153,172,1) 100%); height: auto;}

    .adBlock2_text {
        width: auto;
        text-align: center;  min-height: inherit; padding:30px 15px;}

        .adBlock2_text_con button{ margin: 0 auto; margin-top: 20px;}
    }

    @media only screen and (max-width: 991px){

        .adBlock2_text h2 {
            font-size: 30px;
            line-height: 35px;
        }

        .adBlock2_text_con button {
            font-size: 26px;
            line-height: 30px;
        }
    
    }

 
