.patner_back_wrap{
    background-color: white;
}

.patner_main_wraper{
    background-image: url("../../../../../assets//image/patnerimg.png");
    background-repeat: no-repeat;
    background-position: left top -20px;
    padding-bottom: 30px;
}

.patner_heading_wrap{
    background-image: url("../../../../../assets/image/headbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    
}

.patner_list_wrap {
   font-size: 30px;
   margin-left: 49%;
}

.patner_list_wrap ul li{
    list-style: none;
    background-image: url("../../../../../assets/image/6circle.png");
    background-repeat: no-repeat;
    padding-left: 55px;
    margin-top: 20px;
    font-weight: 600;

}
.patner_inline_list{
    margin: 20px 0 20px -50px;
}

.patner_inline_list  h5{
    
    background-image: url("../../../../../assets/image/6squre.png");
    background-repeat: no-repeat;
     padding-left: 40px;
}

.patner_inline_list  h5:nth-child(2){
    margin-top: 20px;
}

.patner_inline_list{
    font-size: 25px;
    margin-left: 40px;
}