



@font-face {
  font-family: 'MVBoli';
  src: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/font_folder/MVBoli.woff2') format('woff2'),
      url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/font_folder/MVBoli.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}



.landing_page6_container .emp_com button {
  right: 47px;
  top: 50%;
  width: 620px;
  background: linear-gradient(180deg,#ffa100,#f87c01 79%);
    border: 4px solid #fff;
    border-radius: 100px;
    color: #4f2a02;
    font-size: 30px;
    font-weight: 600;
    height: 79px;
    position: absolute;
}


.landing_page6_container .emp_com button {
    right: 47px;
    top: 50%;
    width: 620px;
    
}
.landing_page6_container .brand_testi button, .landing_page6_container .emp_com button {
    background: linear-gradient(180deg,#ffa100,#f87c01 79%);
    border: 4px solid #fff;
    border-radius: 100px;
    color: #4f2a02;
    font-size: 30px;
    font-weight: 600;
    height: 79px;
    position: absolute;
}

.emp_com button {
  background: linear-gradient(180deg,#ffa100,#f87c01 79%);
  border: 4px solid #fff;
  border-radius: 100px;
  color: #4f2a02;
  font-size: 30px;
  font-weight: 600;
  height: 79px;
  position: absolute;
  right: 47px;
    top: 50%;
    width: 620px;
}
.emp_com {position: relative;}
button {cursor: pointer;}

.brand_testi button {
  bottom: 30px;
  left: 550px;
  width: 687px;
  background: linear-gradient(180deg,#ffa100,#f87c01 79%);
  border: 4px solid #fff;
  border-radius: 100px;
  color: #4f2a02;
  font-size: 30px;
  font-weight: 600;
  height: 79px;
  position: absolute;
  right: 47px;
    top: 88%;
    width: 620px;}
.ask_qus button {
  background: linear-gradient(180deg,#ffa100,#f87c01 79%);
  border: 4px solid #fff;
  border-radius: 100px;
  bottom: 30px;
  color: #4f2a02;
  font-size: 24px;
  font-weight: 600;
  height: 79px;
  position: absolute;
  right: 30px;
  width: 550px;
}






















.block4 img {width: 100%;display: block;}

.landing_page6_container {
  background-image: url(../../../../assets//image/fullbackground.webp);
}

.landing_page6_container .preimg {
  width: 100%;
  display: block;
}
.preimg {width: 100%;
  display: block;}
.landing_page6_container div {
  position: relative;
}
.landing_page6_container div button{
  cursor: pointer;
}
.landing_page6_container .block1 button{
  position: absolute;
  bottom: 281px;
  right: 57px;

  width: 328px;
  height: 77px;
  font-size: 40px;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  background: linear-gradient(180deg, rgba(255,149,10,1) 51%, rgba(247,123,1,1) 52%);
}
.landing_page6_container .block2 button{
  position: absolute;
  bottom: 23px;
  right: 532px;
  font-weight: 600;
  /* left: 0; */
  font-size: 30px;
  border-radius: 100px;
  border: 4px solid white;
  height: 75px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  width: 666px;
}
.landing_page6_container .block3 button{
  position: absolute;
  bottom: -39px;
  left: 123px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 100px;
  z-index: 1;
  border: 4px solid white;
  height: 79px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  width: 687px;
}
.landing_page6_container .block4 button{
  position: absolute;
  bottom: 23px;
  right: 76px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 100px;
  border: 4px solid white;
  height: 80px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  width: 655px;
}

.brand_testi{
 position: relative;

}

.landing_page6_container .brand_testi button{
  position: absolute;
  bottom: 30px;
  left: 550px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 100px;
  border: 4px solid white;
  height: 79px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  width: 687px;
  color: rgb(79,42,2);
}

.landing_page6_container .emp_com button{
  width: 620px;
  position: absolute;
  top: 50%;
  right: 47px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 100px;
  border: 4px solid white;
  height: 79px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  color: rgb(79,42,2);
}

.landing_page6_container .ask_qus button{
  width: 550px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-weight: 600;
  font-size: 24px;
  border-radius: 100px;
  border: 4px solid white;
  height: 79px;
  background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  color: rgb(79,42,2);
}
.ask_qus {position: relative;}

.landpage_videowrapper {padding: 5px;background-color: #1a5b7f;width: calc(76% - 43px);margin-top: 15px;}
.landpage_videowrapper .landpage_video_subwrapper {position: relative;overflow: hidden;width: 100%;padding-top: 56.25%;}
.landpage_videowrapper .landpage_video_subwrapper iframe {position: absolute;top: 0;left: 0;bottom: 0;right: 0;width: 100%;height: 100%;}
.video_img img {display: block;width: 100%;}
.video_img {width:73%;}

/* <-----------------CSS By Sanket-----------------> */

/* .landingpage6_common_width {width: 1800px;margin: 0 auto;} */

.landing_page6_bannerblock {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/landingpage6_bannergirl.webp), 
  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_banner_bg1.png),
  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_banner_bg2.jpg);
  background-repeat: no-repeat;
  background-size: auto 71%,auto, cover;
  padding: 45px 0;
  background-position: right 426px bottom 190px, right 590px top 233px, center center;
}
.landing_page6_bannerblock .form_title_wrapper h2{
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-weight: var(--main-font-Bold);
  position: relative;
  z-index: 1;
  text-shadow: 0px 2px 6px rgba(0,0,0,0.3);
}
.landing_page6_bannerblock .form_title_wrapper h4{
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  font-weight: var(--main-font-Bold);
  position: relative;
  z-index: 1;
  text-shadow: 0px 2px 6px rgba(0,0,0,0.3);
}
.landing_page6_bannerblock .landing-banner-right {
  width: 422px;
margin-left: 100px;
}
.landing_page6_bannerblock .banner_footer{
  background-color: #fff;
  padding: 26px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  margin-top: 20px;
  }
.landing_page6_bannerblock .landing-banner-left {
  width: calc(100% - 522px);
      display: flex;
flex-direction: column;
justify-content: space-between;
}
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 49px;    line-height: 61px;
  font-weight: var(--main-font-Bold);background: linear-gradient(180deg, rgba(13,77,123,1) 40%, rgba(5,40,68,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));
}
.landing_page6_bannerblock .main_heading_wrapper {width: 73%;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 38px;line-height: 38px;font-weight: bold;text-transform: uppercase;filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));}
.landing_page6_bannerblock .sub_heading_wrapper h3 span {font-weight: 500;filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));}
.gradient_bluetext {background: linear-gradient(180deg, rgba(5,172,229,1) 39%, rgba(2,142,233,1) 90%); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient_redtext {background: linear-gradient(180deg, rgba(234,42,0,1) 26%, rgba(177,35,4,1) 89%);-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing_page6_bannerblock .main_heading_wrapper > div {padding: 7px 30px 8px 0;background: linear-gradient(90deg, rgb(253 254 251) 50%, rgb(245 251 240 / 75%) 70%);position: relative;}
.landing_page6_bannerblock .main_heading_wrapper > div::after {content: '';position: absolute;height: 100%;width: 30px;right: -30px;background-image: 
  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_heading_smallpc.png);top: 0;
  background-repeat: no-repeat;
  background-size: 100%;
}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 30px;font-family: 'MVBoli';line-height: 42px;font-weight: 500;}
.landing_page6_bannerblock .signature_textwrapper h2:nth-of-type(2) {padding-left: 180px;}
.landing_page6_bannerblock .sub_heading_wrapper {margin-top: 20px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading {width: 32%;
  border-top-left-radius: 7px;border-top-right-radius:7px; background: linear-gradient(90deg, rgba(47,49,51,1) 26%, rgba(14,54,85,1) 89%);padding: 10px 20px;    position: relative;
  right: -30px;}
  .landing_page6_bannerblock .employer_points_wrapper {margin-top: 22px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 34px;font-weight: 400;line-height: 34px;color: #fff;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 22px;font-weight: 500;color: #fff;padding-left: 55px;margin-bottom: 5px;position: relative;text-shadow: 0px 1px 5px #1c8dbd;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper {padding: 15px 50px 44px 13px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_employer_wholebg.webp);background-repeat: no-repeat;background-size: 100% 100%;width: 90%;border-radius: 7px;position: relative;}
.blue_bullets::after {content: '';position: absolute;height: 15px;width: 15px;background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_bannerblue_bullets.png);background-repeat: no-repeat;background-size: 100%;background-position: center;left: 25px;top: 9px;}
.landing_page6_bannerblock .banner_only_para {font-size: 23px;font-weight: 600;color: #0d5e83;margin-top: -10px;}
.landing_page6_bannerblock .land_arrow_wrapper {background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_arrowimg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 42px 45px 42px 39px;
  position: relative;
}
.landing_page6_bannerblock .land_arrow_wrapper h2{font-size: 35px;font-weight: 900;background: linear-gradient(180deg, rgba(255,255,255,1) 45%, rgba(236,236,236,1) 53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;    line-height: 35px;
  letter-spacing: -1px;    text-transform: uppercase;    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.4));font-style: italic;
}
.landing_page6_bannerblock .land_arrow_wrapper:after {    content: "";
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_arrow_shadow.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 30px;
  position: absolute;
  left: -7px;
  top: -13px;
}
.landing_page6_bannerblock #userForm6 .state svg{background: #cccfd0;}


.landing_page6_bannerblock .banner_footer .prcntg_wrapper {padding: 25px;position: relative;    width: 33.33%;
  display: flex;
  align-items: center;    border-radius: 40px 0 0 40px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {height: 132px;width: 132px;border-radius: 50%;position: absolute;    left: -35px;
  transform: translateY(-50%);top: 50%;    display: flex;
  justify-content: center;
  align-items: center;    box-shadow: 3px 6px 6px #6e6e6e;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {height: 100px;width:100px;border-radius: 50%;    display: flex;
  justify-content: center;
  align-items: center;background: linear-gradient(180deg, rgba(246,249,249,1) 34%, rgba(230,237,239,1) 66%);    box-shadow: 1px 1px 8px #6e6e6e;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) {background: linear-gradient(61deg, rgba(255,202,27,1) 47%, rgba(255,170,12,1) 73%);width: 32%;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) {background: linear-gradient(61deg, rgba(170,220,43,1) 47%, rgba(129,180,64,1) 73%);
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) {background: linear-gradient(61deg, rgba(12,209,254,1) 47%, rgba(74,175,254,1) 73%);border-top-right-radius: 50px;border-bottom-right-radius: 50px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper {display: flex;justify-content: center;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {font-size: 36px;font-weight: 700;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {font-size: 25px;font-weight: 600;color: #ffffff;    line-height: 30px;
  text-transform: uppercase;    margin-left: 88px;    text-shadow: 0px 1px 7px #878787;


}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .circle_main_wrap {background-color: #ffd709;left: -10px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p {     margin-left: 117px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .circle_main_wrap {background-color: #a9f01f;    left: -29px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p {margin-left: 95px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .circle_main_wrap {background-color: #0eedfe;left: -29px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {margin-left: 96px;}




.page6_block1 .landingpage6_common_width {
  padding: 0px 0 45px;
}
.common_blue_text {font-size: 35px;    line-height: 39px;
  font-weight: 700;    text-transform: uppercase;text-align: center;
  background: linear-gradient(180deg, rgba(14,83,131,1) 21%, rgba(5,41,68,1) 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;}

.page6_block1 .block1_wrapper {padding: 18px;    background-color: #fff;box-shadow: 0 0 8px rgb(0 0 0 / 30%);border-radius: 7px;}
.page6_block1 .padding_block {padding-left: 700px;}
/* .count_2 {column-count: 2;} */
.page6_block1 .flex_column {display: flex;}
.page6_block1 .flex_column p:first-child {width: 35%;}
.page6_block1 .red_square_text p {margin: 5px 0;margin-left: 45px;font-size: 24px;line-height: 30px;font-weight: var(--main-font-Medium);}
.page6_block1 .sky_gradient {margin-bottom: 15px;}
.page6_block1 .green_circle_text h3 {
  font-size: 28px;
  line-height: 34px;
font-weight: var(--main-font-SemiBold);}
  /* .page6_block1 .count_2 p {margin-top: 0;} */

  .page6_block1 {background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block1_leftimg.webp);background-repeat: no-repeat;    background-position: left -200px bottom -120px;
  }
  .page6_block1 .textwrapper6 {position: relative;}
  .page6_block1 .textwrapper6 .block1_mobile {position: absolute;top: 0;
    left: -230px;}
  .page6_block1 .orange_button button {margin: 0;}
  .page6_block1 .orange_button {margin-top: 25px;}


  .sub_heading_common {font-size: 30px;font-weight: var(--main-font-SemiBold);}
  .orange_text {color: #f87c01;}
  .page6_block2 .block2_card_headng {padding: 10px;background-color: #e9eef1;border-radius: 7px;}
  .page6_block2 .page6_block2_wrapp {background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block2_rightimg.webp), url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block2_laptopleft.webp), #fff;background-repeat: no-repeat;background-size: auto, auto, cover;    background-position: right -70px top -110px, left -10px bottom, center;
    padding: 15px;box-shadow: 0 0 8px rgb(0 0 0 / 30%);border-radius: 7px;}
  .page6_block2 .block2_cards_wrapper {    display: flex;
    justify-content: space-between;align-items: flex-start;}
   .page6_block2 .block2_card {width: 49.5%;padding: 20px;background-color: #fff;box-shadow: 0 0 8px rgb(0 0 0 / 30%);border-radius: 7px;}
  .page6_block2 .card_innercontnt_wrapper {padding: 15px;}
.page6_block2 .common_blue_text {text-align: left;    font-size: 36px;    display: inline;}
.page6_block2 .red_square_text p{font-size: 22px;
  line-height: 28px;font-weight: var(--main-font);}
 .page6_block2 .green_circle_text h3 {
    font-size: 25px;
    line-height: 33px;
    font-weight: var(--main-font-SemiBold);
 }
 .page6_block2 .card_blue_blck {    padding: 13px 21px;
  background-color: #07c7f1;border-radius: 7px;margin-top: 25px;background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_blueblock_littleimg.png);background-repeat: no-repeat;
  background-position: right 15px center;}
 .page6_block2 .card_blue_blck h5 {font-size: 22px;font-weight: var(--main-font-SemiBold);color: #fff;}
 .page6_block2 {position: relative;}
 .page6_block2 .orange_button {position: absolute;bottom: -45px;left: 180px;    z-index: 9; }
 .page6_block2 .block2_card_down {margin-top: 385px;}



 .page6_block3 .sky_gradient {width: 35%;margin: 60px 0 20px;}
  .page6_block3 .common_blue_text {text-align: left;    font-size: 40px;
    }
  .page6_block3 .textwrapper6 {margin-top: 30px;}
  .page6_block3 .block3_top_sec {position: relative;}
  .page6_block3 .page6_block3_innerwrapper .block3_lady {position: absolute;right: 0;width: 542px;
    bottom: -20px;}
  .page6_block3 .block3_top_sec .block3_multiimg {position: absolute;    right: 395px;
    top: -15px;
    width: 30%;
}
.page6_block3 .page6_block3_innerwrapper {padding: 75px 0 40px;}

.bottom_card .card_top_sec {padding: 25px 30px;border-radius: 20px;    margin-left: -15px;
}

.bottom_card .card_bottom_inner p{font-size: 22px;font-weight: var(--main-font);position: relative;    padding-left: 25px;    line-height: 29px;}
.block3_bottom_sec {display: flex;}
.bottom_card {width: calc(21% - 4px);    background: linear-gradient(90deg, rgba(212,220,223,0.8547794117647058) 0%, rgba(254,254,251,0.5942752100840336) 25%);    margin-left: 15px;
}
.card_bottom_inner p::before {
  content: '';
  border: 3px solid #f77b01;
  background: #fff;
  width: 8px;
  height: 8px;
  left: 0;
  top: 8px;
  position: absolute;
background-size: 100%;
}
.block3_bottom_sec .bottom_card:not(:first-child) {margin-left: 85px;}
.card_bottom_inner p {margin-top: 15px;}
.bottom_card .card_bottom_inner {
  padding: 0 20px 50px;

  }
  .bottom_card .card_top_inner h3 {font-size: 27px;font-weight: var(--main-font-SemiBold);line-height: 27px;}
.bottom_card .card_top_inner h4 {font-size: 36px;font-weight: var(--main-font-SemiBold);line-height: 36px;}
.bottom_card .card_top_inner h5 {font-size: 30px;font-weight: var(--main-font-Medium);color: #686868;margin-top: 10px;line-height: 30px;}
.bottom_card .card_top_inner h5 span {display: inline-block;}
.block3_bottom_sec .bottom_card:nth-of-type(1) .card_top_sec {background: linear-gradient(55deg, rgba(250,169,5,1) 34%, rgba(248,193,5,1) 66%);}
.block3_bottom_sec .bottom_card:nth-of-type(2) .card_top_sec {background: linear-gradient(55deg, rgba(81,195,79,1) 34%, rgba(30,210,29,1) 66%);}
.block3_bottom_sec .bottom_card:nth-of-type(3) .card_top_sec {background: linear-gradient(55deg, rgba(22,119,146,1) 34%, rgba(29,147,181,1) 66%);}
.block3_bottom_sec .bottom_card:nth-of-type(4) .card_top_sec {background: linear-gradient(55deg, rgba(9,135,243,1) 34%, rgba(9,168,237,1) 66%);}

.bottom_card .card_top_sec .card_top_inner {padding: 25px 50px;border-radius: 10px;width: 390px;box-shadow: 0 0 8px rgb(0 0 0 / 30%);background-repeat: no-repeat;
  background-size: 8%, 16%;
  background-position: left center, right 15px top 10px;
background-color: #fff;    display: flex;
align-items: flex-start;
flex-direction: column;
justify-content: center;
min-height: 190px;}


.bottom_card:nth-of-type(1) .card_top_sec .card_top_inner {background-image:  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_card1_leftarrow.png), url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_first_rckt.png);}
.bottom_card:nth-of-type(2) .card_top_sec .card_top_inner {background-image: 
  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_card2_leftarrow.png), 
  url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_scnd_thrd_clndr.png)
  }
.bottom_card:nth-of-type(3) .card_top_sec .card_top_inner {background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_card3_leftarrow.png), url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_scnd_thrd_clndr.png)}
.bottom_card:nth-of-type(4) .card_top_sec .card_top_inner {background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_card4_leftarrow.png.png), url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_frth_live.png)}


.bottom_card .card_bottom_inner > span {font-size: 20px;font-weight: var(--main-font);display: block;margin: 15px;
  line-height: 20px;
  margin-left: 25px;
  padding-left: 15px;position: relative;}
  .bottom_card .card_bottom_inner > span::before {content: '';position: absolute;height: 12px;width: 12px;background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_solidash_bullet.png); background-repeat: no-repeat;background-size: 100%;background-position: center;left: -5px;
    top: 4px;
  }
.page6_block3 .cstmr_sprt_sec {display: flex;    align-items: center;
  background-color: #fff;padding: 15px 20px;border-radius: 7px;box-shadow: 0 0 8px rgb(0 0 0 / 30%);    margin-top: 2px;}
  .page6_block3 .cstmr_sprt_sec .customer_sec_wrp {display: flex;}
.page6_block3 .cstmr_sprt_sec .employee_sec {display: flex;    align-items: center;margin-right: 20px;}
.page6_block3 .cstmr_sprt_sec .employee_sec .common_blue_text {font-size: 30px;text-transform: capitalize;font-weight: var(--main-font-SemiBold);}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {font-size: 25px;font-weight: var(--main-font-SemiBold);}
.page6_block3 .cstmr_sprt_sec .employee_img {padding: 12px;background-color: #e0eaee;border-radius: 7px;}
.page6_block3 .cstmr_sprt_sec .employee_img img{display: block;width: 100%;}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc {margin-left: 12px;}
.page6_block3 .cstmr_sprt_sec .employee_sec:nth-of-type(2) {padding-left: 25px;border-left: 2px solid #919ea3;}
.page6_block3 .cstmr_sprt_sec .orange_button {margin-top: 0;    margin-left: 2%;
}


/* .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .circle_sub_wrap
/* <-----------------CSS By Sanket-----------------> */



/* /-----------css by subhashis-------/ */
.landingpage6_common_width {width: 1800px;margin: 0 auto;}
.page6_block4 {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/UKG_kronos_img.webp);
background-repeat: no-repeat;background-position: left -40px bottom;padding-bottom: 170px;}
.padding_block {padding-left: 865px;}
.sky_gradient h2 {font-size: 40px;font-weight: 700;line-height: 46px;text-align: center;text-transform: uppercase;
  background: linear-gradient(180deg, rgba(254,255,255,1) 22%, rgba(227,235,238,1) 62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;filter: drop-shadow(0px 0px 2px #333);

}
.sky_gradient {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/sky_gradient_bg.webp);background-repeat: no-repeat;background-size: 100% 100%;padding:20px;padding-bottom: 25px;}
.green_circle_text h3 {font-size: 30px;line-height: 35px;color: #000;font-weight: 500;margin-bottom: 20px;position: relative;padding-left: 45px;}
.green_circle_text h3:last-child {margin-bottom: 0;}
.green_circle_text h3 span {color: #f87c01;}
.green_circle_text h3::before {content: '';background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/green_circle_img.webp);background-repeat: no-repeat;background-position: left top;position: absolute;width: 32px;height: 32px;left: 0;top: 0;background-size: 100%;}
.red_square_text p {font-size: 25px;line-height: 31px;color: #000;font-weight: 600;position: relative;padding-left: 30px;margin: 20px 0;margin-left: 45px;}
.red_square_text p::before {content: '';border: 3px solid #f77b01;background: #fff;width: 8px;height: 8px;left: 0;top: 8px;position: absolute;}
.textwrapper6 {margin-top: 20px;}
.page6_block4 .textwrapper6 .hand_mobile {position: absolute;left: -250px;top: 0;}
.page6_block4 .textwrapper6 {position: relative;}
.box_text_wrp h3 {font-size: 25px;line-height: 30px;color: #fff;font-weight: 600;text-transform: uppercase;text-align: center;}
.box_text_wrp {background: #094b5e;padding: 14px 10px;width: 85%;
  margin: 0 auto;border-radius: 6px 6px 0px 0;}
.block6_flex {display: flex;flex-wrap: wrap;}
.block5_box_wrp {width: 25%;margin: 10px 0;}
.block6_logo_wrp img {display: block;}
.block6_logo_wrp {display: flex;justify-content: center;align-items: center;background: #fff;border: 1px solid #000;height: 280px;}
.block5_inner_wrp {padding: 25px 70px;background: linear-gradient(180deg, rgba(222,236,239,1) 47%, rgba(232,243,245,1) 73%);
border: 1px solid #d3e2e5;border-radius: 6px;}
.orange_button button {background: linear-gradient(180deg, rgba(255,161,0,1) 0%, rgba(248,124,1,1) 79%);
  width: 696px;
  color: rgb(79,42,2);border-radius: 100px;    font-weight: 600;
  font-size: 30px;    border: 4px solid white;padding: 20px;display: block;
  margin: 0 auto;box-shadow: 0px 4px 11px 2px #c5ccce;cursor: pointer;}
  .orange_button {margin-top: 15px;}
.page6_logo_block {margin-top: -105px;}

.block5_flex {display: flex;justify-content: space-between;align-items: flex-end;}
.communication_block {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/communication_block_bg.webp);background-repeat: no-repeat;background-position: right bottom;padding-bottom: 80px;padding-top: 40px;}
.bold_black_text {font-weight: 600;color: #000 !important;}
.communication_block .red_square_text p {font-weight: 500;}
.communication_block .sky_gradient {width: 85%;margin-bottom: 15px;}
.communication_block .red_square_text p {font-size: 22px;line-height: 28px;}
.communication_block .textwrapper6 {width: 44%;margin-top: 0;}


.help_clients_block {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/problems_help_block_img.webp);background-repeat: no-repeat;background-position: left 10% bottom;padding: 40px 0;margin-top: -80px;}
.help_clients_block .block6_flex {justify-content: space-between;flex-wrap: nowrap;}
.help_clients_block .red_square_text p {font-weight: 500;margin-left: 0;margin: 15px 0;}
.help_clients_block h4 {font-size: 30px;line-height: 35px;color: #000;font-weight: 600;}
.help_clients_block .textwrapper6 {margin-top: 0;width: 60%;}
.solution_wrp, .goals_wrp {width: 49.5%;position: relative;padding-left: 20px;}
/* .goals_wrp {width: 48%;position: relative;padding-left: 20px;} */
.goals_wrp::before, .solution_wrp::before {position: absolute;content: '';width: 2px;height: 90%;background-color: #000;left: 0;top: 10px;}
.block6_bottom {margin-top: 10px;}
.landingpage6_img_wrp {margin-left: 30px;}


.cards_block_inner {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/landingpage6_block7_img.webp);background-repeat: no-repeat;background-position: left top;background-size: cover;padding: 30px;border: 1px solid #fff;
  border-radius: 6px;}
.cards_block_inner h2 {font-size: 40px;line-height: 46px;font-weight: 700;text-transform: uppercase;background: linear-gradient(180deg, rgba(14,83,131,1) 21%, rgba(5,41,68,1) 64%); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;margin-bottom: 20px;}
.block7_gradient_block {width: 19.6%;padding: 25px;display: flex;
  align-items: center;background-repeat: no-repeat;background-position: right 12px top 15px, left top;border-radius: 6px;padding-right: 85px;height: 140px;}
.cards_block_inner .block6_flex {justify-content: space-between;}
.block7_gradient_block h3 {font-size: 25px;line-height: 30px;color: #fff;font-weight: 600;text-shadow: 0px 0px 3px #898888;}
.block7_gradient_block:nth-child(1) {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/visa_card_img.webp), linear-gradient(90deg, rgba(255,192,0,1) 36%, rgba(255,140,0,1) 64%);border: 1px solid #ff8c00;}
.block7_gradient_block:nth-child(2) {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/leader_img.webp),  linear-gradient(90deg, rgba(255,114,0,1) 36%, rgba(251,42,0,1) 64%);border: 1px solid #fb2900;}
.block7_gradient_block:nth-child(3) {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/consumer_img.webp),linear-gradient(90deg, rgba(232,36,83,1) 36%, rgba(179,38,133,1) 64%);border: 1px solid #b32585; }
.block7_gradient_block:nth-child(4) {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/orders_img.webp), linear-gradient(90deg, rgba(142,60,242,1) 36%, rgba(62,97,214,1) 64%);border: 1px solid #3f5fd7; }
.block7_gradient_block:nth-child(5) {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/buyers_img.webp), linear-gradient(90deg, rgba(0,136,255,1) 36%, rgba(0,122,219,1) 64%);border: 1px solid #007bdd; }

.ques_ans_block {background: #fff;padding: 30px;border-radius: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;margin-top: 15px;}
.ques_ans_block h3 {font-size: 25px;line-height: 31px;background: #eaeef1;color: #000;font-weight: 600;padding: 16px 20px;border-radius: 8px;}
.ques_ans_block p {font-size: 22px;line-height: 28px;color: #000;font-weight: 500;position: relative;padding-left: 15px;margin: 15px 0 30px 0;}
.ques_ans_block p::before {content: '';position: absolute;width: 5px;height: 90%;left: 0;top: 4px;background: #c6ccd1;}
.faq_block {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/FAQ_block_img.webp);background-repeat: no-repeat;background-position: right bottom;padding: 30px 0;}
.faq_block .textwrapper6 {margin-top: 0px;width: 61%;}
.ques_ans_block p span {color: #007eff;word-break: break-word;}
.ques_ans_block p:last-child {margin-bottom: 0;}
.faq_block .sky_gradient h2 {text-align: left;}
.block7_flex {display: flex;justify-content: space-between;align-items: flex-end;}
.block7_flex .orange_button button {width: fit-content;padding: 20px 50px;box-shadow: none;}

.video_page_block1 .employer_points_wrapper .employer_heading {
  width: 32%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: linear-gradient(90deg, rgba(47,49,51,1) 26%, rgba(14,54,85,1) 89%);
  padding: 10px 20px;
  position: relative;
  right: -30px;
}
.video_page_block1 .employer_points_wrapper .employer_heading p {
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  color: #fff;
}
.video_page_block1 .employer_points_wrapper .employer_bullet_wrapper {
  padding: 20px 15px 30px 13px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/sky_rectangle_bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 7px;
  position: relative;
}
.video_page_block1 .employer_points_wrapper .employer_bullet_wrapper p {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  padding-left: 55px;
  margin-bottom: 5px;
  position: relative;
  text-shadow: 0px 1px 5px #1c8dbd;
}
.video_page_block1_inner {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/landing_page6_employer_block_img.webp),linear-gradient(90deg, rgba(255,255,255,0) 27%, rgba(255,255,255,1) 54%);background-repeat: no-repeat;background-position: right bottom -40px,right top;padding: 30px 0;padding-right: 705px;
  border-radius: 10px;
  /* / box-shadow: 0 0 8px rgb(0 0 0 / 30%); / */
}
.video_page_block1_inner h3 {font-size: 28px;line-height: 34px;color: #05406f;font-weight: 600;margin-top: 20px;}
.video_page_block1 {margin-bottom: 30px;}
/* 
.whole_wrapper {background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page6/whole_bg.webp);background-repeat: no-repeat;background-position: left top;background-size: 100% 100%;} */


@media only screen and (max-width: 1899px) {
.help_clients_block {background-position: left -3% bottom;}

}
@media only screen and (max-width: 1858px) {
  .landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 42px;line-height: 52px;}
  .landing_page6_bannerblock .signature_textwrapper h2 {font-size: 27px;line-height: 37px;}
.blue_bullets::after {left: 17px;top: 7px;}
/* .landing_page6_bannerblock .banner_only_para {margin-top: 0;} */
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 34px;line-height: 34px;}
  .landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 19px;padding-left: 45px;margin-bottom: 3px;}
    .landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 30px;line-height: 30px;}
      .landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 30px;}
      .landing_page6_bannerblock {background-position: right 346px bottom 180px, right 590px top 233px, center center;}
      .landing_page6_bannerblock #userForm6 .formfild {margin-bottom: 8px;} 

      .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p {margin-left: 110px;}
    .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {font-size: 21px;line-height: 25px;}
    .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {
      font-size: 28px;
  }
  .landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {height: 122px;width: 122px;}
  .landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {height: 90px;width: 90px;}

  .common_blue_text {font-size: 31px;line-height: 35px;}
  .page6_block1 .sky_gradient h2 {font-size: 34px;line-height: 40px;}
  .page6_block1 .green_circle_text h3 {font-size: 26px;line-height: 32px;}
  .page6_block1 {  background-position: left -200px bottom -140px;
  }
  .page6_block1 .padding_block {
    padding-left: 40%;
  }

  


}

@media only screen and (max-width: 1840px) {
.landingpage6_common_width {width: 94%;}

.page6_block2 .orange_button button {
  width: fit-content;
  font-size: 28px;
}


.bottom_card .card_top_sec .card_top_inner {width: initial;}
.page6_block3 .cstmr_sprt_sec .employee_sec .common_blue_text {
  font-size: 28px;}
  .page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {
    font-size: 22px;}
    .bottom_card .card_top_inner h5 {
      font-size: 26px;}

.bottom_card .card_top_inner h4 {
font-size: 32px;
line-height: 34px;
}
.page6_block3 .cstmr_sprt_sec {justify-content: space-between;}
.page6_block3 .cstmr_sprt_sec .orange_button button {width: fit-content;}

.video_page_block1 .employer_points_wrapper .employer_heading {width: 400px;}
  .video_page_block1 .employer_points_wrapper .employer_bullet_wrapper p {font-size: 18px;line-height: 24px;padding-left: 40px;}
  .video_page_block1 .blue_bullets::after {left: 17px;top: 4px;}

}
@media only screen and (max-width: 1820px) {
.block6_logo_wrp img {display: block;width: 90%;}
.sky_gradient h2 {font-size: 30px;line-height: 36px;}
}
@media only screen and (max-width: 1799px) {
  .block7_gradient_block {padding-left: 10px;background-size: 50px, auto;height: auto;}
  .block7_gradient_block h3 {font-size: 18px;line-height: 24px;}
}
@media only screen and (max-width: 1758px) {
  .landing_page6_bannerblock .banner_only_para {font-size: 19px;}
  .landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 28px;}  

  .page6_block1 .sky_gradient h2 {
    font-size: 30px;
    line-height: 36px;
}
.page6_block1 .green_circle_text h3 {
  font-size: 24px;
  line-height: 30px;
}
.page6_block1 .red_square_text p {
  font-size: 20px;
  line-height: 28px;}
  .common_blue_text {
    font-size: 28px;
    line-height: 32px;
  }.page6_block1 {
    background-position: left -220px bottom -180px;

}
}

@media only screen and (max-width: 1740px) {
.green_circle_text h3 {font-size: 25px;line-height: 31px;margin-bottom: 10px;}

.box_text_wrp h3 {font-size: 20px;line-height: 25px;}
.red_square_text p {font-size: 20px;line-height: 26px;margin: 10px 0;margin-left: 45px;}
.page6_block4 {background-position: left -170px bottom;}
.padding_block {padding-left: 54%;}
.communication_block {background-position: right -130px bottom;}

.page6_block1 .green_circle_text h3 {
  font-size: 22px;
  line-height: 28px;    margin-bottom: 15px;

}
.page6_block1 .green_circle_text h3::before {width: 25px;height: 25px;left: 6px;top: 2px;}


.page6_block2 .red_square_text p {
  font-size: 19px;
  line-height: 25px;}
  .page6_block2 .red_square_text p::before {top: 5px;}
  .page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
    font-size: 23px;}
    .page6_block2 .page6_block2_wrapp {
      background-position: right -130px top -120px, left -50px bottom, center;
    }
      .page6_block2 .block2_card_down {
        margin-top: 23%;
    }

    .page6_block3 .common_blue_text {
      font-size: 36px;
  }
  .page6_block3 .block3_top_sec .block3_multiimg {
    right: 365px;
    top: 15px;
    width: 24%;
}
.page6_block3 .page6_block3_innerwrapper .block3_lady {
  right: 0;
  width: 472px;
  bottom: -20px;
}
.bottom_card .card_top_inner h5 {
  font-size: 22px;line-height: 26px;
}
.bottom_card .card_top_inner h4 {
  font-size: 28px;
  line-height: 30px;
}
.bottom_card .card_top_inner h3 {
  font-size: 22px;
  line-height: 24px;
}
.bottom_card .card_bottom_inner {
  padding: 0 20px 30px;
}
.bottom_card .card_top_sec {
  padding: 20px 25px;}
  .bottom_card .card_top_sec .card_top_inner {
    padding: 20px 40px;}
}
@media only screen and (max-width: 1699px) {
  .landing_page6_bannerblock {background-position: right 315px bottom 170px, right 590px top 233px, center center;}
.ques_ans_block h3 {font-size: 22px;line-height: 28px;padding: 10px 20px;}
.ques_ans_block p {font-size: 18px;line-height: 24px;}
.faq_block {background-position: right -200px bottom;}
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 38px;line-height: 48px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 25px;line-height: 35px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 30px;line-height: 30px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 25px;line-height: 25px;}
.blue_bullets::after {left: 12px;top: 5px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 16px;padding-left: 35px;margin-bottom: 7px;}
.landing_page6_bannerblock .sub_heading_wrapper {margin-top: 25px;}
.landing_page6_bannerblock .employer_points_wrapper {margin-top: 30px;}

.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {
  height: 112px;
  width: 112px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {
  height: 80px;
  width: 80px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p {
  margin-left: 90px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {
  font-size: 24px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 20px;
  line-height: 24px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {
 
  margin-left: 70px;
}
}
@media only screen and (max-width: 1640px){
  .page6_block1 .sky_gradient h2 {
    font-size: 26px;
    line-height: 32px;
}
.page6_block1 .green_circle_text h3 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 12px;
}
.page6_block1 .red_square_text p {
  font-size: 17px;
  line-height: 25px;
}
.page6_block1 .red_square_text p::before {top: 7px;}
.page6_block1 {
  background-position: left -230px bottom -210px;

}
}
@media only screen and (max-width: 1620px){
  .landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 24px;line-height: 30px;}
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 32px;line-height: 42px;}
.landing_page6_bannerblock .land_arrow_wrapper:after {
  height: 15px;
  left: 7px;
  top: 1px;
  background-size: contain;
}
.page6_block3 .cstmr_sprt_sec .employee_sec .common_blue_text {
  font-size: 25px;
}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {
  font-size: 19px;
}
.page6_block3 .cstmr_sprt_sec .orange_button button {font-size: 27px;}
}
@media only screen and (max-width: 1548px){
  .landing_page6_bannerblock .banner_only_para {font-size: 17px;}
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 30px;line-height: 40px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 23px;line-height: 33px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 26px;}
.landing_page6_bannerblock .sub_heading_wrapper {margin-top: 15px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {margin-bottom: 10px;line-height: 20px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading {width: 35%;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper {padding: 22px 50px 44px 13px;}
  .blue_bullets::after {background-size: 85%;left: 12px;top: 2px;}
  .landing_page6_bannerblock .land_arrow_wrapper {    padding: 36px 45px 36px 39px;}
  .landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 22px;line-height: 26px;}

  .page6_block1 .red_square_text p {    margin-left: 35px;
    padding-left: 25px;
  }

  .page6_block2 .card_blue_blck h5 {
    font-size: 20px;}
    .page6_block2 .card_blue_blck {padding-right: 20%;}
    .page6_block2 .common_blue_text {
      font-size: 30px;}
      .sub_heading_common {
        font-size: 26px;}
        .page6_block2 .green_circle_text h3 {
          font-size: 23px;}
         .page6_block2 .green_circle_text h3::before {
            width: 25px;
            height: 25px;
            top: 4px;}
          .page6_block2 .green_circle_text h3 {padding-left: 35px;}



          .bottom_card .card_bottom_inner p {
            font-size: 20px;
           
            line-height: 28px;
        }
        .bottom_card .card_bottom_inner > span {
          font-size: 17px;
         
          margin: 12px;
          line-height: 18px;}
          .bottom_card .card_bottom_inner {
            padding: 0 15px 25px;
        }
        .card_bottom_inner p {
          margin-top: 13px;
      }
      .bottom_card .card_bottom_inner > span::before {top: 2px;}
}
@media only screen and (max-width: 1540px){
.communication_block {background-position: right -250px bottom;}
.landingpage6_img_wrp {margin-left: 0;width: 11%;}
.landingpage6_img_wrp img {width: 100%;display: block;}
.help_clients_block {background-position: left -15% bottom;}
.block7_flex .orange_button button {padding: 15px 25px;font-size: 22px;}
.landing_page6_bannerblock .land_arrow_wrapper:after {left: 5px;top: -1px;background-size: contain;}
.common_blue_text {
  font-size: 25px;
  line-height: 30px;
}
.page6_block1 {
  background-position: left -320px bottom -210px;
}

}

@media only screen and (max-width: 1499px){
.block7_gradient_block {padding-right: 50px;}
/* .page6_block1 {
  background-position: left bottom -70px, center;
} */
.page6_block1 .orange_button button {width: fit-content;}

.page6_block3 .common_blue_text {
  font-size: 32px;
}
.bottom_card .card_top_sec .card_top_inner {
  padding: 20px 35px;min-height: 160px;
}
.bottom_card .card_top_sec {
  padding: 20px;
}

.landpage_videowrapper {

  width: calc(92% - 43px);}

}

@media only screen and (max-width: 1460px) {
.block5_inner_wrp {padding: 25px 30px;}
.box_text_wrp {padding: 10px;}
.box_text_wrp h3 {font-size: 16px;line-height: 21px;}
.block6_logo_wrp {height: 240px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 21px;line-height: 27px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 22px;line-height: 26px;}
.landing_page6_bannerblock .banner_only_para {
  font-size: 15px;
}


.bottom_card .card_bottom_inner p {
  font-size: 18px;
  line-height: 26px;
}
.page6_block3 .cstmr_sprt_sec .employee_img {width: 15%;padding: 10px;}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {
  font-size: 17px;
}
.page6_block3 .cstmr_sprt_sec .employee_sec {margin-right: 0;}
.page6_block3 .green_circle_text h3 {
  font-size: 21px;
  line-height: 27px;    padding-left: 35px;

}
.page6_block3 .common_blue_text {
  font-size: 28px;
}
.page6_block3 .textwrapper6 {
  margin-top: 22px;
}
.page6_block3 .sky_gradient {
  margin: 45px 0 20px;
}
.page6_block3 .page6_block3_innerwrapper .block3_lady {
  width: 432px;
}
.page6_block3 .block3_top_sec .block3_multiimg {
  right: 335px;}
  .block3_bottom_sec .bottom_card:not(:first-child) {
    margin-left: 65px;
} 
.page6_block3 .block3_top_sec .sky_gradient h2 {
  font-size: 28px;
  line-height: 34px;
}
.page6_block3 .green_circle_text h3::before {
  width: 27px;
  height: 27px;
}
}
@media only screen and (max-width: 1440px) {
  .landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {
    height: 92px;
    width: 92px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {
  height: 70px;
  width: 70px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {
  font-size: 21px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p {
  margin-left: 75px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {
  margin-left: 55px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper {
  padding: 15px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 17px;
  line-height: 22px;
}


}
@media only screen and (max-width: 1399px) {
.green_circle_text h3 {font-size: 20px;line-height: 26px;}
.red_square_text p {font-size: 18px;line-height: 23px;}
.page6_block4 .textwrapper6 .hand_mobile {width: 26%;left: -163px;}
.page6_block4 {  background-position: left -170px bottom -20px;}
.orange_button button {width: fit-content;padding: 20px 30px;}
.communication_block {background-position: right -330px bottom;padding-top: 20px;}
.red_square_text p {font-size: 18px !important;line-height: 24px !important;}
.red_square_text p::before {top: 4px;}
.landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 20px;line-height: 22px;}
.landing_page6_bannerblock .land_arrow_wrapper {padding: 30px 45px 30px 20px;}
.landing_page6_bannerblock .formwrapper {padding: 25px;}
/* .landing_page6_bannerblock .landing-banner-right {width: 29%;} */
.landing_page6_bannerblock .form_title_wrapper h2 {font-size: 34px;line-height: 38px;}
.landing_page6_bannerblock .form_title_wrapper h4 {font-size: 22px;line-height: 26px;}
.landing_page6_bannerblock .main_heading_wrapper {width: 85%;}
.landing_page6_bannerblock .signature_textwrapper h2:nth-of-type(2) {padding-left: 130px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading {width: 40%;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 23px;}
  .landing_page6_bannerblock .employer_points_wrapper {margin-top: 20px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 15px;}
  .landing_page6_bannerblock .land_arrow_wrapper:after {top: -4px;}
  .landing_page6_bannerblock {
    background-position: right 315px bottom 160px, right 590px top 233px, center center;background-size: auto 61%,auto, cover;
}
.common_blue_text {
  font-size: 22px;
  line-height: 26px;
}
.page6_block1 .textwrapper6 .block1_mobile {
  position: absolute;
  top: 17px;
  left: -157px;width: 18%;
}
.page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
  font-size: 22px;
}
.page6_block2 .common_blue_text {
  font-size: 28px;
}
.page6_block2 .orange_button button {
  font-size: 25px;
}
.page6_block2 .orange_button {

  left: 150px;
}
.page6_block2 .page6_block2_wrapp {
  background-position: right -120px top -70px, left -40px bottom, center;
  background-size: 111%, 102%, cover;

}
.page6_block2 .red_square_text p {margin-left: 35px;}
.video_page_block1_inner h3 {font-size: 22px;line-height: 28px;}

.landpage_videowrapper {

  width: calc(99% - 43px);}

}
@media only screen and (max-width: 1358px) {
  .landing_page6_bannerblock .banner_only_para {font-size: 13px;margin-top: -25px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper {padding: 22px 50px 54px 13px;}

.page6_block3 .cstmr_sprt_sec .orange_button button {
  font-size: 20px;
}
}
@media only screen and (max-width: 1340px) {
.help_clients_block {background-position: left -30% bottom;}
.landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 17px;line-height: 18px;}
}
@media only screen and (max-width: 1299px) {
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 26px;line-height: 32px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 20px;line-height: 24px;}
.landing_page6_bannerblock .banner_only_para {
  margin-top: -10px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {
  height: 82px;
  width: 82px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {
  height: 60px;
  width: 60px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {
  font-size: 19px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 16px;
  line-height: 20px;
}
.landing_page6_bannerblock .banner_footer {
  padding: 15px;}
  .landing_page6_bannerblock {
    background-position: right 315px bottom 130px, right 590px top 233px, center center;background-size: auto 61%,auto, cover;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p {
  margin-left: 65px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {
  margin-left: 45px;
}

.page6_block1 {
  background-position: left -320px bottom -230px;
}
.page6_block1 .padding_block {
  padding-left: 34%;
}
.page6_block1 .red_square_text p {
  margin-left: 28px;
  padding-left: 25px;
}
.sky_gradient h2 {
  font-size: 28px;
  line-height: 34px;
}
.page6_block2 .green_circle_text h3 {
  font-size: 21px;
}
.page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
  font-size: 21px;
}
.page6_block2 .block2_card {
  padding: 15px;}
  .page6_block2 .card_innercontnt_wrapper {
    padding: 10px;
}
.page6_block2 .textwrapper6 {
  margin-top: 12px;
}
.page6_block2 .card_blue_blck h5 {
  font-size: 18px;
}
.sub_heading_common {
  font-size: 24px;
}
.page6_block2 .orange_button button {
  font-size: 22px;
}
.page6_block2 .orange_button {
  left: 130px;
}
.page6_block2 .card_blue_blck {
  padding: 10px 15px;
  padding-right: 20%;

  margin-top: 20px;
}


.page6_block3 .page6_block3_innerwrapper .block3_lady {
  right: -35px;
}
.page6_block3 .block3_top_sec .block3_multiimg {
  right: 327px;top: 35px;
  width: 22%;
}
.page6_block3 .block3_top_sec .sky_gradient h2 {
  font-size: 26px;
  line-height: 32px;
}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {
  font-size: 16px;
}
.bottom_card .card_top_sec .card_top_inner {
  padding: 20px 28px;}
  .card_bottom_inner p::before {top: 5px;}

  .bottom_card .card_top_inner h4 {
    font-size: 24px;
    line-height: 26px;
}
.bottom_card .card_top_inner h5 {
  font-size: 19px;
  line-height: 23px;    margin-top: 5px;

}
.bottom_card .card_top_inner h3 {
  font-size: 18px;
  line-height: 22px;
}
.block3_bottom_sec .bottom_card:not(:first-child) {
  margin-left: 5%;
}
.page6_block3 .cstmr_sprt_sec .orange_button button {
  font-size: 19px;
}

.video_page_block1_inner {background-position: right bottom -2px,right top;}
.video_page_block1 .employer_points_wrapper .employer_heading {width: fit-content;}
}

@media only screen and (max-width: 1248px) {
  .landing_page6_bannerblock .main_heading_wrapper h1 {
    font-size: 24px;
    line-height: 30px;
}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading {
  width: 43%;
}
.landing_page6_bannerblock .banner_only_para {
  font-size: 12px;}
}


@media only screen and (max-width: 1199px) {
.page6_block4 {background-position: right -100px top;background-size: 108%;padding-top: 58%;padding-bottom: 0;}
.padding_block {padding-left: 0;}
.page6_block4 .textwrapper6 .hand_mobile {display: none;}
.landingpage6_common_width {width: 96%;}
.page6_logo_block {margin-top: 25px;}
.block6_logo_wrp {height: 380px;}
.block6_logo_wrp img {width: 100%;}
.block5_box_wrp {width: 50%;}
.communication_block {background-position: center top;background-size: 101%;padding-top: 65%;}
.block5_flex {flex-direction: column;}
.communication_block .textwrapper6 {width: 100%;}
.communication_block .sky_gradient {width: auto;}
.orange_button {display: block;margin: 0 auto;margin-top: 25px;}
.help_clients_block {background-position: center top;background-size: 100%;padding-top: 61%;padding-bottom: 20px;}
.landingpage6_img_wrp {display: none;}
.help_clients_block .textwrapper6 {width: auto;}
.faq_block {background-size: 100%;background-position: right top;padding-top: 72%;padding-bottom: 25px;}
.block7_flex {flex-direction: column;}
.faq_block .textwrapper6 {width: auto;}
.block7_gradient_block {flex: 1 0 27%;margin: 5px;padding: 30px;padding-right: 50px;}
.ques_ans_block {padding: 15px;margin-top: 0;}
.ques_ans_block p {margin: 15px 0;}
.page6_logo_block .orange_button {margin-top: 15px;}
.sky_gradient h2 {font-size: 22px;line-height: 28px;}
.landing_page6_bannerblock { background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_banner_bg2.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;}
.landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 44px;line-height: 54px;}
.landing_page6_bannerblock .main_heading_wrapper > div {padding: inherit;background: none; }
.landing_page6_bannerblock .main_heading_wrapper {width: auto;}
.landing_page6_bannerblock .main_heading_wrapper > div::after {display: none;}
.landing_page6_bannerblock .signature_textwrapper {margin-top: 15px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 30px;line-height: 40px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 34px;line-height: 40px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 br {display: none;}
.landing_page6_bannerblock .landing-col-container {flex-direction: column;}
.landing_page6_bannerblock .landing-banner-left {width: 100%;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper {padding: 20px 20px 10px 20px;width: 100%;background: #2bb5fd;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 19px;margin-bottom: 15px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 30px;}
.landing_page6_bannerblock .banner_only_para {font-size: 24px;margin-top: 15px;}
.landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 26px;line-height: 32px;}
.landing_page6_bannerblock .land_arrow_wrapper {background: #fb2d01;padding: 20px;margin: 20px 0;border-radius: 7px;}
.landing_page6_bannerblock .land_arrow_wrapper:after {display: none;}
.landing_page6_bannerblock .landing-banner-right {width: 100%;margin-left: inherit;}
.landing_page6_bannerblock .form_title_wrapper h4 br{display: none;}
.landing_page6_bannerblock {padding: 20px 0;}

.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper {
  flex-direction: column;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper {width: auto;margin: 15px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) {width: auto;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {margin-left:80px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .circle_main_wrap, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .circle_main_wrap, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .circle_main_wrap {left: -10px;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) {    border-top-right-radius: 0;
  border-bottom-right-radius: 0;}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 20px;
  line-height: 26px;
}


.page6_block1 .padding_block {
  padding-left: 0;
}
.page6_block1 .landingpage6_common_width {
  padding: 0px 0 25px;
}
.page6_block1 .textwrapper6 .block1_mobile {display: none;}
.page6_block1 .orange_button button {margin: 0 auto;}
.page6_block1 {
  background-position: left -200px top -110px;
  padding-top: 58%;
  padding-bottom: 0;
  background-size: 150%;
}
.common_blue_text {
  display: inline;
}

.page6_block2 .common_blue_text {
  font-size: 25px;
}
.sub_heading_common {
  font-size: 22px;
}
.page6_block2 .green_circle_text h3, .page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
  font-size: 19px;
}
.page6_block2 .red_square_text p {
  margin-left: 25px;
}
.page6_block2 .red_square_text p {
  font-size: 16px !important;
  line-height: 22px !important;
  padding-left: 25px;

}
.page6_block2 .red_square_text p::before {
  top: 3px;
}
.page6_block2 .green_circle_text h3::before {
  width: 20px;
  height: 20px;
  top: 6px;
}
.page6_block2 .green_circle_text h3 {
  padding-left: 28px;
}
/* .page6_block2 .page6_block2_wrapp {
  background-size: 93%, 79%, cover;
} */
.page6_block2 .card_blue_blck h5 {
  font-size: 16px;
} 
.page6_block2 .card_blue_blck {
  margin-top: 14px;
}
.page6_block2 .orange_button {
  bottom: -37px;
}
.page6_block2 .orange_button {
  left: 75px;
}
.page6_block2 .orange_button button {
  font-size: 20px;
}


.page6_block3 .page6_block3_innerwrapper .block3_lady, .page6_block3 .block3_top_sec .block3_multiimg {display: none;}
.page6_block3 .sky_gradient {width: auto;    margin: 25px 0 10px;
}
.block3_bottom_sec {flex-wrap: wrap;}
.bottom_card {
  width: 48%;margin: 20px;}
  .bottom_card .card_top_sec {

    margin-left: 0;}
    .block3_bottom_sec .bottom_card:not(:first-child) {
      margin-left: 0;
  }
  .bottom_card {
    width: 46%;margin: 2% !important;}
  .block3_bottom_sec .bottom_card {    border-radius: 10px;
    border-top-left-radius: 20px;}
  .bottom_card .card_top_sec .card_top_inner {background-size: 15%;
    background-position: right 15px top 10px;}
    .bottom_card .card_bottom_inner {
      padding: 0 25px 20px;
  }
  .bottom_card:nth-of-type(1) .card_top_sec .card_top_inner {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_first_rckt.png);
}
.bottom_card:nth-of-type(2) .card_top_sec .card_top_inner {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_scnd_thrd_clndr.png);
}
.bottom_card:nth-of-type(3) .card_top_sec .card_top_inner {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_scnd_thrd_clndr.png);
}
.bottom_card:nth-of-type(4) .card_top_sec .card_top_inner {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_frth_live.png);
}


.page6_block3 .cstmr_sprt_sec {padding: 15px;}
.page6_block3 .cstmr_sprt_sec .employee_sec .common_blue_text {
  font-size: 22px;
}
.page6_block3 .cstmr_sprt_sec .employee_sec .employee_desc > h4 {
  font-size: 14px;
}


.video_page_block1 .employer_points_wrapper .employer_bullet_wrapper {background: #00b3fd;padding: 15px;}
.video_page_block1 .employer_points_wrapper .employer_bullet_wrapper p {padding-left: 25px;}
.video_page_block1 .blue_bullets::after {left: 0;}
.video_page_block1_inner {background-position: center top, center;background-size: 100%, cover;padding:0px;padding-top: 50%;padding-bottom: 25px;}

.landpage_videowrapper {width: auto;}

/* .page6_block2 .block2_cards_wrapper {flex-direction: column;} */
}

@media only screen and (max-width: 1099px) {
  .page6_block2 .block2_card_down {
    margin-top: 35%;
}
.page6_block2 .page6_block2_wrapp {
  background-size: 114%, 119%, cover;
  background-position: right -110px top, left -70px bottom, center;
}

.page6_block3 .cstmr_sprt_sec .employee_sec .common_blue_text {
  font-size: 20px;
}
.page6_block3 .cstmr_sprt_sec .employee_img {
  width: 14%;
  padding: 5px;
}
.page6_block3 .cstmr_sprt_sec .orange_button button {
  font-size: 15px;
}
.page6_block3 .cstmr_sprt_sec .employee_sec:nth-of-type(2) {
  padding-left: 15px;}
}
@media only screen and (max-width: 991px) {
.block6_logo_wrp img {height: 100%;width: 100%;}

.page6_block2 .common_blue_text {
  font-size: 22px;
}
.sub_heading_common {
  font-size: 20px;
}
.page6_block2 .textwrapper6 {
  margin-top: 8px;
}
.page6_block2 .green_circle_text h3, .page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
  font-size: 18px;line-height: 24px;
}
.page6_block2 .green_circle_text h3::before {

  top: 2px;
}
.page6_block2 .card_blue_blck h5 {
  font-size: 14px;
}

.page6_block3 .cstmr_sprt_sec {flex-direction: column;}
.page6_block3 .cstmr_sprt_sec .orange_button {

  margin: 15px auto 0;
}

.page6_block1 {
  background-position: left -163px top -90px;

}
}
@media only screen and (max-width: 930px) {
.block7_gradient_block {flex: 1 0 48%;}
}
@media only screen and (max-width: 899px) {
  .landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 38px;line-height: 48px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 26px;line-height: 36px;}
.landing_page6_bannerblock .signature_textwrapper {margin-top: 10px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 25px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {font-size: 18px;}

.page6_block2 .block2_cards_wrapper {flex-direction: column;}
.page6_block2 .block2_card {width: 100%;}
.page6_block2 .page6_block2_wrapp {
  background-size: 188%, 189%, cover;
  background-position: right top, left bottom 370px, center;
}
.page6_block2 .block2_card {
margin-top: 50%;}
.page6_block2 .block2_card_down {
  margin-top: 38%;
}
.page6_block2 .orange_button {
  left: inherit;bottom: inherit;position: relative;
}
.page6_block2 .card_blue_blck {
  margin-top: 18px;    background-size: 9%;

}
.page6_block3 .page6_block3_innerwrapper {
    padding: 25px 0;
}
}
@media only screen and (max-width: 799px) {
  .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
    font-size: 19px;}
    .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {
      margin-left: 70px;
  }

  .video_page_block1_inner {background-size: 108%, cover;}
  .video_page_block1 .employer_points_wrapper .employer_heading {margin: 0 auto;right: 0;}
  .video_page_block1 .employer_points_wrapper .employer_heading p {font-size: 24px;line-height: 30px;}
}
@media only screen and (max-width: 768px) {
  .landing_page6_bannerblock .signature_textwrapper h2 {font-size: 24px;line-height: 34px;}
  .landing_page6_bannerblock .signature_textwrapper h2:nth-of-type(2) {padding-left: 50px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 26px;line-height: 32px;}

.page6_block1 .flex_column {
  display: block;
}
.page6_block1 .flex_column p:first-child {width: auto;}

.page6_block1 {
  background-position: left -126px top -80px;
}
}
@media only screen and (max-width: 699px) {
  .block5_box_wrp {width: 100%;}
  .block6_logo_wrp {height: auto;}
  .block6_logo_wrp img {height: auto;}
  .help_clients_block .block6_flex {flex-direction: column;}
  .solution_wrp, .goals_wrp {width: auto;}
  .block7_gradient_block {flex: 1 0 98%;padding: 20px;padding-right: 50px;background-size: 36px, auto;}
  .cards_block_inner {padding: 15px;}
  .cards_block_inner h2 {font-size: 30px;line-height: 36px; margin-bottom: 10px;}
  .orange_button button {padding: 20px;font-size: 20px;}
}
@media only screen and (max-width: 668px) {
  .landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 34px;line-height: 44px;}
.landing_page6_bannerblock .sub_heading_wrapper h3 {font-size: 24px;line-height: 30px;}
.landing_page6_bannerblock .signature_textwrapper h2 {font-size: 21px;line-height: 27px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 21px;}
.landing_page6_bannerblock .land_arrow_wrapper h2 {font-size: 20px;line-height: 24px;}
.landing_page6_bannerblock .land_arrow_wrapper {padding: 15px;margin: 15px 0 20px;}
  .landing_page6_bannerblock .banner_only_para {font-size: 20px;line-height: 24px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper {padding: 15px 15px 5px 10px;}
  .blue_bullets::after {background-size: 80%;left: 0px;top: 3px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_bullet_wrapper p {    padding-left: 25px;font-size: 16px;margin-bottom: 12px;}


.page6_block2 .page6_block2_wrapp {
  background-position: right top, left bottom 40%, center;
}
}
@media only screen and (max-width: 629px) {
  .bottom_card .card_top_sec .card_top_inner {
    padding: 20px;
}
.page6_block3 .cstmr_sprt_sec .customer_sec_wrp {flex-direction: column;width: 100%;}
.page6_block3 .cstmr_sprt_sec .employee_sec {width: 100%;}
.page6_block3 .cstmr_sprt_sec .employee_sec:nth-of-type(2) {
  padding-left: 0;border-left: none;
}
.page6_block3 .cstmr_sprt_sec .employee_sec:not(:last-child) {margin-bottom: 10px;}
.page6_block3 .green_circle_text h3::before {
  width: 22px;
  height: 22px;top: 3px;
}
.page6_block3 .green_circle_text h3 {
  font-size: 20px;
  line-height: 26px;
  padding-left: 30px;
}
.page6_block3 .common_blue_text {
  font-size: 26px;
}
}
@media only screen and (max-width: 599px) {
  .landing_page6_bannerblock {padding: 0;}
  .landing_page6_bannerblock .signature_textwrapper h2:nth-of-type(2) {padding-left: 0;}

  .page6_block1 {
    background-position: left -97px top -60px;
}
}
@media only screen and (max-width: 578px) {
  .landing_page6_bannerblock .main_heading_wrapper h1 {font-size: 32px;line-height: 38px;}
.landing_page6_bannerblock .signature_textwrapper h2:nth-of-type(2) {padding-left: 0;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading p {font-size: 19px;}
.landing_page6_bannerblock .employer_points_wrapper .employer_heading {right: -20px;width: 250px;}

.bottom_card .card_top_inner h4 {
  font-size: 22px;
  line-height: 24px;
}
.bottom_card .card_top_inner h5 {
  font-size: 16px;
  line-height: 20px;}
  .bottom_card .card_bottom_inner {
    padding: 0 15px 15px;
}
.bottom_card .card_bottom_inner p {
  font-size: 16px;
  line-height: 24px;    padding-left: 20px;    margin-top: 8px;


}
.bottom_card .card_top_sec {
padding: 15px;}
.bottom_card .card_bottom_inner > span {
  font-size: 15px;
  margin: 8px;
  line-height: 16px;
  margin-left: 15px;
  padding-left: 10px;


}
.card_bottom_inner p::before {
  top: 6px;width: 5px;height: 5px;
}
.bottom_card .card_bottom_inner > span::before {width: 8px;height: 8px;top: 4px;}
.page6_block3 .cstmr_sprt_sec {margin-top: 10px;}

.page6_block2 .page6_block2_wrapp {
  background-position: right top 40px, left bottom 40%, center;
}
}
@media only screen and (max-width: 499px) {
.landingpage6_common_width {width: 94%;}
.cards_block_inner h2 {font-size: 23px;line-height: 28px;}
.red_square_text p {font-size: 16px !important;line-height: 22px !important;padding-left: 24px;margin-left: 30px;}
.green_circle_text h3 {font-size: 18px;line-height: 24px;padding-left: 30px;}
.green_circle_text h3::before {background-size: 24px;}

.landing_page6_bannerblock .banner_footer {
  padding: 10px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 16px;line-height: 20px;
}

.page6_block1 .green_circle_text h3::before {
  width: 18px;
  height: 18px;
  left: 5px;
  top: 4px;
  background-size: 100%;
}
.page6_block1 .sky_gradient {
  margin-bottom: 5px;
}
.page6_block1 .green_circle_text h3 {
  font-size: 18px;
  line-height: 24px;}
  .page6_block1 .sky_gradient h2 {
    font-size: 22px;
    line-height: 28px;
}
.page6_block1 .red_square_text p::before {
  top: 4px;
}


.page6_block2 .green_circle_text h3::before, .page6_block3 .green_circle_text h3::before {background-size: 100%;}
.page6_block2 .card_blue_blck {
  background-size: 16%;
}
.page6_block2 .sky_gradient h2 {
  font-size: 20px;
  line-height: 24px;
}


.bottom_card {
  width: 100%;
}
.page6_block1 {
  background-position: left -79px top -40px;
}

.video_page_block1_inner {background-size: 141%, cover;padding-top: 65%;}


}
@media only screen and (max-width: 420px) {
  .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .circle_main_wrap, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .circle_main_wrap, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .circle_main_wrap {
    left: -15px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(1) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(2) .prcntg_txt p, .landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper:nth-of-type(3) .prcntg_txt p {
  margin-left: 55px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_main_wrap {
  height: 70px;
  width: 70px;
}
.landing_page6_bannerblock .banner_footer .prcntg_wrapper .circle_sub_wrap {
  height: 50px;
  width: 50px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .circle_main_wrap span {
  font-size: 17px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper .prcntg_txt p {
  font-size: 14px;
  line-height: 18px;
}
.landing_page6_bannerblock .banner_footer .prcntg_main_wrapper .prcntg_wrapper {
  margin: 10px;
}


.page6_block2 .common_blue_text {
  font-size: 20px;
}
.page6_block2 .sky_gradient h2 {
  font-size: 19px;
  line-height: 23px;
}
.sub_heading_common {
  font-size: 19px;
}
.page6_block2 .page6_block2_wrapp {padding: 10px;}
.page6_block2 .card_innercontnt_wrapper {
  padding: 0;    margin-top: 10px;

}
.page6_block2 .red_square_text p {
  font-size: 15px !important;    margin-left: 15px;
}
.page6_block2 .green_circle_text h3, .page6_block2 .block2_card.block2_card_down .green_circle_text h3 {
  font-size: 17px;
}
/* .page6_block2 .page6_block2_wrapp {
  background-position: right top 8%, left bottom 35%, center;
} */
.page6_block2 .block2_card {
  padding: 10px;
}
.page6_block2 .orange_button {margin-top: 20px;}
.page6_block2 .orange_button button{
  font-size: 17px;
}
.page6_block3 .common_blue_text {
  font-size: 22px;
}
.page6_block3 .page6_block3_innerwrapper {
  padding: 20px 0;
}
.page6_block3 .green_circle_text h3 {
  font-size: 19px;
  line-height: 25px;
}
.page6_block3 .sky_gradient {
  margin: 20px 0 10px;
}
.page6_block3 .green_circle_text h3::before {
  width: 20px;
  height: 20px;}
  .page6_block1 {
    background-position: left -69px top -35px;
}
}
@media only screen and (max-width: 399px) {
  .landing_page6_bannerblock .form_title_wrapper h2 {font-size: 30px;line-height: 34px;}
  .landing_page6_bannerblock .form_title_wrapper h4 {font-size: 20px;line-height: 24px;}

  .page6_block1 .orange_button button {font-size: 16px;}
  .page6_block1 .orange_button {
    margin-top: 20px;
}
.page6_block1 .landingpage6_common_width {
  padding: 0px 0 20px;
}
.block7_flex .orange_button button {
  font-size: 16px;
}
.faq_block {padding-bottom: 20px;}
.faq_block .orange_button {margin-top: 20px;}

.page6_block2 .page6_block2_wrapp {
  background-position: right top 70px, left bottom 40%, center;
}
}
.landingpage6_main_cont {background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/landing_page_whole_bg.webp);background-repeat: no-repeat;background-size: cover;}


