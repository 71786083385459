.box1{
  
    position: relative;
    margin-top: 300px;
    height: 300px;
    width: 350px;
}
.box1 img{
    width: 100%;
    height: 100%;
}
.box1::before{
    content: "hello world";
    position: absolute;
    top: -25px;
    left: 10%;
    width: 80%;
    border: 2px solid black;
    text-align: center;
    color: white;
    font-size: 25px;
    background-color:rgb(8,75,95) ;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    

}