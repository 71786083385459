.lp20_main {
  font-family: "Poppins", sans-serif;
}

.landingpage20_common_width {
  width: 1300px;
  margin: 0 auto;
}

.landingPage20_main_header_common_wrapper {
  width: 1550px;
  margin: 0 auto;
}

.landingPage20_main_wrapper .Lp20_block_footer_main .Lp20_block_footer_text a{
  text-decoration: none;
    font-weight: 600;
    font-size: 22px;
    color: #015bf3;
}





@media screen and (max-width: 1600px) {
  .landingpage20_common_width {
    width: 94%;
  }

  .landingPage20_main_header_common_wrapper {
    width: 94%;
  }
}

/* ----------------------------landing page 20 header css------------------------ */

.landingPage20_block1_header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landingPage20_block1_textfild_button Button {
  padding: 16px 20px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  border-radius: 7px;
  color: #ffffff;
  font-size: 22px;
}

.landingPage20_main_header_common_wrapper .MuiFormControl-root {
  width: 67%;
  margin-right: 10px;
  /* top: 7px;
    height: 100%; */
}

.landingPage20_block1_textfild_button .MuiInputBase-root {
  height: 100%;
}

.landingPage20_block1_textfild_button .MuiFormControl-root {
  top: 10%;
}
.landingPage20_block1_header_wrapper {
  margin-top: 20px;
}

@media screen and (max-width: 1680px) {
  /* .landingPage20_block1_header_wrapper img {
    width: 21%;
  } */

  /* .landingPage20_block1_textfild_button {
    width: 72%;
  } */

  .landingPage20_main_header_common_wrapper .MuiFormControl-root {
    width: 63%;
  }
}

@media screen and (max-width: 1420px) {
  /* .landingPage20_block1_textfild_button {
    width: 75%;
  } */

  .landingPage20_main_header_common_wrapper .MuiFormControl-root {
    width: 60%;
  }
}

@media screen and (max-width: 1253px) {
  .landingPage20_main_header_common_wrapper .MuiFormControl-root {
    width: 57%;
  }
}

.landingPage20_block2_header {
  margin-top: 16px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage20/banner/landingpage20-video-bg-layer.webp), linear-gradient(90deg, rgba(0, 32, 214, 1) 18%, rgba(1, 61, 229, 1) 57%, rgba(1, 86, 241, 1) 99%);
  ;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingPage20_block2_header_wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 65px;
  padding-top: 65px;
  align-items: center;
}

.landingPage20_block2_header_text {
  width: 41%;
}
.landingPage20_block2_header_wrapper .Lp14block1_sub_right_wrapper{
border: 3px solid #fff;
}


.landingPage20_block2_header_text h1 {
  line-height: 55px;
  font-size: 50px;
  font-weight: 700;
  color: #ffffff;
}

.landingPage20_block2_header_text p {
  line-height: 55px;
  font-size: 36px;
  color: #ffffff;
  margin-top: 22px;
}

.landingPage20_block2_header_text p span {
  font-weight: 700;
}

.landingPage20_block2_header_text h3 {
  font-size: 28px;
  color: #ffffff;
  font-weight: 100;
  margin-top: 10px;
}

.landingPage20_block2_header_text h2 {
  color: #ffffff;
  font-weight: 100;
  font-size: 28px;
  line-height: 37px;
  margin-top: 10px;
}

.landingPage20_block2_header_text h3 span {
  font-weight: 700;
}



@media screen and (max-width: 1499px) {
  .landingPage20_block2_header_text h1 {
    line-height: 49px;
    font-size: 44px;
  }

  .landingPage20_block2_header_text p {
    line-height: 46px;
    font-size: 29px;
  }

  .landingPage20_block2_header_text h2 {
    font-size: 21px;
    line-height: 31px;
  }

  .landingPage20_block2_header_text h3 {
    font-size: 21px;
    line-height: 31px;
  }
}

@media screen and (max-width: 1499px) {
  .landingPage20_block2_header_video {
    margin-top: 0;
  }
}

@media screen and (max-width: 1199px) {
  .landingPage20_block2_header_wrapper {
    flex-direction: column-reverse;
  }

  .landingPage20_block2_header_text {
    width: 100%;
    margin-top: 35px;
  }

  .landingPage20_block2_header_wrapper {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .landingPage20_block2_header_text h1 {
    line-height: 40px;
    font-size: 36px;
  }

  .landingPage20_block2_header_text p {
    line-height: 28px;
    font-size: 25px;
  }

  .landingPage20_block2_header_text h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .landingPage20_block2_header_text h3 {
    font-size: 16px;
    line-height: 26px;
  }

  .landingPage20_block2_header_video {
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .landingPage20_block1_textfild_button Button {
    font-size: 14px;
  }

  .landingPage20_block1_header_wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .landingPage20_block2_header_text h1 {
    line-height: 35px;
    font-size: 30px;
  }

  .landingPage20_block2_header_text p {
    line-height: 24px;
    font-size: 22px;
  }
}

@media screen and (max-width: 499px) {
  .lp20_video_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lp20_video_icon .Lp14block1_sub_sec2_wrapper_ply_btn {
    width: 60px !important;
    height: 60px !important;
    margin-bottom: 10px;
  }

  .landingPage20_block2_header_video button {
    padding: 5px 10px;
    font-size: 13px;
  }

}

/* ----------------------------landing page 20 header css end------------------------ */

/* ----------------------------landing page 20 block1 css------------------------ */

.lp20_block1_sub_wrapper_text {
  background-color: #f3f9ff;
}

.lp20_block1_sub_wrapper_text p {
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  margin-top: 81px;
  padding: 12px;
  font-style: italic;
  margin-bottom: 20px;
}

.lp20_block1_sub_wrapper_text span {
  color: #015bf3;
}

.lp20_block2_wrapper {
  display: flex;
  justify-content: space-between;
}

.ip20_block2_sub2_wrapper {
  width: 49%;
  border: 1px solid #c8d8ec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px;
}

.ip20_block2_sub1_wrapper {
  width: 49%;
  border: 1px solid #c8d8ec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px;
}

.ip20_block2_sub1_text h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 65px;
  color: #0b395a;
}

.ip20_block2_sub1_text p {
  line-height: 38px;
  font-size: 26px;
  color: #0b395a;
}

.ip20_block2_sub1_text p span {
  font-weight: 500;
  font-style: italic;
}

.ip20_block2_sub2_text h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 65px;
  color: #0b395a;
}

.ip20_block2_sub2_text p {
  line-height: 38px;
  font-size: 26px;
  color: #0b395a;
}

.ip20_block2_sub2_text p span {
  font-weight: 500;
  /* font-style: italic; */
}

.ip20_block2_sub2_text p span span {
  font-weight: 500;
  font-style: italic;
}

.ip20_block2_sub1_text {
  text-align: center;
}

.ip20_block2_sub2_text {
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .lp20_block1_sub_wrapper_text p {
    font-size: 22px;
    line-height: 22px;
    margin-top: 21px;
  }

  .ip20_block2_sub1_text h1 {
    font-size: 25px;
    line-height: 35px;
  }

  .ip20_block2_sub1_text p {
    line-height: 34px;
    font-size: 22px;
  }

  .ip20_block2_sub2_text h1 {
    font-size: 25px;
    line-height: 35px;
  }

  .ip20_block2_sub2_text p {
    line-height: 34px;
    font-size: 22px;
  }
}

@media screen and (max-width: 799px) {
  .ip20_block2_sub1_text h1 {
    font-size: 22px;
    line-height: 29px;
  }

  .ip20_block2_sub1_text p {
    line-height: 30px;
    font-size: 19px;
  }

  .ip20_block2_sub2_text h1 {
    font-size: 22px;
    line-height: 29px;
  }

  .ip20_block2_sub2_text p {
    line-height: 30px;
    font-size: 19px;
  }

  .lp20_block1_sub_wrapper_text p {
    line-height: 22px;
    font-size: 18px;
  }
}

@media screen and (max-width: 799px) {
  .lp20_block2_wrapper {
    flex-direction: column;
  }

  .ip20_block2_sub1_wrapper {
    width: 100%;
    margin: 10px 0;
  }
}

@media screen and (max-width: 499px) {
  .Lp20_block4_table_main_wrpr table thead tr th {
    padding: 10px;
    font-size: 13px !important;
    line-height: 17px !important;
  }

  .Lp20_block4_table_main_wrpr table tbody tr td {
    font-size: 12px !important;
    line-height: 17px !important;
  }

}



/* ----------------------------landing page 20 block1 css end------------------------ */

/* ----------------------------landing page 20 block2 css------------------------ */
.lp20_block2_text {
  padding-top: 8%;
  width: 100%;
}

.lp20_block2_text h1 {
  font-size: 36px;
  color: #0b395a;
  line-height: 45px;
  margin-top: 4%;
}

.lp20_block2_text h1 span {
  color: #015bf3;
}

.lp20_block2_text p {
  font-size: 26px;
  font-weight: 500;
  color: #0b395a;
  margin-top: 2%;
  line-height: 33px;
}

.lp20_block2_sub1_text p {
  font-size: 22px;
  color: #0b395a;
  font-weight: 200;
}

.lp20_block2_sub1_text p span {
  color: #0b395a;
  font-weight: 700;
}

.lp20_block2_text Button {
  padding: 18px 81px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  border-radius: 7px;
  color: #ffffff;
  font-size: 22px;
  margin-top: 3%;
}

.lp20_block2_text_from {
  display: flex;
  padding-top: 8%;
}

.lp20_block2_form .landing-banner-right {
  width: 74%;
}

.lp20_block2_form .form_title_wrapper {
  background: linear-gradient(90deg, rgba(15, 196, 119, 1) 0%, rgba(17, 196, 155, 1) 48%, rgba(18, 196, 190, 1) 100%);
}

.lp20_block2_form .formwrapper {
  border: none;
  outline: none;
  padding: 0;
}

.lp20_block2_form .form_style .formfild input {
  border: 1px solid #a2b8d0;
  background-color: #fff;
  color: #000;
}

.lp20_block2_form .MuiSelect-select {
  border: 1px solid #a2b8d0;
  background-color: #fff !important;
  color: #000 !important;
  padding: 9.5px 14px !important;
}

.lp20_block2_form .email_btm_line {
  margin-bottom: 10px;
}

.lp20_block2_form button {
  background: linear-gradient(90deg, rgba(15, 196, 119, 1) 0%, rgba(17, 196, 155, 1) 48%, rgba(18, 196, 190, 1) 100%);
font-size: 32px;
line-height: 37px;
}

.lp20_block2_main {
  background-color: #f0f7ff;
  margin-top: 67px;
  padding-bottom: 65px;
}

.lp20_block2_sub2_text {
  font-size: 26px;
  font-weight: 500;
  font-style: italic;
  background-color: #ffffff;
  padding: 29px;
  text-align: center;
  margin-top: 45px;
  border-radius: 5px;
}

.lp20_block2_sub2_text span {
  color: #015bf3;
}

@media only screen and (max-width: 1400px) {
  .lp20_block2_text Button {
    font-size: 19px;
  }
}

@media only screen and (max-width: 1400px) {
  .lp20_block2_sub2_text {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .lp20_block2_text_from {
    flex-wrap: wrap;
    padding-top: 0
  }

  .lp20_block2_form {
    width: 100%;
  }

  .lp20_block2_form .landing-banner-right {
    width: 100%;
  }

  .lp20_block2_text {
    padding-top: 0;
    text-align: center;
  }

  .lp20_block2_form .formwrapper {
    margin-top: 34px;
  }

  .lp20_block2_text h1 {
    font-size: 28px;
    line-height: 25px;
    margin-top: 21px
  }

  .lp20_block2_text p {
    font-size: 22px;
    margin-top: 24px;
    line-height: 20px;
  }

  .lp20_block2_text Button {
    font-size: 17px;
    padding: 14px 48px
  }

  .lp20_block2_form .reCaptcha .MuiFormControl-root .captchaField {
    justify-content: unset
  }

  .lp20_block2_form .l2submitbtnsection button {
    font-size: 30px;
    line-height: 35px;
  }

  .lp20_block2_form .form_title_wrapper h2 span {
    font-size: 25px;
    line-height: 20px;
  }

  .lp20_block2_main {
    margin-top: 21px;
    padding-bottom: 30px;
  }

  .lp20_block2_sub2_text {
    margin-top: 25px;
  }
}

/* ----------------------------landing page 20 block2 css end------------------------ */

/* ----------------------------landing page 20 block3 css------------------------ */

.lp20_block3_sub_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.lp20_block3_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid; */
  padding: 5% 15px;
  width: 32%;
  background-color: #ffff;
}

.lp20_block3_sub_text h1 {
  font-size: 24px;
}

.lp20_block3_sub_text h2 {
  font-size: 26px;
  color: blue;
}

.lp20_block3_sub_text p {
  font-size: 22px;
}

.lp20_block3_sub_text{
  text-align: center;
  
}

.lp20_block3_sub_img {
  /* border: 2px solid; */
  border-radius: 50%;
  background: #f2f7ff;
  width: 150px;
  height: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp20_block3_main_wrapper h3 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  color: #ffff;
  font-weight: 600;
  position: relative;
}

/* .lp20_block3_main_wrapper p::before{
    content: '';
    position: absolute;
    background: linear-gradient( 180deg, rgba(5, 174, 235, 1) 22%, rgba(17, 147, 220, 1) 61%, rgba(31, 114, 202, 1) 89% );
    width: 9px;
    height: 60%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
} */
.lp20_block3_main {
  background: #002fde;
}

.lp20_block3_main_wrapper {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  padding: 65px 0;
  /* position: relative; */
}

.lp20_block3_main_wrapper h3::before {
  content: "";
  position: absolute;
  left: 48%;
  top: 180%;
  transform: translateY(-50px);
  background-color: #fff;
  background-repeat: no-repeat;
  width: 47px;
  height: 10px;
}

.lp20_block3_main_wrapper Button {
  padding: 10px 56px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  border-radius: 7px;
  color: #ffffff;
  margin-top: 55px;
  font-size: 22px;
  text-transform: capitalize;
  margin: 0 auto;
  margin-top: 50px;
  max-width: 90%;
}

@media screen and (max-width: 1199px) {
  .lp20_block3_sub_text h1 {
    font-size: 20px;
  }

  .lp20_block3_sub_text p {
    font-size: 15px;
  }

  .lp20_block3_sub_text h2 {
    font-size: 20px;
  }

  .lp20_block3_main_wrapper h3 {
    font-size: 32px;
  }

  .lp20_block3_main_wrapper Button {
    font-size: 18px;
    margin-top: 15px;
  }


  .lp20_block3_sub_wrapper {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }

  .lp20_block3_sub {
    width: 47%;
    margin: 1.5%;
  }

  .lp20_block3_main_wrapper {
    padding: 30px 0;
  }

  .lp20_block3_main_wrapper h3::before {
    top: 200%;
  }
}

@media screen and (max-width: 699px) {
  .lp20_block3_main_wrapper h3::before {
    left: 46%;
  }
}

@media screen and (max-width: 400px) {
  .lp20_block3_main_wrapper h3::before {
    left: 43%;
  }
}

/* ----------------------------landing page 20 block3 css end------------------------ */

/* ----------------------------landing page 20 block4 css------------------------ */

.lp20_block4_sub_wrapper_text p {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  font-style: italic;
  padding: 34px;
  margin-top: 87px;
}

.lp20_block4_sub_wrapper_text {
  background-color: #f3f9ff;
}

.lp20_block4_sub_wrapper_text p span {
  color: #015bf3;
}

.lp20_block4_main {
  font-family: "Raleway", sans-serif;
}

.lp20_block4_sub_wrapper {
  display: flex;
  padding: 64px 0;
  justify-content: space-between;
}

.lp20_block4_sub1_left {
  width: 39%;
}

.lp20_block4_sub1_left h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0b395a;
  line-height: 36px;
  text-transform: capitalize;
  position: relative;
  padding-left: 52px;
  margin-bottom: 30px;
}

.lp20_block4_sub1_left h1::before {
  content: "";
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/LP14_block9_table_h1.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 42px;
  height: 51px;
}

.Lp20_block4_table_main_wrpr table thead tr th:nth-child(2) {
  width: 26%;
}

.Lp20_block4_table_main_wrpr table thead tr th:first-child {
  width: 42%;
}

.lp20_block4_sub1_left ul li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 60px;
  transform: translateY(-50px);
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/as_lp14_bl5_right_img.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 40px;
}

.lp20_block4_sub1_left ul li {
  font-size: 22px;
  font-weight: 500;
  color: #0b395a;
  line-height: 30px;
  list-style: none;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.lp20_block4_sub1_right {
  position: relative;
  width: 60%;
}

.Lp20_block4_table_main_wrpr {
  width: 620px;
  max-width: 100%;
  position: relative;
}

.Lp20_block4_table_head {
  padding: 5px 39px;
  width: 100%;
  background-color: #015bf3;
}

.Lp20_block4_table_head h1 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 36px;
  text-align: center;
  color: #fff;
}

.Lp20_block4_table_main_wrpr table {
  width: 100%;
  border-collapse: collapse;
}

.Lp20_block4_table_main_wrpr table thead {
  border: 1px solid #005a87;
}

.Lp20_block4_table_main_wrpr table thead tr th {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 36px;
  background-color: #005a87;
  border: none;
  text-align: left;
  padding-left: 20px;
}

.Lp20_block4_table_main_wrpr table tbody {
  border: 1px solid #e3e3e3;
}

.Lp20_block4_table_main_wrpr table tbody tr td {
  font-size: 16px;
  color: #0b395a;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  padding: 2px 2px 2px 20px;
}

.Lp20_block4_table_main_wrpr table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.Lp20_block4_table_tag {
  position: absolute;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/LP14_tag.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 48px 48px;
  height: 253px;
  bottom: 45px;
  right: -19%;
  width: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Lp20_block4_table_tag {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 25px;
  text-align: center;
}


@media screen and (max-width: 1300px) {
  .lp20_block4_sub1_right {
    width: 64%;
  }
}

@media screen and (max-width: 1199px) {
  .lp20_block4_sub_wrapper {
    flex-direction: column;
    padding: 30px 0;
  }

  .lp20_block4_sub1_right {
    width: 100%;
  }

  .Lp20_block4_table_main_wrpr {
    width: auto;
  }

  .Lp20_block4_table_tag {
    right: 10px;
  }

  .Lp20_block4_table_main_wrpr table thead tr th:first-child {
    width: 34%;
  }

  .lp20_block4_sub1_left {
    width: 100%;
  }

  .lp20_block4_sub1_left ul li {
    width: auto;
    margin-bottom: 20px;
  }

  .lp20_block4_sub_wrapper_text p {
    font-size: 22px;
    line-height: 22px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 850px) {
  .Lp20_block4_table_tag {
    position: initial;
    margin: 0 auto;
    margin-top: 20px;
  }

  .Lp20_block4_table_main_wrpr table thead tr th:first-child {
    width: 38%;
  }

  .Lp20_block4_table_main_wrpr table thead tr th:nth-child(2) {
    width: 36%;
  }

  .Lp20_block4_table_tag {
    right: 42px;
  }

  .lp20_block4_sub1_left h1 {
    font-size: 22px;
    line-height: 28px;
  }

  .lp20_block4_sub1_left ul li {
    font-size: 18px;
    line-height: 22px;
  }

}

@media screen and (max-width: 799px) {
  .lp20_block4_sub_wrapper_text p {
    font-size: 18px;
    line-height: 22px;
  }

}

@media screen and (max-width: 599px) {
  .lp20_block3_sub {
    width: 97%;
    margin: 2% 1.5%;
  }

}



/* ----------------------------landing page 20 block5 css------------------------ */

.Lp20_block5_main_box {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 40px;
}

.Lp20_block5_sub_wrapper_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Lp20_block5_sub_wrapper_text h1 {
  font-size: 36px;
  font-size: 36px;
  font-weight: 600;
}

.Lp20_block5_sub_wrapper_text p {
  font-size: 28px;
  font-weight: 400;
}

.Lp20_block5_sub_wrapper_text::before {
  content: "";
  position: absolute;
  left: 48%;
  top: 157%;
  transform: translateY(-50px);
  background-color: #015bf3;
  background-repeat: no-repeat;
  width: 47px;
  height: 10px;
}

.Lp20_block5_box {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 32%;
  padding: 20px;
  flex-direction: column;
  z-index: 9;
}

.Lp20_block5_box p {
  font-size: 26px;
  text-align: center;
  margin-top: 3%
}

.Lp20_block5_box1_img {
  width: 150px;
  height: 150px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* .Lp20_block5_box1_img img {
  position: relative;
}

.Lp20_block5_box1_img img::after {
  content: '';
  position: absolute;
  right: 0px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background: #002fde !important;
  width: 500px !important;
  height: 5px !important;
} */

.Lp20_block5_box1_img::before {
  content: '';
  position: absolute;
  left: -7px;
  top: 59px;
  transform: translateY(-50px);
  background: #002fde;
  background-repeat: no-repeat;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
}

.Lp20_block5_box:nth-child(1) .Lp20_block5_box1_img::before {
  content: "1";

}

.Lp20_block5_box:nth-child(2) .Lp20_block5_box1_img::before {
  content: "2" !important;

}

.Lp20_block5_box:nth-child(3) .Lp20_block5_box1_img::before {
  content: "3" !important;

}

.Lp20_block5_box1_img img::after {
  content: "";
  position: absolute;
  left: 622px;
  top: 147px;
  transform: translateY(-50px);
  background-color: #015bf3;
  background-repeat: no-repeat;
  width: 47px;
  height: 10px
}

.lp20_block5_button button {
  padding: 6px 45px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  color: #ffffff;
  margin-top: 30px;
  font-size: 22px;
}



.Lp20_block5_box_wrapper {
  margin-top: 108px;
}



.Lp20_block5_main_wrapper {
  background: #f0f7ff;
  padding-bottom: 65px;
  padding-top: 65px;
}
.Lp20_block5_main_box{
  position: relative;
}
.Lp20_block5_main_box::after {
  content: '';
  position: absolute;
  height: 5px;
  width: calc(100% - 416px);
  background-color: #99bbe2;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}


@media screen and (max-width: 1199px) {
  .Lp20_block5_main_box {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;

  }
  .Lp20_block5_main_box::after{
    display: none;
  }
  .Lp20_block5_box {
    width: 50%;
  }

  .Lp20_block5_sub_wrapper_text h1 {
    font-size: 33px;
    font-weight: 600;
  }

  .Lp20_block5_sub_wrapper_text p {
    font-size: 27px;
    font-weight: 400;
  }

  .Lp20_block5_box p {
    font-size: 25px;
  }

  .Lp20_block5_main_wrapper {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .Lp20_block5_box_wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 856px) {
  .Lp20_block5_sub_wrapper_text h1 {
    font-size: 25px;
    font-weight: 600;
  }

  .Lp20_block5_sub_wrapper_text p {
    font-size: 20px;
    font-weight: 400;
  }

  .Lp20_block5_box p {
    font-size: 18px;
    line-height: 25px;
  }

  .lp20_block5_button button {
    margin-top: 12px;
    font-size: 16px;
    padding: 9px 42px;
    line-height: 21px;
  }

  .Lp20_block5_box_wrapper {
    margin-top: unset;
  }

  .Lp20_block5_sub_wrapper_text::before {
    left: 48%;
    top: 173%;
    width: 43px
  }

  .Lp20_block5_main_wrapper {
    padding-bottom: 1px;
  }
}
@media screen and (max-width: 650px) {
  .Lp20_block5_sub_wrapper_text::before {
    top: 151%;
}
}

/* ----------------------------landing page 20 block6 css------------------------ */

.Lp20_block6_sub_wrapper {
  display: flex;
  justify-content: space-between;
  /* margin: 107px 0; */
  align-items: center;
}

.Lp20_block6_box {
  border: 1px solid #d7e6f8;
  justify-content: center;
  padding: 32px;
  height: 353px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  margin: 13px;
  margin-top: -5%;
  background: #ffffff;
}

.Lp20_block6_box_text {
  font-size: 26px;
  text-align: center;
}

.lp20_block6_button {
  text-align: center;
}

.lp20_block6_button Button {
  padding: 10px 56px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  border-radius: 7px;
  color: #ffffff;
  margin-top: 55px;
  font-size: 22px;
  text-transform: capitalize;
}

@media screen and (max-width: 1199px) {
  .Lp20_block6_sub_wrapper {
    flex-wrap: wrap;
    margin-top: 25px;
  }

  .Lp20_block6_box {
    width: 47%;
    margin: 1.5%;
  }

  .lp20_block6_button Button {
    margin-top: 5px;
  }
}
@media screen and (max-width: 499px) {
  .Lp20_block6_box_text {
    font-size: 20px;
    line-height: 25px;
}
}

/* ----------------------------landing page 20 block7 css------------------------ */
.Lp20_block7_sub_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.Lp20_block7_sub_sec1_wrapper img {
  display: block;
  max-width: 100%;
  padding-left: 80px;
  position: relative;
  top: -90px;
}

.Lp20_block7_sub_sec2_wrapper h1 {
  font-size: 26px;
  font-weight: normal;
  width: 85%;
  margin-bottom: 11px;
}

.Lp20_block7_sub_sec2_wrapper h1 span {
  font-weight: 600;
}

.Lp20_block7_sub_sec2_wrapper p {
  font-size: 22px;
  font-weight: normal;
  width: 85%;
}


.Lp20_block7_sub_sec2_wrapper {
  width: 60%;
  color: #ffffff;
  margin-left: 4%;
}

.Lp20_block7_sub_wrapper {
  background: linear-gradient(90deg,
      rgba(0, 33, 215, 1) 0%,
      rgba(0, 56, 226, 1) 41%,
      rgba(1, 88, 241, 1) 100%);
  margin-top: 138px;
}

.Lp20_block7_sub1_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lp20_block7_sub1_sec_wrapper {
  width: 18%;
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  height: 95px;
}

.lp20_block7_textfild_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
}

/* .lp20_block7_textfild_button .MuiInputBase-root {
    height: 100%;}
 .lp20_block7_textfild_button .MuiFormControl-root {
    top: 10%;
}  
.lp20_block7_textfild_button .MuiFormControl-root {
    width: 67%;
    margin-right: 10px;
}  */

.lp20_block7_button Button {
  padding: 10px 56px;
  background: linear-gradient(90deg,
      rgba(15, 196, 119, 1) 0%,
      rgba(17, 196, 155, 1) 48%,
      rgba(18, 196, 190, 1) 100%);
  border-radius: 7px;
  color: #ffffff;
  /* margin-top: 55px; */
  font-size: 22px;
  text-transform: capitalize;
}

.Lp20_block7_sub1_sec_wrapper img {
  display: block;
  max-width: 100%;
}

@media only screen and (max-width: 1299px) {
  .Lp20_block7_sub_sec2_wrapper {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 1299px) {
  .Lp20_block7_sub_wrapper {
    margin-top: 25px
  }
}

@media only screen and (max-width: 1199px) {
  .Lp20_block7_sub_sec2_wrapper p {
    font-size: 19px;
    line-height: 25px;
  }

  .Lp20_block7_sub_sec2_wrapper h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 999px) {

  .landingPage20_block1_header_wrapper img {
    width: 350px;
    display: block;
    max-width: 90%;
  }

  .Lp20_block7_sub_wrapper {
    padding: 20px;
  }

  .Lp20_block7_sub_wrapper {
    flex-direction: column;
  }

  .Lp20_block7_sub_sec1_wrapper img {
    position: unset;
  }

  .Lp20_block7_sub_sec2_wrapper h1 {
    font-size: 26px;
    width: 100%;
    padding-right: 5%;
  }

  .Lp20_block7_sub_sec2_wrapper p {
    font-size: 22px;
    width: 100%;
    padding-right: 5%;
  }

  .Lp20_block7_sub_sec2_wrapper {
    width: 100%;
    margin: 0;
    margin-top: 15px;
    padding: 0;
  }

  .Lp20_block7_sub_sec1_wrapper img {
    padding-left: unset;
  }
}

@media only screen and (max-width: 850px) {
  .Lp20_block7_sub1_sec_wrapper {
    height: 63px
  }

  .Lp20_block7_sub1_sec_wrapper img {
    width: 90px;
    max-width: none;
  }
}

@media only screen and (max-width: 679px) {
  .Lp20_block7_sub_sec2_wrapper h1 {
    font-size: 20px;
    line-height: 26px;
    padding: 0;
  }

  .lp20_block2_text h1 {
    font-size: 20px;
    line-height: 26px;
  }

  .lp20_block2_text p {
    font-size: 18px;
    margin-top: 15px;
    line-height: 24px;
  }

  .landingpage13_frm_main_wrapper .form_title_wrapper {
    padding: 10px;
  }

  .lp20_block2_form .form_title_wrapper h2 span,
  .lp20_block2_form .l2submitbtnsection button {
    font-size: 20px;
    line-height: 26px;
  }

  .landingPage20_block1_header_wrapper {
    flex-direction: column;
  }

  .landingPage20_block2_header_text h1 {
    line-height: 32px;
    font-size: 26px;
  }

  .landingPage20_block2_header_text p {
    line-height: 22px;
    font-size: 18px;
  }

  .landingPage20_block2_header_text h2 {
    font-size: 16px;
    line-height: 25px;
  }

}

@media only screen and (max-width: 600px) {
  .Lp20_block7_sub1_sec_wrapper {
    height: 85px;
    width: 49%;
    margin-bottom: 10px
  }

  .Lp20_block7_sub1_sec_wrapper img {
    width: 100%;
  }

  .Lp20_block7_sub1_wrapper {
    flex-wrap: wrap;
  }

  .Lp20_block7_sub1_sec_wrapper:last-child {
    margin: 0 auto;
  }
}

/* ----------------------------landing page 20 block footer css------------------------ */
.Lp20_block_footer_sub_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lp20_block_footer_main {
  background: #0b395a;
  padding: 25px;
  padding-bottom: 15px;
}

.Lp20_block_footer_sub_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lp20_block_footer_text h5 {
  font-size: 26px;
  font-weight: 600;
}

.Lp20_block_footer_text p {
  font-size: 22px;
}

.Lp20_block_footer_text h1 {
  font-size: 22px;
  font-weight: 100;
}

.Lp20_block_footer_text h1 span {
  font-weight: 600 !important;
}

.Lp20_block_footer_text {
  color: #ffff;
  line-height: 35px;
}

.Lp20_block_footer_sub1_wrapper p {
  font-size: 14px;
  line-height: 20px;
  color: #9eb1be;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
}

.Lp20_block_footer_sub1_wrapper {
  border-top: 1px solid #244e6b;
}

.Lp20_block_footer_text_button .Lp20_block_footer_text_Enrollment {
  padding: 17px 44px;
  background: #015bf3;
  /* border-radius: 7px; */
  color: #ffffff;
  /* margin-top: 17px; */
  font-size: 22px;
  margin-left: 50px;
  text-transform: capitalize;
}


.Lp20_block_footer_text_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lp20_block_footer_sub_wrapper {
  margin: 25px 0;
}

@media screen and (max-width: 1600px) {
  .Lp20_block_footer_text_button {
    margin-right: 80px;
  }

  .Lp20_block_footer_img {
    margin-left: 25px;
  }
}

@media screen and (max-width: 1500px) {
  .Lp20_block_footer_text_button {
    margin-right: 0;
  }

  .Lp20_block_footer_img {
    margin-left: 0;
  }
}

@media screen and (max-width: 1400px) {
  .Lp20_block_footer_text_button .Lp20_block_footer_text_Enrollment {
    font-size: 18px;
    margin-left: 9px;
  }
}

@media screen and (max-width: 1300px) {
  .Lp20_block_footer_text h5 {
    font-size: 22px;
  }

  .Lp20_block_footer_text p {
    font-size: 20px;
  }

  .Lp20_block_footer_text h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .Lp20_block_footer_sub_wrapper {
    flex-direction: column;
  }

  .Lp20_block_footer_text_button {
    flex-direction: column;
  }

  .Lp20_block_footer_text {
    line-height: unset;
  }

  .Lp20_block_footer_text h5 {
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .Lp20_block_footer_text h1 {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 10px;
  }

  .Lp20_block_footer_text p {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 10px;
  }

  .Lp20_block_footer_sub_wrapper {
    margin: unset;
  }

  .Lp20_block_footer_text_button .Lp20_block_footer_text_Enrollment {
    padding: 15px 17px;
  }
}
@media screen and (max-width: 400px) {
  .Lp20_block_footer_text_button .Lp20_block_footer_text_Enrollment {
    font-size: 15px;
  }
}




.landingPage20_block2_header_video .react-player__shadow {
  display: none !important;
}

.landingPage20_block2_header_video button {
  background-color: #015bf3;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: 700;
  border-radius: 7px;
}

.landingPage20_block2_header_video svg {
  width: 100px;
  height: 100px;
  color: #fff;
  cursor: pointer;
}




.lp20_video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.MOdallanding20 .submitbtnsection button{
  background: linear-gradient(90deg, rgba(15, 196, 119, 1) 0%,
   rgba(17, 196, 155, 1) 48%, rgba(18, 196, 190, 1) 100%);
   color: #fff;
}
.MOdallanding20 .modalBaseBox {
    
  border: 10px solid #12c4be !important;
}
.MOdallanding20 .modal_icon_button {
  background-color: #0b9f9a !important;
}

@media screen and (max-width: 899px) {
  .landingPage20_block2_header_video svg {
    width: 80px;
    height: 80px;
  }

  .lp20_video_icon .Lp14block1_sub_sec2_wrapper_ply_btn {
    width: 110px;
    height: 110px;
  }

  .landingPage20_block2_header_video button {
    padding: 8px 13px;
    font-size: 17px;
  }
}

@media screen and (max-width: 599px) {
  .landingPage20_block2_header_video svg {
    width: 60px;
    height: 60px;
  }

  .lp20_video_icon .Lp14block1_sub_sec2_wrapper_ply_btn {
    width: 90px;
    height: 90px;
  }

  .landingPage20_block2_header_video button {
    padding: 7px 12px;
    font-size: 16px;
  }

  .Lp20_block5_box {
    width: auto;
  }

  .Lp20_block6_box {
    width: 97%;
    margin: 10px;
  }
}
@media screen and (max-width: 499px) {
  .landingPage20_block2_header_video button {
    padding: 5px 10px;
    font-size: 14px;
  }
  .Lp20_block6_box {
    height: auto;
  }
  .Lp20_block7_sub_sec2_wrapper h1 {
    font-size: 17px;
    line-height: 23px;
  }
  .Lp20_block7_sub_sec2_wrapper p {
    font-size: 16px;
    line-height: 22px;
  }
  .Lp20_block5_sub_wrapper_text p {
    font-size: 18px;
    line-height: 23px;
  }
  .Lp20_block5_sub_wrapper_text h1 {
    font-size: 21px;
    line-height: 27px;
  }
  .Lp20_block5_sub_wrapper_text::before {
    top: 174%;
}
}