@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.demoimage11{display: block;max-width: 100%;}

.landing_page6_bannerblock .lpage_logowrapper {
    margin-bottom: 0;
}

.landing_page6_bannerblock .landingOne .form_style .formfild input, .landing_page6_bannerblock .landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
    font-weight: var(--main-font-Bold);
    padding: 9px 10px;
    border-radius: 0;
}
.landing_page6_bannerblock .form_style .MuiFormLabel-root{top: -8px;font-weight: var(--main-font-Medium)}
.landing_page6_bannerblock .form_style .formWrapperClass{padding: 0;}
.landing_page6_bannerblock .formwrapper{
  padding: 37px;padding-top: 0;
  outline: 7px solid #0000000d;
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/form_bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
      background-color: #032435;
      border: 0;
      border-radius: 15px;
}

.landing_page6_bannerblock .banner_footer img{display: block;width: 100%;}
.landing_page6_bannerblock .form_title_wrapper{
  background: rgb(0,227,254);
background: linear-gradient(180deg, rgba(0,227,254,1) 0%, rgba(78,172,254,1) 100%);
position: relative;
margin-bottom: 35px;
top: -7px;
border-radius: 0 0 8px 8px;
}
.landing_page6_bannerblock .form_title_wrapper:after{
  content: '';
  width: 100%;
  height: 67%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
 background: rgb(0,227,254);
background: linear-gradient(180deg, rgba(0,227,254,1) 0%, rgba(61,206,254,1) 100%);
      border-radius: 0 0 50% 50%;
          opacity: 0.3;
}
.landing_page6_bannerblock .form_title_wrapper::before{
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/shadow.png);
  background-repeat: no-repeat;
  background-position: center top;
      background-size: 100% auto;
}



.landing_page6_bannerblock .form_style .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent !important;
}
.landing_page6_bannerblock .submitbtnsection button{
background: rgb(255,148,7);
background: linear-gradient(180deg, rgba(255,148,7,1) 0%, rgba(255,148,7,1) 50%, rgba(247,123,1,1) 50%, rgba(247,123,1,1) 100%);
border: 0;
margin-top: 35px;
}




/* @media only screen and (max-width: 1666px) {
.landing_page6_bannerblock{background-position: right 310px top, right 590px top 233px, center center;}
} */