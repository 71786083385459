.LandingPage9Body{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9BG.webp');
 background-size: cover; width: 100%;  
}

.LandingPage9Body_wrapper{ position: relative; padding: 30px; height: 100%;}

.landingpage9_img1{ position: absolute; right: 0; bottom: 0; z-index:3; width: 460px;}

.LandingPage9Body_Top{ display: flex; flex-wrap: wrap; justify-content: space-between;}
.LandingPage9Body_Top .LandingPage9Body_TopLeft{ width: calc(460px - 40px); display: flex; justify-content: center; align-items: center; padding: 10px 20px;   border: solid 1px #a5bac5; border-radius: 4px;}

 .LandingPage9Body_Top .LandingPage9Body_TopLeft img{ display: block; width:auto; max-width: 100%;   }    

 .LandingPage9Body_TopRight{background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_topimg.webp');
 background-size: cover; width: calc(100% - 440px); border-radius: 4px; padding: 20px;     display: flex;
 flex-direction: column;
 justify-content: center;
}
.prospect_Details .LandingPage9FormCon .previewfield .textareafild{margin-top: 0;}

.LandingPage9Body_TopRight h2{ font-size: 38px; line-height: 38px; text-transform: uppercase;}
.LandingPage9Body_TopRight h3{ font-size: 52px; line-height: 52px; text-transform: uppercase;}

.LandingPage9Body_TopRight  span{background: rgb(253,254,254);
    background: linear-gradient(180deg, rgba(253,254,254,1) 0%, rgba(169,189,199,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.LandingPage9Body_TopRight  strong{background: rgb(255,251,0);
    background: linear-gradient(180deg, rgba(255,251,0,1) 0%, rgba(255,207,0,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
.LandingPage9Body_Formwrapper{ margin-top: 25px; position: relative; overflow: hidden;}

.LandingPage9Body_Formwrapper::before{ content: ''; display: block; width: 100%; height:calc(100% - 70px); background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9BGForm.webp');
    background-size: cover; border-radius: 15px; position: absolute; left: 0; top: 0;  z-index: 1;}

.LandingPage9Body_Formwrapper_con{ margin: 40px; margin-bottom: 0px; width: auto; background:#093f5b url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_BG2.webp');
    background-size: cover;  border: solid 6px #6cbbf5; border-radius: 15px; padding: 18px; position: relative; z-index:2;}      

 .LandingPage9FormCon{ position: relative; z-index: 9;} 

 .LandingPage9FormCon .MuiFormControl-root{width: 100%;}

 .LandingPage9FormCon .previewfield{ display: flex; flex-wrap: wrap; justify-content: space-between;}
 .LandingPage9FormCon .previewfield .formfild{ width: 100%; margin-bottom: 25px;    }
 .LandingPage9FormCon .previewfield .form_radio{ width: 100%; margin-bottom: 15px;   }
 .LandingPage9FormCon .previewfield .formfild1{ width: 100%;}
 .LandingPage9FormCon .previewfield .formfild2{ width: 33%;}
 .LandingPage9FormCon .previewfield .formfild4{ width:24.5%;}
 .LandingPage9FormCon .MuiInputBase-root{border-radius: 0; background: #fff; border: none!important; outline: none!important;}
  .LandingPage9FormCon legend{display: none;}  
 
 .LandingPage9FormCon label.Mui-focused{display: none;}  
.LandingPage9FormCon input{ border: none!important; outline: none!important;     font-size: 1rem; }
.LandingPage9FormCon fieldset{ border: none!important; outline: none!important; top: 0 !important;} 
.LandingPage9FormCon .textareafild{ position: relative; font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;}
.LandingPage9FormCon .textareafild .MuiFormControl-root label{ display: none;}
.LandingPage9FormCon .textareafild textarea{ border: none!important; outline: none!important; resize: none; height: 80px!important;     padding: 16.5px 14px;  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400; width: 100%;    font-size: 1rem;}
 
.LandingPage9FormCon .MuiInputLabel-root div{ font-size: 20px; white-space: normal; margin-bottom: 10px; font-weight: bold; color: #fff; word-wrap: break-word; }

.LandingPage9FormCon .previewfield .form_radio  .MuiInputLabel-root div{ margin-bottom: 5px;}
.LandingPage9FormCon .previewfield .form_radio label span{ color: #fff; font-size: 18px; font-weight: bold;}
 
.LandingPage9FormCon .submitbtnsection9{    display: block;  text-align: right;   width: 100%;}
.LandingPage9FormCon .submitbtnsection9 button{ background: rgb(38,244,254);
    background: linear-gradient(180deg, rgba(38,244,254,1) 0%, rgba(75,219,254,1) 50%, rgba(41,206,254,1) 51%, rgba(74,177,254,1) 100%);
border: solid 1px #fff; cursor: pointer; border-radius: 10px; font-size: 45px; color: #fff; text-transform: uppercase; font-weight: bold; width: 300px;
line-height: 60px; border-radius: 4px;     text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}

/* .LandingPage9FormCon  .MuiFormLabel-root{ top: 12px;} */

  .LandingPage9FormCon .MuiFormHelperText-root{ margin: 0; background-color: #d32f2f; color: #fff!important; padding: 5px 5px 4px 5px;}


  .LandingPage9FormCon .css-1h7anqn {
    display: block!important;
    flex-flow: initial!important;
}

.LandingPage9FormCon .MuiFormLabel-filled, .LandingPage9FormCon label.Mui-focused, .LandingPage9FormCon legend {
    display: none;
}


.LandingPage9BottomLogo{ margin: 20px 0 0 0; display: flex; justify-content: center; display: flex;}
.LandingPage9BottomLogo span{ display: block; width: 360px; padding: 15px; background: rgb(223,232,238);
    background: linear-gradient(180deg, rgba(223,232,238,1) 0%, rgba(254,254,254,1) 50%, rgba(223,232,238,1) 100%);
    border: solid 1px #fff;
    border-radius: 10px;}

.LandingPage9BottomLogo span img{ width: 100%;}

.LandingPage9FormCon .previewfield .employeeNum, .LandingPage9FormCon .previewfield .webSite{width: 49.5% !important;}

@media only screen and (max-width: 1640.98px) {
    .landingpage9_img1{ width: 420px;}

}  
  
@media only screen and (max-width: 1460.98px) {
    .LandingPage9Body_TopRight h2{ font-size: 35px; line-height: 35px;}
    .LandingPage9Body_TopRight h3{ font-size: 45px; line-height: 45px;}

    .LandingPage9Body_Top .LandingPage9Body_TopLeft {  width: calc(380px - 40px);}
     .LandingPage9Body_TopRight{ width: calc(100% - 360px);}
}  

@media only screen and (max-width: 1360.98px) {
  
    .LandingPage9FormCon .previewfield .formfild4 {   width: 49.5%;  }
    
}  
   
@media only screen and (max-width: 1199.98px) {
    .LandingPage9Body_Top{ display: block;}
    .LandingPage9Body_wrapper{ padding: 15px;}
    .LandingPage9Body_Top .LandingPage9Body_TopLeft{ width: auto;  padding: 10px;}
    .LandingPage9Body_Top .LandingPage9Body_TopLeft img{ width: auto; max-width: 90%; }
    .LandingPage9Body_TopRight{ width: auto; margin-top: 10px; text-align: center;}
    .landingpage9_img1{ display: none;}

    .LandingPage9Body_Formwrapper::before{ display: none;}
    .LandingPage9Body_Formwrapper_con{ margin: 0; width: auto;}
    .LandingPage9Body_Formwrapper{ margin: 25px 0 0 0;}

    .LandingPage9FormCon .submitbtnsection9{ text-align: center;}

    .LandingPage9BottomLogo {  margin: 20px 0 0 0px;  text-align: center;  }

    .LandingPage9BottomLogo span{ display: inline-block; margin: 0 auto; width: auto;  }

    .LandingPage9BottomLogo span img{ width: auto; max-width: 100%;}
}

@media only screen and (max-width: 991.98px) {
    .LandingPage9FormCon .previewfield .formfild2{ width: 100%;}
    .LandingPage9FormCon .MuiInputLabel-root div{ font-size: 18px;}
    .LandingPage9FormCon .previewfield .form_radio label span{ font-size: 16px;}

    .LandingPage9FormCon .previewfield .formfild4{ width: 100%;}

    .LandingPage9Body_TopRight h2{ font-size: 25px; line-height: 25px;}
    .LandingPage9Body_TopRight h3{ font-size: 35px; line-height: 35px;}
}
@media only screen and (max-width: 800.98px){
.LandingPage9FormCon .previewfield .employeeNum, .LandingPage9FormCon .previewfield .webSite{width: 100% !important;}
}
@media only screen and (max-width: 480.98px) {
    .LandingPage9FormCon .submitbtnsection9 button{ width: 90%; font-size: 35px;}
}
    
