@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.landingpage14_common_width{width: 1320px;margin: 0 auto;}
/* body {
    font-family: 'Raleway', sans-serif;

} */
.landingoage14_main {font-family: 'Raleway', sans-serif !important;}
/* -------------------------sou_chak--------------------- */
.Lp14block1_main_wrapper{background: #000;}
.Lp14blocklogo_main_wrapper{background-color: #fff;padding: 20px;}
.Lp14blocklogo_sub_wrapper img{display: block;max-width: 100%;margin: 0 auto;}
.Lp14block1_sub_left_wrapper{background-color: #015bf3;width: 50%;  position: relative;    display: flex; align-items: center; justify-content: center;}
.Lp14block1_sub_left_wrapper2::before{  content: "";
    position: absolute;
    right: -163px;
    top: 50%;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/lp14_banner_aftr_bg.webp);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 180px;
    height: 100%;
    z-index: 99;
    transform: translateY(-50%);
    }
.Lp14block1_sub_right_wrapper{position: relative;width: 50%;}
.Lp14block1_sub_right_wrapper img{display: block;max-width: 100%;position: relative;height: 100%;}
.Lp14block1_sub_sec1_wrapper{padding: 70px 20px;width: 100%;    padding-left: 299px;    display: flex; flex-direction: column;justify-content: center;}
.Lp14block1_sub_wrapper{display: flex;justify-content: space-between;}
.Lp14block1_sub_sec1_wrapper h1{color: #fff;font-size: 60px;line-height: 65px;font-weight: 800;margin-bottom: 30px;}
.Lp14block1_sub_sec1_wrapper h2{font-size: 35px;font-weight: 700;line-height: 40px;color: #fff;margin-bottom: 30px;}
.Lp14block1_sub_sec1_wrapper h4{color: #fff;font-size: 25px;line-height: 28px;font-weight: 600;margin-bottom: 30px;font-style: italic;}
.Lp14block1_sub_sec1_wrapper p{font-size: 18px;color: #fff;line-height: 22px;font-style: italic;}
.Lp14block1_sub_sec2_wrapper{display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    /* bottom: -106px; */
    transform: translate(-50% ,-50%);
    left: 52%;
    top: 60%;}
.Lp14block1_sub_sec2_wrapper button{background-color: #015bf3;padding: 10px 20px;font-size: 20px;line-height: 24px;color: #fff;border: none;cursor: pointer;font-weight: 700;border-radius: 7px;}
.Lp14block1_sub_sec2_wrapper svg{    width: 100px;
    height: 100px;
    color: #fff;cursor: pointer;}
  .Lp14block1_sub_sec2_wrapper_ply_btn {     background: #011d2ec9;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 20px;}  
/* .Lp14block1_sub_sec2_wrapper_ply_btn{background-color: #011c2e;} */
.Lp14block2_main_wrapper{background-color: #fff;padding: 20px;}
.Lp14block2_sub1_wrapper{margin-bottom: 20px;}
.Lp14block2_sub1_wrapper button{background-color: #015bf3;padding: 10px 20px;color: #fff;font-size: 22px;font-weight: 700;line-height: 22px;margin: 0 auto;border: none;border-radius: 5px;    width: fit-content;height: 70px;max-width: 100%;text-transform: uppercase;}
.Lp14block2_sub2_wrapper{display: flex;justify-content: space-between;flex-wrap: wrap;    margin-bottom: 50px;}
.Lp14block2_sub2_sec_wrapper{width: 32%;border: 1px solid #d8d8d8;border-radius: 5px;padding: 20px;margin-bottom: 30px;}
.Lp14block2_sub2_sec_wrapper img{display: block;max-width: 100%;}
.Lp14block2_sub2_sec_wrapper{display: flex;justify-content: space-around;align-items: center;}
.Lp14block2_sub2_text_sec_wrapper{display: flex;justify-content: center;align-items: center;padding: 10px;}
.Lp14block2_sub2_text_sec_wrapper p{color: #0b395a;font-size: 18px;line-height: 22px;font-weight: 700;}
.Lp14block2_sub3_sec2_wrapper h1{font-size: 60px;line-height: 65px;font-weight: 800;color: #0b395a;margin-bottom: 15px;}   
.Lp14block2_sub3_sec2_wrapper h1 span{color: #015bf3;}
.Lp14block2_sub3_sec1_wrapper img{display: block;max-width: 100%;}
.Lp14block2_sub3_wrapper{display: flex;justify-content: space-between;align-items: center;margin-bottom: 50px;}
.Lp14block3_main_wrapper{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Lp14block3_family_bg.webp);
    background-repeat: no-repeat;background-size: cover;max-width: 100%;padding-top: 80px;    background-color: #f6f6f6;background-position: bottom left;}
.Lp14block3_sub_wrapper{background-color: #fff;width: fit-content;padding: 45px;border-radius: 5px;    margin-left: 46%;    position: relative; bottom: -81px;}
.Lp14block3_sub_wrapper h2{color: #015bf3;font-size: 35px;line-height: 40px;font-weight: 800;margin-bottom: 10px;    position: relative;}
.Lp14block3_sub_wrapper span{color: #0b395a;}
.Lp14block3_sub_wrapper  h2::after{    content: ""; position: absolute; background-color: #015bf3; width: 60px; height: 10px; left: 0; bottom: -20px;}
.Lp14block3_sub_wrapper p{font-size: 25px;line-height: 30px;font-weight: 500;color: #0b395a;margin-top: 30px;    position: relative; padding-left: 40px;}
.Lp14block3_sub_wrapper span{font-weight: 800;}
.Lp14block3_sub_wrapper p::before{content: ""; position: absolute;  left: 0px;  top: -7px; background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/landingpage14_block3_logo.png); 
    background-size: 100%; background-position: center; background-repeat: no-repeat; width: 20px; height: 45px; border-radius: 0; box-shadow: none;}
.Lp14block4_main_wrapper{background-color: #f6f6f6;padding: 180px 20px 50px 20px;}  
.Lp14block4_sub_wrapper h2{font-size: 35px;font-weight: 800;line-height: 40px;text-transform: uppercase;color: #0b395a;position: relative;text-align: center;}  
.Lp14block4_sub_wrapper h2::after{    content: ""; position: absolute; background-color: #015bf3; width: 60px; height: 10px;  transform: translateX(-50%);left: 50%;bottom: -20px;}
.Lp14block4_sub1_wrapper{display: flex;justify-content: space-between;    margin-top: 50px;    margin-bottom: 40px;}
.Lp14block4_sub1_sec_wrapper{display: flex;justify-content: center;align-items: center;width: 33%;padding: 20px;flex-direction: column;}
.Lp14block4_sub1_sec_wrapper img{display: block;max-width: 100%;margin-bottom: 15px;}
.Lp14block4_sub1_sec_wrapper h3{font-size: 24px;line-height: 29px;font-weight: 700;color: #0b395a;margin-bottom: 15px;}
.Lp14block4_sub1_sec_wrapper h4{font-size: 28px;line-height: 33px;font-weight: 700;color: #3d81f4;margin-bottom: 15px;}
.Lp14block4_sub1_sec_wrapper p{font-size: 22px;line-height: 27px;font-weight: 600;color: #86959f;text-align: center;}
.Lp14block8_main_wrapper{background-color: #0b395a;padding: 50px 20px;}
.Lp14block8_sub_wrapper h1{font-size: 36px;line-height: 41px;font-weight: 800;color: #fff;text-align: center;text-transform: uppercase;margin-bottom: 15px;}
.Lp14block8_sub_wrapper h2{font-size: 26px;line-height: 31px;font-weight: 700;color: #fff;text-align: center;position: relative;margin-bottom: 15px;}
.Lp14block8_sub_wrapper h2::after{    content: ""; position: absolute; background-color: #fff; width: 60px; height: 10px;  transform: translateX(-50%);left: 50%;bottom: -20px;}
.Lp14block8_sub1_wrapper{display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap;margin-top: 50px;}
.Lp14block8_sub1_sec_wrapper{display: flex;justify-content: center;align-items: center;flex-direction: column;width: 33%;padding: 20px;}
.Lp14block8_img_sec{background-color: #fff;height:151px ;width:151px ;border-radius: 50%;position: relative;display: flex;justify-content: center;align-items: center;margin-bottom: 15px;}
.Lp14block8_img_sec::after{  content: ""; position: absolute; background-color: #015bf3; width: 60px; height: 60px; left: -25px;top: 0;border-radius: 50%;display: flex;justify-content: center;align-items: center;color: #fff;font-size: 36px;font-weight: 600;}
.Lp14block8_sub1_sec_wrapper h2{font-size: 24px;line-height: 29px;font-weight: 700;color: #fff;text-align: center;margin-bottom: 15px;}
.Lp14block8_sub1_sec_wrapper p{font-size: 20px;line-height: 25px;font-weight: 500;color: #fff;text-align: center;}

.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(1) .Lp14block8_img_sec::after {content: '1';}
.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(2) .Lp14block8_img_sec::after {content: '2';}
.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(3) .Lp14block8_img_sec::after {content: '3';}
.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(4) .Lp14block8_img_sec::after {content: '4';}
.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(5) .Lp14block8_img_sec::after {content: '5';}
.Lp14block8_sub1_wrapper .Lp14block8_sub1_sec_wrapper:nth-of-type(6) .Lp14block8_img_sec::after {content: '6';}
.Lp14block7_main_wrapper{background-color: #fff;padding: 25px 20px;}
.Lp14block7_sub_wrapper{display: flex;justify-content: space-between;align-items: center;    margin-bottom: 40px;}
.Lp14block7_sub_sec1_wrapper img{display: block;max-width: 100%;}
.Lp14block7_sub_sec2_wrapper h1{font-size: 36px;line-height: 41px;font-weight: 800;color: #015bf3;text-transform: uppercase;position: relative;}
.Lp14block7_sub_sec2_wrapper h1::after{    content: "";  position: absolute;  background-color: #015bf3; width: 60px; height: 10px; left: 0; bottom: -20px;}
.Lp14block7_sub_sec2_wrapper span{color: #0b395a;}
.Lp14block7_sub_sec2_wrapper {width: 60%;}
.Lp14block7_sub1_wrapper{display: flex;justify-content: space-between;align-items: center;    margin-bottom: 40px;}
.Lp14block7_sub1_sec_wrapper{width: 18%;border: 1px solid #d8d8d8;border-radius: 7px;display: flex;justify-content: center;align-items: center;padding: 25px;height: 109px;}
.Lp14block7_sub1_sec_wrapper img{display: block;max-width: 100%;}
.Lp14block8_sub2_wrapper{background-color: #fff;    padding: 95px 60px;    position: relative; bottom: -110px;border-radius: 5px;box-shadow: 0 0 10px 0 #53535357;}
.Lp14block8_sub2_wrapper em{font-size: 22px;line-height: 36px;font-weight: 700;color: #0b395a;margin-bottom: 20px;}
.Lp14block8_sub2_wrapper p{color: #015bf3;font-size: 24px;line-height: 29px;font-weight: 600;text-align: center;    margin-top: 20px;}
.Lp14block8_sub2_wrapper .Lp14block8_sub2_1img {   position: absolute; right: 0px; bottom: -66px;width: auto;}
.Lp14block8_sub2_wrapper .Lp14block8_sub2_img {    position: absolute;  left: 0px;  top: -73px;}
.Lp14block9_main_wrapper{background-color: #fff;padding: 140px 20px 40px 20px;}
.Lp14block9_sub_wrapper h1{font-size: 24px;line-height: 31px;font-weight: 700;color: #0b395a;    position: relative;padding-left: 66px;margin-bottom: 15px;}
.Lp14block9_sub_wrapper h1::before{    content: "";  position: absolute; left: 0px; top: 0px; background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/lp14_bl9_1logo.png); background-size: 100%;background-position: center; background-repeat: no-repeat; width: 50px;  height: 45px; border-radius: 0; box-shadow: none;}
.Lp14block9_sub_wrapper p{font-size: 22px;line-height: 27px;font-weight: 500;color: #0b395a;margin-bottom: 15px;}
.Lp14blockheader_main_wrapper{background-color: #0b395a;padding: 25px;}
.Lp14blockheader_sub_wrapper p{font-size: 22px;line-height: 27px;color: #fff;text-align: center;text-transform: uppercase;}
.Lp14blockfooter_main_wrapper{background-color: #0b395a;padding: 25px;padding-bottom: 15px;}
.Lp14blockfooter_sub_wrapper img{display: block;max-width: 100%;margin: 0 auto 15px;}
.Lp14blockfooter_sub_wrapper h5{font-size: 18px;line-height: 23px;color: #fff;text-align: center;font-weight: 400;text-transform: uppercase;margin-bottom: 10px;}
.Lp14blockfooter_sub_wrapper p{text-align: center;font-size: 18px;line-height: 23px;color: #fff;margin-bottom: 10px;}
.Lp14blockfooter_sub_wrapper p a{text-decoration: none;font-weight: 600;font-size: 20px;color: #015bf3;}
.Lp14blockfooter_sub1_wrapper h4{font-size: 14px;line-height: 20px;color: #fff;text-align: center;font-weight: 500;margin-top: 10px;}
.Lp14block2_sub3_sec2_wrapper .Lp14block2_sub1_wrapper button{margin: auto 0 0 0;}
.Lp14block2_sub3_sec2_wrapper h1:nth-of-type(2){margin-bottom: 20px;}
/* .Lp14blockfooter_sub_wrapper{border-bottom: 1px solid #fff;} */
.Lp14blockfooter_sub1_wrapper{border-top: 1px solid #fff;}
.Lp14blockfooter_sub_wrapper {    margin-bottom: 50px;}
/* .page14_react_player {
    width: 100% !important;
    height: 100% !important;
} */
.page14_react_player {width: 100% !important;height: 100% !important;}
.Lp14block1_main_wrapper .landpage_videowrapper .landpage_video_subwrapper {padding-top: 0;}
.Lp14block1_main_wrapper .react-player__preview {padding-top: 50.25%;background-size: 100% 100% !important;}
/* .Lp14block1_main_wrapper .react-player__preview .react-player__shadow {position: absolute;
    top: 50%;
    transform: translateY(-50%);background: #1268a0c9 !important;} */
   .Lp14block1_sub_right_wrapper .react-player__preview{position: relative;}
   .Lp14block1_sub_right_wrapper .react-player__shadow {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);    width: 100px !important; height: 100px !important;border: 17px solid #222222d1;}
   .page14_react_player video{display: block;}
.Lp14block2_sub3_sec2_wrapper{width: 62%;}
/* -------------------------sou_chak--------------------- */
/* //-------------------{abhranil_css}---------------------// */
.landingpage14_bannerblock5_mainwrapper {
    background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/as_lp14_block5_bg.webp);
    margin: 0 auto;
    border-radius: 0;
    padding: 30px 50px;
    background-size: cover;
}
.landingpage14_bannerblock5_mainwrapper li::before{
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/as_lp14_bl5_right_img.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 40px;
    border-radius: 0;
    box-shadow: none;
}
.landingpage14_bannerblock5_mainwrapper li {
    position: relative;
    font-size: 18px;
    color: #f7f9fa;
    font-weight: 500;
    padding: 10px 0px 10px 28px;
}
.landingpage14_bannerblock5_mainwrapper .hr {
    display: none;
}
.landingpage14_bannerblock5_mainwrapper h2 {
    text-align: center;
    font-size: 36px;
    color: #f7f9fa;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 41px;
    margin-top: 95px;
    margin-bottom: 20px;
}
.landingpage14_bannerblock5_mainwrapper p {
    text-align: center;
    font-size: 26px;
    color: #f7f9fa;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 31px;
    margin-bottom: 20px;
    position: relative;
}
.landingpage14_bannerblock5_mainwrapper p::after{
    content: "";
    position: absolute;
    background-color: #015bf3;
    width: 60px;
    height: 10px;
    transform: translateX(-50%);
    left: 50%;
    bottom: -20px;
}
.lp14_block5_last_button button{
    background-color: #015bf3;
    padding: 15px 42px;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin: 0 auto;
    margin-top: 25px;
    border: none;
    border-radius: 5px;
}
.landingpage14_bannerblock5_mainwrapper ul{
    margin-top: 50px;
}   
.landingpage14_bannerblock5_mainwrapper .landingpage5_bannerblock3_thirdblock{
    background: none;
}

.landingpage14_bannerblock10_mainwrapper{
    background-color: #f6f6f6;
    margin: 0 auto;
    padding: 30px 50px;
}
.lp14_block10_sub_wrapper h1 {
    font-size: 36px;
    font-weight: 800;
    line-height: 41px;
    color: #0b395a;
    text-align: center;
    position: relative;
}
.lp14_block10_sub_wrapper h1 ::after{
    content: "";
    position: absolute;
    background-color: #015bf3;
    width: 60px;
    height: 10px;
    transform: translateX(-50%);
    left: 50%;
    bottom: -20px;
}
.lp14_block10_sub_wrapper h1 span {
    color: #015bf3;
}
.lp14_block10_sub_wrapper {
    margin-top: 96px;
    margin-bottom: 65px;
}
.lp14_block10_sub_wrapper2 {
    background: #fff;
    padding: 32px 28px;
}
.lp14_sub_block p {
    font-size: 24px;
    font-weight: 700;
    color: #0b395a;
    position: relative;
    padding-left: 30px;
}
/* .landingpage14_bannerblock10_mainwrapper .css-1fjvggn-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
    margin-bottom: 20px;
} */
/* .landingpage14_bannerblock10_mainwrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled){
    background: #fff;
} */
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root {
    padding: 20px 22px;
    margin-bottom: 20px;
    border: none;
    padding-left: 36px;
    border-radius: 5px;
}
.landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #0b395a;
    padding-left: 10px; 

}
.landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper2 {
    font-size: 22px;
    font-weight: 500;
    color: #0b395a;
}
/* .landingpage14_bannerblock10_mainwrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
    background-color: #fff;
} */
.landingpage14_bannerblock10_mainwrapper .MuiButtonBase-root {
    background: #fff;
    flex-direction: row;
}
.landingpage14_bannerblock10_mainwrapper .MuiAccordionSummary-expandIconWrapper svg {
    transform: rotate(90deg);
    font-size: 30px;
    background: #e5e5e5;
    padding: 5px;
    border-radius: 50%;
}
.landingpage14_bannerblock10_mainwrapper .MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    padding-left: 20px;
}

.landingpage14_bannerblock10_mainwrapper .MuiPaper-root .MuiAccordionSummary-content::before {
    content: '1';
    position: absolute;
    left: -40px;
    top: -7px;
    width: 45px;
    height: 45px;
    color: #fff;
    background: #015bf3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(2) .MuiAccordionSummary-content::before {
    content: '2';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(3) .MuiAccordionSummary-content::before {
    content: '3';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(4) .MuiAccordionSummary-content::before {
    content: '4';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(5) .MuiAccordionSummary-content::before {
    content: '5';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(6) .MuiAccordionSummary-content::before {
    content: '6';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(7) .MuiAccordionSummary-content::before {
    content: '7';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(8) .MuiAccordionSummary-content::before {
    content: '8';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(9) .MuiAccordionSummary-content::before {
    content: '9';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(10) .MuiAccordionSummary-content::before {
    content: '10';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(11) .MuiAccordionSummary-content::before {
    content: '11';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(12) .MuiAccordionSummary-content::before {
    content: '12';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(13) .MuiAccordionSummary-content::before {
    content: '13';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(14) .MuiAccordionSummary-content::before {
    content: '14';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(15) .MuiAccordionSummary-content::before {
    content: '15';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(16) .MuiAccordionSummary-content::before {
    content: '16';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(17) .MuiAccordionSummary-content::before {
    content: '17';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(18) .MuiAccordionSummary-content::before {
    content: '18';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(19) .MuiAccordionSummary-content::before {
    content: '19';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(20) .MuiAccordionSummary-content::before {
    content: '20';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(21) .MuiAccordionSummary-content::before {
    content: '21';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(22) .MuiAccordionSummary-content::before {
    content: '22';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(23) .MuiAccordionSummary-content::before {
    content: '23';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(24) .MuiAccordionSummary-content::before {
    content: '24';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(25) .MuiAccordionSummary-content::before {
    content: '25';
}
.landingpage14_bannerblock10_mainwrapper .MuiPaper-root:nth-child(26) .MuiAccordionSummary-content::before {
    content: '26';
}
.landingpage14_bannerblock10_mainwrapper .MuiAccordionSummary-content {
    position: relative;
    padding-left: 10px;
}
.Lp14last_sub_wrapper button {
    color: #0f65f2;
    background-color: #fff;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    margin: 0 auto;
    border: 2px solid #015bf3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 275px;
    height: 70px;
    max-width: 100%;
    justify-content: center;
}
.Lp14_last_button_wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    width: auto;
    margin-top: 40px;
}
.Lp14_last_button_wrapper .Lp14block2_sub1_wrapper {
    margin-bottom: 0;margin-left: 10px;
}
.landingpage14_bannerblock10_mainwrapper .MuiAccordionDetails-root{
    margin-top: 10px;
}
/* .Lp14_last_button_wrapper .Lp14block2_sub1_wrapper button {
    background-color: #015bf3;
    color: #fff;
    padding: 12px 35px;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    margin: 0 auto;
    border: none;
    border-radius: 2px
} */
.enrol_text {font-weight: 700;font-size: 24px !important;}

/* //////////////////////{abhranil_css_end}//////////////////////////////////////////////////// */

/* ////////////////////////////////////Soubhik Debnath////////////////////////////////////// */
.Lp14_block6{padding-top: 100px;padding-bottom: 125px; background-color: #f6f6f6;}
.Lp14_block6_TOPheading h1 {text-transform: uppercase;font-size: 36px;font-weight: 800;color: #0b395a;line-height: 48px;text-align: center;margin-bottom: 120px;position: relative;}
.Lp14_block6_TOPheading h1 span{color: #015bf3;}
.Lp14_block6_TOPheading h1::before{content: ''; width: 60px;height: 9px; background-color: #015bf3;position: absolute;transform: translateX(-50%);left: 50%;bottom: -20px;}
.Lp14_b6_subwrpr{display: flex;justify-content: space-between;}  
.Lp14_b6_subwrpr .Lp14_b6_comn_minblock {width: 50%;background-color: #fff;padding: 32px;}
.Lp14_b6_subwrpr .Lp14_b6_comn_minblock h2{font-size: 24px;font-weight: 700;color: #0b395a;text-transform: capitalize;margin-bottom: 15px;}
.Lp14_b6_subwrpr .Lp14_b6_comn_minblock h3{font-size: 24px;font-weight: 500;font-style: italic; text-transform: capitalize;color: #86959f; margin-bottom: 15px;}
.Lp14_b6_subwrpr .Lp14_b6_comn_minblock p{font-size: 22px;font-weight: 500;color: #0b395a;line-height: 30px;}
.Lp14_b6_subwrpr .Lp14_b6_comn_minblock img{margin-bottom: 15px;}
.Lp14_b6_subwrpr:first-child{margin-bottom: 25px;}
.Lp14_b6_comn_minblock:nth-child(even){margin-left: 25px;}
.landingpage14_common_width hr {
    border: 1px solid #e9e9e9;
}

.Lp14_block9_bottom_main{display: flex;padding: 50px 0;justify-content: space-between;}
.Lp14_block9_bottom_sub_left h1{font-size: 24px;font-weight: 700;color: #0b395a;line-height: 36px;text-transform: capitalize;position: relative;padding-left: 52px;margin-bottom: 30px;}
.Lp14_block9_bottom_sub_left h1::before{content: '';background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/LP14_block9_table_h1.png);background-repeat: no-repeat;position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 42px;height: 51px;}
.Lp14_block9_bottom_sub_left ul li{font-size: 22px; font-weight: 500;color: #0b395a;line-height: 30px;list-style: none;margin-bottom: 15px; padding-left: 30px;position: relative;}
.Lp14_block9_bottom_sub_left ul li::before{content: ""; position: absolute;left: 0px; top: 60px;transform: translateY(-50px); background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/as_lp14_bl5_right_img.png);  background-repeat: no-repeat;  width: 20px; height: 40px;}
.Lp14_block9_bottom_sub_left {width: 39%;}
.Lp14_block9_bottom_sub_right{width: 60%;}
.Lp14_block9_btm_sub_table_main_wrpr{width: 620px;max-width: 100%;}
.Lp14_block9_table_head{padding: 20px 40px;width: 100%;background-color: #015bf3;}
.Lp14_block9_table_head h1{font-size: 24px;font-weight: 700;text-transform: uppercase;line-height: 36px;text-align: center;color: #fff;}
.Lp14_block9_btm_sub_table_main_wrpr table {width: 100%;border-collapse:collapse ;}
.Lp14_block9_btm_sub_table_main_wrpr table thead tr th{font-size: 18px;color: #fff;font-weight: 600;line-height: 36px;background-color: #005a87;border: none;text-align: left; padding-left: 20px;}
.Lp14_block9_btm_sub_table_main_wrpr table tbody tr td{font-size: 16px;color: #0b395a;font-weight: 500;line-height: 25px;text-align: left;padding: 2px 2px 2px 20px;background-color: #fff;}
.Lp14_block9_btm_sub_table_main_wrpr table tbody tr:nth-child(even) td{background-color: #f3f3f3;}
.Lp14_block9_btm_sub_table_main_wrpr table tbody {border: 1px solid #e3e3e3;}
.Lp14_block9_btm_sub_table_main_wrpr table thead {border: 1px solid #005a87;}
.Lp14_block9_table_tag{position: absolute; background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/LP14_tag.png);background-repeat: no-repeat;background-size: contain;padding: 48px 48px;height: 253px;bottom: 15px;right: 0; width: 255px;display: flex;justify-content: center;align-items: center;}
.Lp14_block9_bottom_sub_right{position: relative;}
.Lp14_block9_table_tag p{font-size: 18px; font-weight: 500;color: #fff;line-height: 25px;text-align: center;}
.Lp14_block9_btm_sub_table_main_wrpr table thead tr th:first-child{width: 42%;}
.Lp14_block9_btm_sub_table_main_wrpr table thead tr th:nth-child(2){width: 26%;}

.Lp14_block9_btm_sub_table_main_wrpr table tbody tr td{
   outline: 20px solid #fff;
}



.Lp14_block9_footer_text_wrpr h1{font-size: 24px; font-weight: 700;color: #0b395a; line-height: 36px; text-transform: capitalize; position: relative; padding-left: 65px;margin-bottom: 15px;margin-top: 30px;}
.Lp14_block9_footer_text_wrpr h1::before { content: ''; background-repeat: no-repeat;position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 51px;height: 51px;}
.Lp14_block9_footer_text_wrpr .Lp14_block9_footer_text_wrpr_sub1 h1::before{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/Lp14_b9_footer_h1LOGO.png);}
.Lp14_block9_footer_text_wrpr .Lp14_block9_footer_text_wrpr_sub2 h1::before{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage14/Lp14_b9_footer_h1LOGO2.png);}
.Lp14_block9_footer_text_wrpr p{font-size: 22px; font-weight: 500;color: #0b395a;line-height: 30px;margin-bottom: 30px;}
.SD_video_img    {height: 100%;}

/* ////////////////////////////////////Soubhik Debnath end ////////////////////////////////////// */

/* -------------------landing page 17 css by subhashis-------------------------- */



/* ---------------------------------responsive---------------------------------- */
.test{width: fit-content;}

@media only screen and (max-width: 1740px){
    .Lp14block3_main_wrapper{background-position:left -97px center }
 
}
@media only screen and (max-width: 1680px){
    .Lp14block1_sub_sec1_wrapper{padding-left: 244px;}
}
@media only screen and (max-width: 1620px){
    .Lp14block1_sub_sec1_wrapper{    padding-left: 149px;}
}

@media only screen and (max-width: 1540px){
    .Lp14block3_main_wrapper{background-position:left -275px center }
}
@media only screen and (max-width: 1470px){
    .Lp14block1_sub_sec1_wrapper {    padding-left: 104px;}

}

@media only screen and (max-width: 1400px){
    .landingpage14_common_width{width: 96%;}
    .Lp14block1_sub_sec1_wrapper {padding-left: 68px;}
    .Lp14block1_sub_sec1_wrapper h1 {font-size: 45px; line-height: 52px;    margin-bottom: 20px;}
    .Lp14block1_sub_sec1_wrapper h2{    font-size: 30px;    line-height: 36px;margin-bottom: 20px;}
    .Lp14block1_sub_sec1_wrapper h4{    font-size: 20px; line-height: 26px;    margin-bottom: 20px;}
    .Lp14block1_sub_sec1_wrapper { padding: 20px 20px;}
  
}
@media only screen and (max-width: 1315px){
    .Lp14block3_main_wrapper {
        background-position: left -350px center;
    }
}

@media only screen and (max-width: 1300px){
    .Lp14_block9_table_tag{right: -38px;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:nth-child(2){width: 21%;}
    .Lp14block1_sub_sec1_wrapper {padding-left: 32px;}
}
@media only screen and (max-width: 1199px){
    .landingoage14_main {margin: -15px;}
    .Lp14block1_sub_sec2_wrapper {top: 50%;}
    .Lp14block3_sub_wrapper{width: 75%;margin: 0 auto;}
    .Lp14block3_main_wrapper{padding-top: 43%;background-size: 200%;background-position: left top;}
    .Lp14block4_main_wrapper {    padding: 465px 20px 50px 20px;margin-top: -346px;}
    .Lp14block2_sub2_sec_wrapper{    flex-direction: column; align-items: center;width: 49%;margin-bottom: 24px;}
    .Lp14block2_sub3_wrapper{flex-direction: column;}
    .Lp14block2_sub2_text_sec_wrapper p{text-align: center;}
    .Lp14block2_sub3_sec2_wrapper h1{text-align: center;}
    .Lp14block4_sub1_sec_wrapper{width: 49%;flex-wrap: wrap;}
    .Lp14block4_sub1_sec_wrapper h3{text-align: center;}
    .Lp14block8_sub1_sec_wrapper{width: 50%;}
    .Lp14block7_sub_sec1_wrapper{width: 30%;}
    .Lp14_block6{padding-top: 30px;padding-bottom: 20px;}
    .Lp14_block6_TOPheading h1{margin-bottom: 40px;}
    .Lp14_block9_bottom_main{flex-direction: column;}
    .Lp14_block9_bottom_sub_left{width: auto;margin-bottom: 20px;}
    .Lp14_block9_bottom_sub_right{width: 100%;}
    .Lp14_block9_btm_sub_table_main_wrpr{width: auto;}
    .Lp14_block9_table_tag {right: 10px;}
    .Lp14block1_sub_left_wrapper2::before {display: none;}
    .Lp14block1_sub_wrapper{flex-direction: column-reverse;}
    .Lp14block1_main_wrapper{background: none;}
    .Lp14block1_sub_right_wrapper {    width: 100%;}
    .Lp14block1_sub_sec1_wrapper br{display: none;}
    .Lp14block1_sub_left_wrapper{width: 100%;height: auto;}
    .Lp14block1_sub_right_wrapper img{width: 100%;}
    .Lp14block2_sub3_sec2_wrapper .Lp14block2_sub1_wrapper button{margin: 0 auto;}
    .Lp14block2_sub3_sec2_wrapper{width: auto;}

    .landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper1{
        font-size: 22px;
        line-height: 27px;
    }
    .lp14_block10_sub_wrapper{
        margin-top: 40px;
        margin-bottom: 45px;
    }
    .landingpage14_bannerblock10_mainwrapper{
        padding: 30px 20px 20px 20px;
    }
}

@media only screen and (max-width: 950px){
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:first-child{width: 32%;}
}
@media only screen and (max-width: 850px){
    .Lp14block7_sub1_sec_wrapper{    height: 63px;}
    .Lp14block7_sub1_sec_wrapper img{    width: 90px;max-width: none;}
    .Lp14block4_main_wrapper{padding: 380px 20px 20px 20px;}
    .Lp14_block6_TOPheading h1{font-size: 30px;    line-height: 37px;}
    .Lp14block7_main_wrapper{padding-bottom: 20px;}
    .Lp14block9_main_wrapper{    padding: 140px 20px 20px 20px;}
    .Lp14block2_sub3_sec2_wrapper h1{    font-size: 40px; line-height: 46px;}
    .Lp14block3_sub_wrapper h2{    font-size: 26px; line-height: 32px;}
    .Lp14block3_sub_wrapper p{    font-size: 20px; line-height: 24px;}
    .landingpage14_bannerblock5_mainwrapper h2 {    margin-top: 20px;    font-size: 30px;    line-height: 36px; }
    .landingpage14_bannerblock5_mainwrapper p {    font-size: 22px;    line-height: 28px;}
    .landingpage14_bannerblock5_mainwrapper ul{    margin-top: 10px;}
    .lp14_block5_last_button button{margin-top: 0;}
    .Lp14_b6_subwrpr .Lp14_b6_comn_minblock h2{font-size: 22px;}
    .Lp14_b6_subwrpr .Lp14_b6_comn_minblock h3{font-size: 21px;}
    .Lp14_b6_subwrpr .Lp14_b6_comn_minblock p{    font-size: 18px;line-height: 24px;}
    .Lp14block7_sub_sec2_wrapper h1 {    font-size: 25px; line-height: 31px;}
    .Lp14block7_sub_wrapper{flex-direction: column;}
    .Lp14block7_sub_sec2_wrapper h1::after{transform: translateX(-50%);left: 50%;}
    .Lp14block7_sub_sec2_wrapper{width: 100%;}
    .Lp14block7_sub_sec1_wrapper { width: auto;margin-bottom: 25px;}
    .Lp14block8_sub_wrapper h1 { font-size: 30px;line-height: 36px;}
    .Lp14block8_sub_wrapper h2 {font-size: 22px; line-height: 27px;}
    .Lp14block8_sub1_sec_wrapper h2 { font-size: 22px; line-height: 27px;}
    .Lp14block8_sub1_sec_wrapper p { font-size: 18px;  line-height: 23px;}
    .Lp14block8_sub2_wrapper em {  font-size: 19px; line-height: 24px;}
    .Lp14block8_sub2_wrapper p{    font-size: 22px; line-height: 27px;margin-top: 10px;}
    .Lp14block9_sub_wrapper h1 { font-size: 22px;line-height: 28px;}
    .Lp14block9_sub_wrapper p { font-size: 18px;line-height: 24px;}
    .Lp14block4_sub1_sec_wrapper p {font-size: 18px;line-height: 22px;}
    .Lp14block4_sub1_sec_wrapper h3 { font-size: 22px;line-height: 26px;}
    .Lp14block4_sub1_sec_wrapper h4 { font-size: 24px; line-height: 28px;}
    .Lp14block4_sub_wrapper h2 {font-size: 30px;}
    .Lp14block3_sub_wrapper { width: 82%;bottom: 0px;padding: 30px;}
    .Lp14block1_sub_sec2_wrapper svg {    width: 150px;height: 112px;}
    .Lp14_block9_bottom_sub_left ul li{font-size: 18px;line-height: 22px;}
    .Lp14_block9_footer_text_wrpr p{font-size: 18px;line-height: 22px;}
    .Lp14_block9_bottom_sub_left h1{font-size: 22px;line-height: 28px;}
    .Lp14_block9_footer_text_wrpr h1{font-size: 22px;line-height: 28px;}
    .Lp14block1_sub_sec1_wrapper h1{text-align: center;}
    .Lp14block1_sub_sec1_wrapper h2{text-align: center;}
    .Lp14block1_sub_sec1_wrapper h4{text-align: center;}
    .Lp14block1_sub_sec1_wrapper p{text-align: center;}
    .landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper2{font-size: 18px;line-height: 22px;}
}
@media only screen and (max-width: 799px){
    .lp14_block10_sub_wrapper{
    margin-top: 35px;
    margin-bottom: 40px;
    }
    /* .Lp14_last_button_wrapper .Lp14block2_sub1_wrapper button {
    width: 230px;
    height: 60px;
    } */
    .Lp14last_sub_wrapper button{
        /* width: 220px; */
        height: auto; 
        font-size: 18px;
    }
    .Lp14block2_sub1_wrapper button {
        /* width: 220px; */
        font-size: 20px;
         height: auto;
    }
}

@media only screen and (max-width: 786px){
    .Lp14_block9_table_tag{position: initial;margin: 0 auto;margin-top: 20px;}
    .Lp14_block9_bottom_main{padding-bottom: 15px;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:nth-child(2){width: auto;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:first-child{width: auto;}
    .Lp14_last_button_wrapper {flex-direction: column;}
    .Lp14_last_button_wrapper .Lp14block2_sub1_wrapper {margin: 10px 0;}
}
@media only screen and (max-width: 682px){
    .Lp14_block9_table_head h1{line-height: 28px;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th{line-height: 27px;}
    .Lp14block1_sub_sec1_wrapper p {font-size: 16px;    line-height: 20px;}
    .Lp14block1_sub_sec1_wrapper h4 {font-size: 18px; line-height: 24px;}
    .Lp14block1_sub_sec1_wrapper h2 {font-size: 25px;line-height: 32px;}
    .Lp14block1_sub_sec1_wrapper h1 {font-size: 40px; line-height: 46px;}
    .Lp14block1_sub_sec1_wrapper {padding-left: 20px;}
    .landingpage13_frm_main_wrapper .form_title_wrapper h2 span {font-size: 22px;line-height: 26px;}
    .SD_MOdallanding14 .landingpage13_frm_main_wrapper .form_title_wrapper {padding: 15px 12px;}
    .SD_MOdallanding14 .l2submitbtnsection button {font-size: 20px;line-height: 25px;}
    .SD_MOdallanding14 .l2submitbtnsection {margin-top: 20px;margin-bottom: 0;}
    .SD_MOdallanding14 .rolelist_mainwrp .formfild input {padding: 5px 14px !important;}
    .SD_MOdallanding14 .form_style .MuiFormLabel-root {top: -9px !important;font-size: 16px !important;}
    .SD_MOdallanding14 .state .MuiSelect-select {padding: 5px 14px !important;}
    .SD_MOdallanding14 .form_style .formWrapperClass {padding-bottom: 0;}

}
@media only screen and (max-width: 600px){
    .Lp14_b6_comn_minblock:nth-child(even){margin-left: 0;}
    .Lp14_b6_subwrpr .Lp14_b6_comn_minblock{width: 100%;margin-bottom: 20px;}
    .Lp14_b6_subwrpr{flex-direction: column;}
    .Lp14block4_sub1_wrapper{flex-direction: column;}
    .Lp14block4_sub1_sec_wrapper{width: 100%;}
    .Lp14_b6_subwrpr:first-child{margin-bottom: 0;}
    .Lp14block7_sub1_sec_wrapper{width: 49%;margin-bottom: 10px;}
    .Lp14block7_sub1_wrapper{flex-wrap: wrap;}
    .Lp14block7_sub1_sec_wrapper:last-child{margin: 0 auto;}
    .Lp14block7_sub1_sec_wrapper img {width: 100%;}
    .Lp14block7_sub1_sec_wrapper{height: 85px;}
    .Lp14block8_sub1_sec_wrapper{width: 100%;}
    .Lp14block3_sub_wrapper{bottom: -35px;width: 94%;padding: 20px;}
    .Lp14block2_main_wrapper{padding-bottom: 0;}
    .Lp14block2_sub3_wrapper{margin-bottom: 30px;}
    .Lp14block2_sub2_wrapper {margin-bottom: 25px;}
    .Lp14block1_sub_sec2_wrapper svg { width: 137px; height: 106px;}
    .Lp14block4_main_wrapper {padding: 421px 20px 20px 20px;}
}

@media only screen and (max-width: 599px){
    .landingpage14_bannerblock5_mainwrapper {
        padding: 25px 32px;
    }
    .landingpage14_bannerblock5_mainwrapper h2{
    margin-top: 35px;
    margin-bottom: 15px;
    }
    .landingpage14_bannerblock5_mainwrapper .landingpage5_bannerblock3_thirdblock{
        padding: 12px 40px;
    }
    .Lp14_last_button_wrapper{
        width: 545px;
        max-width: 100%;
    }
    .Lp14_last_button_wrapper .Lp14block2_sub1_wrapper button{
        font-size: 18px;
       
    }
    .Lp14last_sub_wrapper button{
        font-size: 15px;
        line-height: 20px;
        width: 190px;
        height: 50px;
    }
}
@media only screen and (max-width: 550px){
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:first-child{width: 40%;}
    .Lp14block1_sub_sec2_wrapper svg { width: 75px; height: 93px;margin-bottom: 0;}
    .Lp14block1_main_wrapper .Lp14block1_sub_sec2_wrapper button{font-size: 16px;line-height: 21px;padding: 10px 15px;}
    .Lp14block1_sub_sec2_wrapper_ply_btn {width: 100px;height: 100px;}
}

@media only screen and (max-width: 499px){
    .SD_MOdallanding14 .form_style .formWrapperClass {max-height: 400px;overflow-y: auto;}
    .landingpage14_bannerblock5_mainwrapper ul{
        margin-top: 20px;
    }
    .landingpage14_bannerblock5_mainwrapper .landingpage5_bannerblock3_thirdblock ul{
        column-count: 2;
    }
    .landingpage14_bannerblock5_mainwrapper{
        padding: 10px 10px;
    }
    .lp14_block5_last_button button{
        padding: 15px 25px;
        font-size: 15px;
    }
    .landingpage14_bannerblock5_mainwrapper h2{
    font-size: 30px;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 10px
    }
    .landingpage14_bannerblock5_mainwrapper p{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    }
    .landingpage14_bannerblock5_mainwrapper p::after{
        background-color: #fff;
        width: 70px;
    }
    .landingpage14_bannerblock5_mainwrapper li::before{
        height: 30px;
    }
    .landingpage14_bannerblock5_mainwrapper  .landingpage5_bannerblock3_thirdblock{
        padding: 10px 12px;
    }
    .landingpage14_bannerblock5_mainwrapper li {
        font-size: 14px;
    }

    .Lp14last_sub_wrapper button{
    font-size: 14px;
    line-height: 19px;
    padding: 10px 15px;
    width: 190px;
    height: 43px;
  }
    .Lp14_last_button_wrapper .Lp14block2_sub1_wrapper button{
    font-size: 13px;
    line-height: 17px;
   
    }
    .Lp14_last_button_wrapper{
        margin-top: 25px;
    }
    /* .Lp14block2_sub1_wrapper button {
        width: 170px;
        font-size: 18px;
        height: 43px;
        padding: 0;
    } */
    .landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper1{
    font-size: 18px;
    line-height: 22px;
    }
    .landingpage14_bannerblock10_mainwrapper .MuiPaper-root .MuiAccordionSummary-content::before{
    width: 35px;
    height: 35px;
    left: -30px;
    top: -5px;
    font-size: 22px;
    }
    .lp14_block10_sub_wrapper{
    margin-top: 10px;
    margin-bottom: 35px;
    }
    .lp14_block10_sub_wrapper h1{
        font-size: 30px;
        line-height: 35px;
    }
    .landingpage14_bannerblock10_mainwrapper{
        padding: 20px 20px 20px 20px;
    }
    .landingpage14_bannerblock10_mainwrapper .MuiButtonBase-root{
        align-items: center;
        padding: 0;
    }
}

@media only screen and (max-width: 450px){
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:first-child{width: auto;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th{padding-left: 7px;}
    .Lp14_block9_btm_sub_table_main_wrpr table tbody tr td{font-size: 14px;      word-break: break-word;  padding:10px 7px;}
    .Lp14_block9_btm_sub_table_main_wrpr table thead tr th:nth-child(2){width: 34%;}
    .Lp14_block9_table_head{    padding: 10px 15px;}
}
@media only screen and (max-width: 400px){
    .Lp14block2_sub2_sec_wrapper{width: 100%;}
    .Lp14block7_sub1_sec_wrapper{width: 100%;height: 109px;}
    .Lp14block8_sub2_wrapper .Lp14block8_sub2_img {left: -1px; top: -18px; width: 50px;}
    .Lp14block8_sub2_wrapper .Lp14block8_sub2_1img {right: -1px; bottom: -20px;width: 50px;}
    .Lp14block9_main_wrapper { padding: 30px 20px 20px 20px;}
    .Lp14block8_sub2_wrapper{bottom: 0;    padding: 45px 30px;}
    .Lp14_b6_subwrpr .Lp14_b6_comn_minblock img { display: block;  margin: 0 auto 15px;}
    .Lp14block1_sub_sec1_wrapper h1{    font-size: 35px;  line-height: 41px;}
    .Lp14blockheader_sub_wrapper p{font-size: 18px;line-height: 22px;}
    .SD_MOdallanding14 .modalBaseBox{padding: 8px;width: 95%;}
}
@media only screen and (max-width: 399px){
    .landingpage14_bannerblock5_mainwrapper .landingpage5_bannerblock3_thirdblock {
        padding: 10px 5px;
    }
    .Lp14block1_sub_sec2_wrapper svg {
        width: 55px;
        height: 55px;
    }
    .Lp14block1_sub_sec2_wrapper_ply_btn {
        width: 80px;
        height: 80px;
    }
 
    .Lp14last_sub_wrapper button{
        font-size: 13px;
        line-height: 17px;    
        padding: 0;
        width: 140px;
        height: 40px;
    }
    /* .landingpage14_bannerblock10_mainwrapper .MuiButtonBase-root{
        align-items: flex-start;
    } */
    .landingpage14_bannerblock10_mainwrapper .MuiPaper-root{
        padding: 18px;
        margin-bottom: 15px;
    }
    .landingpage14_bannerblock10_mainwrapper .MuiAccordionSummary-expandIconWrapper svg {
        font-size: 26px;
    }
    .landingpage14_bannerblock10_mainwrapper .MuiPaper-root .MuiAccordionSummary-content::before{
        width: 33px;
        height: 33px;
        font-size: 19px;
        left: -15px;
        top: 0;
    }
    .landingpage14_bannerblock10_mainwrapper .MuiAccordionSummary-content{
        padding-left: 0;
        margin-left: 0;
    }
    .landingpage14_bannerblock10_mainwrapper .lp14_txt_wrapper1{
        padding-left: 26px;
    }
    .lp14_block10_sub_wrapper h1{
        font-size: 28px;
        line-height: 33px;
    }
    .lp14_block10_sub_wrapper {
        margin-top: 5px;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 350px){
    .landingpage14_bannerblock5_mainwrapper li {
        font-size: 12px;
    }
}








/* ---------------------------------responsive---------------------------------- */

/* -------------responsive by subhashis ------------------- */
@media only screen and (max-width: 1890px){
    .Lp14block1_sub_sec1_wrapper {
        padding: 30px 20px;padding-left: 299px;}

}
@media only screen and (max-width: 1699px){
    .Lp14block1_sub_sec1_wrapper h1 {font-size: 44px;line-height: 47px;margin-bottom: 10px;}
    .Lp14block1_sub_sec1_wrapper h1 br {display: none;}
    .Lp14block1_sub_sec1_wrapper h4 {font-size: 23px;line-height: 27px;margin-bottom: 15px;}
    .Lp14block1_sub_sec1_wrapper h2 br, .Lp14block1_sub_sec1_wrapper h4 br {display: none;}
    .Lp14block1_sub_sec1_wrapper h2 {font-size: 30px;line-height: 34px;margin-bottom: 20px;}
}
@media only screen and (max-width: 1620px){
    .Lp14block1_sub_sec1_wrapper{    padding-left: 149px;}
}

@media only screen and (max-width: 1399px){
    .Lp14block1_sub_sec1_wrapper {padding-left: 8%;}

}

@media only screen and (max-width: 1299px){
    .Lp14block1_sub_sec1_wrapper h1 {font-size: 40px;line-height: 46px;}

}