/* .Videolanding4 .new_commercial_banner_left h2 {font-size: 95px; line-height: 105px; } */
/* .Videolanding4 .new_commercial_banner_left h3 {font-size: 56px; line-height: 64px; margin-bottom: 28px;} */

.Videolanding4 .lpage_banner_sub_wrapper {
    position: relative;
    background: none;
    padding: 0px;
    width: 920px;
    margin: 50px 0 50px 0;
}
/* .Videolanding4 .lpage_arrow_wrapper{background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/landing_page4VDO_IMG.png);background-size: cover; background-repeat: no repeat; background-position: center;    padding: 15px 35px;
    background-size: cover;
    border-radius: 10px;
    margin-top: -5px;
    width: 1010px;} */

.Videolanding4 .lpage_arrow_wrapper h2 {font-size: 40px;
        line-height: 48px;
        font-weight: var(--main-font-SemiBold); color: #fff;}

.Videolanding4 .new_commercial_banner_left {
            width: 68%;
        }
.Videolanding4 .new_commercial_banner_left_con {width:auto;}
/* .Videolanding4 .new_commercial_banner_right {
            width: 27%;
        } */
.Videolanding4 .lpage_banner_sub_wrapper{position: relative; z-index: 999;}        
.Videolanding4 .lpage_banner_sub_wrapper::after{content: ""; position: absolute; width: 100%; height: 42px; background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/vdo_shadow_4.png);background-repeat: no-repeat; background-size: 100% 100%; background-position: center; left: 50%; bottom: -33px; transform: translateX(-50%);z-index: -1;}        

@media(max-width:1760px){
.Videolanding4 .lpage_banner_sub_wrapper{width: 980px;}

}

@media(max-width:1660px){
.Videolanding4 .lpage_banner_sub_wrapper {
    width: 880px;
}

}
 

@media(max-width:1560px){
    .Videolanding4 .lpage_arrow_wrapper{width: 928px;}
    .Videolanding4 .lpage_banner_sub_wrapper{width: 900px;}
    /* .Videolanding4 .new_commercial_banner_right{width: 31%;} */
    .Videolanding4 .new_commercial_banner_left {
        width: 65%;
    }
    /* .Videolanding4 .new_commercial_banner_left h2 {
        font-size: 68px;
        line-height: 86px;
    } */
    /* .Videolanding4 .new_commercial_banner_left h3 {
        font-size: 47px;
        line-height: 58px;
       
    } */
    .Videolanding4 .lpage_arrow_wrapper h2 {
        font-size: 40px;
        line-height: 48px;}
    
    /* .Videolanding4 .new_l4form_box{ margin-bottom: 30px; margin-top:70px;} */
}
@media(max-width:1525px){
    /* .Videolanding4 .new_l4form_box .new_l4form_style_heading h2 { font-size: 28px; line-height: 30px;padding: 15px 10px;} */
    
}

/* @media(max-width:1517px){
    .Videolanding4 .new_l4form_box {    width: 96%;
         margin-left:0;}
} */


@media(max-width:1420px){
    .Videolanding4 .lpage_arrow_wrapper{width: 840px; margin-top: 22px;}
    .Videolanding4 .lpage_banner_sub_wrapper{width: 800px;}
    /* .Videolanding4 .new_commercial_banner_right{width: 29%;} */
    .Videolanding4 .lpage_arrow_wrapper h2 {
        font-size: 32px;
        line-height: 40px;}
    /* .Videolanding4 .new_commercial_banner_right{width:33%}  */
    /* .Videolanding4 .new_l4form_box {
        margin-bottom: 25px;
        margin-top: 30px;
    }    */
   
}
@media(max-width:1320px){
 
    .Videolanding4 .lpage_arrow_wrapper {
        width: 728px;
        margin-top: 30px;
    }
    .Videolanding4 .new_commercial_banner_left {
        width: 63%;
    }
    /* .Videolanding4 .new_commercial_banner_right {
        width: 36%;
    } */
    /* .Videolanding4 .new_l4form_box {
        margin-bottom: 20px;
        margin-top: 20px;
    } */
    
    
}

@media (max-width: 1226px){
.Videolanding4 .lpage_arrow_wrapper {
    margin-top: 20px;
}
/* .Videolanding4 .new_l4form_box{
    margin-top: 25px;
} */
}

@media(max-width:1199px){
    .Videolanding4 .new_commercial_banner_left {
        width: 100%;
    }

    .Videolanding4 .lpage_banner_sub_wrapper{ margin-top: 30px;}
    /* .Videolanding4 .new_commercial_banner_right {
        width: 100%;
    } */
    
    /* .Videolanding4 .new_l4form_box { width: 100%;
        margin-left:0; margin-top: 15px;} */
        

    .Videolanding4 .lpage_banner_sub_wrapper {
        width: auto;margin-bottom: 35px;
    }
    .Videolanding4 .lpage_arrow_wrapper {
        width: auto;
        margin-top: 0;
    }
    /* .Videolanding4 .new_commercial_banner_left h2 {
        font-size: 55px;
        line-height: 61px;
    } */
    /* .Videolanding4 .new_commercial_banner_left h3 {
        font-size: 38px;
        line-height: 50px;
        margin-bottom: 15px;
    } */
    .Videolanding4 .lpage_arrow_wrapper h2 {
        font-size: 28px;
        line-height: 38px;
    }

}
@media only screen and (max-width: 900px){
    .Videolanding4  .new_commercial_banner_left h2 {
        margin-top: 0px;
    }
}

/* @media(max-width:767px){
    .Videolanding4 .new_commercial_banner_left h3 {
        font-size: 32px;
        line-height: 40px;}
    
} */
@media(max-width:560px){
   
    .Videolanding4 .lpage_arrow_wrapper{
margin-bottom: 10PX;
    }

}
