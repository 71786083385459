@import url(https://fonts.googleapis.com/css2?family=Yellowtail&display=swap);
.contract-mainwrapper{display:block;overflow:hidden}
.contract-sub-wrapper{width:min(90%,1440px);display:block;margin:0 auto;background:#fff;padding:20px;margin-top:20px;overflow:hidden;clear:both;box-shadow:-6px -6px 24px #b0ad9a57,6px 6px 24px #c4c3bc57;margin-bottom:20px}
.contract-title-wrapper h2{font-size:20px;color:var(--orange)}
.contract-title-wrapper h2{font-size:20px;color:var(--orange);line-height:24px}
.contract-title-wrapper{padding:10px 10px;background:#f8f8f8;border:1px solid #e9e9e9;margin-bottom:10px}
.contract-title-wrapper.titlecenter{text-align:center}
.contract-text-wrapper p{margin-bottom:10px;font-size:16px;line-height:24px}
.contract-text-wrapper p i{font-style:normal;padding:0 9px;border-bottom:1px solid #898989;min-width:115px;margin:0 5px;text-align:center}
.contract-text-wrapper p strong{color:var(--blue2)}
.contract-text-wrapper p span{color:#14a007;font-weight:700}
.contract-container{display:flex;flex-wrap:wrap;background:#f6f6f6;padding:30px;border:1px solid #dedede}
.contract-col{width:calc(50% - 10px);margin:0 5px}
.contract-col h2{font-size:20px;font-weight:500}
.contract-col p b{font-family:Yellowtail,cursive;font-size:29px;color:#131313}
.contract-col p i{font-style:normal;text-decoration:underline;padding:0 8px}
.contract-col p{margin-bottom:8px}
.contract-col p:last-child{margin-bottom:0}
.contract-text-wrapper p a{color:var(--blue2);text-decoration:none}
@media only screen and (max-width:767px){
.contract-col{width:100%;margin:0 0 10px 0}
.contract-container{padding:15px}
}