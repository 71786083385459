.Mrr-name {
  margin: 0 0 15px 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.Mrr-user-data ul {
  margin: auto;
}
.Mrr-list {
  margin: auto;
}
.Mrr-list table {
  width: 100%;
  border-collapse: collapse;
}
.Mrr-user-data li {
  display: grid;
  grid-template-columns: 30% 70%;
  text-align: start;
}
.Mrr-user-data li strong {
  font-size: 0.7rem;
  white-space: nowrap;
  font-weight: 400;
  margin-right: 20px;
}
.Mrr-user-data li p {
  font-size: 0.7rem;
  font-weight: 300;
  margin-left:10px;
}
.Mrr-list table td {
  text-align: center;
}
.Mrr-list table th,
.Mrr-list table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.Mrr-list table td input {
  text-align: center;
    font-size: 20px;
  
    height: 60px;
    border: 1px solid #0000001f;
    border-radius: 4px;
    background-color: transparent;
}
.Mrr-list table td input:hover{
  border: 1px solid #000000;

}
.Mrr-list table td button {
  position: relative;
  z-index: 1;
  color: #0077b6;
  float: right;
  width: 50px;
  border: none;
  height: 30px;
  background-color: #caf0f8;
  border-radius: 4px;
  box-shadow: 0px 0px 9px -3px rgb(0 0 0 / 75%);
}
.Mrr-list table td button i {
  z-index: -1;
  position: relative;
}
.Mrr-header th svg{
  color: #333333ab;
  float: right;
  font-size: inherit;
}
.Mrr-header .user svg{
  float: initial;
    margin-left: 10px;
}
.Mrr-header .user{
  display: flex;
    justify-content: center;
    align-items: center;
}
.roles p {
  font-size: 10px;
}
.inputFild{
    width: 0%;
}
@media only screen and (max-width: 640px){
  .Mrr-list table td input{
    width: 80%;
  }
}
@media only screen and (max-width: 499px){
  .listing_heading h1{
    margin: 0 auto;
  }
  .srchfld_wrp{
    margin-left: auto;
  }
}
