.debrief_wraper{
    height: 600px;
    overflow: auto;
    padding: 20px;
}
.debrief_call{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
}
.debrief_call .MuiFormGroup-root{
    text-align: start;
}
.debrief_call .MuiFormGroup-root .MuiFormControlLabel-root{
padding: 10px 0;
}
.info_text .MuiFormHelperText-root{
    color: #0E35F7 !important;
    font-size: 16px;
    font-weight: 700;
}
.debrief_call .MuiFormControl-root{
    width: 100%;
}
.header{
padding:20px ;
}