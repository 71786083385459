.comp_main_wrap{
    background-image: url("../../../../../assets//image/workcomp.png");
    background-repeat: no-repeat;
    background-position: left -30px bottom 70px;
    background-size: 750px;
}

.text_org {
    color: rgb(252, 130, 45);
}
.comp_text_wrap{
    margin-left: 680px;
    padding-left: 20px;
}
.comp_text_wrap h1{
    font-size: 54px;
    margin-top: 10px;
}

.comp_text_wrap p{
    font-size: 24px;
   line-height: 32px;
   font-weight: 500;
   margin-top: 10px;
}

.comp_text_wrap .comp_list_wrap h3{
    font-size: 36px;
    color: rgb(28,102,172);
    font-weight: 600;
    margin-top: 20px;
}
.comp_text_wrap .comp_list_wrap p{
    font-size: 27px;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 20px;
}

.comp_text_wrap .comp_list_wrap ul{
    column-count: 2;
}

.comp_text_wrap .comp_list_wrap ul li{
    font-size: 23px;
    list-style: none;
    background-image: url("../../../../../assets//image/circle.png");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 40px;
    line-height: 50px;
   
   
}
.comp_footer{
    background-image: url("../../../../../assets/image/workcompfbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 40px 20px 40px;
    border-radius: 10px;
    margin-top: 35px;
}
.comp_footer p{
    font-size: 33px;
    font-weight: 600;
    color: white;  
    padding-left: 20px;
    position: relative;
    
}
.comp_footer p::before{
    content: "";
    width: 8px;
    height: 90%;
    background-color: rgb(254,115,0);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 7px;
}
.footer_text{
    background: linear-gradient(#fbed00, #ca9607 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;
}

@media screen and (max-width:1770px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -30px bottom 70px;
        background-size: 750px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 680px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 50px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 22px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 34px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 22px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
 
    .comp_footer p{
        font-size: 31px;
        font-weight: 600;
       
        
    }



    
}


@media screen and (max-width:1699px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -80px bottom 70px;
        background-size: 750px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 600px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 46px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 22px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 34px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 22px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
 
    .comp_footer p{
        font-size: 30px;
        font-weight: 600;
       
        
    }



    
}

@media screen and (max-width:1550px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -120px bottom 70px;
        background-size: 750px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 550px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 43px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 22px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 34px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 22px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
 
    .comp_footer p{
        font-size: 30px;
        font-weight: 600;
       
        
    }



    
}

@media screen and (max-width:1499px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -130px bottom 70px;
        background-size: 750px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 540px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 40px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 20px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 32px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 23px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 20px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
 
    .comp_footer p{
        font-size: 28px;
        font-weight: 600;
       
        
    }



    
}

@media screen and (max-width:1399px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -130px bottom 70px;
        background-size: 720px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 520px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 35px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 18px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 30px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 21px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 18px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
 
    .comp_footer p{
        font-size: 26px;
        font-weight: 600;
       
        
    }



    
}


@media screen and (max-width:1299px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: left -130px bottom 70px;
        background-size: 720px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin-left: 520px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 32px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 17px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 8px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 28px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 14px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 18px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 16px;
    }
    
   
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 18px;
        background-position: left top 10px;
        padding-left: 40px;
        line-height: 40px;
       
       
    }
 
    .comp_footer p{
        font-size: 26px;
        font-weight: 600;
       
        
    }  
}

@media screen and (max-width:1199px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 800px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 740px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 54px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 24px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 36px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 27px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
    .comp_text_wrap .comp_list_wrap ul{
        column-count: 2;
    }
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 23px;
        list-style: none;
        background-image: url("../../../../../assets//image/circle.png");
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 33px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:1099px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 750px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 680px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 48px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 22px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 36px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 22px;
        padding-left: 40px;
        line-height: 50px;
        background-position: left top 10px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 30px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:999px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 700px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 640px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 44px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 22px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 36px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 18px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 28px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:899px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 650px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 600px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 38px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 20px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 33px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 23px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 18px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 28px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}


@media screen and (max-width:799px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 650px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 600px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 33px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 20px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 33px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 23px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 20px;
        padding-left: 40px;
        line-height: 50px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 24px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:699px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 600px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 550px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 29px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 18px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 29px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 20px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 18px;
        padding-left: 40px;
        line-height: 30px;
        background-position: left top;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(2){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(5){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 20px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}


@media screen and (max-width:599px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 550px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 500px;
        padding-left: 20px;
    }
    .comp_text_wrap h1{
        font-size: 25px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 16px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 26px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 18px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 16px;
        padding-left: 40px;
        line-height: 25px;
        background-position: left top;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(2){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(5){
        margin-top: 20px;
        margin-bottom: 10px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 18px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:499px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 550px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 500px;
        padding-left: 0;
    }
    .comp_text_wrap h1{
        font-size: 20px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 16px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 26px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 18px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 15px;
        padding-left: 40px;
        line-height: 20px;
        background-position:left top;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(2){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(5){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 18px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}

@media screen and (max-width:399px) {


    .comp_main_wrap{
        background-image: url("../../../../../assets//image/workcomp.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 450px;
    }
    
    .text_org {
        color: rgb(252, 130, 45);
    }
    .comp_text_wrap{
        margin: 0;
        margin-top: 400px;
        padding-left: 0;
    }
    .comp_text_wrap h1{
        font-size: 18px;
        margin-top: 10px;
    }
    
    .comp_text_wrap p{
        font-size: 15px;
       line-height: 32px;
       font-weight: 500;
       margin-top: 10px;
    }
    
    .comp_text_wrap .comp_list_wrap h3{
        font-size: 24px;
        color: rgb(28,102,172);
        font-weight: 600;
        margin-top: 20px;
    }
    .comp_text_wrap .comp_list_wrap p{
        font-size: 16px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 20px;
    }
    
   
    
    .comp_text_wrap .comp_list_wrap ul li{
        font-size: 14px;
        padding-left: 40px;
        line-height: 20px;
       
       
    }

       
    .comp_text_wrap .comp_list_wrap ul li:nth-child(2){
        margin-top: 10px;
        margin-bottom: 10px;
       
       
    }

    .comp_text_wrap .comp_list_wrap ul li:nth-child(5){
        margin-top: 20px;
        margin-bottom: 10px;
       
       
    }
    .comp_footer{
        background-image: url("../../../../../assets/image/workcompfbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        margin-top: 35px;
    }
    .comp_footer p{
        font-size: 18px;
        font-weight: 600;
        color: white;  
        padding-left: 20px;
        position: relative;
        
    }
  
}