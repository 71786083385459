/* ----------------------souvik_css------------------ */

.landingpage13_frm_main_wrapper .landing_page5_bannerblock .landing-banner-right {
    width: calc(32% - 100px);
    margin-left: 100px;
    margin-top: 50px;
}
.landingpage13_frm_main_wrapper .landing_page5_bannerblock .formwrapper{
    background: #dcdddf;
    padding: 10px;
   
    border-radius: 8px;
   
    outline: 15px solid #7e7f80;
}
.landingpage13_frm_main_wrapper .formwrapper{
    background:#fff;
    padding: 10px;
    border: 1px solid #7e7f80;
    border-radius: 8px;
    outline: 15px solid #7e7f80;

}
.landingpage13_frm_main_wrapper .form_style .formfild input{
    background-color: #dcdddf;color: #000;
}
.landingpage13_frm_main_wrapper .landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input{
    padding: 16.5px 14px;
    background-color: #dcdddf;
    color: #303232;
    font-size: 18px;
    font-weight: var(--main-font-Bold);

}
.landingpage13_frm_main_wrapper .form_title_wrapper{
   
    background: #c50723;
    padding: 26px 12px;
    text-align: center;
    border-radius: 5px;
}
.landingpage13_frm_main_wrapper .form_title_wrapper h2 span{
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    display: block;
    background: rgb(255, 255, 255);
   
    -webkit-background-clip: text;
    

}
.landingpage13_main_wrapper .landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input{
    padding: 12px 14px;
    background-color: #dcdddf;
    color: #000;
    font-size: 18px;
    font-weight: var(--main-font-Bold);
}
.landingpage13_main_wrapper .landing_page5_bannerblock .form_style .MuiFormLabel-root {
    font-weight: var(--main-font-Bold);
    color: #000 !important;
    top: -3px;
    font-size: 18px;
    word-wrap: break-word;
}
.landingpage13_main_wrapper .landing_page5_bannerblock .l2submitbtnsection button{
    text-shadow: 0 0 2px #0f3a58;
    background: #117f9d;
    background-size: 100% 100%;
    border: none;
    border-radius: 5px;
    font-size: 24px;
    line-height: 29px;
    color: #ffff;
    font-weight: var(--main-font-SemiBold);
    text-transform: uppercase;
    padding: 20px;
    width: calc(100%);
    border: 2px solid #487594;
}
.landingpage13_main_wrapper .l5_videowrapper .l5_vdo_txt_wrp{
   background-color: #c50723 !important;
   background-image: none;
    padding: 15px 15px;
    margin: 0 auto;
    text-align: center;
    border-radius: 15px 15px 0 0;

}
.landingpage13_main_wrapper .l5_videowrapper .l5_vdo_txt_wrp h5{
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    text-align: center;

}
.landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 39px;
    line-height: 44px;
}
.landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2 span{
    color: #fff;
}
.landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper{
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/landingpage13_arrow.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 47px 60px 45px 39px;
    position: relative;
  
}
.landingpage13_frm_main_wrapper .email_btm_line p{
    margin: 5px 0;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3_subwrapper{
    background-image: none;
    background-color: #f1f1f1;
    border-radius: 0;
   
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3_thirdblock{
    background: #f1f1f1;
    border-radius: 0;
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3_thirdblock li{
    color: #000;
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3_thirdblock h2{
    color: #000; 
}
.landingpage13_block5_main_wrapper .gradiant_black_darkBG{
    background: #c50823;
    border-radius: 0px;
  
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3_thirdblock li::before{
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/landingpage13_logo_img.png);
}
.landingpage13_block5_main_wrapper .landingpage5_bannerblock3{
    background: #f1f1f1;
    padding-bottom: 65px;
}
.Landingpage13block4_main_wrapper{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/landingpageblock4_img.png);
background-repeat: no-repeat;background-position: bottom center;width: 100%;background-color: #ffffff;    padding: 100px 0;}
.Landingpage13block4_main_wrapper h1{text-transform: uppercase;text-align: center;font-size: 45px;line-height: 50px;font-weight: 700;    margin-top: -60px;}
.Landingpage13block4_cercial_sec{border-radius: 50%;height: 230px;width: 230px;background-color: #dadee4;padding: 7px; box-shadow: -1px 0px 5px 5px #b3b6bb inset;    display: flex; justify-content: center;align-items: center;position: relative;}
.Landingpage13block4_cercial_sub_sec{border-radius: 50%;height: 188px;width: 188px;background-color: #eaebf2;display: flex;justify-content: center;align-items: center;    z-index: 9;
}
.Landingpage13block4_cercial_sub_sec h2{font-size: 70px;font-weight: 800;color: #9ea0a8;}
.Landingpage13block4_sub_wrapper{display: flex;justify-content: space-around;align-items: center;padding-top: 40px;}
.Landingpage13block9_main_wrapper{background-color: #ffffff;padding: 80px 0;}
.Landingpage13block9_cmn_width{width: 1730px;margin: 0 auto;}
.Landingpage13block9_main_wrapper h1{font-size: 45px;line-height: 50px;font-weight: 700;color: #727272;text-align: center;margin-bottom: 20px;text-transform: uppercase;}
.Landingpage13block9_main_wrapper h2{font-size: 35px;line-height: 40px;font-weight: 600;color: #000;text-align: center;margin-bottom: 20px;}
.Landingpage13block9_main_wrapper p{font-size: 25px;line-height: 30px;text-align: center;}
.Landingpage13block4_text_sub_sec p{text-align: center;font-size: 28px;line-height: 32px;padding-top: 15px;}
.cercle_main_blc { display: flex; justify-content: center; align-items: center;flex-direction: column;}
.landingpage13block4_abs_img{position: absolute;display: block;transform: translate(-50%,-50%);left: 50%;top: 50%;}
.SC_popup .modalBaseBox {width: 960px;max-width: 90%;}
.popup_main_wrapper{width: 905px;margin: 0 auto;padding: 15px;background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/popup_banner_img.png);background-repeat: no-repeat;background-size: cover;
    /* height: 236px; */max-width: 100%;
}
.popup_main_wrapper_block{display: flex;justify-content: space-between;}
.sign_up_wrapper{background-color: #c50823;width: fit-content;}
.sign_up_wrapper button{color: #fff;font-size: 20px;font-weight: 600;padding: 5px 20px;cursor: pointer;}
.popup_main_wrapper_sub_2block h2{color: #fff;}
.popup_main_wrapper_sub_1block img{position: absolute;z-index: 1;}
.popup_main_1wrapper_block{display: flex;flex-wrap: wrap;position: relative;   }
.popup_main_1wrapper_sub_1block{width: 55%;background-color: #9e0420;padding:83px 20px 10px 20px;}
.popup_main_1wrapper_sub_2block{width: 45%;background-color: #fff;padding: 20px 20px 10px 20px;}
.popup_main_1wrapper_sub_1block p{font-size: 20px;line-height: 26px;color: #fff;font-weight: 600;    margin-top: 40px;}
.popup_main_wrapper_sub_2block { margin-bottom: 28px;}
.see_our_full_list_wrapper{width: fit-content;background-color: #117f9d;margin-top: 10px;}
.see_our_full_list_wrapper Button {color: #fff;font-size: 20px;font-weight: 600;padding: 5px 15px;}
.popup_main_1wrapper_sub_2block p{font-size: 25px;line-height: 30px;font-weight: 500;}
.popup_main_1wrapper_sub_2block p span{font-size: 30px;line-height: 36px;}
.popup_main_1wrapper_sub_2block p strong{color: #9e0420;}
.popup_main_1wrapper_sub_2block h5{color: #117f9d;font-size: 18px;line-height: 24px;}
.popup_main_1wrapper_sub_3block{width: 55%;background-color:#117f9d ;padding: 20px;}
.popup_main_1wrapper_sub_4block{width: 45%;background-color:#898989;padding: 20px;display: flex;align-items: center;    justify-content: center;}
.popup_main_1wrapper_sub_3block p{color: #fff; font-size: 20px; line-height: 26px;font-weight: 600;}
.popup_main_1wrapper_sub_5block{width: 55%;background-color: #9e0420;padding: 20px;}
.popup_main_1wrapper_sub_6block{width: 45%;background-color: #fff;padding: 20px;display: flex;align-items: center;    justify-content: center;}
.popup_main_1wrapper_sub_5block p{color: #fff; font-size: 20px; line-height: 26px;font-weight: 600;}
.popup_main_1wrapper_sub_4block img{display: block;}
.popup_main_1wrapper_sub_6block img{display: block;}
.popup_main_1wrapper_block{  height: 500px;
    overflow-y: scroll;}
.landingpage13block12_main_wrapper{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/landingpage13block12_main_wrapper_bg.webp);
background-repeat: no-repeat;background-size: cover;padding: 20px;}
.landingpage13block12_main_wrapper_width{width: 1795px;margin: 0 auto;}
.landingpage13block12_main_sub_wrapper{display: flex;justify-content: space-between;}
.landingpage13block12_main_sub1_wrapper{width: 19.5%;background-color: #c50823;padding: 10px;display: flex;justify-content: center;align-items: center;flex-direction: column;border-radius: 10px;}
.landingpage13block12_main_sub2_wrapper{width: 19.5%;background-color: #a3051c;padding: 10px;display: flex;justify-content: center;align-items: center;flex-direction: column;border-radius: 10px;}
.landingpage13block12_main_sub1_sec_wrapper img{display: block;max-width: 100%;}
.landingpage13block12_main_sub1_sec1_wrapper h2{font-size: 30px;line-height: 36px;font-weight: 600;color: #fff;text-align: center;}


/* -------------demo_css----------- */

.landingpage13_block2_main img{display: block;width: 100%;}
.landingpage13_block3_main img{display: block;width: 100%;}
.landingpage13_block4_main img{display: block;width: 100%;}
.landingpage13_block5_main img{display: block;width: 100%;}
.landingpage13_block6_main img{display: block;width: 100%;}
.landingpage13_block7_main img{display: block;width: 100%;}
.landingpage13_block8_main img{display: block;width: 100%;}
.landingpage13_block9_main img{display: block;width: 100%;}
.landingpage13_block10_main img{display: block;width: 100%;}
.landingpage13_block11_main img{display: block;width: 100%;}
.landingpage13_block12_main img{display: block;width: 100%;}
.landingpage13_block13_main img{display: block;width: 100%;}







/* ----------------------souvik_css------------------ */


/* ////////////////{Abhranil_css}////////////////////////// */

.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper{
    background: #ffffff;
    box-shadow: none;
    padding: 0;
}
.landingpage13_bannerblock2_mainwrapper .landing-container {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 84px;
}
.landingpage13_bannerblock2_mainwrapper  .landingpage5_bannerblock8 {
    background-color: #ffffff;
}
.landingpage13_bannerblock2_mainwrapper h1{
    text-align: center;
    font-size: 45px;
    line-height: 52px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #727272;
    text-transform: uppercase;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step5 {
    background: #898989;
    color: #fff;
    border-radius: 0;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step1_img {
    background: #939393;
    color: #fff;
    flex-direction: inherit;
    width: auto;
    border-top: 0;
    margin: 0;
    position: relative;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step1_img::before{
    content: " ";
    position: absolute;
    background-color: #127f9e;
    right: -6px;
    width: 12px;
    height: 100%;
    background-repeat: no-repeat;
    background-image: none;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step1_img h4 {
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 29px;
    font-weight: 500;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step1_img::after{
    background-image: none;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG {
    background: #c50823 !important;
    bottom: -23px;
    border-radius: 0;
    padding: 20px;
    width: fit-content;
    display: block;
    position: inherit;
    left: inherit;
    transform: none;
    margin: 0 auto;
}
.landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper .txt_button_lp13 h2{
    margin: 0;
    font-size: 35px;
    text-transform: capitalize;
}

@media only screen and (max-width: 1199px){
    .landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG{
        width: 100%;
        display: block;
    } 
}

@media only screen and (max-width: 960px){
    .landingpage13_bannerblock2_mainwrapper h2 {
        color: #727272;
    }   
}
/* /////////////////////////{}/////////////////// */
.lp13_lastblock_mainwrapper {
    background: #0a556d;
    padding: 32px 40px;
}
.lp13_lastblock {
    display: flex;
    justify-content: space-between;
    background:linear-gradient(90deg, rgba(14,108,135,1) 30%, rgba(10,85,109,0) 70%);
    padding: 15px;
    align-items: center;
}
.lp13_lastblock_sub_txt_wrapper1{
    padding: 15px 10px;
    width: 70%;
}
.lp13_lastblock_sub_txt_wrapper1 p {
    font-size: 40px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 18px;
}
.lp13_lastblock_sub_txt_wrapper1 strong {
    color: #1fbdea;
}
.lp13_lastblock_sub_txt_wrapper1 strong a {
    color: #1fbdea;text-decoration: none;
}
.lp13_lastblock-img_wrapper{
    width: 30%;
}
.lp13_lastblock-img_wrapper img {
    width: 100%;
    display: block;
}
.lp13_lastblock_sub_txt_wrapper1 h1 {
    font-size: 60px;
    line-height: 66px;
    color: #00ffe4;
    font-weight: 700;
}
@media only screen and (max-width: 1860px){
.landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2 {font-size: 30px;line-height: 36px;}

}




@media only screen and (max-width: 1499px){
    .lp13_lastblock_sub_txt_wrapper1 p{
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 15px;
    }
    .lp13_lastblock_sub_txt_wrapper1 h1{
    font-size: 50px;
    line-height: 55px;
    }
    .lp13_lastblock-img_wrapper{
        display: flex;
    }
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2 {font-size: 24px;line-height: 30px;}
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper {margin-top: 90px;}


}
@media only screen and (max-width: 1199px){
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper {margin: 15px 0;margin-bottom: 35px;}
    .lp13_lastblock-img_wrapper img {
        display: none;
    }
    .lp13_lastblock-img_wrapper{
        width: 0;
    }
    .lp13_lastblock_sub_txt_wrapper1{
        width: 100%;
        padding: 0;
    }
}    
    @media only screen and (max-width: 899px){
        .lp13_lastblock_sub_txt_wrapper1 p {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 12px;
        }
        .lp13_lastblock_sub_txt_wrapper1 h1{
        font-size: 40px;
        line-height: 45px;
        }
}
@media only screen and (max-width: 699px){
    .lp13_lastblock_sub_txt_wrapper1 p {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 10px;
    }
    .lp13_lastblock_sub_txt_wrapper1 h1{
    font-size: 35px;
    line-height: 40px;
    }
    .lp13_lastblock_mainwrapper{
        padding: 25px 20px;
    }
}
@media only screen and (max-width: 599px){
    .lp13_lastblock{
        padding: 10px;
    }
    .lp13_lastblock_sub_txt_wrapper1 h1{
    font-size: 32px;
    line-height: 37px;
    }
    
}
@media only screen and (max-width: 499px){
    .lp13_lastblock_mainwrapper{
        padding: 30px 10px;
    }
}





/* //////////////{Abhranil_css_end}//////////////////// */

@media only screen and (max-width: 1910px){
    .Landingpage13block9_cmn_width{width: 96%;}
}
@media only screen and (max-width: 1530px){
    .Landingpage13block4_sub_wrapper{    padding: 0 30px;padding-top: 40px;}
    .Landingpage13block4_sub_wrapper{justify-content: space-between;}
    .landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper{margin-bottom: 50px;}
}
@media only screen and (max-width: 1250px){
    .Landingpage13block4_main_wrapper{background-size: 280px;}
    .popup_main_wrapper{max-width: 100%;}
   
}
@media only screen and (max-width: 1199px){
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper{
    background-image: none;
    background: #c50723;
    padding: 20px 25px;
    width: 100%;
   }
   .Landingpage13block4_text_sub_sec p{font-size: 20px; line-height: 25px;}
   .landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG{width: 100%;}
   .Landingpage13block9_main_wrapper h1{    font-size: 35px; line-height: 40px;}
   .Landingpage13block9_main_wrapper h2{font-size: 30px; line-height: 35px;}
   .Landingpage13block9_main_wrapper p {    font-size: 20px; line-height: 25px;}
   .Landingpage13block9_main_wrapper h2 br{display: none;}
   .Landingpage13block9_main_wrapper{    padding: 30px 0;}
   .landingpage13_bannerblock2_mainwrapper h1{    font-size: 40px;line-height: 45px;    margin-top: 20px;}
   .landingpage5_bannerblock8_mainwrapper h3{    font-size: 25px; line-height: 30px;}
   .landingpage13_bannerblock2_mainwrapper h2{color: #fff !important;}
   .landingpage13_bannerblock2_mainwrapper h1{color: #727272 !important;}
   .landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_step5:last-child{margin-bottom: 17px;}
   .Landingpage13block4_main_wrapper h1 br{display: none;}
   .popup_main_1wrapper_block{  height: 300px;
    overflow-y: scroll;}
    
   
}
@media only screen and (max-width: 1020px){
    .popup_main_wrapper_sub_1block img { width: 45%;}
}
@media only screen and (max-width: 950px){
    .Landingpage13block4_main_wrapper h1{    font-size: 35px;  line-height: 40px;}
    .Landingpage13block4_main_wrapper{background-image: none;}
   
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size: 30px; line-height: 35px;}
    .Landingpage13block4_sub_wrapper { justify-content: space-around;}
    .popup_main_wrapper_sub_1block img{width: 45%;}
    .popup_main_wrapper_sub_2block img{width: 45%;}
   
}
@media only screen and (max-width: 890px){
    .popup_main_wrapper_sub_1block img { width: 39%;}
}
@media only screen and (max-width: 799px){
    .popup_main_wrapper_sub_1block img{width: 39%;}
    .popup_main_wrapper_sub_2block{    width: 50%;}
    .see_our_full_list_wrapper Button {font-size: 15px;}
    
}
@media only screen and (max-width: 670px){
    .Landingpage13block4_sub_wrapper{flex-direction: column;}
    .cercle_main_blc{    padding-bottom: 20px;}
    .Landingpage13block4_main_wrapper{    padding: 40px 0;    padding-bottom: 20px; }
    .Landingpage13block4_main_wrapper h1 {    margin-top: -9px;font-size: 30px; line-height: 35px;}
    .Landingpage13block9_main_wrapper h1{    font-size: 30px; line-height: 35px;}
   .Landingpage13block9_main_wrapper h2{font-size: 25px; line-height: 30px;}
   .Landingpage13block9_main_wrapper p {    font-size: 18px; line-height: 23px;}
   .landingpage13_bannerblock2_mainwrapper h1{    font-size: 35px;line-height: 40px;}
   .popup_main_1wrapper_sub_2block p{    font-size: 17px;
    line-height: 22px;}
    .popup_main_1wrapper_sub_2block h5{font-size: 16px;
        line-height: 22px;
    }
    .popup_main_1wrapper_sub_1block p{    font-size: 18px;
        line-height: 22px;}
        .popup_main_1wrapper_sub_3block p{    font-size: 18px;
            line-height: 22px;}
            .popup_main_1wrapper_sub_5block p{font-size: 18px;
                line-height: 22px;}
}
@media only screen and (max-width: 620px){
    .popup_main_wrapper_block{flex-direction: column;}
    .popup_main_wrapper_sub_1block img{width: auto;max-width: 100%;position: relative;}
    .popup_main_wrapper_sub_2block img{width: auto; max-width: 100%;
        display: block;margin: 10px 0;}
        .popup_main_wrapper_sub_2block{width: auto;}
        .popup_main_1wrapper_block{display: block;}
        .popup_main_1wrapper_sub_1block{width: 100%;padding: 20px;}
        .popup_main_1wrapper_sub_1block p{margin-top: 0;}
        .popup_main_1wrapper_sub_2block{width: 100%;}
        .popup_main_1wrapper_sub_3block{width: 100%;}
        .popup_main_1wrapper_sub_4block{width: 100%;}
        .popup_main_1wrapper_sub_5block{width: 100%;}
        .popup_main_1wrapper_sub_6block{width: 100%;}
        .popup_main_wrapper_sub_1block {width: 250px;margin: 0 auto;max-width: 78%;}
        .popup_main_wrapper_sub_2block {width: 80%;margin: 0 auto;}
        .popup_main_wrapper_sub_2block img {max-width: 42%;display: block;margin: 10px auto;}
        .sign_up_wrapper {margin: 0 auto;margin-bottom: 10px;}
        .popup_main_wrapper_sub_2block h2 {font-size: 18px;line-height: 24px;text-align: center;}

}




/* /-------------------subhashis ccs--------------- */
.SD_common_wrapper {width: 1730px;margin: 0 auto;}

.pharmacy_coaching_block {background:url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/coaching_blue_bg.webp), url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/pharmacy_coachin_img.webp);}
.right_img_left_text {background-size: cover;background-repeat: no-repeat;background-position: left top,right bottom;}
.SD_common_padding {padding: 125px 0;padding-right: 37%;}
.SD_font45 h2 {font-size: 45px;line-height: 52px;color: #fff;font-weight: 700;position: relative;padding-bottom: 40px;text-shadow: 1px 1px 10px #042839;text-transform: uppercase;}
.SD_font45 p {font-size: 25px;line-height: 31px;color: #fff;font-weight: 400;margin-top: 15px;}
.SD_font45 h2::after {content: '';background-color: #c50823;position: absolute;height: 12px;width: 100%;bottom: 0;left: 0;}
.SD_font30 h3 {font-size: 30px;line-height: 37px;color: #fff;font-weight: 600;margin-top: 30px;}
.SD_font30 h3 a {color: #7a0d1c;text-decoration: none;}
.SD_font25 p {font-size: 25px;line-height: 35px;color: #000;font-weight: 400;}
.SD_font25 p span {font-weight: 600;}
.SD_textwrapper13 {width: 66%;}
.SD_imagewrapper13 img {display: block; width: 100%; object-fit: cover;}
.kalvin_block {background: #e2e0e0;padding: 20px 0;}
.pharmacy_program_block {
background-image:
 /* url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage13/program_block.webp); */
url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/program_block.webp);
}
.pharmacy_program_block .SD_common_padding {padding-right: 40%;}
.logo_flex {display: flex;justify-content: space-between;width: 1500px;margin: 0 auto;padding: 40px 0;align-items: center;}
.logo_flex img {display: block;}
.red_heading h2 {font-size: 45px;line-height: 51px;color: #ffff;text-align: center;font-weight: 700;text-transform: uppercase;}
.red_heading {padding: 22px;background: #c50823;}
.delivery_block{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/lamdingpage13_delivery_1block_img.webp);
background-repeat: no-repeat;background-position: left center;}
.delivery_block button {background: #c50823 !important;
    border-radius: 0;
    border: none;
    width: 100%;
    text-align: center;
    padding: 20px;
    display: block;
    font-size: 35px;
    line-height: 35px;
    color: #fff;
    font-weight: 700;
    margin-top: 25px;
    font-family: "Poppins", sans-serif;}
.delivery_block .SD_textwrapper13 h2 {font-size: 45px;line-height: 52px;font-weight: 700;text-transform: uppercase;color: #727272;}
.delivery_block .SD_textwrapper13 {
    width: 63%;
}
.delivery_block {padding: 110px 0;}
.SD_flex13 {display: flex;justify-content: space-between;align-items: center;}
/* .popup_wrapper {position: relative;} */
.popup_wrapper img {width: 100%;display: block;position: relative;}
.popup_btn {font-size: 25px;color: #fff;font-weight: 600;text-transform: uppercase;border: none;padding:6px 0;outline: none;width: 20%;background: #c50723;position: absolute;
    top: 24%;
    right: 22%;}

    .popup_wrapper2 img {display: none;}
@media only screen and (max-width: 1840px){
.SD_common_wrapper {width: 94%;}
}
@media only screen and (max-width: 1799px){
    .SD_font45 h2 {font-size: 34px;line-height: 40px;}


}
@media only screen and (max-width: 1760px){
    .delivery_block{    background-position: left -81px center;}
}
@media only screen and (max-width: 1699px){
    .delivery_block .SD_textwrapper13 h2 {
        font-size: 37px;
        line-height: 43px;}

}
@media only screen and (max-width: 1660px){
/* .pharmacy_coaching_block {background-position: left top,right -180px bottom;} */
.pharmacy_program_block {background-position: left -100px top;}
}
@media only screen and (max-width: 1560px){
    .red_heading h2 {
        font-size: 36px;
        line-height: 42px;}
.SD_imagewrapper13 img {width: 100%;}
.SD_imagewrapper13 {width: 30%;}
.SD_font45 p {font-size: 20px;line-height: 27px;}
.logo_flex {width: 96%;}
.delivery_block{padding: 100px 0;}
.delivery_block button{font-size: 30px; line-height: 35px;}
.delivery_block .SD_textwrapper13{width: 60%;}
}
@media only screen and (max-width: 1499px){
    .pharmacy_program_block {
        background-position: left -240px top;
    }
    .delivery_block button{    padding:10px 20px;}

}
@media only screen and (max-width: 1450px){
    .delivery_block{
        background-position: left -205px center;
    }
}
@media only screen and (max-width: 1360px){
   
    .SD_common_padding {padding: 90px 0;padding-right: 37%;}
    .logo_flex img {width: 15%;}
    .logo_flex {padding: 20px 0;}
    .delivery_block {
        padding: 70px 0;
    }
}
@media only screen and (max-width: 1199px){
.right_img_left_text {background: #16799e;}
.pharmacy_coaching_block {display: none;}
.SD_common_padding {padding: 25px 0;}
.SD_font45 h2 {padding-bottom: 20px;}
.SD_font45 h2 br {display: none;}
.SD_font30 h3 {font-size: 25px;line-height: 31px;margin-top: 10px;}
.SD_font25 p, .SD_font45 p {font-size: 18px;line-height: 25px;}
.pharmacy_program_block .SD_common_padding {padding: 25px 0;}
.SD_imagewrapper13 {display: none;}
.delivery_block .SD_textwrapper13 {
    width: auto;
}
.SD_textwrapper13 {width: 100%;}
.delivery_block{background-image: none;padding: 20px;}
}

@media only screen and (max-width: 991px){

    .SD_font45 h2 {
        font-size: 30px;
        line-height: 36px;
    }
    .red_heading h2 {
        font-size: 30px;
        line-height: 36px;
    }
    .red_heading {
        padding: 10px;}
        .delivery_block .SD_textwrapper13 h2 {
            font-size: 30px;
            line-height: 36px;
        }
        .delivery_block button {    font-size: 26px;
            line-height: 30px;}
}

@media only screen and (max-width: 899px){
    .SD_imagewrapper13 {display: none;}
    .SD_textwrapper13 {width: auto;}
}
@media only screen and (max-width: 769px){
    .popup_btn {    width: 19%;    font-size: 18px;}


}

@media only screen and (max-width: 599px){
    .logo_flex {flex-direction: column;}
    .logo_flex img {
        width: 250px;
        margin: 10px 0;
        max-width: 100%;
    }
}
@media only screen and (max-width: 499px){
/* .SD_font45 h2 {font-size: 25px;line-height: 31px;} */
.popup_btn {

    font-size: 12px;padding: 0;    top: 26%;
}
}
@media only screen and (max-width: 450px){
    .SD_font45 h2{    padding: 0 10px 25px 10px;}
    .SD_font30 h3{    padding: 0 10px;}
    .SD_font25 p, .SD_font45 p{padding: 0 10px;}
    .landingpage13_main_wrapper .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 23px;
        line-height: 30px;
    }
    .landingpage13_bannerblock2_mainwrapper h1 {    font-size: 30px;
        line-height: 37px;
        padding: 0 5px;
    }
    .landingpage13_bannerblock2_mainwrapper .landingpage5_bannerblock8_mainwrapper .txt_button_lp13 h2{font-size: 29px;}
    .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 31px;
        line-height: 39px;
    }
}


/* /-------------------subhashis ccs--------------- */

/* -------------SD css-------------- */
.land_footer_block13 {padding: 20px 10px;background: #157d9b;text-align: center;color: #fff;text-transform: capitalize;margin-top: 30px;}
.land_footer_block13 h2 {text-transform: uppercase;}
.landingpage13_main_wrapper .landingOne .state svg {color: #000;}
