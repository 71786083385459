/* ------------------nakagames banner css---------------------------- */


.nakagames_banner {background:
    url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/banner_doctor_img.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/banner_gamer_img.webp),
     url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/nakagames_banner_bg.webp)
     ;background-repeat: no-repeat;    background-position: left 20px top 40px,right 70px top 60px,center top;background-size: auto, auto, 100% 100%;position: relative;}
.nakagames_banner img {display: block;}
.naka_games_logo {margin: 0 auto;}
.nakagames_wrapper {width: 1655px;margin: 0 auto;}
.text_block {background: linear-gradient(180deg, rgba(41,45,48,1) 33%, rgba(18,19,20,1) 82%);text-align: center;padding: 25px;padding-top: 65px;position: relative;margin-bottom: 35px;border-radius: 10px;
border: 1px solid #184d17;}
.text_block p {font-size: 22px;color: #c9d2d9;font-weight: 500;line-height: 28px;}
.text_block h2 {font-size: 40px;color: #a9b5bc;font-weight: 500;line-height: 46px;text-transform: uppercase;letter-spacing: 10px;}
.text_block h1 {font-size: 80px;line-height: 86px;font-weight: 700;text-transform: uppercase;background: linear-gradient(180deg, rgba(191,202,208,1) 3%, rgba(73,84,90,1) 89%);-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;filter: drop-shadow(-2px -5px 1px rgba(0, -1, 0, 3.4)); }
.naka_banner_sub_tetxwrp {padding-top: 215px;padding-bottom: 80px;}
.text_block .position_heading {position: absolute;z-index: 9;top: -30%;left: 50%;transform: translateX(-50%);}
.gree_grad_btn button {display: block;width: fit-content;padding: 25px;color: #fff;font-size: 33px;line-height: 38px;text-transform: uppercase;font-weight: 600;background: linear-gradient(180deg, rgba(9,226,37,1) 3%, rgba(7,112,21,1) 88%);}
.naka_banner_tetxwrp button {margin: 0 auto;}


/* ------------------nakagames block 1 css---------------------------- */
.nakagames_block1 {background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/nakagames_block1_dr_img.webp), url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/block2_bg.webp);background-position: right bottom, right top;background-repeat: no-repeat;    background-size: auto, cover;
    padding-top: 230px;
    margin-top: -170px;
    padding-bottom: 50px;}
.nakagames_block1 h1 {font-size: 55px;line-height: 50px;font-weight: 700;text-transform: uppercase;background: linear-gradient(180deg, rgba(71,187,246,1) 7%, rgba(8,124,205,1) 35%, rgba(1,60,139,1) 86%);-webkit-background-clip: text;
    -webkit-text-fill-color: transparent; display: initial;}
.nakagames_block1 h2 {font-size: 30px;color: #000;font-weight: 600;line-height: 36px;margin-top: 15px;}
.nakagames_block1 p {font-size: 20px;color: #000;font-weight: 600;line-height: 26px;margin: 22px 0;}
.nakagames_block1_sub_wrp {padding-right: 600px;}
.block1_inner_text {width: 90%;}


/* ------------------nakagames block 2 css---------------------------- */
.nakagames_block2_flex {width: 1355px;margin: 0 auto;display: flex;align-items: center;justify-content: space-between;}
.nakagames_block2_flex_text h1 {font-size: 100px;line-height: 90px;font-weight: 700;background: linear-gradient(180deg, rgba(57,209,76,1) 17%, rgba(14,170,34,1) 50%, rgba(8,135,25,1) 79%);-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;filter: drop-shadow(-4px -5px 3px rgba(0, -1, 0, 3.4));}
.nakagames_block2_flex_text h3 {font-size: 35px;line-height: 41px;font-weight: 500;    background: linear-gradient(180deg, rgba(187,199,205,1) 26%, rgba(141,152,160,1) 48%, rgba(100,110,116,1) 81%);-webkit-background-clip: text;
        -webkit-text-fill-color: transparent;}

.nakagames_block2_flex_text p {font-size: 20px;line-height: 25px;color: #c9d2d9;}
.nakagames_block2_flex_img img {display: block;}
.nakagames_block2_flex_text {width: 52%;}
.nakagames_block2 {background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/block2_gamers.webp), url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/block2_main_bg.webp);background-size:auto, cover;background-repeat: no-repeat;padding: 90px 0;background-position: left bottom,right top;}
.nakagames_flex {display: flex;justify-content: space-between;align-items: center;background: #000307;padding: 60px;padding-top: 80px;padding-left: 120px;border-radius: 10px;margin: 80px 0;    margin-bottom: 130px;}
.nakagames_flex_right {display: flex;justify-content: space-between;width: 56%;position: relative;}

.nakagames_flex_left h1 {font-size: 55px;line-height: 64px;font-weight: 700;background: linear-gradient(180deg, rgba(57,209,76,1) 17%, rgba(14,170,34,1) 50%, rgba(8,135,25,1) 79%);-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;text-transform: uppercase;filter: drop-shadow(-4px -5px 3px rgba(0, -1, 0, 3.4));}

.nakagames_flex_left h3 {font-size: 30px;line-height: 36px;font-weight: 500;background: linear-gradient(180deg, rgba(187,199,205,1) 26%, rgba(141,152,160,1) 48%, rgba(100,110,116,1) 81%);-webkit-background-clip: text;
        -webkit-text-fill-color: transparent;margin-bottom: 20px;text-transform: uppercase;}
.nakagames_flex_left p {font-size: 20px;line-height: 25px;color: #c9d2d9;}
.nakagames_flex_left {width: 38%;}
.ash_bg_blk {background: linear-gradient(180deg, rgba(39,40,40,1) 26%, rgba(32,32,32,1) 84%, rgba(25,25,25,1) 100%);padding: 20px;padding-top: 90px;width: 32%;position: relative;border-radius: 6px;}
.ash_bg_blk p {font-size: 30px;color: #a9a8a8;line-height: 36px;text-transform: uppercase;}
.ash_bg_blk p span {font-size: 100px;color: #3c3c3c;font-weight: 700;}
.naka_circle_img {position: absolute;top: -50%;
    transform: translate(-50%, 7%);
    left: 50%;background-repeat: no-repeat;width: 150px;height: 150px;border-radius: 50%;display: flex;justify-content: center;align-items: center;background: linear-gradient(180deg, rgba(39,39,39,1) 16%, rgba(31,31,31,1) 62%, rgba(24,24,25,1) 100%);border: 8px solid #3b3d3d;}
.naka_join_btn {position: absolute;right: 0;bottom: -145px;}
.naka_join_btn button {font-size: 33px; line-height: 36px;color: #fff;font-weight: 600;width: fit-content;background: linear-gradient(180deg, rgba(9,225,37,1) 16%, rgba(8,167,28,1) 62%, rgba(7,113,21,1) 100%);text-transform: uppercase;border-radius: 10px;padding: 25px;}
.nakagames_block2_btm {width: 825px;margin-left: auto;padding: 60px 75px;background: linear-gradient(180deg, rgba(41,45,48,1) 16%, rgba(30,32,34,1) 62%, rgba(18,19,20,1) 100%);border-radius: 12px;}
.nakagames_block2_btm h3 {font-size: 30px;line-height: 34px;color: #09cd22;font-weight: 600;text-transform: uppercase;margin-bottom: 15px;}
.nakagames_block2_btm p {font-size: 20px;line-height: 26px;color: #c9d2d9;font-weight: 500;}
.commission_text {margin: 25px 0;}

/* ------------------nakagames block 3 css---------------------------- */

.nakagames_block3 h1 {font-size: 55px;line-height: 60px;color: #000;font-weight: 600;text-transform: uppercase;}
.nakagames_block3 {text-align: center;padding-top: 50px;background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/block+4_bg.webp);background-repeat: no-repeat;background-position: left center;}
.naka_list {list-style-type: none;display: flex;justify-content: space-between;margin-top: 50px;}
.naka_list li {font-size: 24px;line-height: 28px;text-transform: uppercase;font-weight: 500;padding: 0 2%;border-right: 2px solid #cfcfcf;}
.naka_list li:first-child {padding-left: 0;}
.naka_list li:last-child {padding-right: 0;border-right: none;}
.naka_list li span:hover {border-bottom: 8px solid #09cd22;cursor: pointer;}
.naka_list li span {display: inline-block;height: 50px;}
.nakagames_wrapper2 {padding-bottom: 30px;border-bottom: 2px solid #cfd0d1;}
.naka_block3_btm h1 {font-weight: 500;}
.naka_block3_btm p {font-size: 20px;line-height: 26px;font-weight: 400;margin: 20px 0;}
.naka_block3_btm p span {color: #ff6c00;}
.level_box h3 {font-size: 30px;line-height: 36px;color: #000;font-weight: 600;text-transform: uppercase;}
.level_box {background: #d2d7d4;padding: 20px 40px;width: fit-content;margin: 0 auto;}
.naka_block3_btm {padding: 60px 0;}
.naka_block3_last_blk {display: flex;justify-content: space-between;}
.naka_block3_inner_flex p {font-size: 20px;line-height: 26px;color: #000;font-weight: 500;}
.green_box {background: linear-gradient(180deg, rgba(48,207,68,1) 10%, rgba(14,169,34,1) 58%, rgba(8,133,25,1) 100%);font-size: 48px;color: #fff;width: 120px;margin: 0 auto;padding: 8px 0;border-radius: 12px;margin-bottom: 15px;}
.naka_block3_inner_flex {display: flex;justify-content: center;flex-direction: column;width: 25%;padding: 10px 20px;position: relative;padding-bottom: 70px;}
.naka_block3_inner_flex::before {content: '';position: absolute;top: 0;left: 0;width: 3px;height: 100%;background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/vertical_line_img.webp);background-repeat: no-repeat;}
.naka_block3_inner_flex:last-child::after {content: '';position: absolute;top: 0;right: 0;width: 3px;height: 100%;background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/vertical_line_img.webp);background-repeat: no-repeat;}
.nakagames_block3_inner {padding-bottom: 20px;}



/* ------------------nakagames block 4 css---------------------------- */
/* ---------------------sourjyadeep--------------------------------- */
.nakagames_block4_flex{display: flex;width: 100%;  align-items: center;justify-content:center;padding: 30px 0;}
.nakagames_block4_flex_text{font-size: 36px;color: #000000;line-height: 41px; font-weight: 600;text-transform: uppercase;}
.nakagames_block4 p span{color: #10b926;}
.nakagames_block4{background-color: #eaeff3;}
.nakagames_block4_flex_img{margin-left: 50px; }
.nakagames_block4_flex_img img{display: block;}

/* ------------------------nakagames footer css-------------------- */

.nakagames_block5{display: flex;background-color: #000;align-items: center;justify-content: space-between;padding: 20px 120px;}
.nakagames_block5_flex_text p {font-size: 16px;color: #43474f;text-align: center;}
.nakagames_block5 img {display: inline-block;margin-left: 10px;}
.nakagames_block5_flex_text {width: 90%;}


.nakagames_block5_flex_icon img {cursor: pointer;}


@media only screen and (max-width: 1760px) {
    .nakagames_wrapper {width: 94%;}
    .nakagames_block1 h1 {font-size: 40px;line-height: 40px;}
    .nakagames_block1 h2 {font-size: 24px;line-height: 29px;}
    .naka_list li {font-size: 18px;line-height: 24px;width: 20%;}
    .nakagames_wrapper2 {padding-bottom: 20px;}
}
@media only screen and (max-width: 1699px) {
.nakagames_block1_sub_wrp {padding-right: 40%;}
.gree_grad_btn button {font-size: 26px;line-height: 30px;padding: 15px;}

}

@media only screen and (max-width: 1640px) {
    .nakagames_banner {background-position: left -84px top 40px,right -60px top 60px,center top;}
    .text_block h1 {font-size: 60px;line-height: 66px;}
    .text_block .position_heading {width: 800px;top: -22%;}
}
@media only screen and (max-width: 1599px) {
    .nakagames_block1 {background-position: right -150px bottom, right -270px top;margin-top: -185px;}
    .ash_bg_blk p span {font-size: 60px;}
    .ash_bg_blk p {font-size: 22px;}
    .nakagames_flex_left h1 {font-size: 42px;line-height: 46px;}
    .nakagames_flex_right {width: 60%;}
    .nakagames_block3 h1 {font-size: 32px;line-height: 38px;}
}
@media only screen and (max-width: 1499px) {
    .nakagames_block2 {background-position: left -220px bottom,right top;}
    .nakagames_block2_btm {width: 745px;padding: 40px;}

}
@media only screen and (max-width: 1480px) {
.nakagames_banner {background-position: left -54px top 65px,right -60px top 150px,center top;background-size: 700px, 600px, 100% 100%;}
.nakagames_block2_flex {width: 90%;}
.nakagames_block2_flex_img img {width: 100%;}
.nakagames_block2_flex_img {width: 40%;}
.nakagames_block2_flex_text h1 {font-size: 70px;line-height: 70px;}
.nakagames_block2_flex_text h3 {font-size: 22px;line-height: 26px;display: initial;}
}


@media only screen and (max-width: 1399px) {
.nakagames_block1 h1 {font-size: 36px;line-height: 38px;}
.nakagames_block1 p {font-size: 17px;line-height: 23px;}
.nakagames_flex {padding-left: 60px;}

}

@media only screen and (max-width: 1299px) {
.nakagames_block1 {background-position: right -190px bottom, right -270px top;}
.nakagames_block4_flex_text br {display: none;}
.nakagames_block5_flex_text {width: 80%;}
}


@media only screen and (max-width: 1199px) {
    .nakagames_banner  {background: none,none,url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/nakagames_banner_bg.webp);background-size: cover;background-position: center;}
    .naka_banner_sub_tetxwrp {padding-top: 0;}
    .nakagames_banner img {margin: 0 auto;}
    .text_block {margin-top: 60px;padding-top: 45px;}
    .text_block p {font-size: 18px;line-height: 24px;}
    .text_block h1 {font-size: 40px;line-height: 48px;display: initial;}
    .text_block h2 {font-size: 30px;line-height: 36px;}
    .text_block .position_heading {width: 560px;top: -40px;max-width:80%;}
    .gree_grad_btn button {font-size: 24px;line-height: 30px;padding: 15px 20px;}
    .naka_accs_logo {margin-top: 15px !important;}
    .naka_banner_sub_tetxwrp {padding-bottom: 35px;}
    .nakagames_block1 {background: linear-gradient(180deg, rgba(252,255,255,1) 7%, rgba(231,252,253,1) 35%, rgba(212,249,251,1) 86%);margin: 0;padding: 25px 0;}
    .nakagames_block1_sub_wrp {padding: 0;}
    .block1_inner_text {width: auto;}
    .gree_grad_btn button {display: block;margin: 0 auto;}
    .nakagames_flex {flex-direction: column;padding: 25px;margin: 20px 0;}
    .nakagames_flex_left, .nakagames_flex_right {width: 100%;}
    .nakagames_flex_right  {margin: 0 -1%;margin-top: 100px;flex-wrap: wrap;justify-content: center;}
    .ash_bg_blk {margin: 1%;width: 31%;}
    .naka_join_btn {position: relative;bottom: 0;margin-top: 15px;}
    .nakagames_block2 {background-image:none, url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Nakagames/block2_main_bg.webp);background-size: cover;padding: 20px 0;}
    .nakagames_block2_btm {width: auto;padding: 25px;}
    .naka_list {flex-wrap: wrap;justify-content: center;margin-top: 10px;}
    .naka_list li {width: fit-content;margin: 1%;}
    .naka_block3_last_blk {flex-wrap: wrap;}
    .naka_block3_inner_flex::before {display: none;}
    .naka_block3_inner_flex {width: 48%;padding-bottom: 10px;}
    .naka_block3_inner_flex:last-child::after {display: none;}
    .naka_block3_btm {padding: 30px 0;}
    .green_box {width: 100px;font-size: 35px;}
    .nakagames_block3 {padding-top: 20px;}
}
@media only screen and (max-width: 991px) {
.nakagames_block2_flex {flex-direction: column;}
.nakagames_block2_flex_img {width: auto;}
.nakagames_block2_flex_text {width: 100%;margin-top: 20px;}
.nakagames_block4_flex_text {font-size: 30px;line-height: 36px;}
}
@media only screen and (max-width: 767px) {
    .naka_circle_img {width: 100px;height: 100px;top: -35%;}
    .naka_circle_img img {width: 50px;}
    .nakagames_flex_right {margin-top: 80px;}
    .naka_join_btn button {font-size: 27px;line-height: 27px;}
    .nakagames_block2_flex_text h1 {font-size: 40px;line-height: 45px;}
    .naka_block3_btm p br, .nakagames_block3 h1 br {display: none;}
    .nakagames_block5 {flex-direction: column;padding: 15px;}
    .nakagames_block5 img {margin: 10px;}
    .nakagames_block2_flex_text h3 {font-size: 23px;line-height: 28px;}
}
@media only screen and (max-width: 720px){
    .nakagames_block4_flex{flex-direction: column-reverse;}
    .nakagames_block4_flex_img{margin:0 0 20px;}
    .nakagames_block4_flex_text{font-size: 30px;}
}
@media only screen and (max-width: 640px) {
.ash_bg_blk {margin: 1%;width: 31%;padding: 10px;padding-top: 75px;}
.nakagames_flex {padding: 15px;}
.ash_bg_blk p span {font-size: 40px;}
.ash_bg_blk p {font-size: 17px;}
.ash_bg_blk {width: 100%;}
.naka_block3_inner_flex {width: auto;}
.level_box h3 {font-size: 22px;line-height: 28px;}
}

@media only screen and (max-width: 599px) {
    .text_block h2 {letter-spacing: 6px;font-size: 20px;line-height: 26px;margin-bottom: 10px;}
    .text_block h1 {font-size: 29px;line-height: 35px;margin: 10px 0;}
    .text_block p {font-size: 15px;line-height: 21px;margin-top: 10px;}
    .text_block {padding: 15px;padding-top: 45px;}
    .nakagames_block1 h1 {font-size: 29px;line-height: 33px;}
    .nakagames_block1 h2 {font-size: 21px;line-height: 26px;}
    .nakagames_block1 p {font-size: 17px;line-height: 23px;}
    .naka_join_btn button {font-size: 20px;line-height: 25px;padding: 15px;}
}

@media only screen and (max-width: 499px) {
.gree_grad_btn button {font-size: 18px;line-height: 22px;padding: 10px 15px;}
.text_block .position_heading {top: -25px;max-width: 100%;}
.text_block {margin-top: 45px;}
.ash_bg_blk {padding-top: 125px;width: 100%;margin: 15px 1%;}
.ash_bg_blk p {font-size: 25px;text-align: center;}
.naka_circle_img {top: 0;}
.naka_join_btn {margin-top: 0;}
.nakagames_flex_right {margin-top: 0px;}
.nakagames_block4_flex_text { font-size: 25px;line-height: 31px;}
.nakagames_block2_btm {padding: 15px;}
.nakagames_block2_btm h3 {font-size: 25px;line-height: 31px;}
.naka_list li {border-right: 0;}
.nakagames_block3 h1 {font-size: 25px;line-height: 31px;}
.nakagames_flex_left h1 {font-size: 32px;line-height: 38px;}
.nakagames_flex_left p {font-size: 16px;line-height: 22px; margin-top: 15px;}
.nakagames_flex_left h3 {font-size: 25px;line-height: 30px;}
}

















