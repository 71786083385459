.gradiant_fontBW h2{font-size: 50px;font-weight: 700; line-height: 62px;color: #fff;

    /* background: linear-gradient(180deg, rgb(255 255 255) 19%, rgba(169,171,172,1) 70%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;    filter: drop-shadow(0px 0px 6px  #00000081); */

}
.gradiant_darkBG {background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/LP5_overlayer.png), linear-gradient(180deg, rgba(16,116,193,1) 0%, rgba(0,1,1,1) 100%); background-repeat: no-repeat;    background-size: contain; background-position: right 25px top , center;border-radius: 10px;padding: 17px 15px;border: 0.5px solid #9aadbb;}
.gradiant_black_darkBG {background: linear-gradient(180deg,#6dd61a 4%,#068d13 83%);padding: 20px 60px;width: fit-content;border-radius: 20px;border: none;outline: none;}

.trans_img {position: absolute;right: -130px;top: 50%;transform: translateY(-50%);width: 100px;}

.landingpage5_whole_cont {background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_main_bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: right top;}
.page5_react_player {width: 100% !important;height: 100% !important;}
.landing_page5_bannerblock .landpage_videowrapper .landpage_video_subwrapper {padding-top: 0;}
.landing_page5_bannerblock .react-player__preview {padding-top: 50.25%;background-size: 100% 100% !important;}
.landing_page5_bannerblock .react-player__preview .react-player__shadow {position: absolute;
    top: 50%;
    transform: translateY(-50%);background: #1268a0c9 !important;}
.landingpage5_whole_cont .landingOne .state svg {color: #fff;}
.landing_page5_bannerblock .form_style .formfild input {
    background-color: #06122d;
    color: #fff;
    padding: 12px 14px;
}
.email_btm_line p {    margin: 5px 0;
    color: #ddd;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;}
.email_marg {margin-bottom: 0 !important;}
.landing_page5_bannerblock .form_style .formfild {margin: 20px 0;}
.landing_page5_bannerblock {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_banner_men_dr.webp), */
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/banner_lady_doctor.webp),
     url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/pharmacy_main_banner_bg.webp);
    background-repeat: no-repeat;
    background-size: auto, cover;
    padding: 45px 0;
    padding-bottom: 40px;
    background-position: right 450px top 30px, center top 0;
}

.landing_page5_bannerblock .landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
    padding: 12px 14px;
    background-color: #06122d;
    color: #fff;
    font-size: 18px;
    font-weight: var(--main-font-Bold);
}

.landing_page5_bannerblock .landing-banner-right {
    width: calc(32% - 100px);
    margin-left: 100px;
    margin-top: 85px;
}
.l2submitbtnsection {margin-top: 50px;margin-bottom: 10px;}
.landing_page5_bannerblock .form_style .MuiFormLabel-root {
    font-weight: var(--main-font-Bold);
    color: white !important;
    top: -3px;
    font-size: 18px;
    word-wrap: break-word;
}

.landing_page5_bannerblock .lpage_banner_sub_titlewrapper {
    margin-bottom: 0px;
    position: relative;
    z-index: 1;
}
button{display: block;}

.landing_page5_bannerblock .lpage_logowrapper {

    margin-bottom: 30px;
}

.landing_page5_bannerblock .lpage_banner_titlewrapper {
    margin-bottom: 35px;
}

.landing_page5_bannerblock .MuiInputLabel-root div {
    font-size: 18px;
    white-space: normal;
    margin-bottom: 0px;
    /* margin-bottom: 10px; */
    font-weight: bold;
    color: #00f0ff;
    word-wrap: break-word;
}

.landing_page5_bannerblock .form_style .form_radio .MuiFormControlLabel-root {
    font-weight: 600;
}

.landing_page5_bannerblock .l2submitbtnsection button {
    text-shadow: 0 0 2px #0f3a58;
    background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/phar_submit_btn.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    font-size: 24px;
    line-height: 29px;
    color: #ffff;
    font-weight: var(--main-font-SemiBold);
    text-transform: uppercase;
    padding: 20px;
    width: calc(100%);
    border: 2px solid #487594;
}

.landing_page5_bannerblock .lpage_banner_textwrapper p {
    font-weight: var(--main-font-Medium);
    font-size: 29px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 20px;
}

.landing_page5_bannerblock .formwrapper {
    background: linear-gradient(180deg, rgba(9, 34, 87, 1) 47%, rgba(20, 59, 114, 1) 91%);
    padding: 10px;
    /* border: 1px solid #fff; */
    border-radius: 8px;
    /* margin-top: -38px; */
    outline: 15px solid #72acdd;

}

.landing_page5_bannerblock .form_style .MuiOutlinedInput-notchedOutline {
    border: 1px solid #435e9a !important;
}

.landing_page5_bannerblock .lpage_arrow_wrapper h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 39px;
    line-height: 44px;
    font-weight: var(--main-font-Bold);
    text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}

.landing_page5_bannerblock .lpage_arrow_wrapper h2 span {
    color: #00e4ff;
}

.landing_page5_bannerblock .form_title_wrapper {
    background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/pharmacy_form_top_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 26px 12px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border: 1px solid #0c4e92;
}

.landing_page5_bannerblock .form_title_wrapper::after {
    content: '';
    width: 650px;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -430px;
    border-radius: 50%;
}

.landing_page5_bannerblock .form_title_wrapper h2 span {
    font-size: 30px;
    line-height: 32px;
    color: #fff;
    background: none;
    -webkit-text-fill-color: inherit;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    text-shadow: 1px 0px 6px #565454;
}

.landing_page5_bannerblock .lpage_banner_sub_wrapper {
    position: relative;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/l_page5_banner_bg2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 18px;
    width: 836px;
    border-radius: 10px;
}

.landing_page5_bannerblock .lpage_banner_sub_wrapper::after {
    content: '';
    width: 93%;
    height: 8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -4px;
}


.landing_page5_bannerblock .lpage_banner_textwrapper2 {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/l_page5_banner_bg3.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    width: auto;
    font-size: 23px;
    color: white;
    text-align: center;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 14px;
    background-size: cover;
}

.landing_page5_bannerblock .lpage_banner_textwrapper1 {

    background-color: whitesmoke;
    padding: 20px 40px;
    width: fit-content;
    text-align: center;
    color: black;
    margin-bottom: 10px;
    align-items: center;
    margin-left: 20px;
    border-radius: 10px;

}

.landing_page5_bannerblock .lpage_banner_textwrapper1 p {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
}

.landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span {
    color: white;
    font-size: 55px;
    font-weight: 900;


}

.landing_page5_bannerblock .lpage_banner_textwrapper p span {
    color: #0070af;
    font-size: 33px;
}

.landing_page5_bannerblock .lpage_banner_textwrapper {
    width: 840px;
}

.landing_page5_bannerblock .lpage_banner_sub_wrapper h2 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 37px;
    padding-top: 10px;
}
.landingpage5_bannerblock4 .gradiant_darkBG {padding: 17px 22px;width: fit-content;margin-bottom: 30px;}
.landing_page5_bannerblock .lpage_arrow_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/pharmacy_arrow_img.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 47px 60px 45px 39px;
    position: relative;
}


.landingpage5_bannerblock2 {
    /* background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/free_medication_bg.webp), */
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/block2_couple_doc_img.webp);
     /* url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/drug_search_dr_bg.webp); */
    /* , url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_block2_1bgmain.webp); */
    background-repeat: no-repeat;
    background-size: auto;
    padding: 0 0 70px 0;
    background-position: right 0px top 100px, left -29px bottom -20px;
    /* background-color: #fdfdfc; */
    margin-top: 35px;
    padding-top: 50px;


}
.drug_search_main_wrp {padding-left: 30%;margin-top: 125px;}
.drug_search_main_wrp img {width: 100%;display: block;}
.drug_search_main_wrp .drug_search_wrp {padding: 10px;background: #3987be;border-radius: 10px;}
.drug_search_sub_wrp {padding: 15px;background: #081f50;border-radius: 10px;}


.landing-col-container2 {

    padding-right: 47%;
}

.landingpage5_bannerblock2_left_section {
    width: 40%;
}

.landingpage5_bannerblock2_right_section {
    width: 100%;
}

.landingpage5_bannerblock2 .gradiant_darkBG {
    width: fit-content;
    padding: 15px 35px;margin-bottom: 20px;position: relative;
}

.landingpage5_bannerblock2 .gradiant_fontBW h2 {
    font-size: 34px;
    line-height: 42px;
    text-align: center;
}
.landingpage5_bannerblock2 .gradiant_black_darkBG {padding: 20px;width: 100%;margin-top: 40px;}
/* .landingpage5_bannerblock2_right_subsection{
            background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_block2_img.webp);
            background-size: 100% 100%;
            padding: 25px 60px;
            font-size: 21px;
            font-weight: 600;
            margin-top: 20px;
        } */
.landingpage5_bannerblock2_right_subsection {
    background: linear-gradient(180deg, rgba(77, 92, 103, 1) 45%, rgba(12, 14, 15, 1) 55%);
    padding: 25px 10px;
    margin-top: 20px;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 16px;
    text-align: center;
}

.landing_page5_bannerblock .landpage_videowrapper {
    width: auto;
    margin-top: 0;
}


.landingpage5_bannerblock2_right_subsection label {
    font-size: 36px;
    line-height: 42px;

}

.landingpage5_bannerblock2_right_section h2 {
    font-size: 39px;
    font-weight: 900;
    line-height: 44px;
}

.landingpage5_bannerblock2_right_section h3 span {
    color: #0057a1;
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
}
.landingpage5_bannerblock2 h3 {font-size: 35px;line-height: 40px;color: #0057a1;font-weight: 600;}
.landingpage5_bannerblock2_right_section p {
    font-size: 21px;
    margin-top: 16px;
}

.landingpage5_bannerblock2_right_section p span {
    color: #015a9f;
    font-weight: 600;
}



.landingpage5_bannerblock3_subwrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock3_subwrapper_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    padding: 35px;
    padding-bottom: 67px;
}

.landingpage5_bannerblock3_firstblock {
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 18px;

}
.landingpage5_bannerblock3 .gradiant_fontBW h2 {text-align: center;}
.landingpage5_bannerblock3_firstblock p {
    font-size: 50px;
    line-height: 62px;
    font-weight: var(--main-font-Bold);
    text-align: center;
    text-shadow: 1px 1px 0 #98f0ff;
}

.landingpage5_bannerblock3_secondblock {
    border: 1px solid whitesmoke;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    /* margin: 10px 30px; */
    margin-bottom: 26px;
}

.landingpage5_bannerblock3_secondblock_leftsection {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/immediate_need_bg.webp), url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock3_secondblock_bg.webp);
    background-size: auto 100%, cover;
    width: 49%;
    border: 7px solid #3286bb;
    background-position: right bottom,left top;
    border-radius: 10px;
    background-clip: content-box;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock3_secondblock_leftsection:last-child {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/maintanence_right_img.webp), */
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/right_block3_small_bg.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/maintenance_bg.webp);background-size: auto 100%, cover;
}

.landingpage5_bannerblock3_secondblock_leftsection p {
    font-size: 30px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    line-height: 35px;
}

.landingpage5_bannerblock3_secondblock_leftsection span {
    color: #fe6d01;
    font-size: 58px;
}

.landingpage5_bannerblock3_secondblock_leftsection strong {
    font-weight: 500;
    display: block;
}

.landingpage5_bannerblock3_secondblock_rightsection {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock3_secondblock_rightsection_img.webp), url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock3_secondblock_bg.webp);
    background-size: auto 100%, cover;
    width: 49%;
    border: 7px solid #3286bb;
    background-position: right bottom;
    border-radius: 10px;
    background-clip: content-box;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock3_secondblock_rightsection p {
    font-size: 28px;
    font-weight: 700;
    padding-left: 51px;
    padding-top: 50px;
    padding-bottom: 50px;
    line-height: 35px;
}

.landingpage5_bannerblock3_secondblock_rightsection span {
    color: #fe6d01;
    font-size: 58px;
}

.landingpage5_bannerblock3_secondblock_rightsection strong {
    font-weight: 500;
    display: block;
}


.landingpage5_bannerblock3_thirdblock {
    background: linear-gradient(90deg, rgba(12, 90, 144, 1) 10%, rgba(30, 97, 130, 1) 32%, rgba(19, 91, 136, 1) 53%, rgba(16, 90, 137, 1) 73%, rgba(13, 94, 146, 1) 94%);
    margin: 0 auto;
    /* margin: 20px 33px; */
    border-radius: 15px;
    padding: 30px 50px;

}

.landingpage5_bannerblock3_thirdblock h2 {
    text-align: center;
    font-size: 50px;
    color: #f7f9fa;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 56px;
    margin-bottom: 20px;
}

.landingpage5_bannerblock3_thirdblock ul {
    list-style: none;
    column-count: 6;
    column-gap: 30px;
}

.landingpage5_bannerblock3_thirdblock li {
    position: relative;
    font-size: 18px;
    color: #f7f9fa;
    font-weight: 600;
    padding: 0 0 10px 40px;
}

.landingpage5_bannerblock3_thirdblock li::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_block3_li_logo.png);
    background-size: 100%;
    background-position: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 2px 4px 4px #00000080;
}

.hr {

    background: linear-gradient(90deg, rgb(248 249 238 / 0%) 0%, rgba(177, 185, 193, 0.4654236694677871) 48%, rgb(254 254 243 / 0%) 100%);
    height: 3px;
    width: 100%;
    margin-bottom: 30px;

}

.landingpage5_bannerblock3_fourblock {
    padding: 10px 70px;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: -37px;
    position: relative;
}

.landingpage5_bannerblock3_fourblock h2 {
    font-size: 35px;
    font-weight: 700;
}


.landingpage5_bannerblock4 {
    /* background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock4_1img.webp), url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock4_1bg.webp); */
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/round_bg_with_girl.webp); */
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/round_img_with_medicine.webp);
    background-repeat: no-repeat;
    background-size: auto;
    padding: 45px 0;
    background-position: right 25px bottom -160px, right top;
    margin-top: -42px;
    padding-top: 100px;

}

.landingpage5_bannerblock4 h2 {
    font-size: 50px;
    font-weight: 800;
    line-height: 63px;
}

.landingpage5_bannerblock4 p {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
}

.landingpage5_bannerblock4 span {
    color: #424344;
    font-weight: 600;
}

.landingpage5_bannerblock4 strong {
    color: #0473c9;
    font-weight: 600;
}
.landingpage5_bannerblock4 strong a {text-decoration: none;color: #0473c9;}
.landingpage5_bannerblock5 {
    /* background: rgb(251,251,249);
                background: linear-gradient(90deg, rgba(251,251,249,1) 0%, rgba(251,251,249,1) 100%); */
}

.landingpage5_bannerblock5_firstblock {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/kalvin_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: fit-content;
    margin-bottom: 0px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #c7bcbc;
    padding: 30px 160px;
    padding-bottom: 50px;
}

.landingpage5_bannerblock5_firstblock h3 {
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    background: linear-gradient(180deg, rgb(255 255 255) 19%, rgba(169, 171, 172, 1) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0px 0px 6px #00000081);
    
}

.landingpage5_bannerblock5_firstblock p {
    font-size: 24px;
    color: #e2e4e5;
    text-align: center;
    display: inline;
    line-height: 38px;
}

.landing-col-container4 {
    padding-right: 630px;
}
.landingpage5_bannerblock5_firstblock strong {color: #fff;font-size: 24px;font-weight: 500;margin-left: 30px;}


.landingpage5_bannerblock6_mainwrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock6_1bg.webp), linear-gradient(90deg, rgba(6, 139, 237, 1) 0%, rgba(6, 139, 237, 1) 50%, rgba(1, 188, 255, 1) 100%);
    ;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 80px;
    border-radius: 10px;
    background-blend-mode: screen;
    margin-top: 30px;
}

.landingpage5_bannerblock6 h2 {
    text-align: center;
    color: white;
    font-size: 45px;
    font-weight: 600;
    padding-bottom: 13px;
    padding-top: 10px;
    line-height: 53px;
}

.landingpage5_bannerblock6_firstblock {
    background-color: #254785;
    padding: 20px;
    border: 7px solid #87ceebd9;
    border-radius: 20px;
    margin-bottom: 40px;
}

.landingpage5_bannerblock6_sub_firstblock {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;align-items: center;
}

.landingpage5_bannerblock6_sub_firstblock img {
    width: auto;max-width: 20%;
    padding: 10px;
}

.landingpage5_bannerblock7 {
    /* background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock7_1img.webp),
     url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock7_bg.webp); */
     /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/tablet_img.webp);
    background-repeat: no-repeat;
    background-size: auto, auto;
    background-position: left 0px bottom -21px, left top; */
    padding-top: 50px;
    padding-bottom: 20px;
}
.landingpage5_bannerblock7 .gradiant_darkBG {width: 80%;    padding: 10px 15px;}
/* .landingpage5_bannerblock7_mainwrapper {padding-left: 40%;position: relative;} */
.tablet_img {position: absolute;right: 0;top: 0;}
.landingpage5_bannerblock7 h2 {
    font-size: 50px;
    line-height: 60px;
}

.landingpage5_bannerblock7 h3 {
    font-size: 35px;
    font-weight: 500;
    line-height: 40px;
   margin: 15px 0;
}

.landingpage5_bannerblock7 h3 span {
    color: #0057a1;
}

.landingpage5_bannerblock7 p {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    padding-bottom: 30px;
}

.landingpage5_bannerblock7 p span {
    color: #01befd;
}

.landingpage5_bannerblock8 {
    background-color: #f7f7f0;
}

.landingpage5_bannerblock8_mainwrapper {
    background: linear-gradient(180deg, rgba(20,76,115,1) 8%, rgba(4,22,35,1) 70%);
    box-shadow: 0 0 10px #9fa0a1;
    padding: 30px;
    position: relative;
}

.landingpage5_bannerblock8_sub_mainwrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.landingpage5_bannerblock8_mainwrapper h2 {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    margin-bottom: 20px;
    color: #fff;
    /* background: linear-gradient(180deg, rgb(255 255 255) 19%, rgba(169, 171, 172, 1) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0px 0px 6px #00000081); */
}

.landingpage5_bannerblock8_step1 {
    background: #fff;
    display: flex;
    border: 1px solid lightgray;
    width: 48%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step1_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #ffe723;
}

.landingpage5_bannerblock8_step2 {
    background: #fff;
    display: flex;
    border: 1px solid lightgray;
    width: 48%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step2_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #f559a7;
}

.landingpage5_bannerblock8_step3 {
    background: #fff;
    display: flex;
    border: 1px solid lightgray;
    width: 48%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step3_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #ffa31f;
}

.landingpage5_bannerblock8_step4 {
    background: #fff;
    display: flex;
    border: 1px solid lightgray;
    width: 48%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step4_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #6f5dc5;
}

.landingpage5_bannerblock8_step5 {
    background: #fff;
    display: flex;
  
    width: 48%;
    margin-bottom: 39px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step5_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #ef5577;
}

.landingpage5_bannerblock8_step6 {
    background: #fff;
    display: flex;
    border: 1px solid lightgray;
    width: 48%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.landingpage5_bannerblock8_step6_img {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -1px;
    background: linear-gradient(180deg, rgba(1,194,210,1) 34%, rgba(0,142,194,1) 81%);
    display: flex;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: 8px solid #c7e325;
}

.landingpage5_bannerblock8_step1_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step2_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
     font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step3_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step4_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step5_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step6_img h4 {
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 29px;
    font-weight: 500;
}

.landingpage5_bannerblock8_step1_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step1_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -6px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step2_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step2_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step3_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step3_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -6px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step4_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step4_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -6px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step5_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step5_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -6px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step6_img::after {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo1.png);
    top: -8px;
    right: -7px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step6_img::before {
    content: " ";
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock8_6step_logo2.png);
    bottom: 0;
    right: -6px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
}

.landingpage5_bannerblock8_step1_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_step2_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_step3_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_step4_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_step5_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_step6_img img {
    width: 133px;
   padding-left: 22px;
}

.landingpage5_bannerblock8_mainwrapper h3 {
    font-size: 27px;
    line-height: 32px;
    padding-bottom: 5px;
}

.landingpage5_bannerblock8_mainwrapper p {
    font-size: 22px;
    line-height: 25px;
}

.landingpage5_bannerblock8_mainwrapper p span {
    color: #01befd;
}

.landingpage5_bannerblock8_step1_text {
    padding: 20px;
}

.landingpage5_bannerblock8_step2_text {
    padding: 20px;
}

.landingpage5_bannerblock8_step3_text {
    padding: 20px;
}

.landingpage5_bannerblock8_step4_text {
    padding: 20px;
}

.landingpage5_bannerblock8_step5_text {
    padding: 15px;
}

.landingpage5_bannerblock8_step6_text {
    padding: 20px;
}

.landingpage5_bannerblock8_mainwrapper .bluebgtitle {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -60px;
    width: 910px;
    text-align: center;
}
.landingpage5_bannerblock5_firstblock p strong {margin-left: 50px;}
.landingpage5_bannerblock5_firstblock p span {
    position: relative;
}

.landingpage5_bannerblock5_firstblock p span img {
    position: absolute;
    right: -25px;
    top: -5px;
    width: 20px;
}

.landingpage5_bannerblock5_firstblock p span:first-of-type img {
    top: -10px;
    left: -25px;
}
.landingpage5textwrapper_lastblock{display: block;text-align: center;}





















.landingpage5_bannerblock9_subwrapper1{
    /* background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_1bannerblock9_1imgtwo.webp); */
    background-image:url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/old_men_with_lady.webp);
    background-position: right top -50px;
    background-repeat: no-repeat;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-bottom: 270px;
}
.landingpage5_bannerblock9_subwrapper2{
    /* background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock9_2bg+img.png);  */
    background-image:url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/old_men_doc_img.webp); 
    background-position:left -150px bottom 0px ;
    background-repeat: no-repeat;
    padding-top: 215px;
    padding-bottom: 55px;
    margin-top: -270px;
    background-size: 920px;
}


.landingpage5_bannerblock9_mainwrapper_firstpart {
    width: 55%;
}

.SD_landing_modal .modalBaseBox {width: 500px;height: 250px;border-radius: 15px;max-width: 90%;}
.SD_landing_modal .modalBaseBox img {display: block;margin: 0 auto;margin-top: 10px;max-width: 100%;}
.SD_landing_modal .modalBaseBox p {font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #0569ad;}
.SD_landing_modal .addedit_wrp {margin-top: 20px;}
.landingpage5_bannerblock9_mainwrapper_firstpart p {
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
}

.landingpage5_bannerblock9_mainwrapper_firstpart h3 span {
    color: #0057a1;
}

.landingpage5_bannerblock9_mainwrapper_firstpart p span {
    color: #01bdfb;
}

.landingpage5_bannerblock9_mainwrapper_secondpart {
    padding-left: 33%;
}



.landingpage5_bannerblock9_mainwrapper_secondpart p {
    font-size: 22px;
}

.landingpage5_bannerblock9_mainwrapper_secondpart p span {
    color: #01bcfa;
}

.landingpage5textwrapper h1 {
    font-size: 50px;}
             .landingpage5_bannerblock9_mainwrapper_firstpart h3{font-size: 35px;line-height: 45px;margin-bottom: 10px;color: #0057a1;margin-top: 10px;}
             .landingpage5_bannerblock9_mainwrapper_firstpart p span{color: #026cbe;}   

             .landingpage5_bannerblock9_mainwrapper_secondpart h2{font-size: 35px;color:#0057a1 ;line-height: 45px;margin-bottom: 10px;}
             .landingpage5_bannerblock9_mainwrapper_secondpart p{  font-size: 24px;font-weight: 500;line-height: 31px;} 
             .landingpage5_bannerblock9_mainwrapper_secondpart p span{color: #026cbe;}
             .landingpage5textwrapper .gradiant_fontBW h2{font-size: 32px;font-weight:600;line-height: 40px;}
.landingpage5textwrapper h2{
        font-size: 35px;
    line-height: 42px;
    color: #fff;
    font-weight: var(--main-font-Bold);
    margin-bottom: 28px;
    display: inline;
}

.landingpage5textwrapper p {
    font-size: 24px;
    line-height: 31px;
    color: #fff;
    font-weight: var(--main-font-Medium);
    margin-top: 10px;

}

.landingpage5textwrapper h2 span {
    -webkit-text-fill-color: #01befd;
}
.landingpage5textwrapper .gradiant_black_darkBG{margin-top: 20px;display: block;margin: 0 auto;margin-top: 20px;}


.bluebgtitle {
    background: rgb(1, 254, 234);
    background: linear-gradient(180deg, rgba(1, 254, 234, 1) 0%, rgba(1, 183, 255, 1) 100%);
    padding: 20px 30px;
    border-radius: 18px;
    width: fit-content;

}

.bluebgtitle p {
    font-size: 32px;
    line-height: 36px;
    color: #283801;
    font-weight: var(--main-font-Bold);
    text-shadow: 1px 1px 0 #01faeb;
    margin: 0;
}

.landingpage5_lstblock_subwrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/lpage5lstimage2.jpg);
    background-size: cover;
    padding: 20px;
    /* padding-left: 400px; */
    position: relative;
    border-radius: 5px;
}

.landingpage5_lstblock_subwrapper .abimage {
    display: block;
    max-width: 100%;
    position: absolute;
    left: 20px;
    bottom: 0;
}
.landingpage5_lstblock_subwrapper  .abimage1{ 
    display: block;
    max-width: 100%;
    position: absolute;
    right: 40px;
    bottom: -28px;
}
.landingpage5_lstblock_subwrapper .abimage {
    display: block;
    max-width: 100%;
    position: absolute;
    left: 20px;
    bottom: 0;
}

.landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -41px;
    width: 910px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}
.landingpage5_bannerblock8_mainwrapper h2:last-child{font-size: 32px;line-height: 40px;margin-bottom: 0;color: #fff;}

.landingpage5_block12_subwrapper{
    /* background: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5block12_image1.png), */
    background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/young_doc.webp),
     linear-gradient(90deg, rgba(14,57,135,1) 0%, rgba(14,57,135,1) 40%, rgba(61,117,176,1) 100%);
    background-repeat: no-repeat;
    background-position: right -26px bottom, right top;
    padding: 28px;
    border-radius: 8px;
    padding-right: 44%;
    background-size: auto 100%;
    border: 7px solid #e3e3db;
    margin-bottom: 41px;
}

.landingpage5_block12_subwrapper .bluebgtitle {
    margin-bottom: 10px;
}

.landingpage5_block12_subwrapper .landingpage5textwrapper p:nth-child(2) {
    margin-bottom: 20px;
}

.landingpage5_block12_subwrapper .landingpage5textwrapper .bluebgtitle p {
    margin-bottom: 0px;
}

.demo5img {
    width: auto;
    max-width: 100%;
}

.demo5Con {
    background: rgb(244, 251, 255);
    background: linear-gradient(132deg, rgba(244, 251, 255, 1) 0%, rgba(253, 254, 242, 1) 100%);
    position: relative;
    box-shadow: 1px 1px 10px #c7bcbc;
    border-radius: 10px;
    width: 1795px;
    display: block;
    margin: 0 auto;
    margin-top: -5px;
}

.demo5Con img {
    width: 100%;
    position: relative;
}

.landingpage5_lstblock {
    margin: 0;
    margin-top: -18px;
    background: #eef2ea;
    padding: 20px 0;
    overflow: hidden;
}

.landingpage5_block12 {
    background: #eef2ea;
    overflow: hidden;
}

.demo5Con .bluebgtitle {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -30px;
}

.landing_page5_bannerblock .lpage_arrow_wrapper::after {
    z-index: -1;
}

/* -----------raj-----vdowrpr5--css---starts---------------------- */
.l5_videowrapper {
    width: 910px;
}

.l5_videowrapper .l5_vdo_txt_wrp {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/l5_txt_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 15px;
    margin: 0 auto;
    text-align: center;
    border-radius: 15px 15px 0 0;
}

.l5_videowrapper .l5_vdo_txt_wrp h2 {
   
    font-size: 50px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 6px;
}

.l5_videowrapper .l5_vdo_txt_wrp h5 {
    color: #00ebff;
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    text-align: center;
}

.l5_videowrapper .lpage_banner_sub_wrapper {
    width: 100% !important;
    padding: 0;
    background-image: none;
}




.prescriptions_subblock {    border-radius: 20px;background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/prescriptions_doc_img.webp), url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/prescritions_blue_bg.webp);background-repeat: no-repeat;background-position: left 50px bottom, center;background-size: auto, 100% 100%;padding: 70px 45px 25px 50px;padding-left: 43%;}
.prescriptions_subblock h2 {font-size: 46px;line-height: 54px;color: #fff;text-transform: uppercase;font-weight: 600;    text-shadow: 3px 2px 7px #145eb7;}
.prescriptions_subblock button {font-size: 39px;line-height: 45px;color: #000;font-weight: 600;border: none;outline: none;padding: 5px 10px; width: 470px;background: linear-gradient(90deg, rgba(252,182,58,1) 31%, rgba(240,241,66,1) 69%);
    border-radius: 6px;    margin-left: auto;position: relative;
    top: -20px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
   }
.prescriptions_block {margin-bottom: 40px;}

.last_phone_inner_block {background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/landing_page5_lastblock_bg.webp);background-repeat: no-repeat;background-position: left top;    padding: 25px 30px;background-size: cover;position: relative;}
.lady_call_grl {position: absolute;right: 0;bottom: 0;}
.last_phone_inner_block h2 {font-size: 30px;line-height: 38px;font-weight: 700;text-transform: uppercase;
    /* background:linear-gradient(180deg, rgba(255,255,255,1) 38%, rgba(172,174,175,1) 69%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;    filter: drop-shadow(0px 0px 6px  #00000081); */
color: #fff;
}

.last_phone_inner_block h2 span h2 {display: inline;}
.last_phone_inner_block h2 span h2 a {text-decoration: none;color: #fff;}
.last_phone_inner_block h1 {font-size: 58px;line-height: 64px;font-weight: 700;text-transform: uppercase;background: linear-gradient(180deg, rgba(255,222,0,1) 38%, rgba(255,236,109,1) 69%);-webkit-background-clip: text; -webkit-text-fill-color: transparent;    filter: drop-shadow(0px 0px 6px  #00000081);}
.last_phone_text_wrp {position: relative;padding-left: 30px;}
.last_phone_text_wrp::before {position: absolute;content: '';background: #02d5f4;width: 12px;height: 90%;top: 50%;left: 0;transform: translateY(-50%);}
.last_phone_block {margin: 40px 0;margin-top: 100px;}
    
/* -----------raj-----vdowrpr5--css---ends---------------------- */

.lnding_P05 .form_style .formWrapperClass{
    padding: 0px 15px 5px 15px;
}
.lnding_P05 .l2submitbtnsection{
    margin-top: 20px;
}

@media only screen and (max-width: 1870px) {
    .landingpage5_bannerblock4 h2 {font-size: 45px;line-height: 60px;}
}

@media only screen and (max-width: 1843px) {

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 42px;
        font-weight: 900
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 36px
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 25px;
    }

    .landingpage5_bannerblock8_mainwrapper h3 br {
        display: none;
    }

    .landingpage5_bannerblock8_mainwrapper p br {
        display: none;
    }

    .demo5Con {
        width: 96%;
    }
    .landingpage5_bannerblock4 h2 {font-size: 40px;line-height: 51px;}
}

@media only screen and (max-width: 1820px) {
    .lady_call_grl {right: 0;}
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 44px;
        font-weight: 900
    }
    .landingpage5_bannerblock2 {background-position: right -110px top 170px, left -95px bottom -35px;}
    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 34px
    }

   
}
@media only screen and (max-width:1799px) {
.landingpage5_bannerblock7 {background-position: left -90px bottom -13px;}
.prescriptions_subblock button {top: 10px;}
.prescriptions_subblock {    padding: 70px 50px;padding-left: 47%;}
.landingpage5_bannerblock7 .gradiant_darkBG {
    width: fit-content;
    padding: 20px;
}
.landingpage5_bannerblock7 h2 {
    font-size: 34px;
    line-height: 42px;
}
.landingpage5_bannerblock9_subwrapper1 {background-position: right -130px top -50px;}
}
@media only screen and (max-width:1778px) {
    .landingpage5_bannerblock3_firstblock p {
        font-size: 38px;
        line-height: 50px
    }

    .landing-col-container4 {
        padding-right: 700px;
    }

    .landingpage5_bannerblock4 span br {
        display: none;
    }
    .last_phone_inner_block h2 {
        font-size: 23px;
        line-height: 38px;}
}

@media only screen and (max-width:1745px) {
    .landingpage5_bannerblock9_subwrapper2 {    background-position: left -150px bottom 0px;}
    .landingpage5_bannerblock4 h2 {
        font-size: 48px;
    }.landingpage5_bannerblock4 h2 {
        font-size: 33px;
        line-height: 41px;
    }
    .landingpage5_bannerblock3_secondblock_leftsection:last-child {background-position: right -110px bottom,left top;}
 

    .landingpage5_bannerblock9_mainwrapper_secondpart h2 {
        font-size: 38px;
        line-height: 42px;
        margin-bottom: 15px;
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 24px;
    }
    .landingpage5_block12_subwrapper{background-position: right -77px bottom, right top;}
    .landing_page5_bannerblock {background-position: right 320px top 30px, center top 0;}
}

@media only screen and (max-width: 1700px) {
    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 30px;
        line-height: 37px;
    }
    .landingpage5_bannerblock2 .gradiant_fontBW h2 {
        font-size: 25px;
        line-height: 30px;
    }
    .landingpage5_bannerblock2 .gradiant_black_darkBG {
        padding: 15px 20px;
        width: 710px;max-width: 100%;}
 
 
}

@media only screen and (max-width: 1683px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 43px;
        font-weight: 900
    }

    .landingpage5_bannerblock4 p {
        font-size: 28px;
    }
    .l5_videowrapper {
        width: 820px;
    }
    .landing_page5_bannerblock .lpage_arrow_wrapper {
        margin-top: 30px;
    }
    .l5_videowrapper .l5_vdo_txt_wrp h2{font-size: 40px;}
    .l5_videowrapper .l5_vdo_txt_wrp h5{font-size: 22px;}
}

@media only screen and (max-width: 1666px) {
    /* .landingpage5_bannerblock9_subwrapper1 {
        background-position: right -74px bottom;
    } */
    .last_phone_block {margin-top: 130px;}
    .last_phone_inner_block {padding-right: 30%;}
        .last_phone_inner_block h1 {
            font-size: 45px;
            line-height: 50px;}
    .landingpage5textwrapper h2{
        font-size: 40px;
        line-height: 55px;
    }
    .landingpage5_lstblock_subwrapper .abimage{width: 22%;}
    .landingpage5textwrapper .gradiant_fontBW h2{font-size: 27px;}
    .landingpage5_bannerblock9_subwrapper2 {margin-top: -290px;}
}

@media only screen and (max-width: 1652px) {


    /* .landingpage5_bannerblock9_mainwrapper_secondpart {padding-left: 45%;} */
    .landingpage5_bannerblock9_mainwrapper_firstpart h3 span br {
        display: none;
    }
    .landing_page5_bannerblock .landing-banner-right{margin-top: 100px;}
}
@media only screen and (max-width: 1625px){
    .landingpage5_bannerblock2 {
        background-position: right -110px top 170px, left -95px bottom -75px;
    }
    .l5_videowrapper {
        
        width: 741px;
    }
    .landing_page5_bannerblock .react-player__preview{padding-top: 56.25%;}
}

@media only screen and (max-width: 1600px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 40px;
        font-weight: 900
    }

    .pr {
        padding-right: 200px
    }

    .landingpage5_block12_subwrapper {
        background-position: right -85px bottom, right top;
        padding-right: 50%;
    }

    .landingpage5textwrapper p{font-size: 22px;
        line-height: 30px;}

    .landingpage5_bannerblock2_right_subsection label {
        font-size: 25px;
    }

    .landingpage5_bannerblock2_right_subsection {
        width: 100%;
    }
    .landing_page5_bannerblock .form_title_wrapper h2 span{
        font-size: 25px;
    }
}

@media only screen and (max-width: 1577px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 39px;
        font-weight: 900
    }
    .landingpage5_bannerblock3_secondblock_leftsection:first-child {background-position: right -110px bottom,left top;}
    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 30px
    }

    .landingpage5_bannerblock7 h3 {
        font-size: 37px;

    }
  
    .landingpage5_bannerblock7 p {
 
        font-size: 22px;
    }

    .landingpage5_bannerblock2_right_subsection label {
        font-size: 25px;
    }
    .tablet_img {width: 120px;}
    .landingpage5_bannerblock9_subwrapper2 {
        background-position: left -250px bottom 0px;
    }
    .landingpage5_bannerblock9_subwrapper2 {
        margin-top: -330px;
    }
    
}

@media only screen and (max-width: 1550px) {
    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 781px
    }

   

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 33px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 38px;
        font-weight: 900
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 28px
    }

 
    .landing_page5_bannerblock .lpage_arrow_wrapper {
        margin-top: -11px;
    }

    .landing_page5_bannerblock .formwrapper {
        margin-top: 0px;
    }

 

    .l5_videowrapper .l5_vdo_txt_wrp h5 {
        font-size: 21px;
        line-height: 27px;
    }

    .l5_videowrapper {
        margin-bottom: 58px;
        
    }
}

@media only screen and (max-width: 1540px) {

    /* .landing_page5_bannerblock .landing-banner-right{width:calc(35% - 43px);margin-left:43px;margin-top:166px} */
    

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 20px;
        line-height: 28px
    }

  

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 31px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 36px;
        font-weight: 900
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper {
        width: 130%;
    }

    .landing_page5_bannerblock .lpage_banner_sub_titlewrapper {
        width: 100%;
    }
    .landingpage5textwrapper .gradiant_fontBW h2 {
        font-size: 27px;
    }
   
}
@media only screen and (max-width: 1522px) {
    .last_phone_block {
        margin-top: 80px;
    }

}
@media only screen and (max-width: 1513px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 35px;
        font-weight: 900
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 5
    }

    

    .landingpage5_bannerblock7 h2 br {
        display: none;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart {
        width: 53%;
    }
    .landingpage5_bannerblock9_mainwrapper_firstpart h3{font-size: 30px;line-height: 40px;}
    .landingpage5_bannerblock9_mainwrapper_secondpart h2{font-size: 30px;line-height: 40px;}

    .landingpage5_block12_subwrapper {
        background-position: right -156px bottom, right top;
      
    }
    .landingpage5_lstblock_subwrapper .abimage {
        width: 26%;
    }
    .landingpage5textwrapper .gradiant_fontBW h2 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 1480px) {
    .prescriptions_subblock h2 {
        font-size: 36px;
        line-height: 42px;
    }
    .prescriptions_subblock {
        padding: 45px;
        padding-left: 47%;    background-position: left -80px bottom, center;
    }
    .prescriptions_subblock button {    width: 360px;    font-size: 30px;
        line-height: 38px;}
    .landingpage5textwrapper h1 {
        font-size: 36px;
        line-height: 43px
    }
    .landing_page5_bannerblock {
        background-position: right 190px top 30px, center top 0;
    }
    .landingpage5_bannerblock2 {
        background-position: right -110px top 170px, left -95px bottom -205px;
    
    }
    .landingpage5_bannerblock4 p {
        font-size: 25px;line-height: 32px;
    }
    .drug_search_main_wrp {
        padding-left: 34%;
        margin-top: 125px;
    }
    .bluebgtitle p {
        font-size: 28px
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart {
        width: 51%;
    }

  
    .landingpage5_bannerblock9_subwrapper2 {
        margin-top: -360px;
    }

    .gradiant_fontBW h2 {font-size: 36px;line-height: 48px;}

 
}

@media only screen and (max-width: 1470px) {

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 33px;
        font-weight: 900
    }
    .landingpage5_bannerblock7 .gradiant_fontBW h2 {
        font-size: 33px;
        line-height: 41px;
    }
        .landingpage5_bannerblock7 h3 {
            font-size: 28px;
            line-height: 34px;
            margin: 10px 0;
        }
        .tablet_img {
            width: 80px;
        }
        .landingpage5_bannerblock7 p {
            font-size: 20px;
            line-height: 26px;
        }
    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 29px
    }

    .landingpage5_bannerblock3_secondblock_leftsection p {
        font-size: 22px;
        padding-left: 15px
    }

    .landingpage5_bannerblock3_secondblock_rightsection p {
        font-size: 22px;
        padding-left: 15px
    }
    .landingpage5_lstblock_subwrapper .abimage1{right: 0;}
    .landingpage5textwrapper h2 {
        font-size: 35px;
        line-height: 50px;
    }   
    .landingpage5textwrapper .gradiant_black_darkBG{padding: 20px 25px;}
    .landingpage5_lstblock_subwrapper .abimage{width: 24%;}
    .landingpage5_bannerblock9_subwrapper2 {
        margin-top: -330px;
    }
}

@media only screen and (max-width: 1440px) {
    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 28px
    }
    .landingpage5_bannerblock2 {
        background-position: right -190px top 210px, left -95px bottom -205px;
    }
    .landing-col-container2 {
        padding-right: 52%;
    }
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: 48px
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 688px
    }
    .landingpage5_block12_subwrapper {
        background-position: right -184px bottom, right top;
      
    }
    .landingpage5_bannerblock9_subwrapper1 {
        background-position: right -230px top -50px;
    }

}

@media only screen and (max-width: 1400px) {
    .lady_call_grl {width: 300px;}
    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 653px
    }
    .landingpage5_bannerblock3 .gradiant_fontBW h2{font-size: 42px;line-height: 52px;}
    .landing_page5_bannerblock .form_title_wrapper h2 span {
        font-size: 20px;
        line-height: 25px;
    }
    /* .last_phone_inner_block {padding: 15px;} */
    .last_phone_inner_block h2 {
        font-size: 24px;
        line-height: 32px;
    }
    .last_phone_inner_block h1 {
        font-size: 30px;
        line-height: 36px;
    }
    /* .landing_page5_bannerblock .landing-banner-right{margin-top:138px} */
    .landingpage5_bannerblock2_right_section p {
        font-size: 20px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 31px;
        font-weight: 900
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 27px
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart p {
        font-size: 20px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart p {
        font-size: 20px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h3 {
        font-size: 29px;
    }




    .landingpage5_bannerblock2_right_subsection label {
        font-size: 23px;
    }
    .landingpage5_block12_subwrapper {
        background-position: right -325px bottom, right top;
        padding-right: 41%;
    }
}

@media only screen and (max-width: 1378px) {
    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 653px
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 25px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 31px;
        font-weight: 900;
        line-height: 47px
    }
    .landing_page5_bannerblock {
        background-position: right 155px top 70px, center top 0;
    }
    
}

@media only screen and (max-width: 1366px) {
 
    .landingpage5textwrapper p {
        font-size: 20px;
        line-height: 30px;
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 20px;
        line-height: 29px;
    }

    .demo5Con .bluebgtitle p {
        font-size: 20px;
        line-height: 27px;
    }

    .landingpage5_bannerblock8_mainwrapper h2 {
        font-size: 45px;
    }
    /* .landingpage5_lstblock_subwrapper{padding-left: 328px;} */
}

@media only screen and (max-width: 1346px) {
    .landing_page5_bannerblock .lpage_banner_textwrapper1 {
        padding: 7px 19px;
    }

    .landing_page5_bannerblock .form_title_wrapper {
        padding: 10px;
    }
}

@media only screen and (max-width: 1331px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 30px;
        font-weight: 900;
        line-height: 42px
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 22px
    }

    .landingpage5_bannerblock2_right_section p {
        font-size: 18px
    }

    .landingpage5_bannerblock2_right_subsection {
        padding: 25px 47px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h3 {
        font-size: 27px;
    }

  

    .l5_videowrapper {
        margin-bottom: 53px;
        width: 666px;
    }
}

@media only screen and (max-width: 1314px) {
    .landingpage5_bannerblock2_right_section p {
        font-size: 17px
    }
    .tablet_img {
        width: 60px;
    }
    .landingpage5_bannerblock7 .gradiant_fontBW h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 20px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 29px;
        font-weight: 900;
        line-height: 39px
    }
}

@media only screen and (max-width: 1300px) {
    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 18px;
        line-height: 24px
    }
    /* .landingpage5_bannerblock2 {
        background-position: right -110px top 30px, left -161px bottom -201px;
    } */

    /* .landing_page5_bannerblock .landing-banner-right{margin-top:90px} */
    .landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span {
        font-size: 47px
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper h2 {
        font-size: 25px
    }

    .landing_page5_bannerblock .lpage_banner_textwrapper1 p {
        font-size: 28px
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 5
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 653px
    }


}

@media only screen and (max-width: 1290px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 28px;
        font-weight: 900;
        line-height: 35px
    }
    .landingpage5textwrapper h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .landingpage5textwrapper .gradiant_fontBW h2 {
        font-size: 22px;line-height: 27px;
    }
    .landingpage5textwrapper .gradiant_black_darkBG {
        padding: 15px 25px;
    }
    /* .landingpage5_lstblock_subwrapper {
        padding-left: 287px;
    } */
    .landingpage5_lstblock_subwrapper .abimage{left: 0;}
    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 5
    }
    .landingpage5_bannerblock9_subwrapper2 {
        background-position: left -280px bottom;
    }

}

@media only screen and (max-width: 1275px) {
    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 653px
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper h2 {
        font-size: 23px
    }

    .landing_page5_bannerblock .lpage_banner_textwrapper p span {
        font-size: 28px
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 5
    }

    .landingpage5_bannerblock3_secondblock_leftsection p {
        font-size: 18px;
        padding-left: 5px
    }

    .landingpage5_bannerblock3_secondblock_rightsection p {
        font-size: 18px;
        padding-left: 5px
    }

  

    .landingpage5_bannerblock9_mainwrapper_firstpart h2 {
        font-size: 39px;
    }


    .landingpage5_bannerblock8_mainwrapper p {
        font-size: 18px;
    }

    .bluebgtitle p {
        font-size: 28px;
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1262px) {
    .landingpage5_bannerblock2_right_section p {
        font-size: 16px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 24px;
        font-weight: 900
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 5
    }
 
}

@media only screen and (max-width: 1248px) {
    .landingpage5_bannerblock2_right_section h2 {
        font-size: 22px;
        font-weight: 900;
        line-height: 33px
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 19px;
        line-height: 22px
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 4
    }

    .landingpage5_bannerblock3_thirdblock h2 {
        text-align: center;
        font-size: 50px;
        font-weight: 600
    }

    .landingpage5_bannerblock4 h2 {
        font-size: 28px;
        line-height: 30px;
    }
    .landingpage5_bannerblock3 .gradiant_fontBW h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h2 {
        font-size: 37px;
    }

  

}

@media only screen and (max-width: 1199px) {
    .last_phone_inner_block {padding: 25px;}
    .prescriptions_block {margin: 20px 0;}
    .prescriptions_subblock button {margin: 0 auto;margin-top: 15px;max-width: 90%;top: 0;}
    .prescriptions_subblock {background: linear-gradient(90deg, rgba(22,72,155,1) 31%, rgba(34,145,211,1) 72%);padding: 20px;}
    .landingpage5_bannerblock3_secondblock_leftsection:last-child {background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/maintenance_bg.webp);background-size: cover;background-position: left top;}
    .drug_search_main_wrp {
        padding-left: 0;
        margin: 20px 0;
    }
    .trans_img {display: none;}
    .l2submitbtnsection {margin: 10px 0;}
    .landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG{   
        position: unset;
        transform: unset;
        width: 100%;
        text-align: center;
    }
.landingpage5_bannerblock5 {
    margin-top: 25px;
}
.landingpage5_bannerblock5_firstblock strong {font-size: 18px;margin-left: 30px;}
    .landing_page5_bannerblock .l2submitbtnsection button {width: 500px;
        display: block;
        margin: 0 auto;
        max-width: 100%;}
    .landing_page5_bannerblock .formwrapper {outline: none;}
    .landingpage5_bannerblock3_fourblock {margin-top: 25px;width: auto;padding: 20px;}
    .landingpage5_bannerblock2 .gradiant_black_darkBG {margin-top: 20px;}
    .landingpage5_bannerblock7_mainwrapper {padding: 0;}
    .tablet_img {display: none;}
    .landingpage5_bannerblock7 .gradiant_darkBG {width: auto;margin: 30px 0;}
    .landingpage5_bannerblock5_firstblock {padding: 60px;}
    .landingpage5_block12 {
        margin-top: -38px;
    }
    .landingpage5_bannerblock5_firstblock p span:first-of-type img {
        top: -10px;
        left: -40px;
        width: 30px;
    }
    .landingpage5_bannerblock5_firstblock p span img {bottom: -10px;
        right: -40px;
        width: 30px;}
    .landing_page5_bannerblock .lpage_banner_textwrapper1 {
        margin-left: 0px;
    }

    .demo5Con {
        overflow: hidden;
    }

    .demo5Con .bluebgtitle {
        position: inherit;
        left: 0;
        transform: inherit;
        bottom: 0;
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: -3%;
        text-align: center;
    }

    .landingpage5_bannerblock3_subwrapper {
        padding: 15px;
    }

    .demo5Con {
        overflow: hidden;
    }

    .demo5Con .bluebgtitle {
        position: inherit;
        left: 0;
        transform: inherit;
        bottom: 0;
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: -3%;
        text-align: center;
    }
    .landingpage5_bannerblock5_firstblock p span:first-of-type img {
        top: -5px;
        left: -20px;
        width: 15px;
    }
    .landingpage5_bannerblock5_firstblock p span img {
        top: -4px;
        right: -20px;
        width: 15px;
    }
    .landingpage5_bannerblock5_firstblock p {
        font-size: 18px;
        line-height: 24px;}
        .landingpage5_bannerblock5_firstblock {
            padding: 30px;
        }
    .landingpage5_bannerblock4 {
        background-image: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/landingpage5_bannerblock4_1bg.webp);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0 !important;
    }

    .landing-col-container4 {
        padding-right: 0;
    }

    .landingpage5_bannerblock4 {
       display: none;
    }

    .landingpage5_bannerblock3_fourblock h2 {
        line-height: 45px;
        font-size: 30px;
    }

    .landingpage5_lstblock_subwrapper {
        padding: 20px;
      
        text-align: center
    }

    .landingpage5_bannerblock3_secondblock_leftsection p br {
        display: none;
    }

    .landingpage5_bannerblock3_secondblock_rightsection p br {
        display: none;
    }

    .landingpage5_lstblock_subwrapper .abimage {
        position: inherit;
        margin: 0 auto
    }

    .landingpage5_lstblock .bluebgtitle {
        margin: 0 auto;
        margin-bottom: 10px
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper {
        width: 100%
    }

    .landing_page5_bannerblock .lpage_banner_textwrapper {
        width: 100%;
        text-align: center
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper {
        background: linear-gradient(180deg, rgba(16,114,189,1) 8%, rgba(2,16,26,1) 70%);
        border-radius: 10px;
        padding: 16px 60px 13px 39px;
        width: auto;
        margin: 20px 0;
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 28px;
        line-height: 39px;
        text-align: center
    }
    .landingpage5_bannerblock4 h2{display: inline;}
    .landingpage5_bannerblock4 .gradiant_darkBG{width: 100%;}
    .landingpage5_bannerblock6_mainwrapper{background-image:  linear-gradient(90deg, rgba(6, 139, 237, 1) 0%, rgba(6, 139, 237, 1) 50%, rgba(1, 188, 255, 1) 100%);}
    .landing_page5_bannerblock .lpage_banner_textwrapper1 {
        background-color: #f5f5f5;
        padding: 20px 40px;
        width: auto;
        text-align: center;
        color: #000;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 10px
    }

    .landingpage5_bannerblock3_secondblock_leftsection p {
        padding-left: 0;
        font-size: 30px
    }

    .landingpage5_bannerblock3_secondblock_rightsection p {
        padding-left: 0;
        font-size: 30px
    }

    .landing_page5_bannerblock {
        background: none;
        background: #fefdec;
        background: linear-gradient(90deg, rgba(254, 253, 236, 1) 0%, rgba(247, 254, 254, 1) 25%, rgba(251, 254, 242, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(252, 254, 242, 1) 100%);
        padding-bottom: 40px;
    }

    .landing_page5_bannerblock .landing-banner-right {
        margin-top: 0;
        width: 100%;
        margin-left: 0
    }

    .landingpage5_bannerblock2_right_subsection {
        margin: 0 auto;
        margin-bottom: 25px;
        margin-top: 25px;
    }

    .landingpage5_bannerblock2_right_subsection label {
        font-size: 34px;
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 43px;
        font-weight: 900;
        line-height: 54px
    }

    .landingpage5_bannerblock2_right_section h3 {
        color: #0057a1;
        font-size: 34px
    }

    .landingpage5_bannerblock2 .landing-col-container2 {
        padding-right: 0
    }

    .landingpage5_bannerblock2 {
        background-image: none;
        padding: 0px;
        padding-top: 30px;
        background: #fefdec;
        background: linear-gradient(90deg, rgba(254, 253, 236, 1) 0%, rgba(247, 254, 254, 1) 25%, rgba(251, 254, 242, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(252, 254, 242, 1) 100%);
        margin-top: 0px;
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 36px
    }

    .landingpage5_bannerblock2_right_section p {
        font-size: 23px
    }

    .landingpage5_bannerblock3_firstblock {
        font-size: 38px
    }

    .landingpage5_bannerblock3_secondblock {
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px
    }

    .landingpage5_bannerblock3_secondblock_leftsection {
        width: 100%;
        margin-bottom: 10px;
        background-image: none;
        text-align: center;
        background: #fefdec;
        background: linear-gradient(90deg, rgba(254, 253, 236, 1) 0%, rgba(247, 254, 254, 1) 25%, rgba(251, 254, 242, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(252, 254, 242, 1) 100%)
    }

    .landingpage5_bannerblock3_secondblock_rightsection {
        width: 100%;
        background-image: none;
        text-align: center;
        background: #fefdec;
        background: linear-gradient(90deg, rgba(254, 253, 236, 1) 0%, rgba(247, 254, 254, 1) 25%, rgba(251, 254, 242, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(252, 254, 242, 1) 100%)
    }

    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 4
    }

    .landingpage5_bannerblock2_right_section h2 br {
        display: none;
    }

    .landingpage5_bannerblock2_right_section h3 br {
        display: none;
    }

    .landingpage5_block12_subwrapper {
        background-position: right bottom;
        padding: 20PX;
        background: #0e3987;
        margin-bottom: 20px;
        
    }



    .landingpage5_bannerblock7 {
        background-color: #fbfaed;
        background-image: none;
        padding: 0;
    }

    .landingpage5_bannerblock7 p {
        padding-left: 0;
        font-size: 24px;
        line-height: 31px;
    }

    .landingpage5_bannerblock7 h3 {
        padding-left: 0;
        font-size: 40px;
        margin: 10px 0;
        line-height: 50px;
    }

    .landingpage5_bannerblock7 h2 {
        padding-left: 0;
        font-size: 45px;
        line-height: 50px;
    }

    .landingpage5_bannerblock6 h2 {
        font-size: 38px;
        line-height: 46px;
    }

    .landingpage5_bannerblock9 {
        background-image: none;
        margin-bottom: 38px;
        padding: 0 ;
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart {
        padding-left: 0;
        margin: 0;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart {
        width: 100%;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart p {
        font-size: 22px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h3 {
        font-size: 35px;
        line-height: 41px;
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart h2 {
        font-size: 35px;
        line-height: 41px;
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart p {
        font-size: 22px;
    }

 

    .landingpage5_bannerblock9_subwrapper1 {
        background: none;
        padding: 0px;
    }

    .landingpage5_bannerblock9_subwrapper2 {
      background-image: none;
        padding-top: 25px;
        margin-top: 0px;
        padding-bottom: 25px;
    }

    .landingpage5_bannerblock8_sub_mainwrapper {
        flex-direction: column;
    }

    .landingpage5_bannerblock8_step1 {
        width: 100%;
    }

    .landingpage5_bannerblock8_step2 {
        width: 100%;
    }

    .landingpage5_bannerblock8_step3 {
        width: 100%;
    }

    .landingpage5_bannerblock8_step4 {
        width: 100%;
    }

    .landingpage5_bannerblock8_step5 {
        width: 100%;
    }

    .landingpage5_bannerblock8_step6 {
        width: 100%;
    }

    .landingpage5_bannerblock8_mainwrapper p {
        font-size: 24px;
        line-height: 30px;  
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 30px;
        line-height: 33px;
    }

    .landingpage5_bannerblock8_mainwrapper .bluebgtitle {
        bottom: -45px;
    }

    .landingpage5_bannerblock8_mainwrapper h2 {
        font-size: 40px;
        line-height: 48px;
    }

    .landingpage5_bannerblock8_mainwrapper .bluebgtitle {
        position: inherit;
        left: 0;
        transform: inherit;
        bottom: 0;
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 3%;
        text-align: center;
    }

    .landingpage5_lstblock_subwrapper .abimage {
        width: auto;
    }

    .l5_videowrapper {
        width: auto;
        margin-bottom: 20px;
    }

    .l5_videowrapper .l5_vdo_txt_wrp {
        width: auto;
        border-radius: 15px 15px;
        margin: 0 auto 25px;
    }

    .landingpage5_lstblock_subwrapper .abimage {
       display: none;
    }

    .landingpage5_lstblock_subwrapper .abimage1 {
        display: none;
    }
    .landingpage5textwrapper .gradiant_black_darkBG{margin: 15px auto;margin-bottom: 0;}
    .landing-col-container4 h2 br {
        display: none;
    }

    .landing_page5_bannerblock .lpage_banner_sub_titlewrapper {
        width: 100%;
        margin-top: -15px;
    }
    .lady_call_grl {display: none;}
    .last_phone_inner_block h2 br {display: none;}
    .last_phone_inner_block h2 {
        font-size: 31px;
        line-height: 38px;
    }
    .last_phone_inner_block h1 {
        font-size: 35px;
        line-height: 44px;
    }
    .last_phone_block {margin: 20px 0;}
}

@media only screen and (max-width: 1064px) {
    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 3
    }

    .landingpage5_bannerblock3_thirdblock h2 {
        text-align: center;
        font-size: 41px;
        font-weight: 600;
        line-height: 43px
    }
   

    /* .landingpage5_bannerblock3_fourblock {
        position: inherit;
        left: 0;
        transform: inherit;
        bottom: 0;
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: -3%;
        text-align: center;
        position: relative;
    } */

    .landingpage5_bannerblock8_mainwrapper {
        padding: 30px 30px 30px 30px;
    }

    .landingpage5_bannerblock2_right_subsection label {
        font-size: 28px;
    }
}
@media only screen and (max-width: 1505px){
    .landingpage5_bannerblock4 h2{-webkit-text-fill-color: #dfdbdb; background: none;}
}
@media only screen and (max-width: 1480px){
    .landingpage5_bannerblock9 .gradiant_fontBW h2{-webkit-text-fill-color: #dfdbdb; background: none;}
}
/* @media only screen and (max-width: 1440px){
    .landingpage5_bannerblock2 .gradiant_fontBW h2{   -webkit-text-fill-color: #dfdbdb; background: none;}
} */
/* @media only screen and (max-width: 1224px){
    .landingpage5_bannerblock3 .gradiant_fontBW h2{-webkit-text-fill-color: #dfdbdb; background: none;}
} */
@media only screen and (max-width: 666px){
    /* .landingpage5textwrapper .gradiant_fontBW h2{-webkit-text-fill-color: #dfdbdb; background: none;} */
    .prescriptions_subblock h2 {
        font-size: 26px;
        line-height: 32px;
    }
    .prescriptions_subblock button {font-size: 22px;
        line-height: 29px;}
}
@media only screen and (max-width: 511px){
    .landingpage5_bannerblock7 .gradiant_fontBW h2{-webkit-text-fill-color: #dfdbdb; background: none;}
}
@media only screen and (max-width: 946px) {

    .landingpage5_bannerblock8_mainwrapper {
        padding: 20px 10px 10px 10px;
    }
    
    /* .landingpage5_bannerblock8_mainwrapper h2{-webkit-text-fill-color: #dfdbdb; background: none;} */
}

@media only screen and (max-width: 900px) {
    .landingpage5_bannerblock2_right_subsection label {
        font-size: 25px;
    }

    .landingpage5_bannerblock3_thirdblock h2 {
        text-align: center;
        font-size: 37px;
        font-weight: 600
    }

    .landingpage5_bannerblock3_firstblock p {
        line-height: 43px;
    }

    .landingpage5_bannerblock2_right_section h2 {
        line-height: 48px;
    }

    .landingpage5_bannerblock2_right_section h3 {
        line-height: 36px;
    }

    .landingpage5_bannerblock2_right_section p {
        line-height: 28px;
    }

    .landingpage5_bannerblock5_firstblock h3 {
        font-size: 38px;
        line-height: 40px;
        margin-bottom: 15px;
    }



}

@media only screen and (max-width: 810px) {
    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 2
    }
    .landingpage5_bannerblock3 .gradiant_fontBW h2 {
        font-size: 37px;
        line-height: 46px;
    }
    .landingpage5_bannerblock2_right_subsection {
        padding: 25px 12px
    }

    .landingpage5_bannerblock6_sub_firstblock {
        flex-direction: column;
    }

    .landingpage5_bannerblock6_sub_firstblock img {
        width: 300px;
        padding: 0;
        max-width: 100%;margin: 10px 0;
    }

    .landingpage5_bannerblock6 h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .landingpage5_bannerblock6_firstblock {
        padding: 10px;
        margin-bottom: 0;
    }

    .landingpage5_bannerblock6_mainwrapper {
        padding: 20px 20px;
    }

}
@media only screen and (max-width: 768px){
    .l5_videowrapper .l5_vdo_txt_wrp h5{text-align: center;}
    .landingpage5_bannerblock4 .gradiant_darkBG{text-align: center;}
    .landingpage5_bannerblock7 .gradiant_darkBG{text-align: center;margin-bottom: 20px;}
    .landingpage5_bannerblock9_mainwrapper_firstpart h2{text-align: center;} 
    
}
@media only screen and (max-width: 767px) {

    .landingpage5_bannerblock3_secondblock_leftsection p,
    .landingpage5_bannerblock3_secondblock_rightsection p {
        padding: 20px 0
    }

    .landingpage5_bannerblock3_secondblock_leftsection span,
    .landingpage5_bannerblock3_secondblock_rightsection span {
        display: block;
        margin-bottom: 10px
    }

    .landingpage5_bannerblock8_mainwrapper p {
        font-size: 20px;
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 27px;
        line-height: 29px;
    }

}

@media only screen and (max-width: 700px) {
    .landingpage5_bannerblock2_right_subsection {
        padding: 16px 10px;
        text-align: center;
    }
}

@media only screen and (max-width: 583px) {

    .landingpage5_bannerblock3_firstblock {
        font-size: 34px
    }

    .landingpage5_bannerblock3_secondblock_leftsection p {
        padding-left: 0;
        font-size: 27px
    }

    .landingpage5_bannerblock3_secondblock_rightsection p {
        padding-left: 0;
        font-size: 27px
    }

    .landingpage5_bannerblock3_thirdblock {
        padding: 24px 20px
    }

    .landingpage5_bannerblock6 h2 {
        font-size: 30px;
        line-height: 41px;
    }

    .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 36px;
        line-height: 42px;


    }
    .landingpage5_bannerblock3 .gradiant_fontBW h2 {
        font-size: 30px;
        line-height: 36px;
    }
}

@media only screen and (max-width: 490px) {
    .landingpage5_bannerblock3_thirdblock h2 {
        line-height: 40px;
    }
    .last_phone_inner_block h2 {
        font-size: 21px;
        line-height: 28px;
    }
    .last_phone_inner_block h1 {
        font-size: 30px;
        line-height: 38px;
    }
    .landingpage5_bannerblock8_mainwrapper .gradiant_black_darkBG, .landingpage5_bannerblock2 .gradiant_black_darkBG, .landingpage5_bannerblock3_fourblock, .landingpage5textwrapper .gradiant_black_darkBG {padding: 10px;}
    .landingpage5_bannerblock8_mainwrapper h2:last-child, .landingpage5_bannerblock2 .gradiant_fontBW h2, .landingpage5_bannerblock3 .gradiant_fontBW h2, .landingpage5textwrapper .gradiant_fontBW h2 {font-size: 20px;line-height: 24px;}
    .landingpage5_bannerblock8_mainwrapper h2 {
        font-size: 23px !important;
        line-height: 28px !important;
    }
    .landingpage5_whole_cont .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 25px !important;
        line-height: 29px !important;
    }
    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 21px;
        line-height: 26px;
    }
    .landingpage5_bannerblock3_thirdblock h2 {
        line-height: 28px;
        font-size: 22px;
    }
    .landingpage5_bannerblock6 h2, .landingpage5_bannerblock7 .gradiant_fontBW h2, .landingpage5_bannerblock9_mainwrapper_firstpart h2, .landingpage5_bannerblock9_mainwrapper_firstpart h3, .landingpage5_bannerblock9_mainwrapper_secondpart h2, .landingpage5textwrapper h2 {
        font-size: 22px !important;
        line-height: 28px !important;
    }
    /* .landingpage5_bannerblock3_fourblock {
        left: 0;
        transform: inherit;
        bottom: 0;
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 6%;
        text-align: center;
        position: relative
    } */

    .landingpage5_bannerblock8_mainwrapper p {
        font-size: 18px;
    }

    .landingpage5_bannerblock8_mainwrapper h3 {
        font-size: 26px;
        line-height: 33px;
    }

    .landingpage5_bannerblock8_mainwrapper h2 {
        font-size: 35px;
        line-height: 43px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart p {
        font-size: 20px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h2 {
        font-size: 35px;
        line-height: 44px;
    }

    .landingpage5_bannerblock9_mainwrapper_firstpart h3 {
        font-size: 27px;
        line-height: 37px;
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart h2 {
        font-size: 27px;
    }

    .landingpage5_bannerblock9_mainwrapper_secondpart p {
        font-size: 20px;
    }

    .landingpage5_bannerblock7 p {
        padding-left: 0;
        font-size: 20px;
        line-height: 28px;
    }

    .landingpage5_bannerblock7 h3 {
        padding-left: 0;
        font-size: 27px;
        line-height: 34px;
    }

    .landingpage5_bannerblock7 h2 {
        padding-left: 0;
        font-size: 35px;
        line-height: 47px;
    }

    .landingpage5_bannerblock3_fourblock h2 {
        line-height: 34px;
        font-size: 25px;

    }
}


@media only screen and (max-width: 480px) {
    .SD_landing_modal .modalBaseBox {height: auto;}
    .SD_landing_modal .modalBaseBox p {
        font-size: 30px;
    }
    .landingpage5_bannerblock3_thirdblock ul {
        column-count: 1;
        align-items: center
    }
    .landingpage5_bannerblock5_firstblock h3 {
        font-size: 30px;
        line-height: 35px;}
    .landingpage5_bannerblock2 h3 {
        font-size: 24px;
        line-height: 30px;}
        .landingpage5_bannerblock2_right_section p {
            font-size: 18px;line-height: 24px;
        }
        .landingpage5_bannerblock3_thirdblock h2 {
            line-height: 36px;font-size: 30px;
        }
        .landingpage5_bannerblock4 p {
            font-size: 18px;line-height: 24px;
        }
    .landing_page5_bannerblock .lpage_arrow_wrapper {padding: 15px;}
    .landingpage5textwrapper h1 {
        font-size: 28px;
        line-height: 34px
    }

    .bluebgtitle p {
        font-size: 24px;
        line-height: 31px
    }

    .demo5Con .bluebgtitle p {
        font-size: 24px;
        line-height: 31px;
    }
    .landingpage5_bannerblock4 .gradiant_darkBG {margin-bottom: 15px;}
    .landingpage5_bannerblock8_step1 {
        display: block;
    }

    .landingpage5_bannerblock8_step2 {
        display: block;
    }

    .landingpage5_bannerblock8_step3 {
        display: block;
    }

    .landingpage5_bannerblock8_step4 {
        display: block;
    }

    .landingpage5_bannerblock8_step5 {
        display: block;
    }

    .landingpage5_bannerblock8_step6 {
        display: block;
    }

    .landingpage5_bannerblock8_step1_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step1_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step2_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step2_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step3_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step3_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step4_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step4_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step5_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step5_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step6_img::before {
        display: none;
    }

    .landingpage5_bannerblock8_step6_img::after {
        display: none;
    }

    .landingpage5_bannerblock8_step1_img {
        margin-left: 0px;
    }

    .landingpage5_bannerblock8_step2_img {
        margin-left: 0px;
    }

    .landingpage5_bannerblock8_step3_img {
        margin-left: 0px;
    }

    .landingpage5_bannerblock8_step4_img {
        margin-left: 0px;
    }

    .landingpage5_bannerblock8_step5_img {
        margin-left: 0px;
    }

    .landingpage5_bannerblock8_step6_img {
        margin-left: 0px;
    }
    .landing_page5_bannerblock .form_style .form_radio .MuiFormControlLabel-root {width: auto;}
}
@media only screen and (max-width: 425px){
    .landingpage5_bannerblock2 h3{padding:0 10px;}
    .landingpage5_bannerblock2_right_section p{padding: 0 10px;}
    .landingpage5_bannerblock4 p{padding: 0 10px;}
    .landingpage5_bannerblock7 h3{padding: 0 10px;}
    .landingpage5_bannerblock7 p{padding: 0 10px;margin-bottom: 20px;}
    .landingpage5_bannerblock9_mainwrapper_firstpart h3{padding: 0 10px;}
    .landingpage5_bannerblock9_mainwrapper_firstpart p{padding: 0 10px;}
    .landingpage5_bannerblock9_mainwrapper_secondpart h2{padding: 0 10px;}
    .landingpage5_bannerblock9_mainwrapper_secondpart p{padding: 0 10px;}
    .landingpage5_bannerblock5_firstblock p span:first-of-type img{left: -22px;}
}
@media only screen and (max-width: 410px) {
    .landingpage5_bannerblock3_fourblock h2 {
        font-size: 24px;
        line-height: 29px;
        font-weight: 700;
       
    }
    .landingpage5_bannerblock9_mainwrapper_firstpart h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .landingpage5_bannerblock9_mainwrapper_firstpart h3 {
        font-size: 24px;
        line-height: 31px;
    }
    .landingpage5_bannerblock9_mainwrapper_firstpart p {
        font-size: 18px;
        line-height: 25px;
    }
    .landingpage5_bannerblock9_mainwrapper_secondpart h2 {
        font-size: 24px;
        line-height: 31px;
    }
    .landingpage5_bannerblock9_mainwrapper_secondpart p{
        font-size: 18px;
        line-height: 25px;
    }
    .landingpage5textwrapper .gradiant_fontBW h2 {
        font-size: 22px;
        line-height: 31px;
    }
    .landingpage5textwrapper p {
        font-size: 18px;
        line-height: 25px;
    }
    .landingpage5textwrapper h2 {
        font-size: 26px;
        line-height: 35px;
    }
    .landingpage5textwrapper .gradiant_fontBW h2 {
        font-size: 21px;
        line-height: 28px;
    }

    .landingpage5_bannerblock3_thirdblock li {
        position: relative;
        font-size: 18px;
        font-weight: 600;
        padding: 0 0 11px 33px
    }

    .landingpage5_bannerblock3_thirdblock h2 {
        text-align: center;
        font-size: 28px;
        font-weight: 600
    }

    /* .landingpage5_bannerblock3_firstblock {
        font-size: 26px;
        background: #0be8fe;
        background: linear-gradient(180deg, rgba(11, 232, 254, 1) 0%, rgba(70, 180, 254, 1) 100%);
        width: -moz-fit-content;
        width: fit-content;
        padding: 25px 0;
        font-weight: 600;
        text-align: center;
        border-radius: 10px
    } */

    .landingpage5_bannerblock2_right_section p {
        font-size: 16px
    }

    .landingpage5_bannerblock2_right_section h3 span {
        color: #0057a1;
        font-size: 29px
    }

    .landingpage5_bannerblock2_right_section h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: 35px;
    }

    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        font-size: 19px;
        line-height: 24px;
        text-align: center
    }

    .landing_page5_bannerblock .lpage_banner_textwrapper1 p {
        font-size: 28px
    }

    .landingpage5_bannerblock6 h2 {
        font-size: 25px;
        line-height: 36px;
    }

    .landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span {
        font-size: 40px;
    }
}
/* -------------SD css-------------- */
.land_footer_block {padding: 20px 10px;background: #0072ff;text-align: center;color: #fff;text-transform: capitalize;}
.land_footer_block h2 {text-transform: uppercase;}
.landingpage5_bannerblock3 {margin-bottom: 30px;}

