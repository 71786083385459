/* ------------------------------ common ----------------------------------- */


.common_width {
    width: 1635px;
    margin: 0 auto;
}

@media screen and (max-width: 1799px) {
    .common_width {
        width: 94%;
    }
}





























































/* ------------------------------ common end ----------------------------------- */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ------------------------------ Soubhik Debnath ----------------------------------- */
/* .lp19_banner {
    background-color: #eef1f8;
} */

.lp19_banner_BGimage {
    background-image:
        /* url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimagefooter_white.webp), */
        url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/LP19_banner_Doctor_img.webp);
    /* url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimage1.webp); */
    background-repeat: no-repeat;
    background-position: bottom -16px center, bottom 300px right 459px, top -50px center;
    background-size: 100%, 460px, cover;
    padding-bottom: 20px;
}

.lp19_banner_logo_headding {
    display: flex;
    align-items: center;
}

.lp19_banner_headdingText h1 {
    font-size: 43px;
    margin-left: 75px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    line-height: 40px;
}

.lp19_banner_headdingText h1 span {
    color: #7ddf20;
}

.lp19_banner_logo {
    width: 327px;
    max-width: 20%;
}

.lp19_banner_headdingText {
    width: 80%;
}

.lp19_banner_logo img {
    width: 100%;
    display: block;
}

.lp19_banner_Main {
    padding-top: 40px;
}

.page5_react_playerLP19 {
    width: 100% !important;
    height: 100% !important;
}

.lp19_banner_Video .react-player__preview {
    padding-top: 57.25%;
    position: relative;
}

.lp19_banner_Video .react-player__shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #13cf009e !important;
}

.page5_react_playerLP19 video {
    display: block;
}

.lp19_banner_Body {
    display: flex;
    justify-content: space-between;
}

.lp19_banner_text_video {
    width: 51%;
}

.lp19_banner_Form {
    width: 26%;
    margin-top: 48px;
}

.lp19_banner_Text {
    padding: 50px 0;
    padding-bottom: 20px;
}

.lp19_banner_Text p {
    font-size: 28px;
    color: #fff;
    font-weight: 700;
}

.lp19_banner_Text p span {
    color: #7ddf20;
}

.lp19_banner_Video {
    width: 830px;
    border-radius: 10px;
    border: 6px solid #fff;
    box-shadow: 30px 50px 110px -38px #000000d1;
}

.lp19_banner_Video img {
    display: block;
    width: 100%;
}

.lp19_banner_Form_sub {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25);
}

/* .lp19_banner_Form_sub_top_header{} */
.lp19_banner_Form_sub_top_header .form_title_wrapper h2 span {
    color: #0a3a05;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    padding: 10px;
    background: linear-gradient(180deg, rgba(195, 251, 4, 1) 0%, rgba(55, 195, 60, 1) 100%);
    -webkit-text-fill-color: unset;
}

.lp19_banner_Form_sub .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
}

.lp19_banner_Form_sub .MuiFormControl-root:last-child {
    margin-bottom: 0;
}

.lp19_banner_Form_sub_top_header .landing-banner-right {
    width: 100%;
    margin-left: 0;

}

.lp19_banner_Form_sub_top_header .landingpage13_frm_main_wrapper .form_title_wrapper {
    background: none;
    padding: 0;
    border-radius: 0;
}

.lp19_banner_Form_sub_top_header .landingpage13_frm_main_wrapper .formwrapper {
    outline: none;
    border: none;
    padding: 0;
}

.LP19_formSection {
    padding: 17px;
    padding-top: 23px;
    padding-bottom: 37px;
}

.lp19_banner_Form_sub .MuiInputBase-root {
    border-radius: 0;
}

.lp19_banner_Form_sub button {
    background: linear-gradient(180deg, rgba(54, 54, 54, 1) 0%, rgba(13, 13, 13, 1) 100%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    padding: 20px 0;
}

.lp19_banner_Form .form_item {
    margin-bottom: 8px !important;
}

.lp19_banner_Form_main .email_btm_line p {
    display: none;
}

.lp19_banner_Form_main {
    margin-top: 20px;
}

.lp19_banner_Form_main .l2submitbtnsection {
    margin: 0;
    margin-top: 20px;
}


.lp19_banner_Form .form_style .MuiFormLabel-filled {
    display: none;
}

.lp19_banner_Form .form_style label.Mui-focused {
    display: none;
}

.lp19_banner_Form .form_style legend {
    display: none;
}

.lp19_banner_Form .form_style fieldset {
    top: 0 !important;
}



/* 
.lp19_banner {
    background-color: #eef1f8;
} */

.lp19_banner_BGimage {
    background-image:
        url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimagefooter_white.webp),
        url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/LP19_banner_Doctor_img.webp),
        url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimage1.webp);
    background-repeat: no-repeat;
    background-position: bottom -69px center, bottom 190px right 504px, top -44px center;
    background-size: 100%, 460px, cover;
    padding-bottom: 124px;
}

.lp19_block1_main_wrapper .Lp14block2_sub2_wrapper {
    margin-bottom: 26px;
}

.lp19_banner_logo_headding {
    display: flex;
    align-items: center;
}

.lp19_banner_headdingText h1 {
    font-size: 38px;
    margin-left: 75px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    line-height: 40px;
}

.lp19_banner_headdingText h1 span {
    color: #7ddf20;
}

.lp19_banner_logo {
    width: 327px;
    max-width: 20%;
}

.lp19_banner_headdingText {
    width: 80%;
}

.lp19_banner_logo img {
    width: 100%;
    display: block;
}

.lp19_banner_Main {
    padding-top: 40px;
}

.page5_react_playerLP19 {
    width: 100% !important;
    height: 100% !important;
}

.lp19_banner_Video .react-player__preview {
    padding-top: 57.25%;
    position: relative;
}

.lp19_banner_Video .react-player__shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #13cf009e !important;
}

.page5_react_playerLP19 video {
    display: block;
}

.lp19_banner_Body {
    display: flex;
    justify-content: space-between;
}

.lp19_banner_text_video {
    width: 51%;
}

.lp19_banner_Form {
    width: 26%;
    margin-top: 48px;
}

.lp19_banner_Text {
    padding: 50px 0;
    padding-bottom: 20px;
}

.lp19_banner_Text p {
    font-size: 28px;
    color: #fff;
    font-weight: 700;
}

.lp19_banner_Text p span {
    color: #7ddf20;
}

.lp19_banner_Video {
    width: 830px;
    border-radius: 10px;
    border: 6px solid #fff;
    box-shadow: 30px 50px 110px -38px #000000d1;
}

.lp19_banner_Video img {
    display: block;
    width: 100%;
}

.lp19_banner_Form_sub {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25);
}

/* .lp19_banner_Form_sub_top_header{} */
.lp19_banner_Form_sub_top_header h1 {
    color: #0a3a05;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
    padding: 10px;
    background: linear-gradient(180deg, rgba(195, 251, 4, 1) 0%, rgba(55, 195, 60, 1) 100%);
}

.lp19_banner_Form_sub .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
}

.lp19_banner_Form_sub .MuiFormControl-root:last-child {
    margin-bottom: 0;
}

.LP19_formSection {
    padding: 17px;
    padding-top: 23px;
    padding-bottom: 37px;
}

.lp19_banner_Form_sub .MuiInputBase-root {
    border-radius: 0;
}

.lp19_banner_Form_sub button {
    background: linear-gradient(180deg, rgba(54, 54, 54, 1) 0%, rgba(13, 13, 13, 1) 100%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    padding: 20px 0;
}

.lp19_banner_Form .form_item {
    margin-bottom: 8px !important;
}

.lp19_banner_Form_main .email_btm_line p {
    display: none;
}

.lp19_banner_Form_main {
    margin-top: 20px;
}

.lp19_banner_Form_main .l2submitbtnsection {
    margin: 0;
    margin-top: 20px;
}

.lpage19_modal .modalBaseBox {
    border: 10px solid #519312 !important;
}

.lpage19_modal .submitbtnsection button {
    background: linear-gradient(180deg, rgb(172 221 108) 0%, rgb(46 173 31) 100%);
    color: #112a0e;
    border: 1px solid #bbeb9d;
}

.blank {
    width: 100%;
    height: 60px;
    background: #000;
    margin: 0 auto;
    margin-top: -65px;
    border-radius: 5px;
}

.lpage19_modal .modal_icon_button {
    background-color: #54703a !important;
}

.lpage19_modal .listing_heading {
    border-left: solid 5px #519312;
}

.lpage19_modal .rolelist_mainwrp .css-pjjt8i-MuiToolbar-root .MuiTypography-h6 {
    border-left: solid 5px #519312;
}

@media screen and (max-width: 1780px) {
    .lp19_banner_BGimage {
        background-position: bottom 300px right 346px;
    }

}




@media screen and (max-width: 1680px) {

    .lp19_banner_Form_sub_top_header h1 {
        font-size: 36px;
        line-height: 35px;
    }

}

@media screen and (max-width: 1520px) {
    .lp19_banner_headdingText h1 {
        font-size: 39px;
    }
}

@media screen and (max-width: 1430px) {
    .lp19_banner_Form {
        width: 28%;
    }

    .lp19_banner_Video {
        width: 700px;
    }

    .lp19_banner_new_sec {
        margin-top: -85px;
    }
}

@media screen and (max-width: 1399px) {
    .lp19_banner_BGimage {
        background-position: bottom 300px right 200px;
    }


}

@media screen and (max-width: 1299px) {
    .lp19_banner_BGimage {
        background-position: bottom 330px right 180px;
    }


}

@media screen and (max-width: 1364px) {
    .lp19_banner_Form {
        width: 29%;
    }
}

/* .lp19_banner_BGimage {
    background-position: bottom 75px center, bottom 277px right 28%, top -15px center;
} */

@media screen and (max-width: 1199px) {
    .lp19_banner_Body {
        flex-direction: column;
    }

    .lp19_banner_text_video {
        width: auto;
    }

    .lp19_banner_Video {
        width: 100%;
        max-width: initial;
    }

    .lp19_banner_Form {
        width: auto;
    }

    .LP19_formSection {
        padding: 20px 0;
    }

    .lp19_banner_BGimage {
        background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimage1.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-bottom: 20px;
    }

    .lp19_banner_headdingText h1 {
        font-size: 27px;
        margin-left: 35px;
        line-height: 31px;
    }

    .lp19_banner_logo {
        max-width: 22%;
    }

    .lp19_banner_Text {
        padding: 25px 0;
    }

    .page5_react_playerLP19 {
        width: 100%;
    }

}

@media screen and (max-width: 1280px) {
    .lp19_banner_Form_sub_top_header h1 {
        font-size: 32px;
        line-height: 32px;
    }
}

@media screen and (max-width: 1230px) {

    .LP19_formSection {
        padding-bottom: 24px;
    }
}

@media screen and (max-width: 1199px) {
    .lp19_banner_Body {
        flex-direction: column;
    }

    .lp19_banner_text_video {
        width: auto;
    }

    .lp19_banner_Video {
        width: 100%;
        max-width: initial;
    }

    .lp19_banner_Form {
        width: auto;
    }

    .LP19_formSection {
        padding: 20px 0;
    }

    .lp19_banner_BGimage {
        background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimage1.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-bottom: 20px;
    }

    .lp19_banner_headdingText h1 {
        font-size: 27px;
        margin-left: 35px;
        line-height: 31px;
    }

    .lp19_banner_logo {
        max-width: 22%;
    }

    .lp19_banner_Text {
        padding: 25px 0;
    }

    .page5_react_playerLP19 {
        width: 100%;
    }

}



.lp19_banner_logo {
    width: 327px;
    max-width: 20%;
}

.lp19_banner_headdingText {
    width: 80%;
}

.linked_text {
    color: #000;
    text-decoration: none;
}



.lp19_banner_Video .react-player__preview {
    padding-top: 57.25%;
    position: relative;
}

.lp19_banner_Video .react-player__shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000000cc !important;
}

.page5_react_playerLP19 video {
    display: block;
}

.lp19_banner_Body {
    display: flex;
    justify-content: space-between;
}

.lp19_banner_text_video {
    width: 51%;
}

.lp19_banner_Form {
    width: 26%;
    margin-top: 48px;
}

.lp19_banner_Text {
    padding: 40px 0;
    padding-bottom: 20px;
}

.lp19_banner_Text p {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 700;
}

.lp19_banner_Text p span {
    color: #7ddf20;
}

.lp19_banner_Video {
    width: 830px;
    border-radius: 10px;
    border: 6px solid #fff;
    box-shadow: 30px 50px 110px -38px #000000d1;
}

.lp19_banner_Video img {
    display: block;
    width: 100%;
}

.lp19_banner_Form_sub {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25);
}

.lp19_banner_Form_sub_top_header div h1 {
    padding: 10px;
    background: linear-gradient(180deg, rgba(195, 251, 4, 1) 0%, rgba(55, 195, 60, 1) 100%);
}

.lp19_banner_Form_sub_top_header h1 {
    color: #0a3a05;
    text-transform: uppercase;
    font-size: 29px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
}

.lp19_banner_Form_sub .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
}

.lp19_banner_Form_sub .MuiFormControl-root:last-child {
    margin-bottom: 0;
}

.LP19_formSection {
    padding: 17px;
    padding-top: 23px;
    padding-bottom: 37px;
}

.lp19_banner_Form_sub .MuiInputBase-root {
    border-radius: 0;
}

.lp19_banner_Form_sub button {
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    text-transform: uppercase;
    width: -webkit-fill-available;
    font-size: 40px;
    font-weight: 700;
    line-height: 24px;
    padding: 20px 0;
    border-radius: 5px;
}

/* .lpage19_form_wrpper form {
    padding: 10px;
} */

.lp19_banner_Form .form_item {
    margin-bottom: 8px !important;
}

.lp19_banner_Form_main .email_btm_line p {
    display: none;
}

.lp19_banner_Form_main {
    margin-top: 20px;
}

.lp19_banner_Form_main .l2submitbtnsection {
    margin: 0;
    margin-top: 20px;
}



@media screen and (max-width: 1520px) {
    .lp19_banner_headdingText h1 {
        font-size: 39px;
    }
}

@media screen and (max-width: 1430px) {
    .lp19_banner_Form {
        width: 28%;
    }

    .lp19_banner_Video {
        width: 676px !important;
    }
}

@media screen and (max-width: 1364px) {
    .lp19_banner_Form {
        width: 29%;
    }
}

@media screen and (max-width: 1230px) {

    .LP19_formSection {
        padding-bottom: 24px;
    }
}

@media screen and (max-width: 1199px) {
    .lp19_banner_Body {
        flex-direction: column;
    }

    .lp19_banner_text_video {
        width: auto;
    }

    .lp19_banner_Video {
        width: 100%;
        max-width: initial;
    }

    .lp19_banner_Form {
        width: auto;
        margin-top: 20px;
    }

    .LP19_formSection {
        padding: 20px 0;
    }

    .lp19_banner_BGimage {
        background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/banner/banner_BGimage1.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-bottom: 0px;
    }

    .lp19_banner_headdingText h1 {
        font-size: 27px;
        margin-left: 35px;
        line-height: 31px;
    }

    .lp19_banner_logo {
        max-width: 22%;
    }

    .lp19_banner_Text {
        padding: 25px 0;
    }

    .page5_react_playerLP19 {
        width: 100%;
    }

}


/* ==========================================block1======================================= */
.lp19_block1_5_ease_steps h1 {
    font-size: 52px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.lp19_block1_steps_sub_wrpr {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: calc(100% - 160px);
}

.lp19_block1_steps_sub_wrpr_greenBG {
    height: 180px;
    width: 100%;
    background: linear-gradient(90deg, rgba(58, 185, 67, 1) 0%, rgba(198, 252, 3, 1) 100%);
    border-radius: 100px;
    position: relative;
}

/* .lp19_block1_steps_blocks{background: linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(244,244,244,1) 100%);padding: 10px;    border-radius: 15px;} */
.lp19_block1_steps_blocks {
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    border-top: 8px solid #f1f1f1;
    border-bottom: 8px solid #cacaca;
    border-left: 8px solid #cacaca;
    border-right: 8px solid #eaeaea;
    width: 20%;
    position: relative;
}

.lp19_block1_steps_num_text h1 {
    font-size: 100px;
    font-weight: 700;
    color: #3ebd42;
    line-height: 100px;
    margin-top: 5px;
}

.lp19_block1_steps_num_text h2 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    line-height: 26px;
}

.lp19_block1_steps_desc p {
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
}

.lp19_block1_steps_num_text_logo {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.lp19_block1_steps_arrow {
    margin: auto 22px;
}

.lp19_block1_steps_Main_wrpr {
    margin: 80px 0;
    margin-bottom: 120px;
}

.lp19_block1_steps_blocks::before {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block1/LP19_block1_steps_block_shadow.webp);
    background-size: 100% 100%;
    content: "";
    position: absolute;
    height: 405px;
    width: 166%;
    top: 0;
    left: -140px;
    z-index: -1;
}

.h1Color1 h1 {
    color: #54c739;
}

.h1Color2 h1 {
    color: #70d42b;
}

.h1Color3 h1 {
    color: #94e51b;
}

.h1Color4 h1 {
    color: #aef10f;
}

.lp19_block1_main_wrapper {
    margin-top: -415px;
}

.lp19_block1_steps_sub_wrpr_greenBG::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 4px solid #115d14;
    border-radius: 50%;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.lp19_block1_steps_sub_wrpr_greenBG::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 4px solid #115d14;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.lp19_block1_sub_wrapper .Lp14block2_sub1_wrapper {
    position: relative;
    z-index: 99999;
}

.lp19_block1_steps_arrow img {
    width: 100%;
    display: block;
}

@media screen and (max-width: 1845px) {
    .lp19_banner_BGimage {
        background-position: bottom -36px center, bottom 191px right 469px, top -44px center;
    }
}

@media screen and (max-width: 1680px) {
    .lp19_banner_BGimage {
        background-position: bottom -16px center, bottom 223px right 344px, top 0px center;
        padding-bottom: 187px;
    }

    .lp19_banner_Form_sub_top_header .form_title_wrapper h2 span {
        font-size: 28px;
        line-height: 30px;
    }

    .lp19_block1_main_wrapper {
        padding-top: 250px !important;
    }
}

@media screen and (max-width: 1650px) {
    .lp19_block1_steps_desc p {
        font-size: 18px;
    }

    .lp19_block1_steps_blocks::before {
        height: 372px;
        width: 320px;
        left: -126px;
    }
}

@media screen and (max-width: 1600px) {
    .lp19_block1_steps_blocks::before {
        height: 414px;
    }

    .lp19_block1_main_wrapper {
        background-position: top 44px center !important;
    }

}

@media screen and (max-width: 1544px) {
    .lp19_block1_steps_num_text h1 {
        font-size: 85px;
        line-height: 81px;
    }

    .lp19_block1_steps_desc p {
        line-height: 20px;
    }

    .lp19_block1_steps_blocks::before {
        width: 287px;
        left: -115px;
        height: 396px;
    }


}

@media screen and (max-width: 1460px) {
    .lp19_block1_steps_arrow {
        margin: auto 6px;
    }

    .lp19_banner_BGimage {
        background-position: bottom 37px center, bottom 217px right 237px, top 0px center;
    }

    .lp19_banner_Video {
        width: 650px !important;
    }

    .lp19_block1_main_wrapper {
        padding-top: 208px !important;
    }

    .lp19_block1_main_wrapper {
        background-position: top 21px center !important;
    }



}

@media screen and (max-width: 1400px) {
    .lp19_block1_steps_sub_wrpr {
        width: calc(100% - 160px);
    }

    .lp19_block1_steps_logo img {
        width: 44px;
        margin-left: 10px;
    }

    .lp19_block1_steps_desc p {
        font-size: 16px;
        line-height: 18px;
    }

    .lp19_block1_steps_blocks::before {
        height: 371px;
    }

}

@media screen and (max-width: 1313px) {
    .lp19_block1_steps_num_text h1 {
        font-size: 70px;
        line-height: 70px;
    }

    .lp19_block1_steps_sub_wrpr {
        width: calc(100% - 140px);
    }

    .lp19_block1_steps_sub_wrpr_greenBG::before {
        left: 30px;
    }

    .lp19_block1_steps_sub_wrpr_greenBG::after {
        right: 30px;
    }

    .lp19_block1_steps_blocks::before {
        height: 358px;
        left: -104px;
        width: 263px;
    }

    .lp19_banner_Video {
        width: 580px !important;
    }

    .lp19_banner_BGimage {
        background-position: bottom 73px center, bottom 202px right 237px, top -51px center;
    }

    .lp19_banner_Form_sub_top_header .form_title_wrapper h2 span {
        font-size: 25px;
        line-height: 26px;
    }


}

@media screen and (max-width: 1199px) {
    .lp19_block1_steps_arrow {
        display: none;
    }

    .lp19_block1_main_wrapper {
        padding-top: 0px !important;
    }

    .lp19_banner_BGimage {
        padding-bottom: 0;
    }


    .lp19_banner_Video {
        width: auto !important;
    }

    .lp19_block1_steps_sub_wrpr {
        transform: none;
        position: initial;
        width: fit-content;
        flex-wrap: wrap;
    }

    .lp19_block1_steps_blocks::before {
        display: none;
    }

    .lp19_block1_steps_sub_wrpr_greenBG {
        height: auto;
        border-radius: 15px;
        padding: 20px;
    }

    .lp19_block1_steps_sub_wrpr_greenBG::before,
    .lp19_block1_steps_sub_wrpr_greenBG::after {
        display: none;
    }

    .lp19_block1_steps_Main_wrpr {
        margin: 20px 0;
        margin-bottom: 30px;
    }

    .lp19_block1_steps_blocks {
        width: 31%;
        margin: 10px;
    }

    .lp19_block1_main_wrapper {
        margin-top: 0;
    }

    .lp19_block1_5_ease_steps .blackh1 {
        color: #000;
    }

    .lp19_banner_new_sec {
        margin-top: 25px !important;
    }

    .lp19_banner_new1_sec {
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: 934px) {
    .lp19_banner_logo img {
        width: 100% !important;
        height: 100% !important;
    }

    .lp19_block1_steps_blocks {
        width: 47%;
    }

}

@media screen and (max-width: 767px) {

    .lp19_banner_logo_headding {
        flex-direction: column;
    }

    .lp19_banner_logo {
        margin-bottom: 20px;
    }

    .lp19_banner_headdingText {
        width: auto;
        margin-left: 0;
    }

    .lp19_banner_headdingText h1 {
        margin-left: 0;
    }

    .lp19_banner_logo {
        max-width: none;
    }

    .lp19_banner_logo {
        width: 240px;
    }

    .lp19_banner_Main {
        padding-top: 20px;
    }
}

@media screen and (max-width: 735px) {
    .lp19_block1_steps_blocks {
        width: 100%;
    }

    .lp19_block1_5_ease_steps h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 450px) {
    .lp19_banner_logo {
        width: 79%;
    }

    .lp19_banner_headdingText h1 {
        font-size: 22px;
        line-height: 24px;
        padding: 0 5px
    }

    .lp19_banner_Text p {
        font-size: 23px;
        line-height: 29px;
        padding: 0 5px
    }

    .lp19_banner_Form_sub_top_header h1 {
        font-size: 23px;
        line-height: 25px;
    }

    .lp19_banner_new1_sec p {
        font-size: 18px !important;
        line-height: 27px !important;
    }

    .lp19_block1_sub1_sec1_text h1 {
        font-size: 23px !important;
        line-height: 25px !important;
    }

    .lp19_block1_sub1_new_wrapper p {
        font-size: 20px !important;
        line-height: 30px !important;
    }


}

@media screen and (max-width: 350px) {

    .captchaField div,
    .captchaField iframe {
        width: 100% !important;
    }

    .lp19_banner_Form .formWrapperClass {
        padding: 0px 0px 5px 0px !important;
    }

    .blank {
        display: none;
    }

}



/* ==========================================block1======================================= */

















.lp19_banner_Video {
    width: 800px;
}



















/* ------------------------------ Soubhik Debnath end ----------------------------------- */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ------------------------------ Sourjyadeep Majumder ----------------------------------- */

/* .Landingpage19_Footer_button{
    background-color: #015bf3;
    text-transform: uppercase;
    color: #fff;
} */
.Landingpage19_Footer-img {
    display: block;
    max-width: 100%;
}

.Landingpage19_Footer_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: end;
}

.Landingpage19_Footer_text {
    padding-left: 60px;
    padding-right: 28px;
}

.Landingpage19_Footer_text h1 {
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
}

.Landingpage19_Footer_text h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
}

.Landingpage19_Footer_text h5 span a {
    font-weight: bold;
    color: #000;
    text-decoration: none;
}

.Landingpage19_Footer_button h2 {
    background-color: #0054f4;
    padding: 15px 0;
    display: block;
    color: #fff;
    border: none;
    width: 100%;
    max-width: 100%;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
}

/* .Landingpage19_Footer-img { margin-right: 44px;} */
.Landingpage19_Footer_button {
    width: 460px;
}

.Landingpage19_Footer {
    padding: 50px 0;
}

.Landingpage19_Footer_block2 p {
    text-align: center;
    border-top: 1px solid #e2e4e9;
    padding: 15px;
    color: #9f9f9f;
}

.lp19_block3_flex {
    display: flex;
    justify-content: space-between;
}

.lp19_block3_text h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
}

.lp19_block3_text p {
    font-size: 70px;
    color: #0026bc;
    line-height: 61px;
    font-weight: 700;
}

.lp19_block3_text p span {
    color: #f41400;
}

.lp19_block3_text {
    position: relative;
}

.lp19_block3_deliverfree_img {
    position: absolute;
    left: -250px;
}

.lp19_block3_flex_item {
    margin-top: 55px;
    position: relative;
}

.lp19_block3_text button {
    margin-top: 50px;
    padding: 25px 72px;
    font-weight: 600;
    line-height: 22px;
    max-width: 100%;
    background-color: #1f1f1f;
    font-size: 24px;
    color: #ffffff;
    border: none;
    border-radius: 10px
}

.lp19_block3_sub2_wrapper_item {
    display: flex;
    background-repeat: no-repeat;
    background-color: #001bb0;
    position: relative;
    justify-content: center;
    padding: 20px;
    justify-content: space-evenly;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/dreamstime2_img.png), url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/dreamstime_img.png);
    background-position: left center, right center, center;
    background-blend-mode: multiply;
    margin-top: 90px;
    position: relative;
    border-radius: 11px
}

.lp19_block3_sub2_wrapper_item img {
    border: 5px solid #3039a1;
    border-radius: 12px
}

.lp19_block3_deliveryman_img {
    position: absolute;
    left: -52px;
    bottom: 90px;
}

.lp19_block3_flex_item {
    padding-left: 680px;
}

.lp19_block3_sub2_wrapper_item::after {
    content: '';
    position: absolute;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/Layer_after_logo.png);
    background-repeat: no-repeat;
    width: 2%;
    height: 19%;
    left: 3%;
    transform: translateX(-50%);
    bottom: 57px
}

.lp19_block3_sub2_wrapper_item::before {
    content: '';
    position: absolute;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/Layer_after_logo.png);
    background-repeat: no-repeat;
    width: 2%;
    height: 19%;
    right: 1%;
    transform: translateX(-50%);
    bottom: 37%
}

/* .lp19_bl3_img{width: 18%;} */
@media only screen and (max-width: 1650px) {
    .Landingpage19_Footer_button h2 {
        font-size: 20px;
    }

    .lp19_block3_sub2_wrapper_item::after {
        left: 2%;
    }

    .Landingpage19_Footer_button {
        width: 460px;
    }
}

.Landingpage19_Footer {
    padding: 50px 0;
    background: #fff;
}

.Landingpage19_Footer_block2 p {
    text-align: center;
    border-top: 1px solid #e2e4e9;
    padding: 15px;
    color: #9f9f9f;
    background: #fff;
}

.lp19_block3_flex {
    display: flex;
    justify-content: space-between;
}

.lp19_block3_text h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
}

.lp19_block3_text p {
    font-size: 70px;
    color: #0026bc;
    line-height: 61px;
    font-weight: 700;
}

.lp19_block3_text p span {
    color: #f41400;
}

.lp19_block3_text {
    position: relative;
}

.lp19_block3_deliverfree_img {
    position: absolute;
    left: -250px;
}

.lp19_block3_flex_item {
    margin-top: 55px;
    position: relative;
}

.lp19_block3_text button {
    margin-top: 50px;
    padding: 20px 50px;
    font-weight: 600;
    line-height: 22px;
    max-width: 100%;
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    box-shadow: 2px 2px 9px 1px #000;

    font-size: 24px;

    border: none;
    border-radius: 10px
}

.lp19_block3_sub2_wrapper_item {
    display: flex;
    background-repeat: no-repeat;
    background-color: #001bb0;
    position: relative;
    justify-content: center;
    padding: 20px;
    justify-content: space-evenly;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block3/dreamstime2_img.png), url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block3/dreamstime_img.png);
    background-position: left center, right center, center;
    background-blend-mode: multiply;
    margin-top: 90px;
    position: relative;
    border-radius: 11px
}

.lp19_block3_sub2_wrapper {
    /* padding: 0 22px; */
    position: relative;
}

.lp19_block3_sub2_wrapper_item img {
    border: 5px solid #3039a1;
    border-radius: 12px
}

.lp19_block3_deliveryman_img {
    position: absolute;
    left: -52px;
    bottom: 90px;
}

.lp19_block3_flex_item {
    padding-left: 680px;
}

.lp19_block3_sub2_wrapper_item::after {
    content: '';
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block3/Layer_after_logo.png);
    background-repeat: no-repeat;
    width: 2%;
    height: 19%;
    left: 3%;
    transform: translateX(-50%);
    bottom: 57px
}

.formWrapperClass {
    margin-top: 20px;
}

.lp19_banner_Form .l2submitbtnsection {
    margin-top: 20px;
}

.lp19_block3_sub2_wrapper_item::before {
    content: '';
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block3/Layer_after_logo.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    right: 5px;
    transform: translateX(-50%);
    bottom: 57px
}

.lp19_banner_Form .MuiFormHelperText-root {
    position: absolute !important;
    left: -185px !important;
    background-color: #ffebeb !important;
    padding: 5px 10px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.5) !important;
    border-radius: 3px !important;
    border: 1px solid #f00 !important;
    transition: .50s all ease !important;
    font-weight: 600 !important;
    margin: 0 !important;

}

.lp19_banner_Form .MuiFormHelperText-root::after {
    display: none;
}

.lp19_banner_Form .MuiFormHelperText-root::before {
    display: none;
}

.lp19_block3_main_wrapper {

    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block1/lp19_blc1_new_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 85%;
    background-position: top 110px center;
    width: 100%;
    padding-bottom: 40px;
    padding-top: 290px;
}


@media only screen and (max-width: 1670px) {
    .lp19_block3_flex_item {
        padding-left: 44%;
    }

    .lp19_block3_text p {
        font-size: 51px;
        line-height: 55px;
    }

    .lp19_block3_sub2_wrapper_input_field button {
        padding: 20px !important;
    }

}


@media only screen and (max-width: 1650px) {
    .Landingpage19_Footer_button h2 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1560px) {
    .lp19_block3_sub2_wrapper_item::after {
        width: 25px;
        height: 25px;
        left: 10px;
        transform: none;
    }

    .lp19_block3_sub2_wrapper_item::before {
        width: 25px;
        height: 25px;
        right: 10px;
        transform: none;
    }

    .lp19_block3_deliverfree_img img {
        width: 130px;
    }

    .lp19_block3_deliverfree_img {
        left: -150px;
    }
}


@media only screen and (max-width: 1399px) {
    .lp19_block3_sub2_wrapper_item::after {
        width: 15px;
        height: 15px;
        left: 10px;
        background-size: 15px;
    }

    .lp19_block3_sub2_wrapper_item::before {
        width: 15px;
        height: 15px;
        right: 10px;
        background-size: 15px;
    }

    .lp19_block3_text p {
        font-size: 37px;
        line-height: 42px;
    }
}

@media only screen and (max-width: 1360px) {

    .lp19_block3_sub2_wrapper_item::after,
    .lp19_block3_sub2_wrapper_item::before {
        display: none;
    }

    .lp19_block3_sub2_wrapper_item img {
        width: 18%;
    }
}

@media only screen and (max-width: 1299px) {
    .lp19_block3_sub2_wrapper_input_field .form_wrp_l19 input {
        width: 75% !important;
    }

}



@media only screen and (max-width: 1199px) {
    .lp19_block3_deliveryman_img {
        display: none;
    }

    .lp19_block3_flex_item {
        padding-left: 0;
        margin-top: 0;
    }

    .Landingpage19_Footer-img img {
        display: block;
        margin: 0 auto;
    }

    .lp19_block3_deliverfree_img {
        position: relative;
        left: 0;
    }

    .lp19_block3_text p br {
        display: none;
    }

    .lp19_block3_sub2_wrapper_item,
    .lp19_block3_text button {
        margin: 0 auto;
        margin-top: 25px;
    }

    .lp19_block3_sub2_wrapper {
        padding: 0;
    }

    .Landingpage19_Footer_flex {
        flex-direction: column;
        align-items: center;
    }

    .Landingpage19_Footer-img {
        margin-bottom: 20px;
    }

    .Landingpage19_Footer_text h1 {
        font-size: 29px;
        line-height: 35px;
        text-align: center;
    }

    .Landingpage19_Footer_text h5 br {
        display: none;
    }

    .Landingpage19_Footer_text h5 {
        text-align: center;
        margin: 10px 0;
    }

    .Landingpage19_Footer_button {
        width: 300px;
    }

    .Landingpage19_Footer-img img {
        max-width: 100%;
    }

    .lp19_block3_text h1 {
        margin-bottom: 20px;
    }

    .lp19_banner_Form_sub_top_header h1 br {
        display: none;
    }

    .lp19_banner_Form_sub_top_header h1 {
        padding: 10px 25px !important;
    }

    .lp19_banner_Form .form_item {
        margin-bottom: 23px !important;
    }

    .lp19_banner_Body .lp19_banner_Form .MuiFormHelperText-root {
        position: relative !important;
        left: 0 !important;
        padding: 0 5px !important;
        top: 0 !important;
        bottom: inherit !important;
        transform: unset;
        box-shadow: none;
        border-radius: 0 !important;
        border: none !important;
        margin: 0;
        background: #fff !important;
    }
}

@media only screen and (max-width: 1060px) {
    .lp19_block3_sub2_wrapper_input_field .form_wrp_l19 {
        flex-direction: column;
    }

    .lp19_block3_sub2_wrapper_input_field .form_wrp_l19 input {
        width: 100% !important;
        margin-bottom: 25px;
    }


}

@media only screen and (max-width: 768px) {
    .lp19_block3_deliverfree_img img {
        display: none;
    }

    .lp19_block3_sub2_wrapper_item {
        flex-wrap: wrap;
        background-image: none;
    }

    .lp19_block3_sub2_wrapper_item img {
        width: 48%;
        margin-bottom: 15px;
    }

    .lp19_block3_sub2_wrapper_item img:last-child {
        margin-bottom: 0;
    }

    .lp19_block3_text h1 {
        font-size: 20px;
        line-height: 26px;
    }

    .lp19_block3_text p {
        font-size: 30px;
        line-height: 34px;
    }

    .lp19_block3_text button {
        font-size: 20px;
        height: auto;
        padding: 10px 50px;
    }

    .lp19_block3_sub2_wrapper_input_field button {
        font-size: 19px !important;
        height: auto;
        padding: 10px 50px !important;
    }

}

@media only screen and (max-width: 550px) {
    .Landingpage19_Footer_text h5 {
        font-size: 20px;

        line-height: 23px;
    }

    .Landingpage19_Footer_text h1 {
        font-size: 25px;
        line-height: 28px;

    }

    .Landingpage19_Footer {
        padding: 20px 10px;
    }

    .Landingpage19_Footer_button h2 {
        margin: 0 auto;
        padding: 10px;
        width: auto;
        height: auto;
    }

    .lp19_block3_sub2_wrapper_input_field .form_wrp_l19 input {
        height: 50px !important;
    }
}

.lp19_banner_new2_sec {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 10px;
}

.lp19_banner_new2_sec p {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
    padding-left: 15px;
}

.lp19_banner_new2_sec p:before {
    content: '';
    background: #79dd21;
    width: 8px;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}



.lp19_banner_new2_sec p span {
    font-weight: 700;
}

.lp19_banner_new2_sec p span span {
    color: #001bb0;
}

/* .lp19_block1_main_wrapper{
    margin-top: 0;
} */
.lp19_block1_jim_gail {
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 25px;
    border-radius: 20px;

}

.lp19_block1_jim_gail_text h2 {
    font-size: 40px;
    font-weight: 700;
}

.lp19_block1_jim_gail_text p {
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    position: relative;
    padding-left: 50px;
    margin: 13px 0px;
}

.lp19_block1_jim_gail_text p span {
    position: relative;
}

.lp19_block1_jim_gail_text p::before {
    content: '';
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/text-leyer.webp);
    background-repeat: no-repeat;
    left: 0;
    top: -24px;
    position: absolute;
    width: 45px;
    height: 45px;
}

.lp19_block1_jim_gail_text p span::after {
    content: '';
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/text-layer.webp);
    background-repeat: no-repeat;
    right: -64px;
    bottom: -12px;
    position: absolute;
    width: 45px;
    height: 45px;
}

.lp19_block1_jim_gail {
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/background-jim-gail.webp), linear-gradient(90deg, rgba(2, 16, 97, 1) 10%, rgba(1, 22, 141, 1) 63%, rgba(0, 27, 175, 1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top, center left;
    margin-bottom: 30px;
}

.lp19_block1_jim_gail_img {
    padding: 20px;
    background-color: #06114b;
    border-radius: 10px;
}

.lp19_block1_jim_gail_text {
    padding-left: 45px;
}

.lp19_block1_jim_gail_img img {
    border: solid;
    border-radius: 7px;
}

.lp19_block1_layer {
    height: 53px;
}

.lp19_tstimnl_text_wrp p ::before {
    content: '';
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/banner/text-leyer.webp);
    background-repeat: no-repeat;
    left: 8px;
    top: -36px;
    position: absolute;
    width: 45px;
    height: 45px;
    background-size: 30px;
}

.lp19_block3_sub2_wrapper_input_field .form_wrp_l19 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lp19_block3_sub2_wrapper_input_field .form_wrp_l19 input {
    width: 80%;
    padding: 13px;
    border-radius: 6px;
    background-color: #eff2f6;
    border: 1px solid #ccd2df;
    height: 70px;
    font-size: 20px;
}

.lp19_block3_sub2_wrapper_input_field input::placeholder {
    font-size: 18px;
    color: #b5b5b5;
    text-transform: capitalize;
}

.lp19_block3_sub2_wrapper_input_field {
    margin-top: 35px;
    background: #fff;
    padding: 25px;
    border-radius: 10px;
}

.lp19_block3_sub2_wrapper_input_field .Lp14block2_sub1_wrapper {
    margin: 0;
}

.lp19_block3_sub2_wrapper_input_field button {
    padding: 20px 50px;
    font-weight: 600;
    line-height: 22px;
    max-width: 100%;
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    box-shadow: 2px 2px 9px 1px #000;
    font-size: 24px;
    border: none;
    border-radius: 10px;
    margin-bottom: 0;
}

.lp19_block3_sub2_wrapper_input_field input:focus-visible {
    outline: none;
}




@media only screen and (max-width: 1199px) {
    .lp19_tstimnl_text_wrp p ::before {
        left: 2px;
    }
}

@media only screen and (max-width: 900px) {
    .lp19_tstimnl_text_wrp p ::before {
        left: 0;
        background-size: 25px;
        top: -29px;
    }
}

@media only screen and (max-width: 700px) {
    .lp19_tstimnl_text_wrp p ::before {
        left: 0;
        background-size: 20px;
        top: -23px;
    }
}


@media only screen and (max-width: 1199px) {

    /* .lp19_block1_jim_gail {
        flex-wrap: wrap;
        justify-content: center;
    } */
    .lp19_block1_jim_gail_text h2 {
        font-size: 35px;
    }

    .lp19_block1_jim_gail_text p {
        font-size: 25px;
    }

    .lp19_block1_jim_gail {
        background: linear-gradient(90deg, rgb(56 60 86) 10%, rgba(1, 22, 141, 1) 63%, rgba(0, 27, 175, 1) 100%);
    }
}

@media only screen and (max-width: 1000px) {
    .lp19_block1_jim_gail_text h2 {
        font-size: 30px;
    }

    .lp19_block1_jim_gail_text p {
        font-size: 20px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 900px) {
    .lp19_block1_jim_gail {
        flex-direction: column;
    }

    .lp19_block1_jim_gail_text p::before {
        background-size: 25px;
        top: -10px;
    }

    .lp19_block1_jim_gail_text p span::after {
        background-size: 25px;

        right: -54px;
        bottom: -28px;
    }

    .lp19_block1_jim_gail p {
        padding-left: 30px
    }

    .lp19_block1_jim_gail_text {
        padding-left: 20px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 751px) {
    .lp19_block1_steps_blocks {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .lp19_block1_jim_gail_text h2 {
        font-size: 27px;
    }

    .lp19_block1_jim_gail_text p {
        font-size: 17px;
    }

    .lp19_block1_jim_gail_text p::before {
        background-size: 15px;
        top: -5px;
        width: 45px;
        height: 45px;
    }

    .lp19_block1_jim_gail_text p span::after {
        background-size: 15px;
        right: -8px;
        bottom: -22px;
        width: 19px;
        height: 22px;
    }

    .lp19_block1_jim_gail_text {
        padding-left: 0px;
    }

    .lp19_block1_jim_gail {
        padding: 20px 10px;
    }

    .lp19_block1_jim_gail_img {
        padding: 10px;
    }
}

@media only screen and (max-width: 1500px) {
    .lp19_block1_layer {
        display: none;
    }
}


.lpage19_form_wrpper #userForm13 .form_item input,
.lpage19_form_wrpper #userForm13 .state .MuiSelect-select {
    padding: 12.5px 14px !important;
    background: #fff;
    border: 1px solid #d2d3dc;
    border-width: 1px !important;
    border-color: #d2d3dc !important;
}


.lpage19_form_wrpper #userForm13 .form_item .MuiFormLabel-root,
.lpage19_form_wrpper #userForm13 .state .MuiSelect-select {
    top: -4px !important;
}

/* .lpage19_form_wrpper {display: none;} */































































/* ------------------------------ Sourjyadeep Majumder end ----------------------------------- */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ------------------------------ Souvik Chakraborty ----------------------------------- */


.lp19_banner_new_sec {
    background-color: #25a0ff;
    padding: 20px;
    width: 65%;
    margin-top: -65px;
    border-radius: 10px 10px 0 0;
    margin-left: 15px;
}

.lp19_banner_new_sec .Lp14block2_sub1_wrapper button {
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    box-shadow: 2px 2px 9px 1px #000;
}

.lp19_banner_new1_sec {
    background-color: #fff;
    padding: 25px;
    box-shadow: 0 0 10px 0 #53535357;
    /* margin-bottom: 100px; */
    border-radius: 10px;
}

.lp19_banner_new1_sec p {
    font-size: 28px;
    line-height: 34px;
    color: #000;
    font-weight: 600;
}

.lp19_banner_new1_sec p span {
    color: #3ab03e;
}

@media only screen and (max-width: 1399px) {
    .lp19_banner_new_sec {
        margin-top: -26px;
    }

    .lp19_block1_sub1_sec1_text h1 {
        font-size: 27px !important;
        line-height: 33px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .lp19_banner_new_sec {
        margin-top: 26px;
    }
}

@media only screen and (max-width: 1199px) {
    .lp19_banner_new_sec {
        width: 100%;
        margin-left: 0;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .lp19_banner_new1_sec {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 499px) {
    .lp19_banner_new1_sec {
        margin-bottom: 10px;
    }

    .lp19_banner_Form_sub button {
        font-size: 30px;
        padding: 14px 0;
    }
}




/* ------------------------------ block1----------------------------------- */

.lp19_block1_sub_wrapper .Lp14block2_sub2_sec_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block1/lp19_bl1_sec2_img.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    object-fit: cover;
    border: 5px solid #cad1de;
}

.lp19_block1_sub_wrapper .Lp14block2_sub2_text_sec_wrapper {
    display: block;
    width: 65%;
}

.lp19_block1_sub_wrapper .Lp14block2_sub2_text_sec_wrapper p {
    color: #fff;
}

.lp19_block1_sub_wrapper .Lp14block2_sub1_wrapper button {
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    font-size: 20px;
    padding: 10px 50px;
    box-shadow: 2px 2px 9px 1px #000;
}

.lp19_block1_sub1_wrapper {
    background: linear-gradient(42deg, rgba(255, 255, 255, 1) 62%, rgb(136 197 243) 90%);
    padding: 60px 40px;
    box-shadow: 0 0 10px 0 #53535357;
    border-radius: 10px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.lp19_block1_sub1_sec1_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lp19_block1_sub1_sec1_text {
    width: 82%;
}

.lp19_block1_sub1_sec1_text h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    color: #fff;
}

.lp19_block1_sub1_sec1_text h1 span {
    color: #3ab03e;
    font-weight: 700;
}

.lp19_block1_sub1_sec2_text {
    width: 30%;
    margin-left: 20px;
}

.lp19_block1_sub1_sec2_text .Lp14block2_sub1_wrapper {
    margin-bottom: 0;
}

.medicine_img {
    position: absolute;
    right: 30px;
    bottom: 0;
}

.lp19_block1_sub1_new_wrapper {
    width: 80%;
}

.lp19_block1_sub1_new_wrapper p {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    color: #000;
}

.lp19_block1_sub1_new_wrapper p span {
    color: #3ab03e;
}

.lp19_block1_main_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block1/lp19_blc1_new_bg.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    padding-top: 271px;
}

@media only screen and (max-width: 1520px) {
    .lp19_block1_sub1_new_wrapper {
        width: 65%;
    }

}

@media only screen and (max-width: 1250px) {
    .medicine_img {
        right: 5px;
    }
}

@media only screen and (max-width: 1199px) {
    .medicine_img {
        display: none;
    }

    .lp19_block1_sub1_sec1_wrapper {
        width: 100%;
    }

    .lp19_block1_sub_wrapper .Lp14block2_sub1_wrapper button {
        width: auto;
    }

    .lp19_block1_sub1_new_wrapper {
        width: 100%;
    }

    .lp19_block1_sub_wrapper .Lp14block2_sub2_text_sec_wrapper {
        width: 100%;
    }


}

@media only screen and (max-width: 1024px) {
    .lp19_block1_sub1_sec1_wrapper {
        flex-direction: column;
    }

    .lp19_block1_sub1_sec1_text {
        width: 100%;
        margin-bottom: 20px;
    }

    .lp19_block1_sub_wrapper .Lp14block2_sub1_wrapper button {
        width: auto;
    }

    .lp19_block1_sub1_sec2_text {
        width: 100%;
        margin-left: 0;
    }

}

@media only screen and (max-width: 999px) {
    .lp19_block1_sub1_wrapper {
        padding: 20px;
    }

    .lp19_block1_sub1_new_wrapper p {
        font-size: 24px;
        line-height: 30px;
    }

}

@media only screen and (max-width: 399px) {
    .lp19_block1_jim_gail_img img {
        display: block;
        width: 100%;
    }

}




/* ------------------------------ block1-end----------------------------------- */

/* ------------------------------ block2----------------------------------- */
.lp19_block2_sub_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block2/lp19_blc3_img.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #53535357;
    padding: 40px;
    margin-top: 50px;
}

.lp19_block2_sub_wrapper h1 {
    font-size: 40px;
    line-height: 46px;
    font-weight: 700;
    color: #010f67;
    text-align: center;
    margin: 0;
    margin-bottom: 100px;
}

.lp19_block2_sec_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    position: relative;
    padding: 1px 153px;
}

.lp19_block2_sub_sec_wrapper {
    background-color: #fff;
    border-radius: 50%;
    height: 345px;
    width: 345px;
    box-shadow: 0 0 10px 0 #53535357;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    z-index: 99;
}

.lp19_block2_sub_sec_wrapper img {
    display: block;
    max-width: 100%;
}

.lp19_block2_sub_sec_wrapper h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    color: #000;
    margin: 0;
    margin-bottom: 10px;
}

.lp19_block2_sub_sec_wrapper h3 {
    font-size: 40px;
    line-height: 46px;
    font-weight: 700;
    color: #000;
    margin: 0;
    margin-bottom: 10px;
}

.lp19_block2_sub_sec_wrapper p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    text-align: center;
    width: 20%;
    width: 63%;
}

.lp19_block2_sub_sec_outer_wrapper {
    position: relative;
    width: 32%;
}

.lp19_block2_main_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block2/block2LP19bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
    padding-bottom: 200px;
    padding-top: 185px;
    position: relative;
    z-index: 0;
    margin-top: -170px;
}

.lp19_block3_main_wrapper {
    margin-top: -463px
}

.lp19_block2_sub_sec_outer_wrapper:nth-child(1)::before {
    content: '';
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block2/lp19_blc2_1after.png);
    background-repeat: no-repeat;
    width: 522px;

    height: 300px;
    left: 50%;
    bottom: -81px;
    transform: translateX(-50%);
    background-position: center;
    background-size: 100%;
}

.lp19_block2_sub_sec_outer_wrapper:nth-child(2)::before {
    content: '';
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block2/lp19_blc2_2after.png);
    background-repeat: no-repeat;
    width: 522px;
    height: 300px;
    left: 50%;
    bottom: 164px;
    transform: translateX(-50%);
    background-size: 100%;
    background-position: center;
}

.lp19_block2_sub_sec_outer_wrapper:nth-child(3)::before {
    content: '';
    position: absolute;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage19/block2/New_1Project.png);
    background-repeat: no-repeat;
    width: 522px;
    height: 300px;
    left: 50%;
    bottom: -77px;
    transform: translateX(-50%);
    background-size: 100%;


    background-position: center;
}

.lp19_block2_sub_wrapper .Lp14block2_sub1_wrapper button {
    background: linear-gradient(180deg, rgba(191, 249, 6, 1) 0%, rgba(70, 200, 54, 1) 100%);
    color: #0a3a05;
    padding: 10px 50px;
    box-shadow: 2px 2px 9px 1px #000;
}

@media only screen and (max-width: 1650px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 126px;
    }

}

@media only screen and (max-width: 1640px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 121px;
    }
}

@media only screen and (max-width: 1630px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 112px;
    }
}

@media only screen and (max-width: 1600px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 99px;
    }

}

@media only screen and (max-width: 1530px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 69px;
    }

    .lp19_banner_Video {
        width: 737px;
    }

    .lp19_banner_new_sec {
        margin-top: -65px;
    }
}

@media only screen and (max-width: 1430px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 21px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(1)::before {
        width: 500px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(3)::before {
        width: 500px;
    }
}

@media only screen and (max-width: 1340px) {
    .lp19_block2_sub_sec_outer_wrapper:nth-child(1)::before {
        width: 470px;
        bottom: -77px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(3)::before {
        width: 470px;
    }
}

@media only screen and (max-width: 1260px) {
    .lp19_block2_sec_wrapper {
        padding: 1px 0px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(1)::before {
        width: 430px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(3)::before {
        width: 430px;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(2)::before {
        bottom: 150px;
    }
}

@media only screen and (max-width: 1199px) {
    .lp19_banner_new2_sec img {
        display: none;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(1)::before {
        display: none;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(2)::before {
        display: none;
    }

    .lp19_block2_sub_sec_outer_wrapper:nth-child(3)::before {
        display: none;
    }

    .lp19_block2_main_wrapper {
        background-position: center;
        background-size: 100%;
    }

    .lp19_banner_new2_sec {
        margin: 25px 0;
    }

    .lp19_block2_sec_wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }

    .lp19_block2_sub_sec_outer_wrapper {
        width: 50%;
    }

}

@media only screen and (max-width: 999px) {
    .lp19_block2_sub_wrapper {
        padding: 30px 0;
    }

    .lp19_block2_sub_wrapper {
        margin-top: 0;
    }

    .Lp14block2_sub2_wrapper {
        margin-bottom: 0;
    }

}

@media only screen and (max-width: 850px) {
    .lp19_block2_sub_sec_outer_wrapper {
        width: 100%;
        margin-bottom: 40px;
    }

    .lp19_block2_sub_wrapper h1 {
        margin-bottom: 40px;
    }

    .lp19_block2_sec_wrapper {
        margin-bottom: 20px;
    }

    .lp19_block2_main_wrapper {
        background-image: none;
    }
}

@media only screen and (max-width: 499px) {
    .lp19_block2_sub_sec_wrapper {
        width: 270px;
        height: 270px;
    }

    .lp19_block2_sub_sec_wrapper img {
        width: 28%;
    }

    .lp19_block2_sub_sec_wrapper h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 0;
    }

    .lp19_block2_sub_sec_wrapper h2 {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 0;
    }

    .lp19_block2_sub_sec_wrapper p {
        font-size: 14px;
        line-height: 19px;
    }

    .lp19_block2_sub_sec_outer_wrapper {
        margin-bottom: 20px;
    }

    .lp19_block2_sub_wrapper h1 {
        margin-bottom: 30px;
    }
}







/* ------------------------------ block2-end----------------------------------- */



/* ---------------------------css by subhashis-------------------- */
.lp19_block3_sub1_wrapper {
    margin-top: 30px;
}



.lp19_tstimnl_text_wrp {
    text-align: center;
    background: #fff;
    padding: 30px;
    padding-top: 40px;
    margin-top: -25px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.lp19_tstimnl_text_wrp h2 {
    font-size: 24px;
    line-height: 28px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    margin: 15px 0;
}

.whole_cont {
    background-color: #eef1f8;
}

.lp19_tstimnl_text_wrp p {
    font-size: 18px;
    line-height: 25px;
    color: #000;
    font-weight: 500;
    position: relative;
}

.lp19_tstimnl_text_wrp p span {
    color: #092c98;
}


.lp19_tstimnl_flex_wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -3%;
}

.lp19_tstimnl_img_wrp img {
    width: 290px;
    margin: 0 auto;
    border: 15px solid #fff;
    height: 180px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    margin-top: -190px;
    z-index: 1;
}

.lp19_tstimnl_img_wrp {
    position: relative;
}

/* .lp19_tstimnl_img_wrp img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
} */

.lp19_tstimnl_innr_wrp {
    width: 28%;
    margin: 1%;
}

/* .lp19_tstimnl_img_wrp:after {
    content: '';
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/testimonials_img_shadow.webp);
    background-repeat: no-repeat;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 100%;
    height: 50px;
    position: absolute;
} */

.testi_heading {
    font-size: 40px;
    line-height: 45px;
    color: #010f67;
    text-transform: uppercase;
    font-weight: 700;
    margin: 20px 0;
    text-align: center;
    margin-bottom: 160px;
}

.lp19_tstimnl_img_wrp::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-52%);
    bottom: -32px;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/landingpage19/block3/testimonials_img_shadow.webp);
    width: 380px;
    height: 90px;
    background-repeat: no-repeat;
    z-index: 0;
}




@media only screen and (max-width: 1199px) {
    .lp19_tstimnl_img_wrp {
        margin-top: 0;
        width: auto;
    }

    .lp19_tstimnl_img_wrp img {
        margin-top: 0;
        max-width: 100%;
        height: auto;
    }

    .lp19_tstimnl_text_wrp {
        padding: 15px;
    }

    .testi_heading {
        margin: 25px 0;
    }

    .lp19_tstimnl_img_wrp::before {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .lp19_tstimnl_innr_wrp {
        width: 48%;
    }

    .lp19_tstimnl_flex_wrp {
        margin: 0 -1%;
    }

    .lp19_tstimnl_text_wrp p {
        font-size: 15px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 560px) {
    .lp19_tstimnl_innr_wrp {
        width: auto;
    }


}












































































/* ------------------------------ Souvik Chakraborty end ----------------------------------- */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */