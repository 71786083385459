@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

:root {
    --red: #ee2a32;
    --white: #fff;
    --green: #98e81d;
    --orange: #f87f07;
    --purple: #7e3661;
    --pink: #ea53c2;
    --blue: #def6fe;
    --blue2: #1b75b2;
    --deep-blue: #013266;
    --yellow: #e3db00;
    --black: #000;
    --links: #1dbce9;
    --gray: #c6c7c5;
    --gray1: #f8f7ed;
    --gray2: #7c7878;
    --gray3: #e1e1e1;
    --gray4: #a4a7a2;
    --main-font: 400;
    --main-font-Medium: 500;
    --main-font-SemiBold: 600;
    --main-font-Bold: 700;
    --main-font-ExtraBold: 800;
    --backgroundColor: rgba(246, 241, 209);
    --colorShadeA: rgb(106, 163, 137);
    --colorShadeB: rgb(121, 186, 156);
    --colorShadeC: rgb(150, 232, 195);
    --colorShadeD: rgb(187, 232, 211);
    --colorShadeE: rgb(205, 255, 232);
}

.BackendBody {
    width: 100%;
    min-height: 100vh;
    transition: ease all 0.3s;
    position: relative;
    padding: 0 25px 25px 25px;
    background-color: var(--gray1);
}

.BackendLeft {
    min-width: 60px;
    max-width: 60px;
    background-color: var(--blue2);
    color: #fff;
    transition: all 0.3s;
    position: relative;
    z-index: 11;
    box-shadow: 0 0 3px 0px rgb(0 0 0 / 40%);
    float: left;
    width: 100%;
    position: fixed;
    height: 100%;
    overflow: inherit;
}

.BackendOutlet {
    min-height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
}

.BackendHeader .MuiBox-root {
    position: fixed;
    margin-left: -25px;
}

:root {
    --red: #ee2a32;
    --white: #fff;
    --green: #98e81d;
    --orange: #f87f07;
    --purple: #7e3661;
    --pink: #ea53c2;
    --blue: #def6fe;
    --blue2: #1b75b2;
    --deep-blue: #013266;
    --yellow: #e3db00;
    --black: #000;
    --links: #1dbce9;
    --gray: #c6c7c5;
    --gray1: #f8f7ed;
    --gray2: #7c7878;
    --gray3: #e1e1e1;
    --gray4: #a4a7a2;
    --main-font: 400;
    --main-font-Medium: 500;
    --main-font-SemiBold: 600;
    --main-font-Bold: 700;
    --main-font-ExtraBold: 800;
    --backgroundColor: rgba(246, 241, 209);
    --colorShadeA: rgb(106, 163, 137);
    --colorShadeB: rgb(121, 186, 156);
    --colorShadeC: rgb(150, 232, 195);
    --colorShadeD: rgb(187, 232, 211);
    --colorShadeE: rgb(205, 255, 232);
}

.configure-role .MuiInputLabel-shrink {
    background: rgb(255, 255, 255);
}

.image_card_modal {
    overflow: hidden;
    overflow-y: auto;
    z-index: 1 !important;
}

.image_card_modal.modalBaseStyle.indexclass {
    /* position: absolute !important; */
    z-index: 99 !important;
}

.modal_width {
    width: 16%;
}


/* .BackendBody {
  width: 100%;
  min-height: 100vh;
  transition: ease all 0.3s;
  position: relative;
  padding: 0 25px 25px 85px;
  background-color: var(--gray1);
} */

.BackendLeft {
    min-width: 60px;
    max-width: 60px;
    background-color: var(--blue2);
    color: #fff;
    transition: all 0.3s;
    position: relative;
    z-index: 11;
    box-shadow: 0 0 3px 0px rgb(0 0 0 / 40%);
    float: left;
    width: 100%;
    position: fixed;
    height: 100%;
    overflow: inherit;
}

.folder_view_right .stageChipB {
    display: block;
    width: 100%;
}

.folder_view_right .stageChipB .MuiChip-root {
    margin: 10px 0;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgb(185 180 170) 100%);
    border: 1px solid #e5e1e1;
    box-shadow: 2px 3px 5px #e1e0d5;
}

.folder_view_right .stageChipB .MuiSvgIcon-root {
    position: absolute;
    left: inherit;
    right: 0;
    top: 6px;
    background: #f67d24;
    color: #fff;
    border: 1px solid #b7530a;
}

.folder_view_right .Fv-tags li {}

.folder_view_right .MuiChip-root {
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgb(185 180 170) 100%);
    border: 1px solid #e5e1e1;
    box-shadow: 2px 3px 5px #e1e0d5;
}

.folder_view_right .Fv-tags .MuiSvgIcon-root {
    position: absolute;
    right: 0;
    top: -7px;
    left: inherit;
    background: #f67d24;
    color: #fff;
    border: 1px solid #b7530a;
}

.BackendOutlet {
    min-height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
}

.BackendHeader .MuiBox-root {
    position: fixed;
    margin-left: -25px;
}

.notes_details_custom_head {
    margin: 0 0 15px 0 !important;
    padding: 10px !important;
    background: #ebe2e2 !important;
}

.note_list_head .MuiTypography-h6 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.note_list_head .MuiPaper-root {
    box-shadow: none !important;
}

.note_list_head .MuiToolbar-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


/* /-------------------snackbar css----/ */


/* .tableProgressBar, .MuiLinearProgress-root {margin: 5px 0;} */

.MuiDialog-paperWidthSm .MuiLinearProgress-root {
    margin: 2px;
}

.mpdal_healper .MuiFormHelperText-root {
    color: #000 !important;
}

.modal_sbBtn {
    margin-top: 20px;
    justify-content: center;
}


/* .MuiPopover-paper {
    margin-top: 0 !important;
} */

.MuiSnackbar-root {
    justify-content: center !important;
    left: 0 !important;
    right: 0 !important;
}

.MuiSnackbarContent-root {
    justify-content: center !important;
    background-color: #000 !important;
}

.MuiSnackbarContent-message {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}


/* /-----------css by subhashis------/ */


/* .landingpage6_common_width {width: 1800px;margin: 0 auto;} */


/*-----Backend left part -------------*/

.left_logo {
    background-color: #fff;
    display: block;
    padding: 5px;
    border-bottom: solid 1px #62a5d3;
}

.left_logo img {
    width: 100%;
}

.left_centerblock ul {
    margin: 0;
    padding: 0;
}

.left_centerblock ul li {
    list-style: none;
    display: block;
    border-bottom: solid 1px #62a5d3;
    position: relative;
}

.left_centerblock ul li button {
    list-style: none;
    display: block;
    border-bottom: solid 1px #62a5d3;
    position: relative;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.left_centerblock ul li button svg {
    width: 30px;
    height: 30px;
    color: var(--gray1);
}

.left_centerblock ul li a,
.left_centerblock ul li button {
    padding: 10px 0;
    display: block;
    position: relative;
    width: 100%;
}

.left_centerblock ul li img {
    width: 50%;
    display: block;
    margin: 0 auto;
    opacity: 0.4;
}

.left_centerblock ul li span {
    display: none;
    background-color: var(--orange);
    position: absolute;
    font-size: 12px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    transform: translateY(-50%);
    top: 50%;
    left: 90%;
    z-index: 9;
}

.left_centerblock ul li span::after {
    content: "";
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    background-color: var(--orange);
    width: 10px;
    height: 10px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.left_centerblock ul li:hover span {
    display: block;
}

.left_centerblock ul li a.active,
.left_centerblock ul li button.active {
    background-color: var(--gray1);
    position: relative;
}


/* .left_centerblock  ul li a.active img{filter: inherit;} */

.left_centerblock ul li a.active,
.left_centerblock ul li button.active svg {
    color: var(--blue2);
}

.left_centerblock ul li a.active::after,
.left_centerblock ul li button.active::after {
    content: "";
    display: block;
    width: 5px;
    height: 100%;
    background-color: var(--gray1);
    position: absolute;
    right: -3px;
    top: 0;
}

.left_centerblock ul li ul {
    display: none;
    transition: all 0.8;
}

.left_centerblock ul li:hover ul {
    display: block;
    transition: all 0.8s;
}

.left_centerblock ul li ul li {
    border-top: solid 1px #62a5d3;
    border-bottom: none;
}

.left_centerblock ul li ul li button {
    padding: 10px 2px;
    font-size: 14px;
    color: #fff;
}

.left_centerblock ul li ul li button.active {
    background: var(--deep-blue);
}

.left_centerblock ul li ul li button:hover {
    background: var(--deep-blue);
}

.left_centerblock ul li ul li button.active::after {
    display: none;
}

.left_centerblock ul li.active ul {
    display: block;
}

.BackendLeft .left_centerblock ul li span svg {
    display: none;
}

.BackendLeft .MuiTouchRipple-root {
    display: none !important;
}

.BackendLeft .MuiButton-iconSizeMedium {
    display: none !important;
}

.left_centerblock ul li button {
    font-size: 12px !important;
    line-height: 14px;
    text-transform: capitalize;
    min-width: inherit;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


/* span.MuiButton-endIcon {display: none !important;}
span.MuiButton-endIcon svg {display: none;} */

.ProgramBody {
    display: block;
    text-align: center;
}

.ProgramBody .chip_blockCon {
    font-size: 16px;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 10px;
    padding: 8px 10px;
    font-size: 18px;
    display: block;
    color: #fff;
    margin: 5px 0;
    cursor: pointer;
}

.BackendLeft .left_menu_svg {
    display: block;
}

.BackendLeft .left_wrapper {
    position: relative;
}

.BackendLeft .left_menu_svg {
    display: block;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
}

.BoxMui_modalPage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 10px solid #0569ad !important;
    background: #fff;
    padding: 20px !important;
    width: 400px;
}

.BoxMui_modalPage h2 {
    padding: 0px !important;
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    line-height: 30px;
}

.BoxMui_modalPage .closeModalBtn {
    border-radius: 50% !important;
    background-color: #0569ad !important;
    position: absolute !important;
    top: -15px;
    right: -15px;
    padding: 0;
    width: 35px;
    height: 35px;
    color: var(--white) !important;
    min-width: inherit !important;
}


/* /-----------------large list modal css-------/ */

.BoxMui_modal {
    border: 10px solid #0569ad !important;
    background: #fff;
    padding: 20px !important;
}

.BoxMui_modal h2 {
    padding: 10px 80px !important;
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
}

.BoxMui_modal h3 {
    padding: 10px 34px !important;
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
}

.BoxMui_modal .closeModalBtn,
.modalCloseBtn,
.closeModalBtn {
    border-radius: 50% !important;
    background-color: #0569ad !important;
    position: absolute !important;
    top: -15px;
    right: -15px;
    padding: 0;
    width: 35px;
    height: 35px;
    color: var(--white) !important;
    min-width: inherit !important;
}

.BoxMui_modal .MuiButton-text:hover {
    background: #114170;
}

.BoxMui_modal .MuiButton-contained {
    margin: 0 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    background: #0569ad;
}

.BoxMui_modal .MuiButton-contained:hover {
    background-color: var(--deep-blue);
}

.lastLogin_Info .BoxMui_modal {
    width: 750px;
    padding: 0 !important;
}


/* .lastLogin_Info button {display: none;} */

.lastLogin_Info .userlisttable {
    padding: 10px;
}

.lastLogin_Info .rolelist_mainwrp .css-pjjt8i-MuiToolbar-root .MuiTypography-h6 {
    border-left: none;
}

.lastLogin_Info .MuiTypography-root {
    padding: 10px 0 !important;
    background: #0569ad;
    color: #fff !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-bottom: 10px;
}

.modal_title {
    padding: 0px 80px;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    background: #0569ad;
}

.property_container .property_label {
    font-weight: 600;
    width: 33%;
}

.property_container .property_value {
    width: 60%;
}

.property_container .property_value img {
    width: 100%;
    display: block;
}

.view_details .MuiTypography-root {
    padding: 20px 24px !important;
}

.view_details .MuiPaper-root {
    width: 600px;
}

.view_details .data_container {
    width: auto !important;
    box-shadow: none;
}

.view_details .data_block {
    margin: 0 !important;
    border-radius: 0 !important;
}


/* .view_details .MuiDialogContent-root {box-shadow: none;} */


/* /-----------------large list modal css-------/ */


/* /-----------------modal css-------/ */

.MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 15px !important;
}

.MuiDialog-paper {
    border: 10px solid #0569ad;
}

.MuiDialogTitle-root {
    padding: 20px 80px !important;
}

.MuiDialogActions-root button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 20px;
    text-transform: capitalize;
    background: var(--blue2);
}

.close_icon_btn:hover,
.close_icon_btn {
    background-color: var(--blue2) !important;
    color: #fff !important;
}

.adminformbody .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.contractDetails .MuiDialog-paper p {
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
}

.MuiDialogActions-root button:hover {
    background-color: var(--deep-blue);
}

.notes_main_header {
    padding: 0 20px !important;
}

.notes_main_header p {
    margin-bottom: 0;
    font-size: 35px;
    color: var(--white);
    padding: 10px 5px;
    background: #003166;
    text-align: center;
}

.notes_details .notes_header p {
    text-align: center;
    font-size: 23px;
    line-height: 28px;
    padding: 0 10px;
}

.view_details .MuiDialogActions-root .close_btn {
    display: none;
}

.view_details .MuiDialogActions-root {
    padding: 0 !important;
}

.attachfile .link_input_container {
    width: 100%;
}


/* /-----------------modal css-------/ */


/* /-------------search modal css-----/ */


/* .searchBarHead {display: flex;justify-content: space-between;align-items: center;padding: 0 10px 0 50px;}

.searchForm {padding: 0 30px;display: flex;flex-wrap: wrap;align-items: center;}
.searchForm button {display: block;margin-left: auto;padding: 10px 30px;font-size: 20px;}
.searchForm .MuiFormControl-root {margin: 10px 0 !important;margin-top: 5px !important;width: 100%;}

.formGroup {flex: 1 0 45%;margin: 0 1%;}

@media only screen and (max-width: 899px) {
  .formGroup {flex: 1 0 80%;margin: 0;}
  .searchForm button {width: 250px;margin: 0 auto;}

} */

.searchBarHead svg {
    color: var(--white);
}

.searchBarHead .MuiIconButton-root {
    background: var(--white);
}

.searchBarHead .MuiIconButton-root svg {
    color: rgb(163, 159, 159);
}

.searchBarHead .MuiIconButton-root:hover {
    background: var(--white);
}

.PatientSearch .inputblock2line {
    flex: 1 0 48%;
    margin: 0px 0.3%;
    margin-bottom: 15px;
}

.PatientSearch .inputblock1line {
    width: 100%;
    margin: 0 0.3%;
}

.PatientSearch .inputblock4line {
    width: 30%;
    margin: 0 0.3%;
}

.priorityInput {
    width: 30%;
    margin: 0 0.3%;
    flex: inherit !important;
}

.PatientSearch .searchForm .MuiTextField-root {
    flex: 1 0 45%;
}

.PatientSearch .searchForm .MuiButton-contained {
    margin: 0 auto;
    font-size: 20px;
    margin-top: 10px;
}

.searchBarCloseIcon {
    position: absolute;
    right: 10px;
    top: 2px;
}


/* .searchForm .MuiFormControl-root {margin: 10px 0;} */


/* .searchForm .MuiFormControl-root {margin: 10px 0 !important;} */

.PatientSearch .inputblock1line .MuiFormControl-root {
    margin: 0 !important;
}

.PatientSearch .inputblock2line .MuiFormControl-root {
    margin: 0 !important;
}

.PatientSearch .inputblock2line .MuiFormLabel-root,
.PatientSearch .inputblock1line .MuiFormLabel-root {
    margin-bottom: 5px;
}

.PatientSearch .searchForm {
    margin-top: 20px;
    padding: 20px 40px;
    display: flex;
    flex-wrap: wrap;
}

.searchBarHeading {
    color: var(--white);
    text-align: center;
    font-size: 30px;
    padding: 0px 20px;
    background-color: #1976d2;
}

.notes_details .MuiDialog-paper {
    max-width: 90% !important;
    min-width: 600px !important;
    margin: 0 !important;
    max-height: inherit;
}

.notes_details .MuiDialog-paper .MuiToolbar-gutters {
    margin: 0;
    padding: 0;
    min-height: inherit;
    background: #f3f3f3;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
    box-shadow: none;
    margin: 0;
    padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root .MuiTypography-h6 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.notes_details .notesTable .MuiTypography-h6 {
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th svg {
    color: #000 !important;
}

.notes_details {
    z-index: 100 !important;
}

.notes_details .notesTable th {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .notesTable td {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .notesTable th svg {
    color: #000 !important;
}

.css-1ty026z {
    padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td img {
    width: 180px;
    cursor: pointer;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr {
    position: relative;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr .MuiLinearProgress-root {
    position: absolute;
    left: 0;
    width: 100%;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
    margin: 0;
    min-height: inherit;
    padding: 0;
}

.textarea_add_note {
    width: 100%;
    height: 80px !important;
    padding: 10px;
    border-radius: 0px;
    outline: none;
    border: solid 1px #ccc !important;
    resize: none;
}

.notes_details .textarea_add_note {
    height: 100px !important;
}

.notes_details .MuiTypography-h6 {
    margin: 0 !important;
    padding: 0 10px !important;
}

.notes_details h2.MuiTypography-h6 {
    margin: 0 0 15px 0 !important;
    padding: 10px !important;
    background: #ebe2e2 !important;
}

.notes_details .stackPagination {
    margin: 15px 0;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
    z-index: 1100 !important;
}

.notes_details .action_btn_container {
    display: block;
}

.notes_details .notes_btn_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: inherit !important;
    max-width: 90% !important;
    overflow-y: inherit;
}

.css-126xj0f .MuiDialog-paperWidthSm {
    max-width: inherit !important;
    max-width: 90% !important;
    overflow-y: inherit;
}

.css-126xj0f {
    z-index: 100 !important;
}

.css-126xj0f .css-1rv1kxc {
    padding: 0 !important;
    background: #f3f3f3 !important;
    min-height: inherit !important;
}

.hmmodal .css-1rv1kxc .MuiTypography-h6 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.hmmodal_user_Box {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 400px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.hmmodal_user_Box_wrapper {
    padding: 0;
}

.hmmodal_user_Box_wrapper h3 {
    margin-bottom: 0;
    font-size: 24px;
    color: var(--white);
    padding: 5px 15px;
    background: #003166;
    text-align: center;
    font-weight: normal;
}

.hmmodal_user_Box_wrapper .MuiToolbar-gutters button.delete_data,
.hmmodal_user_Box_wrapper .MuiToolbar-gutters button.status_data {
    color: #111;
    background: #e3eefa;
    background: #fff;
    margin: 0 4px;
    box-shadow: 0 0 6px #030c14;
}

.hmmodal_user_Box_wrapper h4 {
    margin-bottom: 0;
    font-size: 24px;
    color: var(--white);
    padding: 5px 15px;
    background: #003166;
    font-weight: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.hmmodal_user_Box_wrapper h4 span {
    padding-right: 15px;
}

.hmmodal_user_Box_wrapper h4 .addCampaign_btn {
    display: block;
    font-size: 28px;
    width: 30px;
    height: 30px;
    background: #fff;
    color: #003166;
    border-radius: 100%;
    cursor: pointer;
    border: none;
}

.hmmodal_user_Box_wrapper .MuiFormControl-root {
    width: 100%;
}

.hmmodal_user_Box_wrapper form {
    padding: 15px;
}

.hmmodal_user_Box_wrapper .inputBlock1line {
    margin-bottom: 10px !important;
}

.MuiDialog-paperScrollPaper {
    overflow: inherit !important;
}

.hmmodal_user_Box_wrapper .inputBlock1line fieldset {
    border: none !important;
}

.hmmodal_user_Box_wrapper input {
    padding: 12px 14px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333;
    border: solid 1px #ddd !important;
    outline: none !important;
    border-radius: 4px !important;
    opacity: inherit !important;
}

.hmmodal_user_Box_wrapper svg {
    width: 20px;
    height: 20px;
}

.hmmodal_user_Box_wrapper textarea {
    padding: 5px 14px;
    height: 60px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333;
    border-radius: 0;
    resize: none;
    border: solid 1px #ddd !important;
    outline: none !important;
    border-radius: 4px !important;
}

.hmmodal_user_Box_wrapper .backend_addform button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    min-width: inherit;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 15px;
    text-transform: capitalize;
    background: var(--blue2) !important;
    margin: 10px 5px 0 0 !important;
}

.hmmodalCloseBTN {
    border-radius: 50% !important;
    height: 30px;
    opacity: 1;
    padding: 5px !important;
    position: absolute !important;
    right: -14px;
    top: -17px;
    background-color: #0569ad !important;
    color: var(--white) !important;
    min-width: inherit !important;
    z-index: 9;
}

.folder_view_con .adminformbody .formInline .Formsubmit_button {
    margin-top: 0;
}

.NoRecordsDiv {
    display: block;
    width: 100%;
    text-align: center;
    background: #e9e7e7;
    padding: 10px;
    font-size: 18px;
    color: #ff0000 !important;
    margin: 0 5px;
}

.Noaccesspage {
    height: calc(100vh - 80px);
    width: 100vw;
    background: linear-gradient(45deg, #f8f7ed 12.5%, #fff 12.5%, #fff 37.5%, #f8f7ed 37.5%, #f8f7ed 62.5%, #fff 62.5%, #fff 87.5%, #f8f7ed 87.5%);
    background-size: 100px 100px;
    background-position: 50px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Noaccesspage_div {
    clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0% 70%, 0 0);
    background-color: rgb(76 75 27 / 59%);
    border-radius: 10px 10px 0 0;
    width: 400px;
    max-width: 90%;
    padding: 15px;
    text-align: center;
}

.Noaccesspage_div h2 {
    margin: 0;
    padding: 0;
    font-size: 40px;
    line-height: 46px;
    color: #fff;
    position: relative;
}

.Noaccesspage_div h2::before {
    content: "";
    width: 120px;
    height: 5px;
    background: #fff;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
}

.Noaccesspage_div p {
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin: 25px 0 0 0;
    padding: 0;
}

.Noaccesspage_div svg {
    color: #fff;
    width: 80px;
    height: 80px;
    margin-top: 10px;
}

.landingmodal {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    align-items: center;
    vertical-align: middle;
}

.notes_details .MuiDialog-paper .MuiToolbar-gutters {
    margin: 0;
    padding: 0;
    min-height: inherit;
    background: #f3f3f3;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
    box-shadow: none;
    margin: 0;
    padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root .MuiTypography-h6 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.notes_details .notesTable .MuiTypography-h6 {
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th svg {
    color: #000 !important;
}

.notes_details {
    z-index: 100 !important;
}

.notes_details .notesTable th {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .notesTable td {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}

.notes_details .notesTable th svg {
    color: #000 !important;
}

.css-1ty026z {
    padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td img {
    width: 180px;
    cursor: pointer;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr {
    position: relative;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr .MuiLinearProgress-root {
    position: absolute;
    left: 0;
    width: 100%;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
    margin: 0;
    min-height: inherit;
    padding: 0;
}

.textarea_add_note {
    width: 100%;
    height: 50px !important;
    padding: 10px;
    border-radius: 0px;
    outline: none;
    border: solid 1px #ccc !important;
    resize: none;
}

.notes_details .MuiTypography-h6 {
    margin: 0 !important;
    padding: 0 10px !important;
}

.notes_details h2.MuiTypography-h6 {
    margin: 0 0 15px 0 !important;
    padding: 10px !important;
    background: #ebe2e2 !important;
}

.notes_details .stackPagination {
    margin: 15px 0;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
    z-index: 1100 !important;
}

.notes_details .action_btn_container {
    display: block;
}

.notes_details .notes_btn_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: inherit !important;
    max-width: 90% !important;
    overflow-y: inherit;
}

.hmmodal_user_Box {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 400px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.hmmodal_user_Box_wrapper {
    padding: 0;
}

.hmmodal_user_Box_wrapper h3 {
    margin-bottom: 0;
    font-size: 24px;
    color: var(--white);
    padding: 5px 15px;
    background: #003166;
    text-align: center;
    font-weight: normal;
}

.hmmodal_user_Box_wrapper h4 {
    margin-bottom: 0;
    font-size: 24px;
    color: var(--white);
    padding: 5px 15px;
    background: #003166;
    font-weight: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.hmmodal_user_Box_wrapper h4 span {
    padding-right: 15px;
}

.hmmodal_user_Box_wrapper h4 .addCampaign_btn {
    display: block;
    font-size: 28px;
    width: 30px;
    height: 30px;
    background: #fff;
    color: #003166;
    border-radius: 100%;
    cursor: pointer;
    border: none;
}

.hmmodal_user_Box_wrapper .MuiFormControl-root {
    width: 100%;
}

.hmmodal_user_Box_wrapper form {
    padding: 15px;
}

.hmmodal_user_Box_wrapper .inputBlock1line {
    margin-bottom: 10px !important;
}

.MuiDialog-paperScrollPaper {
    overflow: inherit !important;
}

.hmmodal_user_Box_wrapper .inputBlock1line fieldset {
    border: none !important;
}

.hmmodal_user_Box_wrapper input {
    padding: 12px 14px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333;
    border: solid 1px #ddd !important;
    outline: none !important;
    border-radius: 4px !important;
    opacity: inherit !important;
}

.hmmodal_user_Box_wrapper svg {
    width: 20px;
    height: 20px;
}

.hmmodal_user_Box_wrapper textarea {
    padding: 5px 14px;
    height: 60px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333;
    border-radius: 0;
    resize: none;
    border: solid 1px #ddd !important;
    outline: none !important;
    border-radius: 4px !important;
}

.hmmodal_user_Box_wrapper .backend_addform button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    min-width: inherit;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 15px;
    text-transform: capitalize;
    background: var(--blue2) !important;
    margin: 10px 5px 0 0 !important;
}

.hmmodalCloseBTN {
    border-radius: 50% !important;
    height: 30px;
    opacity: 1;
    padding: 5px !important;
    position: absolute !important;
    right: -14px;
    top: -17px;
    background-color: #0569ad !important;
    color: var(--white) !important;
    min-width: inherit !important;
    z-index: 9;
}

.folder_view_con .adminformbody .formInline .Formsubmit_button {
    margin-top: 0;
}

.NoRecordsDiv {
    display: block;
    width: 100%;
    text-align: center;
    background: #e9e7e7;
    padding: 10px;
    font-size: 18px;
    color: #ff0000 !important;
    margin: 0 5px;
}

.Noaccesspage {
    height: calc(100vh - 80px);
    width: 100vw;
    background: linear-gradient(45deg, #f8f7ed 12.5%, #fff 12.5%, #fff 37.5%, #f8f7ed 37.5%, #f8f7ed 62.5%, #fff 62.5%, #fff 87.5%, #f8f7ed 87.5%);
    background-size: 100px 100px;
    background-position: 50px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Noaccesspage_div {
    clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0% 70%, 0 0);
    background-color: rgb(76 75 27 / 59%);
    border-radius: 10px 10px 0 0;
    width: 400px;
    max-width: 90%;
    padding: 15px;
    text-align: center;
}

.Noaccesspage_div h2 {
    margin: 0;
    padding: 0;
    font-size: 40px;
    line-height: 46px;
    color: #fff;
    position: relative;
}

.Noaccesspage_div h2::before {
    content: "";
    width: 120px;
    height: 5px;
    background: #fff;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
}

.Noaccesspage_div p {
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin: 25px 0 0 0;
    padding: 0;
}

.Noaccesspage_div svg {
    color: #fff;
    width: 80px;
    height: 80px;
    margin-top: 10px;
}

.landingmodal {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    align-items: center;
    vertical-align: middle;
}

.notes_details .MuiPaper-root,
.view_details .MuiPaper-root {
    box-shadow: none !important;
}

.returnToOwn {
    white-space: break-spaces !important;
}

@media only screen and (max-width: 1199.98px) {
    .PatientSearch .searchForm .MuiButton-contained {
        margin: 0 auto;
        font-size: 25px;
        padding: 5px 10px;
        width: 250px;
    }
    .notes_details table,
    .notes_details tbody,
    .notes_details td,
    .notes_details tr {
        display: block;
        width: 100%;
        min-width: inherit !important;
    }
    .notes_details tr {
        margin: 10px 0;
        border: solid 1px #ccc;
    }
    .notes_details tr:first-of-type {
        margin-top: 0px;
    }
    .notes_details thead {
        display: none;
    }
    .notes_details td {
        position: relative;
        padding-left: 50% !important;
    }
    .notes_details td:before {
        content: attr(title);
        position: absolute;
        left: 0;
        padding-left: 10px;
        font-weight: bold;
    }
    .notes_details .MuiDialogContent-root {
        padding: 15px;
    }
    .userForm .inputblock2line {
        flex: 1 0 48% !important;
    }
    .MuiAutocomplete-root {
        min-width: inherit !important;
    }
}

@media only screen and (max-width: 991px) {
    .PatientSearch .searchForm .MuiFormControl-root {
        margin: 5px 0 !important;
    }
    .PatientSearch .inputblock2line {
        flex: 1 0 90%;
        margin: 10px 0;
    }
}

@media only screen and (max-width: 679px) {
    .userForm .inputblock2line {
        flex: 1 0 98% !important;
    }
    .list_search .list_search_item {
        flex: 1 0 100% !important;
    }
    .list_search .inputBlock {
        flex: 1 0 100% !important;
    }
}

@media only screen and (max-width: 599px) {
    .view_details .MuiDialogContent-root {
        padding: 20px 10px;
    }
    .view_details .MuiTypography-root {
        padding: 20px 10px !important;
    }
    .property_container .property_value {
        width: 45%;
    }
    .property_container .property_label {
        width: 50%;
    }
    .view_details .property_container {
        font-size: 13px !important;
    }
    .IPInfo_Modal .property_container .property_value {
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 530px) {
    .property_value {
        width: 48%;
    }
}

@media only screen and (max-width: 499px) {
    .searchForm .MuiBox-root {
        margin: 0 5px;
    }
    .searchBarHeading {
        font-size: 25px;
    }
    .searchBarHead .MuiIconButton-root {
        background: var(--white);
        width: 30px;
        height: 30px;
        top: 2px;
    }
}

@media only screen and (max-width: 440px) {
    .BoxMui_modal .closeModalBtn,
    .modalCloseBtn,
    .close_icon_btn {
        top: -25px;
        right: -20px;
    }
}


/* /----------preview modal---------/ */

.listflexblock li {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
    word-break: break-word;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
}

.listflexblock li strong {
    width: 25%;
}

.listflexblock li span {
    display: block;
    width: 65%;
}

.listflexblock li .MuiChip-label {
    width: auto;
}

.listflexblock li .viewModChips {
    width: auto;
}

.listflexblock li:nth-child(odd) {
    background: #d7d7d74f;
}

.userList_modal .BoxMui_modal .listflexblock {
    height: 400px;
    overflow-y: scroll;
}

.SR_UserDetail_preview .BoxMui_modal .listflexblock {
    max-height: 400px;
    height: 100%;
    overflow-y: scroll;
}

.delete_modal_Btn {
    padding: 10px;
}

.delete_modal_Btn button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 20px;
    text-transform: capitalize;
    background: var(--blue2);
}

.userList_modal .delete_modal p {
    margin-bottom: 0;
    font-size: 35px;
    color: var(--white);
    padding: 10px 5px;
    background: #003166;
    text-align: center;
}

.delete_modal_Detail {
    text-align: center;
    font-size: 23px;
    line-height: 28px;
    padding: 10px;
}

.heading h2 {
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    background: #0569ad;
    padding: 0 10px;
    margin-top: -1px;
}

.heading h3 {
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    background: #0569ad;
}

.notes_details .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-bottom: 0 !important;
}

@media only screen and (max-width: 440px) {
    .listflexblock li {
        font-size: 13px;
        line-height: 16px;
    }
    .listflexblock li strong {
        width: 32%;
    }
    .listflexblock li span {
        width: 57%;
    }
    .BoxMui_modal .heading h2 {
        font-size: 26px;
        padding: 5px !important;
    }
}


/* /--------snackbar css---------/ */

.MuiSnackbar-root {
    width: 100% !important;
    transform: inherit !important;
    bottom: 17% !important;
    transform: translateY(-17%) !important;
}

.MuiSnackbar-root .MuiAlert-root {
    /* background-color: rgb(204 229 204); */
}

.MuiSnackbarContent-message {
    text-align: center !important;
}

.MuiSnackbarContent-root {
    display: block !important;
}

.MuiSnackbarContent-action {
    display: none !important;
}


/* /-------------search modal css-----/ */


/* /-----------add landing page css---------/ */

.thumbnail h1 {
    font-size: 22px;
    font-weight: 600;
}

.upload-container .MuiFab-circular {
    width: 40px;
    height: 40px;
}


/* /-----------add landing page css---------/ */


/* <-----------Navigation Menu list CSS---------> */

.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li:not(:last-child) {
    border-bottom: 1px solid #cccccca1;
}

.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li li {
    padding: 0 !important;
    border: none !important;
}

.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li li button {
    padding: 0;
    color: #2e2e2e;
    text-transform: none;
}

.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li span {
    display: block;
    width: 100%;
}


/* <-----------Navigation Menu list CSS---------> */


/*-----Backend top part -------------*/

.header_menu_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.admin_topRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-left: 1px solid #cccccf;
    padding-left: 25px;
}

.header_centerblock {
    width: 100%;
}

.toggle_button {
    display: none;
}

.toggle_button button {
    align-items: center;
    background-color: var(--orange);
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    height: 50px;
    padding: 0;
    justify-content: center;
    width: 50px;
    margin-right: 20px;
}

.toggle_button button svg {
    color: #fff;
}

.header_menu_block ul li button span svg {
    display: block;
}

.topuserblock {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.topuserblock .PersonIcon {
    background-color: var(--deep-blue);
    border-radius: 100px;
    color: #fff;
    height: 30px;
    padding: 5px;
    width: 30px;
}

.topuserblock .userarrowicon {
    background-color: var(--deep-blue);
    border-radius: 100px;
    color: #fff;
    height: 20px;
    margin: 0;
    padding: 0;
    width: 20px;
}

.name_drop {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: inherit;
}

.name_drop button {
    min-width: inherit;
}

.header_menu_block ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    padding: 10px 0;
}

.header_menu_block ul li {
    list-style: none;
    /* margin-bottom: 10px; */
}

.header_menu_block ul li a {
    list-style: none;
    color: #000;
    text-decoration: none;
    margin: 0 5px;
    font-size: 16px;
    border-radius: 5px;
    padding: 6px 10px;
}

.header_menu_block a.active,
.header_menu_block a:hover,
.header_menu_block button.active {
    background-color: var(--orange);
    color: #fff;
}

.header_centerblock button {
    color: var(--black);
    font-weight: 400;
    text-transform: capitalize;
    font-size: 16px;
    padding: 4px 10px;
}

.header_centerblock button:hover {
    color: var(--white);
    background: var(--orange);
}

.BackendHeader {
    background-color: #e4e9eb;
    margin: 0 -25px;
    padding: 0px 25px;
    border-bottom: 1px solid #cccccf;
}


/* .MuiMenu-paper {
    margin-top: 40px;
} */

.MuiMenu-list {
    padding: 0 !important;
}

.select_class_unique_013568 .MuiPaper-elevation .MuiMenu-list {
    height: 350px;
}

.userDetail_dropDwn li:hover {
    background-color: var(--orange) !important;
    color: var(--white) !important;
}

.userDetail_dropDwn li a:hover {
    color: var(--white) !important;
}

.headerLogo {
    width: 200px;
    border-right: 1px solid #cccccf;
    padding: 0;
    padding-right: 25px;
}

.headerLogo img {
    width: 100%;
}

.userDetail_dropDwn ul.MuiMenu-list {
    padding: 0px;
    min-width: 180px;
    max-width: 200px;
}

.userDetail_dropDwn h1 {
    margin: 0;
    padding: 10px;
    font-size: 20px;
    color: #fff;
    background-color: var(--blue2);
    font-weight: 500 !important;
    border-bottom: 1px solid #cccccf;
    text-align: center;
}

.userDetail_dropDwn li {
    list-style: none;
    border-bottom: 1px solid #cccccf;
    display: block;
    text-align: center;
    width: 100%;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.userDetail_dropDwn li a {
    text-decoration: none;
    color: #333;
    display: block;
    text-align: center;
    width: 100%;
    text-transform: inherit !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.headerRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 220px);
    flex-direction: row-reverse;
}

.page_heading {
    margin: 25px 0;
    padding: 10px;
    font-size: 24px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
    background: #fff;
    box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
}

.hideTop .page_heading {
    margin-top: 40px;
}

.dataform1 {
    display: block;
    position: relative;
}

.dataform1_wrapper {
    background: #fff;
    border-radius: 8px;
    display: block;
    box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
    padding: 25px;
    width: 800px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.dataform2_wrapper {
    background: #fff;
    border-radius: 8px;
    display: block;
    box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
    padding: 25px;
    margin-bottom: 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.dataform {
    display: block;
    position: relative;
}

.dataform_wrapper {
    background: #fff;
    border-radius: 8px;
    display: block;
    box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.header_menu_block ul li button {
    list-style: none;
    color: #000;
    text-decoration: none;
    margin: 0 5px;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 10px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.header_menu_block ul li button:hover {
    background-color: var(--orange);
    color: #fff;
}

.header_menu_block ul li button svg {
    display: none;
}


/* .MuiPopover-root .MuiPaper-elevation {top: 46px !important;} */

.BackendHeader .left_menu_svg {
    display: none;
}

.header_menu_block .MuiButton-root {
    padding: 5px 10px !important;
}

.topmenu .MuiPaper-root {
    margin-top: 30px;
}

.topmenu .MuiMenu-list .MuiMenuItem-gutters,
.topmenu .MuiButtonBase-root {
    font-size: 16px;
    font-weight: 400;
}


/* <-------------Backend Header List Menu Orientation CSS--------------> */

.childMenuActive .MuiMenu-paper {
    margin-left: 155px;
}


/* <-------------Backend Header List Menu Orientation CSS--------------> */


/*-----Backend footer part -------------*/

.footerBody {
    margin-top: auto;
    background-color: var(--white);
    text-align: center;
    color: var(--gray2);
    padding: 15px;
    font-size: 16px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
}

.footer_mail {
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
    text-transform: capitalize;
}

.footer_mail .mail_to {
    text-decoration: none;
    color: blue;
    /* color: var(--gray2); */
    text-transform: lowercase;
}

.footerBody span {
    padding: 0 5px;
    display: inline-block;
    cursor: pointer;
}

.showTop .BackendLeft {
    display: none;
}


/* .hideTop .BackendHeader {
  display: none;
} */

.SD_tag_cycle .tagChipwrp {
    margin-bottom: 20px;
}

.SD_tag_cycle .tagChipwrp:first-child {
    margin-bottom: 0px;
}

.showTop .BackendBody {
    padding-left: 25px;
}

.footer p {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #979fa7;
}

.footer p span {
    padding: 5px;
}

.footer p span a {
    color: #979fa7;
    text-decoration: none;
}

.footer p span a:hover {
    color: #000;
}

.footer {
    padding: 20px 0;
    background-color: #e0e7ee;
}

.footercontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerloginbtn {
    font-size: 20px;
    padding: 6px 30px;
    border-radius: 5px;
    background: #ddd;
    background: linear-gradient(180deg, rgba(8, 231, 252, 1) 0%, rgba(39, 134, 234, 1) 100%);
    color: #fff;
    border: 1px solid #42c3f7;
    cursor: pointer;
    box-shadow: 4px 4px 5px #0268875e;
    transition: all 0.3s;
    font-weight: var(--main-font-SemiBold);
    text-shadow: 1px 1px #087cad;
}

.footerloginbtn:hover {
    box-shadow: 4px 5px 6px #166f9fad;
}

.footer p span button {
    color: #979fa7;
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: none;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
}

.footer p span button:hover {
    color: #000;
}

.folder_view_body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.folder_view_left,
.folder_view_right {
    width: 290px;
    background-color: var(--gray1);
    padding: 15px;
    border-radius: 10px;
}


/* .folder_view_left,.folder_view_right .blocks{margin: 10px 0;} */

.folder_view_left h3,
.folder_view_right h3 {
    background-color: var(--white);
    color: #333;
    padding: 15px;
    font-size: 18px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
}

.folder_view_left p {
    margin: 5px 0;
    border-bottom: solid 1px #ccc;
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    padding: 6px 0;
    color: #333;
}

.folder_view_left p strong {
    color: var(--orange);
    font-weight: 600;
    width: 80px;
}

.folder_view_left p span {
    width: calc(100% - 85px);
}

.email {
    word-break: break-all;
}

.folder_view_con {
    width: calc(100% - 620px);
    border: solid 1px #e5e5e1;
    padding: 15px;
    border-radius: 10px;
}

.folder_view_left button {
    position: relative;
    padding: 8px;
    margin: 5px 0;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    background-color: #3498db;
    border-bottom: 5px solid #2980b9;
    text-shadow: 0px -2px #2980b9;
    display: block;
    width: 100%;
    text-transform: capitalize;
}

.folder_view_left button:last-of-type {
    margin-bottom: 0;
}

.folder_view_left button:hover {
    background-color: #f2cf66;
    border-bottom: 5px solid #d1b358;
    text-shadow: 0px -2px #d1b358;
    color: #333;
    text-shadow: 0px -2px #d1b358;
}

.folder_view_left button:active {
    transform: translate(0px, 5px);
    -webkit-transform: translate(0px, 5px);
    border-bottom: 5px solid #fff;
}

.folder_view_left .active_btn {
    background-color: #f2cf66;
    border-bottom: 5px solid #d1b358;
    text-shadow: 0px -2px #d1b358;
    color: #333;
    text-shadow: 0px -2px #d1b358;
}

.folder_view_right h3 svg,
.folder_view_left h3 svg {
    border-radius: 100px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    color: #fff;
    background: #333;
    padding: 5px;
}

.folder_view_con h3 {
    background-color: #f2cf66;
    color: #333;
    margin-bottom: 10px;
    padding: 15px;
    font-size: 22px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
}

.folder_view_con h3 svg {
    border-radius: 100px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    color: #fff;
    background: #333;
    padding: 5px;
}

.folder_view_con p {
    margin: 0;
    border-bottom: solid 1px #ebebeb;
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    padding: 10px;
    color: #333;
}

.folder_view_con p strong {
    color: var(--orange);
    font-weight: 600;
    width: 320px;
}

.folder_view_con p:nth-of-type(odd) {
    background: #ebebeb;
}

.paragraph p {
    background: none !important;
    display: block;
    padding: 0;
    margin: 5px 0;
    border: none;
    color: #0009;
}

.passwordCls p {
    font-size: 13px;
    color: red;
    text-align: right;
}

.blue_text,
.blue_text p {
    color: #1976d2;
}

.folder_view_con p span {
    width: calc(100% - 320px);
}

.folder_view_con p span span {
    width: auto;
}

.left_centerblock .leftArrowIcon {
    width: 20px;
    height: 20px;
}

.leadlistcon_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;
}

.leadlistcon {
    display: block;
    margin: 5px;
    flex: 1 1 48%;
    background: #f3f3f3;
    padding: 10px;
    border: 1px solid #e7e6e1;
}

.leadlistcon h4 {
    font-weight: normal;
}

.leadlistcon h4 strong {
    font-weight: 500;
    padding-right: 10px;
    color: var(--orange);
}

@media only screen and (max-width: 1660.98px) {
    .folder_view_con .formInline .inputBlock {
        width: 100% !important;
        flex: inherit !important;
    }
    .folder_view_con .formInline .statusinputBox {
        flex: 40% !important;
    }
}

@media only screen and (max-width: 1499px) {
    .header_menu_block ul li button {
        margin: 0 3px;
        padding: 5px;
    }
    .header_menu_block .MuiButton-root {
        padding: 0 4px !important;
    }
    .header_menu_block .MuiButton-endIcon {
        margin-left: 2px;
    }
    .headerLogo {
        width: 170px;
        padding-right: 0;
    }
    .headerRight {
        width: calc(100% - 170px);
    }
}

@media only screen and (max-width: 1300.98px) {
    .folder_view_con h3 {
        font-size: 20px;
    }
    .folder_view_con h3 svg {
        width: 30px;
        height: 30px;
    }
    .folder_view_con .formInline .statusinputBox {
        flex: inherit !important;
    }
    .folder_view_left,
    .folder_view_right {
        width: 300px;
    }
    .folder_view_left h3,
    .folder_view_right h3 {
        padding: 10px;
        font-size: 20px;
    }
    .folder_view_right h3 svg,
    .folder_view_left h3 svg {
        width: 30px;
        height: 30px;
    }
    .folder_view_con {
        width: calc(100% - 640px);
    }
}

@media only screen and (max-width: 1199.98px) {
    .folder_view_left_btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .folder_view_left button {
        width: 49%;
    }
    .folder_view_body {
        display: block;
    }
    .folder_view_left,
    .folder_view_right {
        width: auto;
    }
    .folder_view_con {
        width: auto;
        margin: 15px 0;
    }
    .hideTop .page_heading {
        margin-top: 25px;
    }
    .hideTop .rolelist_mainwrp {
        margin-top: 0;
    }
    .BackendHeader .MuiBox-root {
        margin-left: 0;
    }
    .dataform_wrapper {
        padding: 15px;
    }
    .dataform1_wrapper {
        padding: 15px;
    }
    .toggle_button {
        display: block;
    }
    .header_centerblock ul {
        display: none;
    }
    .showdiv ul {
        display: flex;
    }
    .admin_topRight {
        border-left: none;
        padding-left: 0;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .header_centerblock {
        display: block;
        width: 100%;
    }
    .headerRight {
        display: block;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: row-reverse;
    }
    .showdiv ul {
        display: block;
        border-top: 1px solid #cccccf;
    }
    .header_menu_block ul li {
        display: block;
    }
    .header_menu_block ul li a {
        display: block;
        text-align: center;
        border-radius: 0px;
        border-top: 1px solid #cccccf;
    }
    .BackendHeader {
        padding: 0;
    }
    .header_menu_block ul li a {
        margin: 0;
    }
    .headerLogo {
        padding-left: 25px;
        width: 225px;
    }
    .hideTop .BackendHeader {
        display: none;
    }
    .hideTop .BackendLeft {
        display: none;
    }
    .hideTop .BackendHeader {
        display: block;
    }
    .BackendBody {
        padding: 0;
        width: auto;
    }
    .showTop .BackendBody {
        padding-left: 0px;
    }
    .BackendHeader {
        margin: 0;
    }
    .BackendOutlet {
        padding: 15px;
    }
    .header_menu_block ul li button {
        display: block;
        width: 100%;
        border-top: 1px solid #cccccf;
        margin: 0;
        border-radius: 0px;
        padding: 6px 0;
        font-size: 18px;
    }
    .header_centerblock button {
        width: 100%;
        border-top: 1px solid #cccccf;
    }
    .MuiMenu-paper {
        max-width: 100% !important;
        width: 100%;
        left: 0 !important;
    }
    .header_menu_block .MuiButton-root {
        display: flex;
    }
    /* .topmenu .MuiPaper-root {
        top: 310px !important;
    } */
    .topmenu .MuiPaper-root li {
        display: block;
        text-align: center;
        font-size: 18px;
        margin: 0;
        padding: 6px 0;
    }
    .userDetail_dropDwn ul.MuiMenu-list {
        max-width: initial;
    }
}

@media only screen and (max-width: 991.98px) {
    .dataform1_wrapper {
        width: 100%;
    }
    .rolelist_mainwrp_Table {
        padding: 15px;
    }
}

@media only screen and (max-width: 780px) {
    .lastLogin_Info .BoxMui_modal {
        width: 94%;
    }
}

@media only screen and (max-width: 640px) {
    .folder_view_left_btn {
        display: block;
    }
    .folder_view_left button {
        width: 100%;
    }
    .rolelist_mainwrp_Table {
        padding: 15px;
    }
    .footercontainer {
        display: block;
    }
    .footerloginbtn {
        margin: 0 auto;
        display: block;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 560px) {
    .MuiDialogTitle-root {
        padding: 20px 30px !important;
    }
    .BoxMui_modal h2 {
        padding: 10px !important;
    }
    .BoxMui_modal {
        width: 90% !important;
    }
}

@media only screen and (max-width: 400.98px) {
    .header_menu_block {
        display: block;
    }
    .admin_topRight {
        border-left: none;
        padding-left: 0;
        position: inherit;
        right: 0;
        top: 0;
        display: block;
        width: 90%;
        margin: 0 5%;
    }
    .topuserblock {
        justify-content: space-between;
        width: 100%;
    }
    .toggle_button button {
        margin-right: 0px;
        margin: 5px;
    }
    .headerLogo {
        padding: 0;
        border: none;
        width: auto;
        text-align: center;
    }
    .headerLogo img {
        max-width: 280px;
        margin: 10px auto;
    }
    .folder_view_left h3,
    .folder_view_right h3 {
        flex-direction: column;
    }
    .folder_view_con h3 {
        font-size: 18px;
        padding: 10px;
    }
    .folder_view_con h3 svg {
        width: 26px;
        height: 26px;
    }
    .folder_view_left button {
        line-height: 30px;
    }
    .dashboard_block1 h2 {
        font-size: 20px;
    }
}


/* /-----------table css start by subhashis............../ */

.trainingCenter_main {
    margin-left: 0px !important;
    width: 100% !important;
    justify-content: space-between;
    padding: 0px !important;
    margin-top: 0 !important;
}

.commonWidth_admin {
    width: 1764px;
    margin: 0 auto;
}

.trainingCenter_Sub {
    /* margin-left: 0px !important; */
    width: 100% !important;
    justify-content: space-between;
    /* padding: 20px !important; */
    display: flex;
    margin: 20px 0 !important;
}

.trainingCenter_Sub .MuiGrid-grid-xl-8 {
    width: 70% !important;
    max-width: inherit !important;
    flex-basis: inherit !important;
}

.commonWidth_admin .MuiGrid-root {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
}

.traingcenterdata_wrapper {
    /* padding: 15px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0 !important;
    padding-top: 0px !important;
}

.traingcenterdata_wrapper app-traing-center {
    /* margin: 0.5%; */
    border-radius: 0px;
    box-shadow: -1px 1px 6px #0000008f;
    width: 100%;
    transition: transform 0.2s;
    /* background: rgb(28 25 127);
    background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%); */
    padding: 0;
    border: 1px solid #f5f5f5;
}

.traingcenterdata_wrapper .tncgCenter_dta {
    background-color: #ffffff;
    border-radius: 0px;
}

.traingcenterdata_wrapper app-traing-center .tncgCenter_dta {
    margin: 5px;
}

.tittle_wrapper {
    background: linear-gradient(180deg, #25cfe9 27%, #42b3f5 58%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ongoingg .tittle_wrapper p {
    color: #fff;
    text-shadow: 1px 1px 5px #221f06;
}

.ongoing .tittle_wrapper {
    background: rgb(236, 224, 80);
    background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
}

.traingcenterList_wrapper app-traing-center,
.trainingCenter_main .tittle_wrapper {
    cursor: pointer;
}

.trainingDesc_modal .test_html_class {
    /* {background: linear-gradient(184deg,#ffffff 27%,#c0b9b9 67%); */
    background-color: #dbe4eb;
    background-repeat: no-repeat;
}


/* overflow-y: scroll;} */


/* height: 80vh;} */

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
    padding: 1%;
    /* width: 98%; */
}

.description_wrapper .se-image-container figure img {
    width: 100%;
    display: block;
    margin: 10px 0;
}

.traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper .media_content {
    overflow: hidden;
    height: auto;
    padding: 8px;
    background: rgb(83, 91, 94);
    background: linear-gradient(138deg, rgb(127 134 137) 0%, rgba(41, 52, 58, 1) 100%);
    width: auto;
    position: relative;
}

.media_content video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.media_content[_ngcontent-umg-c107] video[_ngcontent-umg-c107] {
    width: 100%;
}

.mediacontrol_wrapper[_ngcontent-umg-c107] {
    padding: 0 15px;
}

.traingcenterdata_wrapper .mediacontrol_wrapper {
    padding: 0 !important;
    margin: 0;
}

.mediacontrol_icon_wrapper[_ngcontent-umg-c107] {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.traingcenterdata_wrapper .mediacontrol_icon_wrapper {
    background: #fff;
    padding: 2px;
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
    display: flex;
    /* align-items: center; */
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
    display: flex;
    /* align-items: center; */
}

.traingcenterdata_wrapper .mat-progress-bar-fill::after {
    background-color: #fcd602;
}

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
    padding: 15px;
    /* padding: 15px 15px 0; */
    /* width: 98%; */
}

.traingcenterdata_wrapper .mad_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.description_wrapper {
    padding-bottom: 5px;
}

.description_wrapper p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    word-break: break-all;
}

.description_wrapper h2,
.description_wrapper h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
}

.description_wrapper .se-component figure {
    width: 100% !important;
}

.mad_button_wrapper button {
    background-color: #00c8f5;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    border: none;
    margin: 2px;
    cursor: pointer;
}

.traingcenterdata_wrapper app-traing-center {
    /* margin: 0.5%; */
    border-radius: 0px;
    box-shadow: -1px 1px 6px #0000008f;
    width: 100%;
    transition: transform 0.2s;
    /* background: rgb(28 25 127);
    background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%); */
    padding: 0;
    border: 1px solid #f5f5f5;
}

.PatientSearch .joindate .MuiFormControl-root {
    width: 48.5%;
}


/* /-----------table css end by subhashis............../ */


/* <-----------------Calender Success Banner CSS Starts *Sanket*--------------------> */

.calender_banner_mainwrapper {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.calender_banner_subwrapper {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_img.webp);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
}

.calender_banner_subwrapper {
    padding: 54px 0;
}

.calender_banner_blockwrap {
    display: flex;
    justify-content: space-between;
    width: 67%;
    padding: 50px 10px 55px 25px;
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_block_whlbg.webp), #ffffff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 1px solid #fff;
    border-radius: 15px;
    align-items: center;
}

.calender_banner_mainwrapper .calender_block_left {
    width: 37%;
}

.calender_banner_mainwrapper .calender_block_left img {
    display: block;
    width: 100%;
}

.calender_banner_mainwrapper .calender_block_right {
    width: 55%;
}

.campain_modal_width {
    width: fit-content;
}

.calender_banner_mainwrapper .calender_block_right h1 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(200, 210, 208, 1) 45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 41px;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.4));
    font-weight: var(--main-font-Bold);
    text-align: center;
}

.calender_banner_mainwrapper .calender_block_right p {
    font-size: 27px;
    line-height: 31px;
    color: #00f2ee;
    font-weight: var(--main-font-Medium);
    padding-left: 45px;
}


/* <-----------------Calender Success Banner CSS Ends *Sanket*--------------------> */


/* <-----------------Calender Success Banner Responsive Starts *Sanket*--------------------> */

@media only screen and (max-width: 1799px) {
    .calender_banner_mainwrapper .commonWidth_admin {
        width: 96%;
    }
}

@media only screen and (max-width: 1748px) {
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 39px;
    }
    .calender_banner_mainwrapper .calender_block_right {
        width: 54%;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 25px;
        line-height: 29px;
    }
    .calender_banner_mainwrapper .calender_block_left {
        width: 35%;
    }
}

@media only screen and (max-width: 1658px) {
    .calender_banner_blockwrap {
        align-items: center;
        width: 65%;
        padding: 35px;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 37px;
    }
    /* .calender_banner_mainwrapper .calender_block_left {width: 33%;} */
    .calender_banner_mainwrapper .calender_block_right {
        width: 55%;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 34px;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 22px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1458px) {
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 19px;
        line-height: 23px;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1299px) {
    .calender_banner_blockwrap {
        padding: 25px;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 28px;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        padding-left: 35px;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 18px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 1199px) {
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 16px;
        line-height: 18px;
        padding-left: 25px;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 1099px) {
    .calender_banner_blockwrap {
        width: 70%;
    }
    .calender_banner_subwrapper {
        background-position: right -70px bottom;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 968px) {
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 22px;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 14px;
        line-height: 16px;
        padding-left: 20px;
        text-align: center;
    }
    .calender_banner_subwrapper {
        background-position: right -80px bottom;
    }
}

@media only screen and (max-width: 899px) {
    .calender_banner_mainwrapper {
        background: linear-gradient(90deg, rgba(5, 55, 207, 1) 16%, rgba(40, 216, 200, 1) 88%);
    }
    .calender_banner_subwrapper {
        background: none;
        padding: 30px 0;
    }
    .calender_banner_blockwrap {
        width: 100%;
    }
}

@media only screen and (max-width: 628px) {
    .calender_banner_blockwrap {
        flex-direction: column;
        background: #fff;
        padding: 15px;
    }
    .calender_banner_mainwrapper .calender_block_left {
        width: 46%;
    }
    .calender_banner_mainwrapper .calender_block_right {
        width: 100%;
        margin-top: 15px;
        padding: 15px 10px;
        background: var(--blue2);
    }
    .calender_banner_mainwrapper .calender_block_right h1,
    .calender_banner_mainwrapper .calender_block_right p {
        text-align: center;
        padding-left: 0;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 16px;
    }
    .calender_banner_mainwrapper .calender_block_right p br {
        display: none;
    }
    .hmmodal_user_Box {
        min-width: inherit;
    }
}

@media only screen and (max-width: 448px) {
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 399px) {
    .calender_banner_mainwrapper .calender_block_right p {
        font-size: 12px;
        line-height: 14px;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 19px;
        line-height: 25px;
    }
    .calender_banner_mainwrapper .calender_block_left {
        width: 54%;
    }
    .calender_banner_mainwrapper .calender_block_right p {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 340px) {
    .calender_banner_mainwrapper .calender_block_left {
        width: 60%;
    }
    .calender_banner_mainwrapper .calender_block_right h1 {
        font-size: 17px;
        line-height: 23px;
    }
}


/* <-----------------Calender Success Banner Responsive Ends *Sanket*--------------------> */


/* <----------------------Additional Training Center CSS------------------------> */

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_cat_data {
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_cat_data .training_name_text {
    width: calc( 100% - 40px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_cat_data svg {
    margin-right: 8px;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block:not(:first-child) {
    margin-top: 8px;
}


/* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 20px;} */

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {
    margin: 0px auto;
    width: 96%;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 4px;
    border-radius: 5px;
    padding: 5px;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent>img {
    display: block;
    width: 20%;
    border-radius: 5px;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent>p {
    margin-left: 20px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.active {
    background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.active>p {
    color: #000;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block.inactive .cat_block_cat_data {
    background: linear-gradient(180deg, rgb(102 102 102) 0%, rgb(4 4 4) 100%);
}


/* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block.cat_block_cat_data:hover {} */

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block.active .cat_block_cat_data {
    background: linear-gradient(180deg, rgb(4 4 4) 0%, rgb(102 102 102) 100%);
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .traingcenterlist_innerwrapper {
    width: 94%;
    margin: auto;
    position: sticky;
    top: 15px;
    max-height: 90vh;
    overflow-y: scroll;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .traingcenterlist_innerwrapper .readmorediv button {
    padding: 10px 25px;
    background-color: #000;
    color: #fff;
    font-size: 18px;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.inactive {
    background: linear-gradient(180deg, rgb(89 172 255) 0%, rgb(68 119 171) 100%);
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.ongoing {
    /* background: #F7F71B; */
    background: linear-gradient(180deg, rgb(255 239 20) 0%, rgb(221 186 15) 100%);
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.ongoing p {
    color: #333;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.ongoing.notdone p {
    color: #fff;
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.done {
    background: linear-gradient(180deg, rgba(68, 209, 61, 1) 0%, rgba(25, 148, 52, 1) 100%);
    /* background: #76ae4b; */
}

.additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent.notdone {
    background: linear-gradient(180deg, rgb(89 192 255) 0%, rgb(25 117 211) 100%);
}

.trainingCenter_main .traingcenterdata_wrapper {
    display: flex !important;
}

.cat_block {
    margin-bottom: 8px;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper {
    display: block;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper {
    width: 98%;
    border: 5px solid #000;
    position: sticky;
    top: 15px;
    background-color: #fff;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .leftpart_header {
    padding: 15px;
    background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .leftpart_header h1 {
    font-weight: 600;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .mediacontentpart>div {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .mediacontentpart>div iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .addtnl_mediacontentpart {
    padding: 15px;
    padding-bottom: 0;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .description {
    background-color: #fff;
    display: block;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .description p {
    font-size: 18px;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .nextbuttondiv {
    width: fit-content;
    margin: 5px 5px 5px auto;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper .nextbuttondiv button {
    padding: 5px 20px;
    color: #fff;
    font-size: 20px;
    background: linear-gradient(180deg, rgb(21 142 161) 0%, rgb(78 179 194) 100%);
}

.readmorediv {
    text-align: center;
    margin: 15px 0px;
}

.trainingCenter_main .disabled {
    background-color: #0000003d !important;
}


/* <----------------------Additional Training Center CSS------------------------> */


/* <--------------Training Material List CSS-----------> */

.tableDataTrainingMaterial .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .program_names .program_block {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.08);
    padding: 6px 12px;
    font-size: 13px;
    border-radius: 16px;
}

.chip_block,
.program_block {
    display: inline-block;
    margin: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.08);
    padding: 6px 12px;
    font-size: 13px;
    border-radius: 16px;
    max-width: 105px;
    overflow: hidden;
}

.MuiDialog-container .chip_block,
.modalBaseStyle .chip_block,
.modalBaseStyle .program_block,
.MuiDialog-container .program_block {
    max-width: inherit;
}

.contract_related_program .chip_block {
    display: inline-block;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.08);
    padding: 6px 12px;
    border-radius: 16px;
    max-width: fit-content;
    overflow: inherit;
    font-size: 15px;
}


/* <--------------Training Material List CSS-----------> */

@media only screen and (max-width: 1548px) {
    .additional_trainingCenter_Sub .traingcenterlist_wrapper {
        width: 28%;
    }
    .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent>p {
        font-size: 16px;
        line-height: 22px;
    }
    /* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 10px;} */
}

@media only screen and (max-width: 1199px) {
    /* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 0;} */
    .additional_trainingCenter_Sub .traingcenterlist_wrapper .traingcenterlist_innerwrapper {
        width: 100%;
    }
    .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent>img {
        display: block;
        width: 10%;
    }
}

@media only screen and (max-width: 480px) {
    .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data .lessoncontent>p {
        margin-left: 14px;
    }
}


/* /------------responsive end-------/ */


/* =======================Training Center===================== */

.trainingCenter_wrpr {
    min-height: 100vh;
    background-color: #cbf3fd;
    /* overflow: hidden; */
}

.trainingCenter_tile {
    text-align: center;
    background: rgb(18, 50, 71);
    background: linear-gradient(355deg, rgba(18, 50, 71, 1) 0%, rgba(28, 18, 67, 1) 100%);
    padding: 15px;
}

.trainingCenter_tile h1 {
    font-size: 30px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.trainingCenter_main {
    margin-left: 0px !important;
    width: 100% !important;
    justify-content: space-between;
    padding: 0px !important;
    margin-top: 0 !important;
}

.traingcenterdata_wrapper {
    /* padding: 15px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0 !important;
    padding-top: 0px !important;
}

.traingcenterdata_wrapper app-traing-center {
    /* margin: 0.5%; */
    border-radius: 0px;
    box-shadow: -1px 1px 6px #0000008f;
    width: 100%;
    transition: transform 0.2s;
    /* background: rgb(28 25 127);
    background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%); */
    padding: 0;
    border: 1px solid #f5f5f5;
}

.traingcenterdata_wrapper app-traing-center .tncgCenter_dta {
    margin: 5px;
}

.traingcenterdata_wrapper .tncgCenter_dta {
    background-color: #ffffff;
    border-radius: 0px;
}

.traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper .media_content {
    overflow: hidden;
    height: auto;
    padding: 8px;
    background: rgb(83, 91, 94);
    background: linear-gradient(138deg, rgb(127 134 137) 0%, rgba(41, 52, 58, 1) 100%);
    width: auto;
    position: relative;
}

.traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper .media_content iframe {
    min-height: 80vh;
}

.traingcenterdata_wrapper .mediacontrol_wrapper {
    padding: 0 !important;
    margin: 0;
}

.traingcenterdata_wrapper .mediacontrol_icon_wrapper {
    background: #fff;
    padding: 2px;
}

.traingcenterdata_wrapper .mat-progress-bar-buffer {
    background-color: #16c7f9;
}

.traingcenterdata_wrapper .mat-progress-bar-fill::after {
    background-color: #fcd602;
}

.traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper img {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
    display: flex;
    /* align-items: center; */
}

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
    padding: 15px;
    /* width: 98%; */
}


/* .description_wrapper p{
  word-break: break-all;
} */

.tittle_wrapper {
    background: rgb(5, 41, 88);
    background: linear-gradient(180deg, rgb(5 41 88 / 81%) 0%, rgba(5, 41, 88, 1) 100%);
    display: flex;
    align-items: center;
}

.expan_icon_wrapper {
    color: #fff;
    /* display: flex; */
    display: none;
}

.tittle_wrapper p {
    font-size: 25px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    /* background: rgb(253,253,253);
  background: linear-gradient(180deg, rgba(253,253,253,1) 0%, rgba(194,195,196,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
    margin: 0;
    padding: 5px;
    color: #fff;
    text-shadow: 0 0 2px #000;
}

.incomplete .tittle_wrapper {
    background: rgb(0, 197, 255);
    background: linear-gradient(180deg, rgba(0, 197, 255, 1) 0%, rgba(10, 133, 170, 1) 100%);
}

.incomplete .tittle_wrapper p {
    color: #fff;
    text-shadow: 1px 1px 5px #888;
}

.complete .tittle_wrapper {
    background: rgb(68, 209, 61);
    background: linear-gradient(180deg, rgba(68, 209, 61, 1) 0%, rgba(25, 148, 52, 1) 100%);
}

.complete .tittle_wrapper p {
    color: #fff;
    text-shadow: 1px 1px 5px #888;
}

.ongoing .tittle_wrapper {
    /* background: rgb(236, 224, 80); */
    background: linear-gradient(180deg, rgb(255 239 20) 0%, rgb(221 186 15) 100%);
}

.ongoing .tittle_wrapper p {
    color: #333;
    /* text-shadow: 1px 1px 5px #888; */
    text-shadow: none;
}

.expan_icon_wrapper .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: auto;
    width: auto;
    background: #fff;
    color: #000;
    border-radius: 100%;
    padding: 2px 1px 0 1px;
    margin-left: 10px;
}

.description_wrapper p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
}

.mad_button_wrapper button {
    background: linear-gradient(180deg, rgba(0, 200, 245, 1) 15%, rgba(23, 153, 176, 1) 81%);
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    border: none;
    margin: 2px;
    cursor: pointer;
}

.media_content video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.media_content audio {
    width: 100%;
    position: relative;
    top: 50%;
}

.traingcenterList_wrapper {
    display: none;
}

.traingcenterList_wrapper {
    position: sticky;
    top: 0;
    height: fit-content;
    right: 0;
    /* border: 5px solid #00c8f5; */
    padding-left: 20px !important;
    padding-top: 0px !important;
}

.traingcenterList_wrapper app-traing-center,
.trainingCenter_main .tittle_wrapper {
    cursor: pointer;
}

.trainingCenter_wrpr .show_percentage {
    background-color: #16c7f9;
    margin: 0px 0 0px;
    padding: 26px 0px;
}

.trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 1px 6px 2px rgb(0 0 0 / 16%);
    padding: 0px 29px;
}

.trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .totalcountshow {
    width: 35%;
}

.trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .progressbar_wrapper {
    width: 60%;
    display: flex;
    justify-content: end;
    align-items: center;
    background: rgb(255, 255, 255);
    /* background: linear-gradient(180deg, rgb(5 41 88 / 81%) 0%, rgba(5,41,88,1) 100%); */
    border-radius: 50px;
    padding: 20px 0;
}

.totalcountshow p {
    font-size: 25px;
    line-height: 25px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    /* text-align: center; */
    color: #000000;
    padding: 5px 0;
}

.progressbar_wrapper mat-progress-bar {
    width: 94%;
    height: 20px;
    border-radius: 20px;
}

.progressbar_wrapper .mat-progress-bar .mat-progress-bar-buffer {
    background: #16c7f9;
    /* background: linear-gradient(90deg, rgba(214,123,233,1) 0%, rgba(214,123,233,1) 100%); */
    /* border: 1px solid #fff; */
}

.progressbar_wrapper .mat-progress-bar-fill::after {
    background-color: #fcd602 !important;
}

.progressbar_wrapper p {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    word-break: normal;
    white-space: nowrap;
    padding-left: 10px;
}

.colourTheme_wrpr {
    background-color: #000;
}

.colourTheme_Subwrpr {
    justify-content: space-between;
}

.colourTab {
    background-color: #fff;
    margin: 0 !important;
    padding: 0;
    max-width: 32.33% !important;
    flex-basis: 32.33% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px !important;
    border-radius: 5px;
}

.colourTab .colourLogo {
    width: 16% !important;
}

.colourTheme_text {
    /* padding-left: 77px; */
    width: 80%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.colour_boolets {
    margin-right: 14px;
}

.colour_boolets img {
    display: block;
    width: 100%;
}

.colourTheme_text p {
    width: 80%;
    font-size: 24px;
    font-weight: 600;
    position: relative;
}


/* .colourTheme_text:nth-child(1)::after{
  content: "";
  position: absolute;
  background-color: #42ff00;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  left: 0;
    top: -12%;
    transform: translateX(12%);
} */

.trainingCenter_wrpr .trainingCenter_Banner img,
.colourTheme_wrpr img {
    display: block;
    width: 100%;
}

.commonWidth_admin {
    width: 1764px;
    margin: 0 auto;
}

.trainingCenter_Banner {
    background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_training_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
}

.trainingCenter_BannerSub {
    background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_trnbanner_hmn.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
}

.bannrContntWrpr {
    overflow: hidden;
}

.bannerContent {
    background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_bnrtxt_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 42%;
    border-radius: 3px;
    border: 1px solid #27ddf5;
    padding: 30px 40px 40px;
    border-right: 0;
    margin: 70px 0;
    /* background-position: right center; */
}

.bannerContent h1 {
    font-size: 37px;
    font-weight: 600;
    color: #fff;
    /* padding: 30px 40px 40px; */
    line-height: 45px;
}

.bannerContent h1 span {
    color: #fdf402;
}

.trainingCenter_Sub {
    margin-left: 0px !important;
    width: 100% !important;
    justify-content: space-between;
    /* padding: 20px !important; */
    display: flex;
    margin: 20px 0 !important;
}


/* .trainingCenter_Sub .MuiGrid-root {
  flex: 1 0 66%;
} */


/* .traingcenterdata_wrapper {
  width: 60% !important;
}

.traingcenterList_wrapper {
  width: 38% !important;
} */

.alertPara {
    margin-bottom: 15px;
    font-size: 35px;
    color: white;
    padding: 0px 5px;
    background: #003166;
}

.trainingCenter_Sub .MuiGrid-grid-xl-8 {
    width: 70% !important;
    max-width: inherit !important;
    flex-basis: inherit !important;
}

.trainingCenter_Sub .MuiGrid-grid-xl-4 {
    width: 29% !important;
    max-width: inherit !important;
    position: relative;
    flex-basis: inherit !important;
}

.colourTab:first-child {
    margin-left: 0 !important;
}


/* .additional_trainingCenter_Sub .traingcenterdata_wrapper, .additional_trainingCenter_Sub .traingcenterlist_wrapper {background-color: #000;padding: 20% 0;} */

.viewModChips {
    width: inherit;
    text-align: center;
    max-width: 30%;
    margin: 4px;
}

.viewModChips:nth-child(even) {
    background-color: #0569ad;
    color: white;
}

.viewModChips:nth-child(odd) {
    background-color: #6b6c6c;
    color: white;
}


/* 
.colourTab:last-child{
  margin-right: 0 !important;
} */

@media only screen and (max-width: 1800px) {
    .trainingCenter_wrpr .show_percentage {
        padding: 26px 0%;
    }
    .trainingCenter_wrpr .show_percentage.commonWidth_admin {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1640px) {}

@media only screen and (max-width: 1464px) {
    .commonWidth_admin {
        width: 94%;
        /* margin: 0 auto; */
    }
    .trainingCenter_BannerSub {
        background-position: right -110px bottom;
    }
}

@media only screen and (max-width: 1199px) {
    .notes_details .MuiDialog-paper {
        max-width: 90% !important;
        min-width: 90% !important;
        margin: 0 !important;
    }
    .tittle_wrapper p {
        font-size: 22px;
        line-height: 24px;
    }
    .trainingCenter_Sub {
        display: flex;
        flex-direction: column-reverse;
    }
    .training_right_block .category_block {
        padding: 12px;
    }
    .traingcenterdata_wrapper {
        width: auto !important;
    }
    .traingcenterdata_wrapper app-traing-center {
        display: block;
    }
    .commonWidth_admin .MuiGrid-root {
        display: block !important;
    }
    .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
        display: block;
    }
    .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .totalcountshow {
        width: auto;
        padding: 10px 0 0 0;
    }
    .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .progressbar_wrapper {
        width: auto;
        padding: 5px 0 15px 0;
    }
    .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
        padding: 0 15px;
    }
    .commonWidth_admin .MuiGrid-root {
        display: flex !important;
        width: 100% !important;
    }
    .colourTab {
        width: auto;
        margin: 0 !important;
        max-width: 100% !important;
        flex-basis: inherit !important;
    }
    .colourTheme_wrpr {
        background-color: #000;
        overflow: hidden;
    }
    .commonWidth_admin .traingcenterList_wrapper {
        width: auto !important;
        padding-left: 0 !important;
        display: block !important;
    }
    .trainingCenter_BannerSub {
        background: none;
    }
    .bannerContent {
        width: auto;
        padding: 20px;
    }
    .bannerContent h1 {
        font-size: 35px;
    }
    .trainingCenter_BannerSub {
        background: none;
    }
    .bannerContent {
        width: auto;
        padding: 20px;
        margin: 30px 0;
    }
    .bannerContent h1 {
        font-size: 35px;
    }
    .PatientSearch .formGroup {
        flex: 1 0 48% !important;
    }
}

.commonWidth_admin .traingcenterList_wrapper {
    width: auto !important;
    padding-left: 0 !important;
    /* display: block!important; */
}

.center {
    text-align: center;
}


/* .trainingCenter_Banner{
  min-height: 250px;
} */

@media only screen and (max-width: 900px) {
    .bannerContent h1 {
        font-size: 30px;
        line-height: 35px;
    }
    .totalcountshow p,
    .progressbar_wrapper p {
        font-size: 20px;
        text-align: center;
    }
    .folder_view_con p {
        display: block;
    }
    .folder_view_con p strong {
        display: block;
        width: auto;
    }
    .folder_view_con p span {
        width: auto;
    }
}

@media only screen and (max-width: 560px) {
    .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .progressbar_wrapper {
        display: block;
    }
    .progressbar_wrapper mat-progress-bar {
        width: auto;
    }
    .progressbar_wrapper p {
        text-align: center;
        padding: 5px 0 0 0;
    }
    .bannerContent h1 {
        font-size: 27px;
        line-height: 30px;
    }
    .bannerContent {
        margin: 20px 0;
    }
}

@media only screen and (max-width: 480px) {
    .commonWidth_admin .MuiGrid-root {
        display: block !important;
    }
    .colourTab .colourLogo {
        width: 16% !important;
        display: block;
        margin: 0 auto;
    }
    .colourTheme_text {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .colourTheme_text p {
        width: auto;
    }
    .colourTheme_text p {
        font-size: 20px;
    }
    .colour_boolets {
        margin-right: 14px;
        width: 30px;
    }
    .bannerContent h1 {
        font-size: 21px;
        line-height: 24px;
    }
    .totalcountshow p {
        line-height: 24px;
    }
    .traingcenterdata_wrapper app-traing-center {
        padding: 0;
    }
}


/* =======================Training Center===================== */

.Formsubmit_button {
    margin-top: 15px;
}


/* =======================Contract list page templete modal css===================== */

.contractDetails .property_container {
    display: block !important;
}


/* ----- saumya ------- */


/* .css-1u2w381-MuiModal-root-MuiDrawer-root {
z-index:initial!important;
} */

.resetmodalDataBtn {
    height: 76%;
}

.noDataFound {
    width: 87%;
    margin: 20px 0;
    text-align: center;
    /* background: #0569ad; */
    /* color: white; */
    padding: 5px;
}

.noDataFound span {
    font-weight: 600;
}

.capitalize {
    text-transform: capitalize;
}

.affiliatedisplaydatabtn .data_block .data_set {
    font-size: 17px !important;
    font-weight: lighter;
}

@media print {
    .w9_btn_wrp {
        display: none;
    }
    .w9_top_left h4 {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .w9_top_left h4 span {
        font-size: 30px !important;
    }
    .margtop1 {
        padding-top: 10px !important;
    }
    .margtop2 {
        margin-top: 100px !important;
    }
    .margin3 {
        margin-top: 0px !important;
    }
    .margin4 {
        margin-top: 60px !important;
    }
    .new_right {
        width: 28% !important;
    }
    .new_left {
        width: 65% !important;
    }
    .new_right .width70 {
        width: 120px !important;
    }
    .new_right .width50 {
        width: 100px !important;
    }
    .w9_block1_left div h3 {
        font-size: 18px !important;
        line-height: 26px !important;
    }
    .w9_common_flex p,
    .w9_common_flex li,
    .block2_common p,
    .w9_flex-wrapper2 p,
    .w9_flex-wrapper2 .MuiTypography-root,
    .w9_flex4 p,
    .font20_w9 p,
    .flex5 p,
    .w9_para p {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .w9_common_flex h2 {
        font-size: 20px !important;
        line-height: 24px !important;
    }
}

.affiliate-reportlist-navigation .active {
    background: orangered;
}

.affiliate_report .search_btn {
    display: none;
}

.partner_commission_summary .search_btn {
    display: none;
}

.partner_commission_summary .replayicon {
    display: none;
}

.ae_commission_summary .search_btn {
    display: none;
}

.ae_commission_summary .replayicon {
    display: none;
}

.affiliate_report .replayicon {
    display: none;
}

.affiliate_report .search_btn {
    display: none;
}

.affiliate_commission_summary .search_btn {
    display: none;
}

.affiliate_commission_summary .replayicon {
    display: none;
}

.listNavigate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listNavigate .toogleBtns {
    list-style: none;
    display: flex;
}

.listNavigate .toogleBtns li {
    margin: 5px;
}

.listNavigate .total_bill_mt {
    list-style: none;
    width: auto !important;
    margin: 2px !important;
    display: flex;
}

.listNavigate .total_bill_mt .double_bill {
    margin: 5px;
}

.displaynone {
    display: none;
}

.affiliatedisplaydatabtn button {
    display: none;
}

.affiliatedisplaydatabtn .close_btn {
    display: none !important;
}

.affiliatedisplaydatabtn .data_block .data_set {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.affiliatedisplaydatabtn .data_block .data_set ul {
    margin: 5px !important;
    padding: 15px !important;
    background: rgb(242, 242, 242);
    width: 31.5%;
}

.affiliatedisplaydatabtn .data_btn_container button {
    display: block;
}


/* .data_container .data_block .data_set  */

.affiliateInnerHtmlBlocks {
    list-style: none !important;
    margin: 20px !important;
}

.package_blocks .MuiFormHelperText-root {
    width: auto;
    left: 0;
    padding-left: 0px !important;
    margin: 5px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 600;
}

.notifyMsg p {
    color: red;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
}

.package_blocks {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.package_blocks .MuiFormControl-root {
    margin: 5px 0;
    /* width: 90%; */
    display: flex;
    justify-content: center;
    /* align-items:center ; */
}

.adminform_wrapper .Noteheading {
    width: 100%;
    position: absolute;
    right: 0;
    text-align: right;
    top: -15px;
}

.adminform_wrapper .Noteheading p {
    width: 100%;
    position: absolute;
    top: 0px;
    color: #cc0000;
}

.rolelist_mainwrp .tableNote p {
    color: #e10000;
    font-size: 18px;
    text-align: right;
}

.displaynone {
    display: none;
}

.CommisionHint {
    position: absolute;
    right: 0;
    color: #1b75b2;
}

.alertPara2 {
    margin-bottom: 15px;
    font-size: 35px;
    color: white;
    padding: 16px 0px;
    background: #003166;
}

.modalBTN {
    border-radius: 5px !important;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px !important;
    line-height: 26px !important;
    margin-right: 8px !important;
    padding: 8px 20px !important;
    text-transform: capitalize !important;
    background: var(--blue2) !important;
}

@keyframes dot-elastic {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dot-elastic-after {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f2cf66;
    color: #f2cf66;
    animation: dot-elastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f2cf66;
    color: #f2cf66;
    animation: dot-elastic-before 1s infinite linear;
}

.dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f2cf66;
    color: #f2cf66;
    animation: dot-elastic-after 1s infinite linear;
}

.contractSendBtn {
    background: #ffffff !important;
    color: #003166 !important;
    font-weight: 600 !important;
}

.listflexblocknew li {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
    word-break: break-word;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
}

.listflexblocknew li strong {
    width: 25%;
}

.listflexblocknew li span {
    display: block;
    width: 65%;
}

.listflexblocknew li:nth-child(odd) {
    background: #d7d7d74f;
}

.userList_modal .BoxMui_modal {
    width: 600px;
    max-width: 94%;
    padding: 0 !important;
}

.userList_modal .BoxMui_modal .listflexblocknew {
    height: auto;
    /* overflow-y: scroll; */
}

.listflexblocknew .heading {
    text-align: left !important;
    background: #0569ad;
    color: white;
    font-size: 18px;
}

.headingblock-FV .heading {
    margin-bottom: 0px;
    box-shadow: none;
    padding: 0px;
}

.headingblock-FV {
    background-color: #f2cf66;
    color: #333;
    margin-bottom: 10px;
    padding: 15px;
    font-size: 22px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
    justify-content: space-between;
}

.uName {
    font-size: 15px;
}


/* .folder_view_left .folder_btn{
  line-height: 18px!important;
} */

.folder_btn .count {
    width: 59px;
    height: auto;
    color: black;
    text-decoration: none;
    background: #f2cf66;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: 0px;
    font-size: 14px;
    background: #f2cf66;
    font-weight: bold;
    text-decoration: none;
    text-shadow: none;
    border: 1px solid black;
}

.view_contract_modal {
    margin: 25px !important;
    padding: 0 !important;
}

.view_contract_modal .cont-body {
    padding: 25px !important;
}

.view_contract_modal .cont-body p {
    font-size: 18px !important;
    line-height: 33px !important;
    text-align: justify;
}

.view_contract_modal .cont-body h4 {
    font-size: 30px !important;
}

.view_contract_modal .btn-grp .signatureBlock {
    width: 100%;
    border: 4px solid #0569ad;
}

.loaderBlock .loader {
    margin: 15px auto !important;
    width: 50%;
}

.signatureBlock .sign {
    width: 100%;
    background-color: #ffff;
}

.signatureBlock .sign .MuiInputLabel-formControl {
    background-color: transparent;
    color: #242222;
    padding: 2px;
    width: 105px;
    text-align: center;
}

.signatureBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.signMod {
    width: 100%;
    border: 1px dashed #000;
    margin: 10px 0;
}

.signMod .signModHere {
    background-color: #ffff;
    padding: 9px;
    min-height: 54px;
    max-height: 54px;
    font-family: Parisienne, cursive;
    font-size: 25px;
}

.view_contract_modal .btn-grp {
    background: #0569ad;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
}

.view_contract_modal .btn-grp .sendBtn {
    width: 15%;
    padding: 15px;
    border: 2px solid white;
}

.view_contract_modal .btn-grp .DLBtn {
    width: 5%;
    padding: 15px;
    border: 2px solid white;
}

.UA-scroll-activity {
    max-height: 800px;
    overflow: hidden;
    overflow-y: auto;
}

.contract_modal .BoxMui_modal {
    width: 1024px;
    height: auto;
    max-width: 100%;
}

.contract_modal .BoxMui_modal {
    padding: 0px !important;
}

.contract_modal textarea {}


/* .contract_modal .heading{
  position: sticky;
  top: 0;
} */

.contract_modal .btn-grp {
    background: #0569ad;
}

.contract_modal .btn-grp .notesblock {
    width: 100%;
    border: 4px solid #0569ad;
}

.contract_modal .cont-body {
    max-height: 61vh;
    overflow: hidden;
    overflow-y: auto;
}

.contract_modal .btn-grp .notesblock .notesinput {
    width: 100%;
    height: 67px !important;
    background: white;
    overflow-y: scroll;
    resize: none;
    padding: 8px;
    /* border: 2px solid #0569ad00; */
}

.contractdrawer .MuiDrawer-paper {
    width: 53%;
    margin: 0 auto;
}

.contractdrawer .heading {
    text-align: center;
    position: sticky;
    top: 0;
}

.contractdrawer .btn-grp {
    margin: 10px auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contractdrawer .btn-grp .conts {
    padding: 17px;
    margin: 2px;
}

.contractdrawer .drawerCloseIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
}

.personalInfoFV span.chipss {
    margin: 0 3px;
}

.user-activity {
    margin: 20px;
    border-radius: 10px;
}

.user-activity .ua-data-block {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-activity .ua-data-block button {
    margin: 0 0 0 auto;
}

.user-activity .ua-data-block .actionBtn {
    text-decoration: none !important;
}

.user-activity:nth-child(2n - 1) {
    background-color: #7ecbfff0;
    padding: 10px;
    border: 3px solid #3498db;
}

.user-activity .heading {
    display: flex;
    text-transform: capitalize;
}


/* .user-activity:nth-child(2n - 1) .heading{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #0093f7;
    color: white;
    font-weight: 500;
    font-size: 17px;
}

.user-activity:nth-child(2n) .heading{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #f2cf67;
    color: black;
    font-weight: 500;
    font-size: 17px;

} */

.user-activity:nth-child(2n) {
    background-color: rgb(255 237 182);
    padding: 10px;
    border: 3px solid #f2cf66;
}

.adminlisttable_v2 tbody tr td span.training-green,
.adminlisttable_v2 tbody tr td span.training-red {
    border-radius: 25px;
    color: #fff;
    display: block;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    width: 80px;
}

.traing-percentage-number-color {
    border-radius: 25px;
    color: #fff;
    display: block;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    width: 80px;
}

.adminlisttable_v2 tbody tr td span.training-red {
    background: #d70101;
}

.adminlisttable_v2 tbody tr td span.training-yellow {
    background: #e3ab10;
}

.adminlisttable_v2 tbody tr td span.training-green {
    background: #a0a945;
}

.configRoundRobinModal .feildContainer {
    display: flex;
    flex-direction: row;
}

.ReportIssueModal .feildContainer {
    display: flex;
    flex-direction: column;
}

.ReportIssueModal .feildContainer .textF {
    padding: 8px;
}

.training-block-per-user .TS-block {
    background: #e1e1e1;
    margin: 20px 0;
}

.training-block-per-user .TS-block {
    background: #e1e1e1;
    margin: 20px 0;
}

.training-block-per-user .TS-block .progressBarNew {
    width: 48%;
}

.training-block-per-user .TS-block .progressBarNew span {
    width: 100%;
}

.Fv-tags {
    list-style: none;
    margin: 13px 0;
    position: relative;
}

.Fv-tags li {
    position: relative;
    margin: 10px 0;
}

.training-block-per-user .total-percentage {
    padding: 10px;
    /* position: sticky; */
    top: 0px;
    background: white;
}

.qIcon {
    cursor: pointer;
    font-size: 19px !important;
    background: #e7c14f;
    color: black;
    padding: 2px;
    border-radius: 25px;
    position: relative;
    left: -26px;
    top: -11px;
}

.stageChipB {
    position: relative;
}

.blocks .stageChipB:first-child .MuiChip-root {
    background: #f2cf66;
}

.folder_view_con .stageChipB::after {
    content: "";
    width: 20px;
    height: 12px;
    background: #7a7259;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
}

.folder_view_con .stageChipB:last-of-type:after {
    display: none;
}

.tagsBlck {
    position: relative;
}

.chipDesBuble {
    display: none;
    transform: translatey(0px);
    animation: float 5s ease-in-out infinite;
    /* mix-blend-mode: multiply; */
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    /* letter-spacing: 3px; */
    /* font-size: 15px; */
    color: #000000;
    background-color: #f2cf66;
    border: 3px solid #d1b358;
    padding: 15px;
    border-radius: 11px;
    position: absolute;
    top: -190px;
    margin: 10px;
    /* box-shadow: 20px 20px #83af9b; */
    font-family: "Baloo 2", cursive;
    /* border: 1px solid #c8c8a9; */
    z-index: 999;
    width: 200px;
    font-size: 16px;
}

.blocks .chipDesBuble {
    position: absolute;
    left: 0;
}

.forLife_Cycle .chipDesBuble {
    top: -80px;
}

.forLife_Cycle .tagChipwrp:nth-child(1) .chipDesBuble {
    top: 0;
    animation: none;
    padding: 5px 10px;
}

.forLife_Cycle .tagChipwrp:last-child .chipDesBuble {
    top: -45px;
    animation: none;
    padding: 5px 15px;
}

.chipDesBuble:after {
    transform: translatey(0px);
    animation: float2 5s ease-in-out infinite;
    content: ".";
    font-weight: bold;
    -webkit-text-stroke: 0.5px #e7c14f;
    -webkit-text-fill-color: #e7c14f;
    /* // border: 1px solid #c8c8a9; */
    text-shadow: 22px 22px #e7c14f;
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: #3498db;
    position: relative;
    display: block;
    bottom: -30px;
    left: 0;
    box-shadow: 22px 22px #e7c14f;
    z-index: -2;
}

.forLife_Cycle .tagChipwrp:nth-child(1) .chipDesBuble:after,
.forLife_Cycle .tagChipwrp:last-child .chipDesBuble::after {
    display: none;
}

.qIcon:hover+.chipDesBuble {
    display: block;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes float2 {
    0% {
        line-height: 30px;
        transform: translatey(0px);
    }
    55% {
        transform: translatey(-20px);
    }
    60% {
        line-height: 10px;
    }
    100% {
        line-height: 30px;
        transform: translatey(0px);
    }
}


/* --------------------- */


/* /-----------------modal css-------/ */


/* .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 15px !important;
}

.MuiDialog-paper {
    border: 10px solid #0569ad;
}

.MuiDialogTitle-root {
    padding: 20px 80px !important;
}

.MuiDialogActions-root button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f!important;
    color: #fff!important;
    font-size: 20px;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 20px;
    text-transform: capitalize;
    background: var(--blue2);
}

.contractDetails .MuiDialog-paper p {
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
}

.MuiDialogActions-root button:hover {
    background-color: var(--deep-blue);
}

.notes_main_header {
    padding: 0 20px !important;
    margin-top: 20px !important;
}

.notes_main_header p {
    margin-bottom: 0;
    font-size: 35px;
    color: var(--white);
    padding: 10px 5px;
    background: #003166;
    text-align: center;
}
.notes_details .notes_header p {text-align: center;}

.view_details .MuiDialogActions-root button {
    display: none;
}

.view_details .MuiDialogActions-root {
    padding: 0 !important;
} */

.delete_modal .MuiPaper-root {
    width: 600px !important;
}

.delete_modal .MuiTypography-h6 {
    padding: 0 !important;
}

.delete_modal .action_btn_container {
    display: flex;
}

.delete_modal .notes_header p {
    margin-top: 10px;
}

.muiTable .MuiToolbar-root .search_btn {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
}

.muiTable .MuiToolbar-root .search_btn::after {
    content: "\f002";
}

.muiTable .MuiToolbar-root .search_btn svg {
    display: none;
}


/* ============================Training-Center CSS======================== */

.trngCentr_banner_mainwrapper {
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_trangcntr_bnrbg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/tngCntr_bnr_hmn1.webp);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
    padding: 62px 0;
    position: relative;
}

.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
    content: "";
    position: absolute;
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/bnr_hmn_bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 121px;
    top: 0px;
    right: -18px;
}

.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after {
    content: "";
    position: absolute;
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnr_side3.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 121px;
    right: 36%;
    top: 0;
    transform: translateX(-9%);
}

.trngCentr_banner_blockwrap {
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/text_bnr_Mbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 62%;
    border-radius: 20px 0 0 20px;
    position: relative;
}

.trngCentr_block_subwrap {
    /* background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/alf_subbnr.png); */
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_new_BGtrng.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 0 0 20px;
    width: 91%;
    position: relative;
}

.trngCentr_block_subwrap::after {
    content: "";
    position: absolute;
    /* clip-path: polygon(100% 0, 100% 23%, 0 100%, 0 77%); */
    /* background-color: #3befa1;  */
    height: 100%;
    width: 123px;
    right: -27px;
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_inner_sidecolor.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 0;
}

.paralgrm1 {
    position: absolute;
    bottom: -62px;
    left: -8px;
    z-index: 9;
}

.paralgrm2 {
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-8%);
    z-index: 9;
}

.paralgrm3 {
    position: absolute;
    right: -2%;
    top: 27%;
    transform: translate(-7%, -21%);
    z-index: 9;
}

.paralgrm4 {
    position: absolute;
    top: -11px;
    right: 9%;
    z-index: 9;
}

.paralgrm5 {
    position: absolute;
    bottom: -62px;
    right: 24%;
    z-index: 9;
}

.trngCentr_banner_mainwrapper .trngCentr_block_text {
    padding: 38px 45px;
    /* background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnrM.png); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 0 0 20px;
    /* clip-path: polygon(6% 0%, 92% 0, 81% 100%, 0 101%, 0 55%); */
    /* background-color: #fff; */
}

.trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-weight: var(--main-font-SemiBold);
    font-size: 39px;
    line-height: 47px;
    color: #000;
    width: 91%;
}

.trainingCenter_main .commonWidth_training {
    width: 1764px;
    margin: 0 auto;
}

.modal_titlenew {
    margin-bottom: 15px;
}

@media only screen and (max-width: 1900px) {
    .trainingCenter_main .commonWidth_training {
        width: 96%;
    }
}

@media only screen and (max-width: 1840px) {
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
        background-position: right -60px bottom;
    }
}

@media only screen and (max-width: 1780px) {
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 30px;
        line-height: 39px;
    }
    /* .trngCentr_banner_mainwrapper .trngCentr_block_text{
        clip-path: polygon(6% 0%, 92% 0, 81% 126%, 0 101%, 0 55%);
    } */
    .trngCentr_block_subwrap {
        width: 86%;
    }
    .trngCentr_block_subwrap::after {
        width: 108px;
        right: -23px;
    }
}

@media only screen and (max-width: 1620px) {
    .trngCentr_banner_blockwrap {
        background-position: right 46px bottom 0;
        width: 61%;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 27px;
        line-height: 38px;
    }
    .trngCentr_block_subwrap {
        width: 94%;
    }
    .trngCentr_block_subwrap::after {
        width: 88px;
        right: -11px;
    }
}

@media only screen and (max-width: 1600px) {
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
        background-position: right -135px bottom;
    }
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after {
        right: 30%;
        transform: translateX(0%);
    }
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
        background-position: right -167px bottom;
    }
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
        right: -53px;
    }
}

@media only screen and (max-width: 1550px) {
    .trngCentr_block_subwrap::after {
        width: 104px;
        right: -11px;
    }
}

@media only screen and (max-width: 1470px) {
    .trngCentr_block_subwrap {
        width: 97%;
        background-position: right 61px top;
    }
    .trngCentr_banner_blockwrap {
        background-position: right 0px bottom 0;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        width: 74%;
    }
    .paralgrm3 {
        right: 3%;
        transform: translate(-58%, -21%);
    }
    .paralgrm4 {
        right: 22%;
    }
    .paralgrm5 {
        right: 37%;
    }
    .trngCentr_block_subwrap::after {
        right: 46px;
        width: 91px;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 22px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 1280px) {
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 20px;
        line-height: 28px;
    }
    .trngCentr_block_subwrap::after {
        right: 51px;
        width: 87px;
    }
}

@media only screen and (max-width: 1100px) {
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
        background: none;
    }
    .trngCentr_banner_blockwrap {
        width: 100%;
    }
    .trngCentr_block_subwrap {
        width: 100%;
        background-position: right 0px top;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text {
        padding: 42px 54px;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 27px;
        line-height: 35px;
        width: 94%;
    }
    .paralgrm3,
    .trngCentr_block_subwrap::after {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .paralgrm1,
    .paralgrm2,
    .paralgrm4,
    .paralgrm5,
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after,
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
        display: none;
    }
    .trngCentr_block_subwrap {
        background-size: 100% 100%;
    }
    .trngCentr_banner_blockwrap {
        background-size: 100% auto;
    }
    .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
        padding: 30px 0;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text {
        padding: 30px 34px;
    }
}

@media only screen and (max-width: 600px) {
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        font-size: 20px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 450px) {
    .trngCentr_block_subwrap {
        background: none;
        background-color: #fff;
        border-radius: 10px;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text {
        padding: 15px;
    }
    .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
        width: 100%;
    }
}

.forprviewform .submitbtnsection {
    display: none;
}

.form_preview {
    padding-top: 15px;
    padding-bottom: 15px;
}

.forprviewform {
    text-align: start !important;
    display: block !important;
}

.forprviewform .css-1nrlq1o-MuiFormControl-root {
    margin: 15px 0;
}


/* .forprviewform .previewfield input {
  padding: 10px ;
} */

.previewfield .css-dmmspl-MuiFormGroup-root {
    display: block !important;
}

.previewfield_modal {
    /* padding: 40px !important; */
    width: 600px;
}

.previewfield_modal .datamodalwrper {
    height: 200px;
    overflow-y: scroll;
}

.common-modalbox .mat-dialog-container {
    background: #f1fff1;
}


/* .quiz-container .heading {
    text-align: center;
    /* background-color: #3a24ad; 
    color: #fff;
    text-shadow: 1px 1px 5px #888;
    margin-bottom: 10px;
    background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
} */

.quiz-container .modal-btns {
    /* background-color: #3a24ad;
  color: #fff;
  margin-right: 10px; */
    color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    padding: 1px 25px;
    background: linear-gradient(180deg, #00c8f5 15%, #1799b0 81%);
}

.quiz-container .btns-wrap {
    margin-top: 15px;
    text-align: center;
}

.quiz-container .question {
    font-weight: bold;
}

.quiz-container .option-wrap input {
    margin-right: 5px;
}

.quiz-container .mat-progress-bar {
    margin-top: 15px;
}

.groupview_modal {
    padding: 30px !important;
    overflow-y: scroll;
    height: 400px;
}

.folder_view_con div p span .chip_block {
    padding: 0;
    font-size: inherit;
    max-width: 100%;
}


/* /-------------css by subhashis-----------/ */

.affiliatemodal .BoxMui_modal {
    /* width: 1500px; */
    width: 900px;
    max-width: 98%;
    position: relative;
}

.affiliatemodal .package_blocks .MuiFormControl-root {
    margin: 5px;
    width: 100%;
}

.affiliatemodal .package_blocks .MuiFormControl-root .MuiInputLabel-shrink {
    background: #fff;
}

.affiliatemodal .package_blocks .MuiTextField-root {
    width: 100%;
}

.affiliatemodal .package_blocks {
    margin: 0;
}

.affiliatemodal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.affiliatemodal h2 {
    color: #fff;
    background: #0569ad;
    font-size: 30px;
    font-weight: 600;
}

.affiliatemodal .btnSection button {
    display: block;
    margin: 0 auto;
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
    width: 150px;
}

.outer_width_wrp {
    width: 820px !important;
    max-width: 94%;
}

.outer_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e6e6e6;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.modal_inner_wrp {
    width: 70%;
}

.bold_text {
    font-weight: 600;
}

.right_details {
    margin-left: 20px;
}

.slug_container {
    font-size: 30px;
    color: #fff;
    background: #003166;
    text-align: center;
    font-weight: 600;
    padding: 10px;
}

.scroll_wrp {
    height: 400px;
    overflow-y: scroll;
}

@media only screen and (max-width: 1699px) {
    .total_bill_mt li .total_bill_mt_text {
        align-items: center;
        display: flex;
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 1399px) {
    .listNavigate .toogleBtns {
        width: 50%;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1199px) {
    .total_bill_mt {
        width: 600px;
        max-width: 100%;
    }
    .toogleBtns {
        justify-content: center;
    }
    .total_bill_mt {
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 10px !important;
    }
    .listNavigate .toogleBtns,
    .listNavigate .total_bill_mt {
        width: 100%;
    }
    .listNavigate {
        flex-direction: column;
    }
    .total_bill_mt li .total_bill_mt_text,
    .total_bill_mt li .total_bill_mt_amount {
        text-align: center;
        width: auto !important;
    }
    .total_bill_mt {
        width: 600px !important;
        max-width: 100%;
    }
}

@media only screen and (max-width: 499px) {
    .SD_affiliate_cls {
        max-height: 400px;
        overflow-y: auto;
    }
    .outer_flex {
        flex-direction: column;
    }
    .modal_inner_wrp {
        width: 100%;
    }
    .outer_flex button {
        margin-top: 10px;
    }
    .BoxMui_modalPage {
        width: 90%;
    }
    .quiz-container {
        min-width: 90%;
    }
}


/* -------------CIF Form------------- */

.adminformbody .form_contain_wraper h3 {
    width: 100%;
}


/* .adminformbody .form_contain_wraper .cif {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 60%;
} */

.form_container .cif_contact_form {
    width: 65%;
    margin: 0 auto;
}


/* ---------------------------------- */

.lead_select_button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.lead_select_button .MuiButton-containedPrimary {
    margin: 0 5px;
    font-size: 18px;
}

.lead_select_button .MuiButton-containedPrimary.active {
    background: #f67d24;
}

.download_csv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
}

.download_csv .download_text .download_text_star {
    color: red;
}


/* -----------------show-tag-list-------------------- */

.showTagListwrpr .tagChipwrp,
.showstageListwrpr .tagChipwrp,
.showDispositionMap .tagChipwrp {
    position: relative;
}

.showTagListwrpr .MuiChip-root,
.showstageListwrpr .MuiChip-root,
.showDispositionMap .tagChipwrp .MuiChip-root {
    margin: 10px;
    position: relative;
    margin-bottom: 50px;
    width: 250px;
}

.showDispositionMap .tagChipwrp .MuiPaper-root {
    margin: 10px;
    position: relative;
    margin-bottom: 50px;
    width: 250px;
    width: auto;
}

.showDispositionMap .tagChipwrp .MuiPaper-root .MuiCardContent-root {
    background: #f8f7ed;
    /* background: #fffcdc; */
    /* background: #f67d24; */
    /* color: #fff; */
}

.showTagListwrpr .tagChipwrp:nth-of-type(1) .MuiChip-root,
.showstageListwrpr .tagChipwrp:nth-of-type(1) .MuiChip-root {
    background-color: #7ecbfff0;
}

.showTaglistMap,
.showStagelistMap,
.showDispositionMap .MuiBox-root {
    display: flex;
    flex-direction: column;
}

.showTagListwrpr .tagChipwrp::before,
.showstageListwrpr .tagChipwrp::before,
.showDispositionMap .tagChipwrp::before {
    content: "";
    position: absolute;
    /* background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/Dotted-line1.png); */
    background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/Top_Arrow_Disposition.png);
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    bottom: -5px;
    right: 50%;
    transform: translateX(50%);
    background-size: cover;
}

.showTagListwrpr .tagChipwrp:last-child::before,
.showstageListwrpr .tagChipwrp:last-child::before {
    display: none;
}

.showTagListwrpr .tagChipwrp:last-of-type .MuiChip-root,
.showstageListwrpr .tagChipwrp:last-of-type .MuiChip-root {
    margin-bottom: 0px;
}

.tagChipwrp .qIcon {
    position: absolute;
    right: 54px;
    top: 0;
    left: unset;
}

.largeModal .addedit_wrp .dataform1_wrapper::before,
.largeModal .addedit_wrp .dataform1_wrapper::after {
    display: none;
}

@media only screen and (max-width: 560px) {
    .tagChipwrp .qIcon {
        right: 10px;
    }
    .showTagListwrpr .MuiChip-root,
    .showstageListwrpr .MuiChip-root {
        /* max-width: 200px; */
        width: 90%;
        max-width: unset;
    }
}

@media only screen and (max-width: 480px) {
    .showDispositionMap .tagChipwrp .MuiChip-root {
        width: 300px;
        /* width: auto; */
    }
}

@media only screen and (max-width: 420px) {
    /* .showDispositionMap .tagChipwrp .MuiChip-root{
    width: 210px;
  } */
    .showDispositionMap .tagChipwrp .MuiPaper-root {
        /* width: 210px !important; */
        min-width: auto;
    }
    .showDispositionMap .tagChipwrp .MuiPaper-root .MuiCardContent-root {
        font-size: 14px;
    }
    .showDispositionMap .tagChipwrp .qIcon {
        right: 40px;
    }
}

.modalblock .BoxMui_modal h6 {
    font-size: 18px;
    margin-bottom: 10px;
}

.LeadFolderMain .folder_view_con .leadlistcon_wrapper {
    overflow: hidden;
}

.LeadFolderMain .folder_view_con .leadlistcon_wrapper .leadlistcon {
    word-wrap: break-word;
}

.sendconfirmmail {
    margin-left: auto;
}

.editiconinsucccess {
    cursor: pointer;
}

.modalview_group .nodatafound {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}


/*****************lead add edit form**************/


/* .lead_add_edit_wrapper{
  padding-bottom: 0;
}

.lead_add_edit_wrapper .Formsubmit_button{
  margin-bottom: 25px;
} */


/*----------------lifeCycle MOdal----------------------------------*/

.forLife_Cycle .showTagListwrpr,
.forLife_Cycle .showstageListwrpr {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding: 30px 0;
}

.forLife_Cycle .BoxMui_modal {
    padding: 20px 0 !important;
}


/*****************css by subhashis**************/

.followup_wrap {
    height: 700px;
    overflow-y: scroll;
}

.followup_wrap .MuiPagination-root {
    margin-bottom: 15px;
}

.followup_container {
    width: 900px !important;
    max-width: 90%;
}

.SD_dnote_modal {
    width: 700px !important;
    max-width: 94%;
}

.SD_dnote_modal .MuiPaper-root {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .affiliatemodal .package_blocks {
        flex-wrap: wrap;
    }
    .affiliatemodal .package_blocks .MuiFormControl-root {
        flex: 1 0 33%;
    }
    .package_blocks {
        margin-bottom: 20px !important;
    }
    .package_blocks:last-child {
        margin-bottom: 0px;
    }
    .affiliatemodal h2 {
        font-size: 25px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 560px) {
    .affiliatemodal .package_blocks .MuiFormControl-root {
        flex: 1 0 auto;
        margin: 5px 0;
    }
    .followup_wrap .dataform2_wrapper {
        padding: 10px 0;
    }
}

@media only screen and (max-width: 399px) {
    .affiliatemodal h2 {
        font-size: 19px;
        line-height: 24px;
    }
}

.faqCategoryAddEditForm .inputBlock1line .MuiFormControl-root {
    width: 8%;
}

.btnWpperr {
    text-align: center;
    margin-top: 10px;
}

.navigatebtnwrpper {
    text-align: center;
    margin-top: 21px;
}


/*-----contract Send drawer--------------- */


/* ----------------successBlue-COast MOdal------------ */

.successBlucoastMOdal .previewfield .formfild6 .MuiOutlinedInput-root {
    width: 100%;
}

.b2dashboard {
    background: rgb(38, 244, 254);
    background: linear-gradient(180deg, rgba(38, 244, 254, 1) 0%, rgba(75, 219, 254, 1) 50%, rgba(41, 206, 254, 1) 51%, rgba(74, 177, 254, 1) 100%);
    border: solid 1px #fff;
    cursor: pointer;
    border-radius: 10px;
    font-size: 17px;
    color: #00336e;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    margin: 15px auto 0;
    padding: 10px;
    /* text-shadow: 1px 1px 4px rgb(0 0 0 / 70%); */
    display: flex;
    align-items: center;
}

.b2dashboard svg {
    padding-left: 10px;
    height: 40px;
    width: 40px;
    color: #00336e;
}

.blueCoast_Success .content p {
    text-align: left !important;
}

.blueCoast_Success .content h1 {
    text-transform: uppercase;
}

.blueCoast_Success .content h1 span:nth-child(3) {
    text-transform: initial;
}

.associatedUser_Info .property_container_main .property_container .property_label:nth-child(1) {
    color: #f87f07;
}

.associatedUser_Info .property_container_main .property_container {
    border-bottom: solid 1px #ccc;
}

.associatedUser_Info .property_container_main .property_container:last-child {
    border-bottom: none;
}

.backend_addform .inputBlock1line.active_cbox .MuiFormControl-root {
    width: fit-content;
    width: -moz-fit-content;
}

.adminformbody .addAdmin .formInline .inputBlock {
    margin: 10px 0.3%;
}

.addeditleadbulk .AddEditQuiz button {
    display: none;
}


/* ============bluecoast new action icon modal================= */

.blucoastList_specialbtn {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    line-height: 26px;
    padding: 8px 20px;
    text-transform: capitalize;
    background: var(--blue2);
    border: none;
    margin: 15px 10px 0;
    width: 75px;
}

.notesModal_blcoast .blucoastList_specialbtn {
    width: auto;
    cursor: pointer;
}

.specialCommonModal .modalview_group h1,
.specialCommonModal .modalview_group h3 {
    text-align: center;
    font-weight: 500;
}

.specialCommonModal .confirmationBtns {
    display: flex;
    justify-content: center;
}

.bluecoastLead_list .disposition_show span {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bluecoastLead_list .disposition_show span p:nth-of-type(1),
.bluecoastLead_list .operational_disposition_show span p:nth-of-type(1) {
    font-weight: bold;
    width: 100%;
}

.bluecoastLead_list .disposition_show span p:nth-of-type(2) {
    width: 100%;
}

.bluecoastLead_list .disposition_show span {
    flex-direction: column;
}

.notesModal_blcoast {
    z-index: 99 !important;
    overflow: hidden;
    overflow-y: auto;
}

.modal_text_field_cls {
    margin-top: 10px !important;
}

.notesModal_blcoast .Notes_Form textarea {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    border: solid 1px #ccc !important;
    height: 100px !important;
    padding: 10px;
    resize: none;
    outline: none !important;
    font-size: 14px;
    color: #333;
    font-family: "Poppins", sans-serif;
}

.notesModal_blcoast .modalBaseBox {
    width: 950px !important;
    max-width: 90%;
}

.disposition_Modal .modalBaseBox {
    width: 470px !important;
    max-width: 90%;
}

.disposition_Modal .modal_title {
    font-size: 30px;
}

.notesModal_blcoast .rolelist_mainwrp_Table .bluecoastnotes .MuiBox-root {
    padding: 15px;
}

.notesModal_blcoast .checkboxgroup {
    display: flex;
    align-items: center;
}

.notesModal_blcoast .rolelist_mainwrp_Table {
    padding: 0;
}

.All_Noteswrpr .rolelist_mainwrp_Table .MuiTableContainer-root {
    height: 300px;
    padding: 15px;
}

.notesModal_blcoast .rolelist_mainwrp_Table .MuiBox-root {
    padding: 10px;
}

.notesModal_blcoast .innerWrapperfor_notes {
    max-height: 700px;
    overflow: auto;
}

.notesModal_blcoast .rolelist_mainwrp_Table {
    margin: 15px;
}

.notesModal_blcoast .rolelist_mainwrp_Table .bluecoastnotes .MuiTableContainer-root tbody tr td:nth-child(2) {
    /* word-break: break-all; */
    overflow-wrap: anywhere !important;
    white-space: pre-wrap;
}

.notesModal_blcoast .rolelist_mainwrp_Table h3 {
    text-align: center;
}

.notesModal_blcoast .modal_title {
    font-size: 30px;
    padding: 10px 80px;
}

.mngDispotn_modal {
    z-index: 98 !important;
}

.mngDispotn_modal .Formsubmit_button button {
    display: block;
    margin: 20px auto 0 0;
}

.preview_details .modalBaseBox {
    width: 650px;
}

.mngDispotn_modal .modalBaseBox {
    width: 650px;
    max-width: 95%;
}

.dispField {
    margin-bottom: 10px;
}

.topmenu.MuiPopover-root .MuiMenuItem-gutters {
    white-space: normal;
}

.MuiPopover-root .MuiMenuItem-gutters {
    white-space: pre-wrap;
    justify-content: space-between;
}

.aeRep_top .MuiMenu-list .MuiPopover-root .MuiMenuItem-gutters {
    white-space: no-wrap;
}

.manage_affEmp {
    white-space: nowrap !important;
}

.mngDispotn_modal .modal_title {
    font-size: 25px;
}

.mngDispotn_modal .disposition_task .userForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mngDispotn_modal .disposition_task .userForm .form_item {
    width: 49.5%;
}

.mngDispotn_modal .disp_dropDown .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mngDispotn_modal .disp_dropDown .MuiSelect-select .disposition_color_for_dropdown {
    margin-left: 5px;
}

.All_Noteswrpr .rolelist_mainwrp_Table .MuiTableContainer-root tr td:nth-child(4) {
    word-break: break-all;
}


/* .managDistmdl .modalBaseBox{width: 500px !important;} */

.bluecoastLead_list .show_priority {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(7) {
    width: 5% !important;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(6) {
    width: 5% !important;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(9),
.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th.next_steps_view,
.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th.why {
    /* width: 3% !important; */
    width: 200px !important;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Tabl .MuiTableContainer-root .other_info {
    width: 1% !important;
}

.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
    max-width: 200px;
    cursor: pointer;
}

.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.approxgrossreciept {
    max-width: 100px !important;
}


/* .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:last-child{
    max-width: 200px !important;
} */

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root td.last_note .last_table_note {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    border-bottom: 0px solid #000 !important;
    border-right: 0px solid #000 !important;
    border-left: 0px solid #000 !important;
    cursor: pointer;
    /* word-break: break-all; */
    overflow-wrap: anywhere !important;
}


/* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root td:first-of-type{
  border-top: 0px solid #000 !important;
} */


/* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root tr:nth-of-type(1) td.last_note{
  border-top: 0px solid #000 !important;

} */

.bluecoastLead_list .show_priority .showcolor {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: block;
    border: 1px solid rgb(126, 126, 126);
    margin-left: 5px;
}

.bluecoastLead_list .show_priority .Low {
    background-color: rgb(102, 255, 0);
}

.bluecoastLead_list .show_priority .Medium {
    background-color: rgb(250 255 0);
}

.bluecoastLead_list .show_priority .High {
    background-color: rgb(151, 12, 12);
}


/* .bluecoastLead_list .rolelist_mainwrp_Table .MuiTableContainer-root th{width: 8% !important;} */

.bluecoastLead_list .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(1) {
    width: 1% !important;
    display: none;
}

.bluecoastLead_list .rolelist_mainwrp_Table .MuiTableContainer-root td:nth-child(1) {
    display: none;
}


/* .bluecoastLead_list .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(2) {
    width: 1% !important;
} */

.bluecoastLead_list .MuiTableContainer-root {
    overflow-x: inherit;
}

.showDispositionMap .tagChipwrp {
    text-align: center;
    padding: 10px 0;
}

.showDispositionMap .tagChipwrp .qIcon {
    right: 75px;
    top: 5px;
}

.showDispositionMap .tagChipwrp:last-child:before {
    display: none;
}

.showDispositionMap .MuiBox-root {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding: 30px 0;
}

.forLife_Cycle .tagChipwrp:nth-child(1) .chipDesBuble {
    top: -44px;
    animation: none;
    padding: 5px 10px;
}

.showDispositionMap .tagChipwrp:last-child .MuiChip-root {
    margin-bottom: 0 !important;
}

.disposition_Modal .chipDesBuble {
    animation: none;
}

.disposition_Modal .showDispositionMap .MuiBox-root .tagChipwrp:first-child .chipDesBuble {
    top: -30px;
}

.disposition_Modal .chipDesBuble:last-child {
    top: -57px;
}

.disposition_Modal .chipDesBuble:after {
    display: none;
}

.addFormBlueCOast {
    /* width: 1000px;
  max-width: 90%;
  margin: 0 auto; */
    overflow: hidden;
    overflow-y: auto;
}

.addFormBlueCOast .dataform2_wrapper {
    max-height: 600px;
    overflow-y: auto;
}

.addFormBlueCOast .modalBaseBox {
    padding: 20px 20px 0 !important;
    width: 1000px !important;
}

.access_dealMOdal .accessDeal_editModal {
    width: 550px !important;
}

.access_dealMOdal.otherOnly_Info .accessDeal_editModal,
.access_dealMOdal .accessDeal_nextSteps {
    width: 850px !important;
}


/* .addFormBlueCOast .MuiFormLabel-filled, .addFormBlueCOast label.Mui-focused, .addFormBlueCOast legend {
  display: none !important;
} */


/* .addFormBlueCOast label.Mui-focused, */

.addFormBlueCOast .MuiFormLabel-filled,
.addFormBlueCOast .MuiInputLabel-shrink,
.addFormBlueCOast legend {
    display: none !important;
}

.grossreceipts .MuiTextField-root .MuiInputLabel-outlined {
    color: rgba(0, 0, 0, 0.6);
}

.addFormBlueCOast .grossreceipts fieldset {
    top: 0;
}

.addFormBlueCOast .modalBaseBox .dataform2_wrapper .grossreceipts label {
    margin-bottom: 7px;
}

.addFormBlueCOast .textareafild textarea {
    height: 100px !important;
    resize: none;
    font-family: "Poppins", sans-serif;
    padding: 5px;
}

.addFormBlueCOast .dataform2_wrapper h2 {
    margin-bottom: 15px;
}

.addFormBlueCOast .employeeNum,
.addFormBlueCOast .webSite {
    width: 100%;
    margin: 5px .3%;
}

.addFormBlueCOast .employeeNum label,
.addFormBlueCOast .webSite label {
    padding-bottom: 3px;
}

.addFormBlueCOast .employeeNum .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuioutlinedInput-notchedOutline {
    border-color: #000 !important;
}

.blueCoast_Success .content p span {
    word-break: break-all;
}

.bluecoastLead_list .rolelist_mainwrp_Table tr td:nth-child(3),
.bluecoastLead_list .rolelist_mainwrp_Table tr td:nth-child(4),
.bluecoastLead_list .rolelist_mainwrp_Table tr th:nth-child(3),
.bluecoastLead_list .rolelist_mainwrp_Table tr th:nth-child(4),
.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:nth-child(5) {
    /* word-break: break-all; */
    overflow-wrap: anywhere !important;
}

.setTime_Modal .modalBaseBox,
.setPriorityMdl .modalBaseBox {
    width: 500px !important;
    max-width: 90%;
}

.setTime_Modal .modal_title {
    margin-bottom: 15px;
}

.setTime_Modal .Formsubmit_button {
    justify-content: center;
    margin-top: 20px;
}

.setTime_Modal .datetimeform .dateTimePicker .MuiFormControl-root {
    width: 100% !important;
}

.addFormBlueCOast .textareafild .MuiFormControl-root,
.addFormBlueCOast .grossreceipts .MuiFormControl-root,
.addFormBlueCOast .css-13sljp9 {
    width: 100% !important;
}

.form_radio .MuiFormControl-root .MuiFormGroup-root,
.form_radio .css-1h7anqn {
    flex-direction: row !important;
}

.allMeetingMOdal {
    z-index: 99 !important;
}

.Ownership_trnsfer .transfer_owner_notes {
    margin-top: 10px;
}

.Ownership_trnsfer .transfer_owner_notes textarea {
    resize: none;
    width: 100% !important;
    padding: 10px;
}

.allMeetingMOdal .MuiPaper-root {
    background: none;
    box-shadow: none;
}

.allMeetingMOdal .rolelist_mainwrp_Table {
    padding: 15px;
}

.sendEmail_modal .modalview_group p {
    text-align: center;
    font-size: 23px;
    line-height: 28px;
    padding: 0 10px;
    background: none;
    color: #000;
}

.sendEmail_modal .confirmationBtns {
    display: flex;
    justify-content: center;
}

.sendEmail_modal .modalBaseBox {
    padding: 0 !important;
}

.sendEmail_modal .notes_main_header {
    padding: 0 !important;
}

.sendEmail_modal .modalview_group {
    padding: 20px;
}

.sendEmail_modal .modalBaseBox {
    width: 650px !important;
}

.disposition_task {
    position: relative;
}

.disposition_task .Formsubmit_button {
    width: auto;
}

.disposition_task .skip_button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.SentMail_mainModal .rolelist_mainwrp_Table .MuiTableContainer-root th:first-child,
.SentMail_mainModal .rolelist_mainwrp_Table .MuiTableContainer-root td:first-child {
    display: none;
}

.mngDispotn_modal .MuiFormControl-root {
    width: 100% !important;
}

.mngDispotn_modal .css-1ald77x {
    display: none !important;
}

.mngDispotn_modal .inputBlock fieldset legend span {
    display: none !important;
}

.preview_details {
    z-index: 99 !important;
}

.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.index_column {
    width: auto !important;
    max-width: none !important;
    min-width: auto !important;
}

.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root th {
    padding: 4px !important;
}

.rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:last-child,
.rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
    max-width: 170px;
}
.mngDispotn_modal .MuiPopover-root .MuiMenuItem-gutters{
    justify-content: space-between;
}

@media only screen and (max-width: 1750px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        min-width: 80px;
    }
}

@media only screen and (max-width: 1650px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        min-width: 70px;
    }
    .rolelist_mainwrp_Table .MuiTableContainer-root td {
        font-size: 13px !important;
    }
    .rolelist_mainwrp_Table .MuiTableContainer-root th {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 1550px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        min-width: 50px;
    }
    /* .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:last-child {
        max-width: 170px;
    } */
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.ae_name_show,
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.prospect_info {
        min-width: 80px;
    }
}

@media only screen and (max-width:1450px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        max-width: 150px;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:nth-child(5) {
        overflow-wrap: anywhere;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.lost_declined {
        max-width: 70px;
        min-width: 30px;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.approxgrossreciept {
        max-width: 60px !important;
        word-wrap: break-word;
    }
    
}

@media only screen and (max-width: 1400px) {
    /* .bluecoastLead_list .disposition_show span p:nth-of-type(2) {
  width: 55%;
} */
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        max-width: 110px;
    }
}

@media only screen and (max-width:1300px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        max-width: 80px;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:last-child {
        max-width: 113px;
    }
    .bluecoastLead_list .show_priority .showcolor {
        height: 20px;
        width: 20px;
    }
}

@media only screen and (max-width: 1199px) {
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.approxgrossreciept,
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td.lost_declined {
        max-width: none !important;
    }
    .access_dealMOdal.otherOnly_Info .accessDeal_editModal,
    .access_dealMOdal .accessDeal_nextSteps {
    width: 95% !important;
}
}

@media only screen and (max-width: 780px) {
    .rolelist_mainwrp_Table .MuiTableContainer-root td.attendee_details {
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 700px) {
    .addFormBlueCOast .modal_title {
        padding: 15px;
        font-size: 25px;
    }
    .preview_details .modalBaseBox {
        width: 90%;
    }
    .notesModal_blcoast .modal_title {
        font-size: 25px;
        padding: 15px;
    }
    .dataTransfer_modal .MuiBox-root {
        width: 90%;
    }
    .dataTransfer_modal .alertPara2 {
        font-size: 25px;
    }
    .addFormBlueCOast .modalBaseBox {
        width: 95% !important;
        padding: 10px !important;
    }
    .addFormBlueCOast .dataform2_wrapper {
        padding: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .disposition_task .skip_button {
        position: inherit;
    }
    .disposition_task .skip_button button,
    .disposition_task #dispositiontask .Formsubmit_button button {
        margin: 20px auto 0;
    }
    .disposition_task .Formsubmit_button {
        margin: 0 auto;
    }
    .mngDispotn_modal .disposition_task .userForm .form_item {
        width: 100%;
    }
    .addFormBlueCOast .addBlueCstLead .form_item .MuiFormLabel-root div {
        white-space: pre-wrap;
    }
    .mngDispotn_modal .disp_dropDown .MuiSelect-select {
     font-size: 10px;
     padding: 12px 10px;
    }
    .access_dealMOdal .grossreceipts .MuiFormLabel-root {
        font-size: 11px;
    }
}


/* @media only screen and (max-width: 1760px) { 
} */


/* ====================== REP SIGNUP CAMPAIGN================== */

.chosecampainnamewrapper .imgBlock_wrpr {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.chosecampainnamewrapper .imgBlock_wrpr img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.chosecampainnamewrapper .imgBlock_wrpr .showimg label {
    text-align: center;
    display: block;
}

.customLinks_mainwrpr .dashboard_con4 h2 {
    width: 100%;
}

.customLinks_mainwrpr .steps_wrpr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.customLinks_mainwrpr .steps_wrpr div {
    /* background: #07163d; */
    /* width: 31.5%; */
    width: 49.5%;
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 3px;
    /* padding: 10px 10px 10px 30px; */
    min-width: 180px;
    float: left;
    position: relative;
    background-color: #0089b4;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
    cursor: pointer;
    transition-property: width;
    transition-duration: 1s;
    transition-timing-function: linear;
    transition-delay: 0.2s;
    /* clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%); */
}


/* .customLinks_mainwrpr .steps_wrpr div:nth-child(2){
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
}
.customLinks_mainwrpr .steps_wrpr div:last-child{
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
} */


/* .customLinks_mainwrpr .steps_wrpr div:hover{background-color: #2c3f4c;}   */

.customLinks_mainwrpr .steps_wrpr div:nth-child(2),
.customLinks_mainwrpr .steps_wrpr div:nth-child(3) {
    margin-left: 20px;
}

.customLinks_mainwrpr .steps_wrpr div p {
    color: #ffffff;
    padding: 15px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customLinks_mainwrpr .steps_wrpr div p .MuiSvgIcon-root {
    margin-left: 7px;
}

.customLinks_mainwrpr .steps_wrpr div:after,
.customLinks_mainwrpr .steps_wrpr div::before {
    content: " ";
    position: absolute;
    top: 0%;
    right: -13px;
    width: 22px;
    height: 100%;
    background-color: #0089b4;
    /* clip-path: polygon(41% 0, 100% 50%, 40% 100%, 40% 100%, 40% 50%, 40% 0); */
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 40% 100%, 40% 50%, 40% 0);
    z-index: 2;
    transition: border-color 0.2s ease;
}

.customLinks_mainwrpr .steps_wrpr div:nth-child(2):before,
.customLinks_mainwrpr .steps_wrpr div:nth-child(3)::before {
    content: " ";
    position: absolute;
    top: 0%;
    left: -16px;
    width: 22px;
    height: 100%;
    background-color: #0089b4;
    /* clip-path:  polygon(40% 0, 70% 50%, 40% 100%, 40% 100%, 40% 50%, 40% 0); */
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 50% 50%, 0% 0%);
    z-index: 2;
    transition: border-color 0.2s ease;
}

.customLinks_mainwrpr .steps_wrpr div:last-child::after {
    display: none;
}


/* .steps_wrpr div:after,
.steps_wrpr div:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 29px solid transparent;
  border-left: 17px solid #0089b4;
  z-index: 2;
  transition: border-color 0.2s ease;
} */

.customLinks_mainwrpr .steps_wrpr div:hover {
    width: 60%;
}


/* .steps_wrpr div:after:hover, .steps_wrpr div:before:hover{background-color: #2c3f4c;} */

.steps_wrpr div:before {
    right: auto;
    left: 0;
    /* border-left: 17px solid #fff; */
    z-index: 0;
}

.steps_wrpr div:first-child:before {
    border: none;
}

.steps_wrpr div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.steps_wrpr div:last-child:after {
    display: none;
}

.steps_wrpr div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.addRequst_cmpgn {
    text-align: center;
    color: var(--blue2);
    font-weight: 500;
}

.cmpaignModal_Wrpr .modalBaseBox {
    width: 900px;
    max-width: 90%;
    padding: 15px;
}

.cmpaignModal_Wrpr .chosecampainnamewrapper {
    max-height: 700px;
    overflow: auto;
}

.cmpaignModal_Wrpr .chosecampainnamewrapper .showimg {
    width: 49.5%;
    cursor: pointer;
}

.cmpaignModal_Wrpr .chosecampainnamewrapper .showimg:hover {
    border: 4px solid #000;
    border-radius: 4px;
}

.marketing_links_wrpr .links_Header {
    margin-top: 30px;
    background: #ffdcb5;
    width: 100%;
    padding: 15px;
    color: #000000;
    font-size: 25px;
    text-align: center;
    box-shadow: 3px 3px 4px #99999978;
    border-left: solid 5px #ff8704;
}

.customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 div {
    opacity: 0.7;
    border-bottom: 1px solid transparent;
    filter: blur(1px);
}

.customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 div.nousernameadd {
    opacity: 1;
    border-bottom: 1px solid #90e3ff;
    filter: blur(0px) drop-shadow(-22px 29px 15px #0138498f);
    width: 40%;
    z-index: 1;
    transform: scale(1.3);
    left: 105px;
}

.customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 div.nousernameadd:hover {
    width: 34%;
}

.customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 div:hover {
    width: 31.5%;
}

.customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 {
    margin-top: 20px;
}

.customlinksData_text .customlinksData_text_icon.asiconwrapper1 {}

.customlinksData_text .customlinksData_text_icon.asiconwrapper1 span {
    filter: blur(2px);
    opacity: 0.6;
}

.customlinksData_text .customlinksData_text_icon.asiconwrapper1 span.nothasuniqurl {
    filter: blur(0px);
    transform: scale(1.7);
    opacity: 1;
}

.customlinksData_text .customlinksData_text_icon.asiconwrapper1 span.nothasuniqurl span {
    filter: blur(0px);
    box-shadow: 1px 1px 20px #484848;
    opacity: 1;
    border: 1px solid #fff;
}


/* .nousernameadd{
    filter:blur(0px) drop-shadow(-22px 29px 15px #0138498f);
    transform: scale(1.1);
} */

.steps_wrpr div.nousernameadd:first-child:before,
.steps_wrpr div.nousernameadd:nth-child(2)::before {
    border: none;
    height: 2px;
    width: 1%;
    background: #f67d24;
    position: absolute;
    left: 0;
    top: -1px;
    box-shadow: 0 0 15px #f67d24;
    animation: border-animation 3s infinite;
    border-radius: 20px;
}

@keyframes border-animation {
    0% {
        width: 1%;
    }
    50% {
        width: 100%;
    }
    75% {}
}

.MOdallanding13 .listing_heading {
    margin-top: 0;
}

.MOdallanding13 .searchBarHeading {
    margin-bottom: 10px;
}

.MOdallanding13 .rolelist_mainwrp_Table {
    overflow-x: auto;
    max-height: 60vh;
}

.MOdallanding13 .submitbtnsection button {
    background: rgb(197, 8, 35);
    background: linear-gradient(180deg, rgba(197, 8, 35, 1) 0%, rgba(126, 11, 28, 1) 100%);
    border: 1px solid #c50823;
    border-radius: 5px;
    font-size: 19px;
    line-height: 20px;
    color: #fff;
    font-weight: var(--main-font-SemiBold);
    text-transform: uppercase;
    padding: 15px;
    width: auto;
    margin: 0 5px;
}

.MOdallanding13 .submitbtnsection button .material-icons {
    display: none;
}

.MOdallanding13 .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
    width: auto;
}

.MOdallanding13 .modalBaseBox {
    border: 10px solid #c50823 !important;
}

.MOdallanding13 .listing_heading {
    border-left: solid 5px #c50823;
}

.MOdallanding13 .rolelist_mainwrp .css-pjjt8i-MuiToolbar-root .MuiTypography-h6 {
    border-left: solid 5px #c50823;
}

.MOdallanding13 .modal_icon_button {
    background-color: #c50823 !important;
}

.MOdallanding13 .searchMedicineandCategoryList {
    margin: 0 -10px 5px -10px;
    padding: 10px;
    box-shadow: 0 0px 3px #888;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(199, 204, 208, 1) 100%);
}

.MOdallanding13 .searchMedicineandCategoryList .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-u4skj5-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 65px;
    background: #fff;
}


/*-------------*/

.MOdallanding5 .listing_heading {
    margin-top: 0;
}

.MOdallanding5 .searchBarHeading {
    margin-bottom: 10px;
}

.MOdallanding5 .rolelist_mainwrp_Table {
    overflow-x: auto;
    max-height: 60vh;
}

.MOdallanding5 .submitbtnsection button {
    background: rgb(0, 87, 161);
    background: linear-gradient(180deg, rgba(0, 87, 161, 1) 0%, rgba(9, 59, 101, 1) 100%);
    border: 1px solid #0057a1;
    border-radius: 5px;
    font-size: 19px;
    line-height: 20px;
    color: #fff;
    font-weight: var(--main-font-SemiBold);
    text-transform: uppercase;
    padding: 15px;
    width: auto;
    margin: 0 5px;
}

.MOdallanding5 .submitbtnsection button .material-icons {
    display: none;
}

.MOdallanding5 .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
    width: auto;
}

.MOdallanding5 .modalBaseBox {
    border: 10px solid #0057a1 !important;
}

.MOdallanding5 .listing_heading {
    border-left: solid 5px #0057a1;
}

.MOdallanding5 .rolelist_mainwrp .css-pjjt8i-MuiToolbar-root .MuiTypography-h6 {
    border-left: solid 5px #0057a1;
}

.MOdallanding5 .modal_icon_button {
    background-color: #0057a1 !important;
}

.MOdallanding5 .searchMedicineandCategory {
    margin: 0 -10px 5px -10px;
    padding: 10px;
    box-shadow: 0 0px 3px #888;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(199, 204, 208, 1) 100%);
}

.MOdallanding5 .searchMedicineandCategory .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-u4skj5-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 65px;
    background: #fff;
}

.modoal_for_CampaignUrl .MuiDialog-paper {
    width: 620px !important;
}

.modoal_for_CampaignUrl .mainwrpr_unique_url form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.modoal_for_CampaignUrl .domainUrlField {
    width: 50%;
    margin-top: 0px;
    word-break: break-all;
}


/* .modoal_for_CampaignUrl .form_item {
  width: 25%;
} */


/* .modoal_for_CampaignUrl
  .hmmodal_user_Box_wrapper
  .form_item
  .MuiFormControl-root {
  width: 110% !important;
} */

.modoal_for_CampaignUrl .commonText {
    width: 4%;
    margin-top: 18px;
    margin-left: -48px;
}

.modoal_for_CampaignUrl .urlHints {
    margin-top: 10px;
}

.modoal_for_CampaignUrl .urlHints p {
    text-align: left;
    font-size: 15px;
    padding-bottom: 8px;
    /* border-bottom: 1px solid #225287; */
}

.modoal_for_CampaignUrl .urlHints h5 {
    text-align: left;
    font-size: 16px;
    padding-top: 8px;
    color: red;
}

.modoal_for_CampaignUrl .urlHints .HINT_urlwrapper {
    display: flex;
    flex-wrap: wrap;
}

.mainwrpr_unique_url .urlHints span {
    padding: 5px 10px;
    color: #000;
    margin: 2px;
    position: relative;
    padding-left: 20px;
    display: block;
}

.mainwrpr_unique_url .urlHints span::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3c96f7;
}

.modoal_for_CampaignUrl .passwordCls p {
    text-align: left;
}

.modoal_for_CampaignUrl .mainwrpr_unique_url .uniq_URL {
    width: 45%;
}

.modoal_for_CampaignUrl .urlHints h6 {
    font-size: 14px;
}

.modoal_for_CampaignUrl .uniq_URL .MuiInputLabel-shrink {
    display: none !important;
}

.modoal_for_CampaignUrl .uniq_URL legend span {
    display: none !important;
}

.modoal_for_CampaignUrl .uniq_URL .MuiInputLabel-root {
    transform: translate(14px, 12px) scale(1) !important;
}

.creatcampain .urlHints p {
    color: #000;
    font-size: 16px;
}

.creatcampain .urlHints p span {
    font-weight: bold;
}

.LandingBlCst_New .MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;
}

.creatcampain .addCampaign_btn {
    font-size: 16px;
    text-transform: inherit !important;
    color: var(--white);
    background: #003166;
    padding: 5px 10px;
    min-width: 150px;
    display: block;
    font-weight: 600;
    margin: 0 0 0 auto;
}

.creatcampain .icon_details_wrp .MuiButton-root:hover {
    background: #003166 !important;
}

.successAccesssFree .successmodal9 .formWrapperClass,
.successBlucoastMOdal .successmodal9 .formWrapperClass {
    padding: 15px 30px;
}

.copy_urlSnack_modal_Wrpr .modalBaseBox {
    width: 600px;
}

.copy_urlSnack_modal_Wrpr .hmmodal_user_Box_wrapper {
    display: flex;
    justify-content: center;
}

.copy_urlSnack_modal_Wrpr .hmmodal_user_Box_wrapper img {
    width: auto;
    display: block;
}

.copy_urlSnack_modal_Wrpr .icon_details_wrp {
    margin-top: 30px;
}

.marketin_campaign {
    width: 600px !important;
    max-width: 95% !important;
}

.marketin_campaign h2 {
    color: #000;
    text-align: center;
    line-height: 25px;
    font-size: 20px;
}

.marketin_campaign h1 {
    color: #000;
    text-align: center;
    line-height: 30px;
    font-size: 25px;
    background: #0569ad;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.emailExistAlert_modal h2 {
    font-size: 28px;
    color: #000;
    margin-bottom: 0;
}

.emailExistAlert_modal .modal_title {
    padding: 15px 50px;
    font-size: 30px;
}

.emailExistAlert_modal .modal_title span {
    display: block;
    line-height: 40px;
}

.emailExistAlert_modal h3 {
    color: #000;
}

.marketin_campaign_instrctn {
    max-width: 95% !important;
    margin: 0 auto;
    width: 100%;
}

.marketin_campaign_instrctn .Marketing_cmpgn_mainwrpr .socialMedia_post_wrpr li {
    font-size: 24px;
    margin-bottom: 25px;
}


/* .marketin_campaign_instrctn .Marketing_cmpgn_mainwrpr{
  max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding: 25px 40px;
} */

.marketin_campaign_instrctn .landpage_videowrapper {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 25px;
}


/* .marketin_campaign_instrctn h1{color: #000;text-align: center;line-height: 30px;font-size: 25px;background: #0569ad;text-align: center;padding: 10px;font-weight: 600;color: #fff;margin-bottom: 10px;} */


/* .marketin_campaign_instrctn .Marketing_cmpgn_mainwrpr h2{
text-align: center;    font-size: 40px;
font-weight: 600;padding: 20px 0;
} */

.fb_post_header {
    padding: 30px 0;
    border-bottom: 2px solid #000;
    margin-bottom: 20px;
    font-size: 46px;
    font-weight: 600;
    text-align: center;
}

.marketin_campaign_instrctn .facebook_post_details li {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
}

.marketin_campaign_instrctn .facebook_post_details li img {
    max-width: 100%;
    max-height: 100%;
    margin-top: 15px;
    display: block;
}

.marketin_campaign_instrctn .facebook_post_details li a {
    color: #1155cc;
}

.marketin_campaign_instrctn .items_wrpr {
    display: flex;
    flex-direction: column;
}

.marketin_campaign_instrctn .items_wrpr {
    font-size: 26px;
    margin-bottom: 30px;
}

.marketin_campaign_instrctn .items_wrpr p {
    font-weight: bold;
}

.marketin_campaign_instrctn .items_wrpr span {
    padding-left: 40px;
}

@media only screen and (max-width: 1400px) {
    .marketin_campaign_instrctn {
        width: 95%;
    }
}

@media only screen and (max-width: 840px) {
    .marketin_campaign_instrctn .facebook_post_details li {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .marketin_campaign_instrctn .items_wrpr {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .marketin_campaign_instrctn .Marketing_cmpgn_mainwrpr .socialMedia_post_wrpr li {
        font-size: 16px;
    }
    .marketin_campaign_instrctn .landpage_videowrapper {
        width: 95%;
    }
}

@media only screen and (max-width: 640px) {
    /* .marketin_campaign_instrctn {
    padding: 20px;
  } */
    .marketin_campaign_instrctn .Marketing_cmpgn_mainwrpr h2 {
        font-size: 26px;
        margin-bottom: 10px;
        padding: 10px 0;
    }
}

@media only screen and (max-width: 480px) {
    .chosecampainnamewrapper .imgBlock_wrpr {
        flex-direction: column;
    }
    .cmpaignModal_Wrpr .chosecampainnamewrapper .showimg {
        width: 100%;
    }
}


/* ============Click Clount/conversion report============== */

.Count_ReportMOdal .MuiButton-containedPrimary.active {
    background: #f67d24;
}

.Count_ReportMOdal .countModal_headr h3 {
    font-size: 25px;
    line-height: 30px;
}

.Count_ReportMOdal .countModal_headr {
    padding: 15px 30px;
}

.Count_ReportMOdal .click_buttonwp {
    display: flex;
    justify-content: center;
    margin: 0 0 10px;
}

.click_buttonwp button {
    margin: 5px;
}

.Count_ReportMOdal .tableDataConversionDetailsforconversion table thead tr .MuiTableCell-paddingCheckbox,
.Count_ReportMOdal .conversionlistTable table thead tr .MuiTableCell-paddingCheckbox {
    display: none;
}

.Count_ReportMOdal .tableDataConversionDetailsforconversion table tbody tr .td:first-child {
    display: none;
}

.Count_ReportMOdal .rolelist_mainwrp_Table .conversionlistTable .MuiTableContainer-root td:nth-child(1) {
    display: none;
}

.Count_ReportMOdal .countDetail_Title {
    display: flex;
    justify-content: center;
}

.Count_ReportMOdal .countDetail_Title p {
    padding: 5px;
    /* margin-top: 10px; */
    font-weight: 600;
    text-transform: uppercase;
}

.Count_ReportMOdal {
    z-index: 99 !important;
    overflow-y: scroll;
}

.Count_ReportMOdal .daterangepicker_one {
    justify-content: center;
    margin-top: 20px;
}

.Count_ReportMOdal .showchartdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.Count_ReportMOdal .showchartdiv .clickchart,
.Count_ReportMOdal .showchartdiv .conversionchart,
.Count_ReportMOdal .showchartdiv .noDataFound {
    margin: 10px auto;
    width: 45%;
}

@media only screen and (max-width: 1500px) {
    .Count_ReportMOdal .campain_modal_width {
        width: 95%;
        /* max-height: 700px; */
    }
}

@media only screen and (max-width: 1199px) {
    .bluecoastLead_list_mainwrpr .bluecoastLead_list td {
        position: relative !important;
        padding-left: 10px !important;
    }
    .bluecoastLead_list_mainwrpr .bluecoastLead_list td:before {
        position: inherit !important;
        padding-left: 0px !important;
        padding-bottom: 5px !important;
        display: block !important;
    }
    .Count_ReportMOdal .showchartdiv {
        flex-direction: column;
    }
    .Count_ReportMOdal .showchartdiv app-pie-chart {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Count_ReportMOdal .showchartdiv .clickchart,
    .Count_ReportMOdal .showchartdiv .conversionchart,
    .Count_ReportMOdal .showchartdiv .noDataFound {
        width: 95%;
    }
    .Count_ReportMOdal .showchartdiv .pieChrt_Hint {
        text-align: center;
    }
}

@media only screen and (max-width: 900px) {
    .notesModal_blcoast .modalBaseBox {
        width: 90%;
    }
    /* .cmpaignModal_Wrpr .modalBaseBox {
    width: 90%;
  } */
    .modoal_for_CampaignUrl .MuiDialog-paper {
        width: 90% !important;
    }
}

@media only screen and (max-width: 800px) {
    .customLinks_mainwrpr .steps_wrpr div {
        margin: 0;
    }
}

@media only screen and (max-width: 750px) {
    .customLinks_mainwrpr .steps_wrpr {
        flex-direction: column;
    }
    .customLinks_mainwrpr .steps_wrpr div {
        width: 100%;
        margin-bottom: 80px;
        border-radius: 4px;
    }
    .cmpaignModal_Wrpr .modal_title {
        padding: 20px;
        font-size: 25px;
    }
    .customLinks_mainwrpr .steps_wrpr div:last-child {
        margin-bottom: 0;
    }
    .customLinks_mainwrpr .steps_wrpr div::after {
        content: "";
        position: absolute;
        background: url(../src/assets/image/steps_mbl_arrw.png);
        height: 80px;
        width: 80px;
        background-repeat: no-repeat;
        bottom: -85px;
        left: 50%;
        transform: translateX(-50%);
        top: unset;
        background-size: cover;
        border: none !important;
    }
    .steps_wrpr div:before {
        position: inherit;
        border: none;
    }
    .customLinks_mainwrpr .steps_wrpr div:after,
    .customLinks_mainwrpr .steps_wrpr div::before,
    .customLinks_mainwrpr .steps_wrpr div:nth-child(2):before,
    .customLinks_mainwrpr .steps_wrpr div:nth-child(3)::before {
        clip-path: none;
    }
    .customLinks_mainwrpr .steps_wrpr div::before,
    .customLinks_mainwrpr .steps_wrpr div:nth-child(2):before,
    .customLinks_mainwrpr .steps_wrpr div::before,
    .customLinks_mainwrpr .steps_wrpr div:nth-child(3)::before {
        position: inherit;
    }
    .customLinks_mainwrpr .steps_wrpr div:nth-child(2),
    .customLinks_mainwrpr .steps_wrpr div:nth-child(3) {
        margin-left: 0;
    }
    .customLinks_mainwrpr .steps_wrpr.as_btn_wrapper1 div.nousernameadd {
        width: 50%;
        transform: translate(-50%, 0) scale(1.3);
        left: 50%;
    }
    .steps_wrpr div.nousernameadd:first-child:before {
        display: none;
    }
    .customLinks_mainwrpr .steps_wrpr div p {
        font-size: 18px;
        padding: 10px;
    }
    .copy_urlSnack_modal_Wrpr .modalBaseBox {
        width: 90%;
    }
    /* .modoal_for_CampaignUrl .domainUrlField{
    word-break: break-all;
  } */
    .Count_ReportMOdal .COuntSData_mainwrpr .dash_brd_date {
        text-align: center;
    }
}

@media only screen and (max-width: 640px) {
    /* .bluecoastLead_list_mainwrpr .bluecoastLead_list td {
    position: relative !important;
    padding-left: 10px !important;
  }

  .bluecoastLead_list_mainwrpr .bluecoastLead_list td:before {
    position: inherit !important;
    padding-left: 0px !important;
    padding-bottom: 5px !important;
    display: block !important;
  } */
    .customLinks_mainwrpr .steps_wrpr div {
        border-radius: 4px;
    }
    .modoal_for_CampaignUrl .commonText {
        margin-left: 0;
    }
    .copy_urlSnack_modal_Wrpr .hmmodal_user_Box_wrapper img {
        width: 100%;
    }
    .modoal_for_CampaignUrl .hmmodal_user_Box_wrapper .SD_header_flex h4 {
        line-height: 25px;
    }
    .view_details .modal_title {
        font-size: 25px;
        line-height: 27px;
        padding: 15px;
    }
    .view_details .modal_title .previewModalTitle {
        font-size: 25px;
    }
}


/* ============bluecoast new action icon modal================= */

.SD_drawer {
    z-index: 0 !important;
}


/* ---------------------mukesh css----------- */

.MuiPopover-root .css-10hburv-MuiTypography-root {
    word-break: break-all;
    white-space: normal;
}

.loadingforredireect {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(148, 199, 231);
    background: linear-gradient(180deg, rgba(148, 199, 231, 1) 0%, rgba(0, 154, 255, 1) 100%);
}

.loadingforredireect .MuiCircularProgress-root {
    width: 75px !important;
    height: 75px !important;
    color: #e87404 !important;
}

.disablecopy {
    user-select: none;
}


/* mukesh create css */

.overview_class {
    z-index: 9999 !important;
}

.SD_modal_cls .modalBaseBox {
    width: 700px;
    max-width: 90%;
}

.SD_modal_cls .modalBaseBox .btn-grp {
    margin-top: 20px;
}

.SD_modal_cls .modalBaseBox .btn-grp button {
    margin: 0 auto;
    margin-top: 20px;
    display: block;
}

.SD_modal_cls .modalBaseBox .cont-body {
    height: 400px;
    overflow-y: scroll;
    margin-top: 20px;
}

.SD_modal_cls .modalBaseBox .heading h2 {
    text-align: center;
}

.assign_hm_bdd_field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
}

.MK_modal_wrap .ChipsSet-grp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.MK_modal_wrap .MuiStack-root {
    display: block;
}

.assign_hm_bdd_field .chips {
    width: 300px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.assign_hm_bdd_field .assign_bdd_field {
    margin-top: 40px;
}

.assign_hm_bdd_field .assign_hm_field .chips {
    margin-top: 10px;
}

.assign_hm_bdd_details .associate_BDD_details {
    margin: 20px 0;
}

.attechment_preview_mt .listflexblock li:last-child {
    display: block;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
    word-break: break-word;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
}

.attechment_preview_mt .listflexblock li:last-child img {
    width: 100%;
}

.total_bill_mt {
    list-style: none;
    /* width: 34%; */
    margin: 0 auto;
}

.total_bill_mt li {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    /* border: 1px solid grey; */
}

.total_bill_mt li .total_bill_mt_text {
    background-color: #116ec9;
    font-size: 20px;
    color: white;
    padding: 10px;
    font-weight: 600;
    width: fit-content;
}

.total_bill_mt li .total_bill_mt_amount {
    background-color: #ff4500;
    font-size: 20px;
    color: white;
    padding: 10px;
    font-weight: 600;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment_modal_mt .modalBaseBox {
    width: 1500px !important;
    max-width: 90%;
}

.blue_cost_btn_mt {
    display: flex;
}

.opp_btn_mt {
    margin-right: 20px !important;
}

.op_modal_mt .css-1p5q5e5-MuiStack-root {
    margin-bottom: 10px;
}


/* ----------css by subhashis----------- */


/* -------------------businesslead-list css ------------------- */

.SD_rolelist_mainwrp_Table .MuiTable-root .prospecttitle {
    width: 90px;
}

@media only screen and (max-width: 1599px) {
    .SD_rolelist_mainwrp_Table .MuiTable-root .MuiTableCell-root:last-child {
        width: 146px !important;
        padding: 4px 3px !important;
    }
    .SD_rolelist_mainwrp_Table .MuiTable-root .ae_name_show,
    .SD_rolelist_mainwrp_Table .MuiTable-root .prospect_info {
        width: 90px !important;
    }
}

@media only screen and (max-width: 1399px) {
    /* .SD_rolelist_mainwrp_Table .MuiTable-root .MuiTableCell-root:last-child {
  width: 70px !important;
} */
    /* .rolelist_mainwrp_Table .MuiTableContainer-root td {
        font-size: 13px !important;
    }
    .rolelist_mainwrp_Table .MuiTableContainer-root th {
        font-size: 13px !important;
    } */
    .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:nth-child(12) {
        width: 90px !important;
    }
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(9){
  width: 150px !important;
} */
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(9) {
        width: 115px !important;
    } */
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root .last_note {
        width: 130px !important;
        padding: 4px 2px !important;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root .last_note .last_table_note {
        width: 115px !important;
    }
    .SD_rolelist_mainwrp_Table .MuiTable-root .MuiTableCell-root .listing_btn_class {
        width: 131px !important;
    }
    .access_dealWrpr .MuiTable-root .MuiTableCell-root:last-child {
        width: 120px !important;
        max-width: 120px !important;
        padding: 4px 3px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .SD_rolelist_mainwrp_Table .MuiTable-root .ae_name_show,
    .SD_rolelist_mainwrp_Table .MuiTable-root .prospect_info,
    .SD_rolelist_mainwrp_Table .MuiTable-root .prospecttitle,
    .SD_rolelist_mainwrp_Table .MuiTable-root .MuiTableCell-root:last-child {
        width: auto !important;
    }
    .rolelist_mainwrp_Table .MuiTableContainer-root td {
        font-size: 16px !important;
    }
    .rolelist_mainwrp_Table .MuiTableContainer-root th {
        font-size: 16px !important;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(9) {
        width: auto !important;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root .last_note {
        width: auto !important;
        padding: 4px 8px !important;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .MuiTableContainer-root .last_note .last_table_note {
        width: auto !important;
    }
    .SD_rolelist_mainwrp_Table .MuiTable-root .MuiTableCell-root .listing_btn_class {
        width: auto !important;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td {
        max-width: none;
    }
    .rolelist_mainwrp_Table.access_dealWrpr .MuiTableContainer-root td:last-child {
        max-width: none !important;
    }
}


/* .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
 width: auto !important; 
} */


/* .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child .listing_btn_class {
  max-width: 120px !important;
} */

.customlinksData_select .MuiInputBase-root {
    width: 100%;
}

.SD_input_field .MuiFormControl-root,
.SD_input_field {
    width: 100%;
}

.SD_header_flex h4 {
    display: block;
    padding: 10px;
    text-align: center;
}

.hmmodal_user_Box_wrapper .SD_header_flex h4 button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.pharsavings_table_cls {
    display: flex;
    padding: 2px 10px;
    align-items: flex-start;
    margin: 5px 0;
}

.icon_details_wrp {
    padding: 10px 20px;
    background: #00316626;
}

.pharsavings_table_cls div {
    width: 170px;
    font-weight: 600;
    color: #044d9d;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.pharsavings_table_cls p {
    width: 70%;
    font-size: 14px;
    font-weight: 500;
}

.pharsavings_table_cls div svg {
    width: 27px;
    height: 27px;
    font-size: 25px;
    padding: 4px;
    background: #fff;
    border-radius: 50%;
    margin-right: 10px;
}

.icon_details_wrp h1 {
    font-size: 26px;
    /* margin-bottom: 10px; */
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .icon_details_wrp {
        padding: 15px 2px;
    }
    .icon_details_wrp h1 {
        font-size: 24px;
        margin-bottom: 5px;
    }
    .mngDispotn_modal .modal_title {
        padding: 15px 10px;
    }
}

@media only screen and (max-width: 599px) {
    .pharsavings_table_cls div {
        width: 160px;
        font-size: 13px;
    }
    .pharsavings_table_cls p {
        width: 78%;
        font-size: 13px;
        margin-left: 10px;
    }
    .hmmodal_user_Box_wrapper .SD_header_flex h4 span {
        font-size: 20px;
        line-height: 26px;
    }
}

.SD_MOdallanding .modalBaseBox {
    width: 1400px;
    max-width: 95%;
}

.SD_MOdallanding14 .modalBaseBox {
    width: 700px;
    background: linear-gradient(180deg, rgba(9, 34, 87, 1) 47%, rgba(20, 59, 114, 1) 91%);
    max-width: 90%;
}

.rolelist_mainwrp h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.SD_MOdallanding14 .formwrapper {
    background: transparent !important;
    padding: 0;
    margin-bottom: 0;
    box-shadow: none;
    outline: none !important;
    border: none !important;
    padding: 0 !important;
}

.SD_MOdallanding14 .rolelist_mainwrp .formfild input {
    padding: 12px 14px !important;
    background-color: #fff !important;
    font-weight: var(--main-font-Bold);
    color: #303232 !important;
    font-size: 18px;
}

.SD_MOdallanding14 .form_style .formWrapperClass {
    padding: 15px 0;
}

.SD_MOdallanding14 .landingpage13_frm_main_wrapper .form_title_wrapper {
    background: #0069b1;
}

.SD_MOdallanding14 .l2submitbtnsection button {
    padding: 10px;
    font-size: 27px;
    line-height: 34px;
    background: url(https://d37pbainmkhx6o.cloudfront.net/landing-page5/phar_submit_btn.webp);
    background-size: cover;
    border: 2px solid #487594;
}

.SD_MOdallanding14 .form_style .formfild {
    margin-bottom: 12px !important;
}

.SD_MOdallanding14 .l2submitbtnsection {
    margin-top: 40px;
}

.SD_MOdallanding14 .MuiModal-backdrop {
    background-color: rgb(0 0 0 / 73%);
}

.SD_MOdallanding14 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SD_MOdallanding14 .state .MuiSelect-select {
    background: #fff !important;
    padding: 12px 14px !important;
    border-radius: inherit;
    font-weight: var(--main-font-Bold);
    color: #303232 !important;
    font-size: 18px;
}

.SD_MOdallanding14 .landing-banner-right {
    width: auto;
    margin-left: 0;
}

.somethingwentwrong {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.creatcampain .MuiDialog-paper {
    width: 920px !important;
}

@media only screen and (max-width: 540px) {
    .SD_MOdallanding .submitbtnsection {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
    }
    .SD_MOdallanding .submitbtnsection button {
        margin: 5px 0;
        display: block;
        width: 200px;
        max-width: 100%;
    }
    .preview_details .data_block .property_container:nth-of-type(2) .property_value:nth-child(2),
    .preview_details .data_block .property_container:nth-of-type(7) .property_value:nth-child(2) {
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 499px) {
    .SD_MOdallanding .modalBaseBox {
        padding: 10px;
    }
    .SD_MOdallanding .modalBaseBox .rolelist_mainwrp_Table {
        padding: 0;
        margin: 0;
    }
    .SD_MOdallanding .listing_heading h1 {
        font-size: 20px;
        line-height: 25px;
    }
    .SD_MOdallanding14 .l2submitbtnsection button {
        font-size: 20px;
        line-height: 26px;
    }
    .SD_MOdallanding14 .landingpage13_frm_main_wrapper .form_title_wrapper {
        padding: 12px;
    }
    .SD_MOdallanding14 .landingpage13_frm_main_wrapper .form_title_wrapper h2 span {
        font-size: 24px;
        line-height: 30px;
    }
}

.cursorpointer {
    cursor: pointer;
}

.cmpaignModal_Wrpr {
    z-index: 9 !important;
    overflow: hidden;
    overflow-y: auto;
}


/*****Css Copy Url modal List*/


/* .copy_url_campaign .MuiTableContainer-root{
  height: 300px;
  overflow-y: scroll;
  overflow-x: auto;
} */

.cmpaignModal_Wrpr .modalBaseBox {
    padding-bottom: 0;
}

.statusinput input[type='checkbox'] {
    display: none;
}

.copy_url_modal_Wrpr {
    z-index: 9 !important;
}

.copy_url_modal_Wrpr .modalBaseBox {
    width: 1200px;
    height: 700px;
}

.copy_url_modal_Wrpr .copy_url_campaign {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
}


/* .copy_url_modal_Wrpr .copy_url_campaign .rolelist_mainwrp_Table{
  height: 300px;
} */


/* .copy_url_modal_Wrpr  .rolelist_mainwrp .MuiTableContainer-root{
  height: 300px;
  overflow-y: auto;
} */


/* .copy_url_modal_Wrpr .copy_url_campaign {
  height: auto;
  max-height: 400px;
  overflow-y: auto;
} */

@media only screen and (max-width: 1300px) {
    .copy_url_modal_Wrpr .modalBaseBox {
        width: 80%;
    }
}


/* -------------souvik  chakraborty css-------------- */

@media only screen and (max-width: 1199px) {
    .MuiPopover-root {
        width: 90%;
        margin: 0 auto;
    }
    .MuiPopover-root .MuiMenu-paper {
        min-width: inherit !important;
    }
}

.SD_bluecoast_preview .previewModalTitle {
    font-size: 35px;
}


/* .SD_bluecoast_preview .MuiButton-root {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
} */

.SD_bluecoast_preview .icon_wrppr_preiew {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.SD_bluecoast_preview .icon_wrppr_preiew .modalBTN {
    box-shadow: none !important;
    background: none !important;
}

@media only screen and (max-width: 540px) {
    .SD_bluecoast_preview .icon_wrppr_preiew {
        position: inherit;
        transform: translateY(0%);
    }
}

.SD_bluecoast_preview {
    position: relative;
}

.SD_bluecoast_preview .MuiButton-root svg {
    color: #fff;
}

.pdfdownload {
    width: 850px !important;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-8000px);
    margin: 0 auto;
    padding: 10 px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


/* #template1{
  width: 650px !important;
  height: 890px !important;
  text-align: center;
} */


/* .pdfdownload {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.pdfdownload .modal_title {
    border-bottom: 4px solid #000;
    font-size: 25px;
    background: transparent;
    color: #000;
    line-height: 25px;
    padding: 0;
}

.COuntSData_mainwrpr {
    max-height: 680px;
    overflow: hidden;
    overflow-y: auto;
}

.pdfdownload .property_container .property_value {
    margin-left: 30px;
    width: 55%;
}

.pdfdownload .property_container {
    margin: 5px 0;
    display: flex;
}

.pdfdownload .property_container label {
    display: block;
    font-size: 14px;
    line-height: 14px;
    word-break: break-word;
}

.pdfdownload .property_label {
    width: 50%;
}

.pdfdownload .data_containerscroll {
    height: auto;
}

.COuntSData_mainwrpr .norecord {
    text-align: center;
}

.visiblehiddenclas {
    visibility: hidden;
}

.displaynonecls {
    display: none;
}

.creatcampain .pixelTrack textarea {
    height: 130px !important;
    overflow-y: auto !important;
}

.header4piechrt {
    text-align: center;
    margin-top: 10px;
}

.header4piechrt h3 {
    background: #ffdcb5;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    border-radius: 4px;
}

.creatcampain .passwordCls p {
    color: #000;
}

.showchartdiv .pieChrt_Hint {
    font-size: 14px;
}

.creatcampain .for_exmpl {
    font-size: 13px;
    color: #0000ff;
}


/* .creatcampain .pixelHint{
   padding-left: 20px;
 } */

.creatcampain .pixelHint p {
    line-height: 20px;
    color: #0000ff;
}

.creatcampain .pixelHint {
    margin-bottom: 5px !important;
}

.creatcampain .pixelTrack {
    margin-bottom: 0px !important;
}

.gotoadmindashbbtn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.gotoadmindashbbtn button .MuiSvgIcon-root {
    margin-left: 10px;
}

.gotoadmindashbbtn button {
    background-color: #1976d2;
    color: #ffffff;
}

.gotoadmindashbbtn .MuiButton-root:hover {
    background-color: #1976d2 !important
}

.daterangepicker_one .gotoadmindashbbtn {
    margin-left: 20px;
    margin-top: 0;
}

@media only screen and (max-width: 1500px) {
    .SR_ClassModal .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
        width: 30% !important;
    }
}

@media only screen and (max-width: 480px) {
    .pdfdownload {
        max-width: 100% !important;
        width: auto !important;
    }
}

@media only screen and (max-width: 360px) {
    .SD_bluecoast_preview .MuiButton-root {
        right: -13px;
    }
}


/* ===========BlueCoast Lead list & outside data lsit============ */

.bluecoastLead_list_mainwrpr .click_buttonwp {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    /* flex-wrap: wrap; */
}


/* .bluecoastLead_list_mainwrpr .navigationMain div{
  width: 31.5%;
} */

.bluecoastLead_list_mainwrpr .click_buttonwp .MuiButton-root.active {
    background: #f67d24;
}

.sr_toggle_btn .bluecoastLead_list_mainwrpr .click_buttonwp {
    margin: 0px 0 25px !important;
}

.sr_toggle_btn {
    overflow: hidden;
}

.sr_toggle_btn .modalBaseBox .rolelist_mainwrp .rolelist_mainwrp_Table .MuiPaper-elevation {
    overflow: hidden;
    overflow-y: auto;
    max-height: 300px;
}

.sr_toggle_btn .rolelist_mainwrp_Table {
    overflow-x: unset;
    max-height: none;
}


/* .sr_toggle_btn {
  z-index: 999999 !important;
} */

@media only screen and (max-width: 1450px) {
    .bluecoastLead_list_mainwrpr .navigationMain div button p {
        font-size: 11px;
    }
    .click_buttonwp button {
        padding: 6px;
    }
}

@media only screen and (max-width: 1152px) {
    .bluecoastLead_list_mainwrpr .navigationMain {
        flex-direction: column;
        margin-top: 0px;
    }
    .bluecoastLead_list_mainwrpr .navigationMain div button {
        width: 98%;
    }
    .bluecoastLead_list_mainwrpr .click_buttonwp button {
        margin: 5px auto;
        display: block;
    }
    .bluecoastLead_list_mainwrpr .navigationMain div button p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 580px) {
    .bluecoastLead_list_mainwrpr .click_buttonwp {
        flex-direction: column;
        margin-top: 0px;
    }
}


/* -----------------tag modal css by subhashis--------------- */

.MuiButtonBase-root .MuiListItemText-root {
    white-space: pre-wrap;
}

.SD_modalblock .BoxMui_modal {
    width: 900px;
    max-width: 90%;
    overflow: inherit;
    height: auto;
}

.SD_modalblock .BoxMui_modal .dataform2_wrapper {
    height: 400px;
    overflow-y: scroll;
}

.SD_marketing_modal .marketin_campaign {
    width: 700px;
    max-width: 95%;
}

.formmm_new_hidden {
    display: none;
}

.modalBaseBox .MuiInputLabel-shrink {
    background: #fff;
}


/* ***************Landing Page15 Options********* */

.landingPage15options_Wrpr {
    position: relative;
    background: rgb(130, 251, 251);
    background: linear-gradient(180deg, rgba(130, 251, 251, 1) 47%, rgba(91, 186, 252, 1) 100%);
    min-height: 100vh;
}

.lp15Togglebtn_Wrpr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.lp15Togglebtn_Wrpr img {
    width: 70%;
    display: block;
}

.lp15Togglebtn_Wrpr .btnsWrpr {
    border-radius: 4px;
    background: #fff;
    width: 45%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: -2px 9px 9px 3px rgb(0 0 0 / 49%);
    cursor: pointer;
    padding: 60px 0;
}

.lp15Togglebtn_Wrpr .btnsWrpr:hover {
    box-shadow: -2px 9px 9px 3px rgb(0 0 0 / 70%);
}

.landingPage15options_Wrpr .optionTitle {
    /* background-color: #021e3e; */
    width: 100%;
    /* margin: 0 auto; */
}

.landingPage15options_Wrpr .optionTitle h3 {
    padding: 20px 0;
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    background: #062b4e;
    color: #ffffff;
    border-radius: 4px;
    margin-bottom: 20px;
}

.SD_comming_modal_wrp {
    width: 400px;
    max-width: 96%;
}

.SD_comming_modal_wrp h1 {
    text-align: center;
}

.newstypeforpharmecy {
    width: 55%;
    margin: 0 auto;
    color: #f5f5f5;
    margin-top: 20px;
}

.afiliat_comition_configaration_btn {
    display: none !important;
}


/* *****************QR COde Modal Css********** */

.qr_modal_class canvas {
    display: flex;
    margin: 0 auto;
}

.qr_modal_class .qr_code_download_button {
    display: flex;
    justify-content: center;
}


/* ----------------------w9 modal & NDA modal css------------------- */

.SD_w9_modal .formSection {
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

.w9_top_right h2 {
    padding: 0 !important;
}

.SD_w9_modal .configure-role {
    width: 1600px;
    max-width: 96%;
    margin: 15px auto;
}

.SD_w9_modal .w9_common_width {
    width: 100%;
}

.SD_w9_modal .MuiTypography-root .SD_w9_modal .configure-role .MuiTypography-root {
    color: #fff !important;
    background: #0569ad !important;
}

@media only screen and (max-width: 1040px) {
    .SD_w9_modal .NDAContract_commnwidth {
        width: 100%;
    }
    .SD_w9_modal .NDAContract_header h3 {
        font-size: 30px;
        line-height: 36px;
    }
}

@media only screen and (max-width: 767px) {
    .SD_w9_modal .formSection {
        height: 300px;
    }
}

@media only screen and (max-width: 499px) {
    .SD_w9_modal .NDAContract_header h3 {
        font-size: 24px;
        line-height: 28px;
    }
}


/* *********************Notes View List****************** */

.NotesViewList_main .rolelist_mainwrp_Table {
    border-radius: 8px !important;
    box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15) !important;
}

.NotesViewList_main .rolelist_mainwrp_Table table th {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.NotesViewList_main .rolelist_mainwrp_Table table td {
    border: solid 1px #ccc !important;
    padding: 4px 8px;
    text-align: left;
    font-size: 16px;
}


/* MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root */


/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root */

.disposition_task .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
}

.disposition_task .MuiFormControl-root fieldset legend span {
    display: none !important;
}

@media only screen and (max-width: 1199.98px) {
    .NotesViewList_main .rolelist_mainwrp_Table table td {
        position: relative !important;
        padding-left: 10px !important;
    }
}

@media only screen and (max-width: 480px) {
    .NotesViewList_main .rolelist_mainwrp_Table table tbody tr {
        word-wrap: break-word;
    }
}


/* ===========================Sent MAil to AEs=================== */


/* .SentMAil_ModalWrpr{
  max-width: 70%;
} */

.SentMail_mainModal {
    z-index: 99 !important;
    overflow: hidden !important;
    overflow: auto !important;
}

.SentMail_mainModal .modalBaseBox {
    width: 50%;
    /* max-height: 700px; */
}

.dispositionCOunt .search_btn {
    display: none !important;
}

.dispositionCOunt .stackPagination {
    display: none;
}

.AEs_name_header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.AEs_name_header h2 {
    font-size: 22px;
    color: #000;
}

.SentMailAEs .modalBaseBox {
    /* width: 850px; */
    max-width: 45%;
}

.SentMailAEs .property_container {
    flex-direction: column;
}

.SentMailAEs .property_container .property_label {
    width: 100%;
}

.SentMailAEs .property_container .property_value {
    width: 100%;
}

.SentMail_mainModal .rolelist_mainwrp_Table {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.SentMailAEs .modalBaseBox .view_details .data_containerscroll {
    max-height: 65vh !important;
}

.SentMailAEs .view_details .modal_title h1 {
    font-size: 35px;
}

.SentMail_mainModal .modal_title {
    padding: 20px;
}

@media only screen and (max-width: 1199px) {
    .SentMail_mainModal .modalBaseBox {
        width: 90%;
    }
    .AEs_name_header {
        flex-direction: column;
    }
    .AEs_name_header h2 {
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 800px) {
    .SentMailAEs .property_container .property_value span div:first-of-type {
        max-width: 100%;
    }
    .SentMailAEs .property_container .property_value span div img {
        max-width: 100%;
    }
    .SentMailAEs .property_container .property_value span div p {
        word-wrap: break-word;
    }
    .SentMailAEs .view_details .modal_title {
        padding: 10px;
    }
    .SentMailAEs .previewModalTitle {
        font-size: 25px;
    }
    .SentMail_mainModal .modal_title {
        padding: 10px;
        font-size: 25px;
    }
    .SentMailAEs .modalBaseBox {
        max-width: 90%;
    }
    .SentMailAEs .view_details .modal_title h1 {
        font-size: 25px;
    }
}


/* =====================commission manual entry======================= */

.commission_ManualENtry_wrpr .previewfield {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.commission_ManualENtry_wrpr .form_item {
    width: 32.5%;
}

.commission_ManualENtry_wrpr .dateTimePicker {
    width: 100%;
}

.planType .MuiFormControl-root {
    margin: 0;
    width: 100% !important;
}

.commission_ManualENtry_wrpr .css-13sljp9 {
    width: 100% !important;
}

.commission_ManualENtry_wrpr .Formsubmit_button {
    margin-top: 20px;
}

.commission_ManualENtry_wrpr .MuiFormControl-root fieldset legend span,
.commission_ManualENtry_wrpr .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
}

.reCaptcha .MuiFormControl-root .captchaField {
    display: flex;
    justify-content: center;
}

.reCaptcha .captchaErrorCls {
    margin: -4px auto 0;
    z-index: 1;
    color: #ee2a39 !important;
    background-color: transparent;
    font-weight: 600;
}


/* @media only screen and (max-width: 1199px){
.reCaptcha .captchaErrorCls{
  color: #ee2a39 !important;
  background-color: transparent;
  font-weight: 600;
}
} */


/***************Email campaign approver*****/

.email_camp_aspire tbody .MuiTableCell-root {
    word-break: break-all;
}

.email_camp_aspire .rolelist_mainwrp_Table .MuiTableContainer-root th:nth-child(2) {
    width: 3% !important;
}

.sd_affiliate_comm .data_set li {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.sd_affiliate_comm .MuiPaper-root {
    width: 1000px !important;
    max-width: 90%;
}

@media only screen and (max-width: 1316px) {
    .sd_affiliate_comm .MuiPaper-root {
        margin: 20px auto !important;
        max-width: 100%;
    }
    .sd_affiliate_comm {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 1199px) {
    .sd_affiliate_comm .data_set li {
        font-size: 17px;
        line-height: 23px;
    }
    .sd_affiliate_comm ul {
        width: 30% !important;
    }
}

@media only screen and (max-width: 991px) {
    /* .sd_affiliate_comm ul {
    width: 48% !important;
  } */
}

@media only screen and (max-width: 679px) {
    .sd_affiliate_comm ul {
        width: 100% !important;
    }
}

.Landingpage19_Footer_block1 .linked_btn {
    margin-top: 20px;
}

.lp14_btn {
    background: linear-gradient(180deg, #bff906, #46c836);
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 9px 1px #000;
    color: #0a3a05;
    font-size: 24px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 50px;
    max-width: 100%;
    padding: 20px 40px;
    height: auto;
    text-transform: uppercase !important;
}

.SD_btn {
    background: #015bf3 !important;
}

.custom_red_btn {
    background: #c50823 !important;
    border-radius: 0;
    border: none;
    width: fit-content;
    text-align: center;
    padding: 20px;
    display: block;
    font-size: 28px;
    line-height: 35px;
    color: #fff;
    font-weight: 700;
    margin-top: 15px;
    text-transform: capitalize;
}

.linked_btn {
    text-decoration: none;
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.custom_btn {
    font-size: 26px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px 40px;
}

.cST {
    text-transform: capitalize;
}


/* ======================Blue Coast Leads List Css MOdification=================================== */

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata {
    position: relative;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular {
    position: absolute;
    right: 35px;
}


/* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular button{
  padding: 9px 8px 6px !important;
}
.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular button:nth-of-type(3){
  padding: 14px 8px 6px !important;
}
.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular button:nth-of-type(2){
  padding: 8px !important;
} */

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .listCount {
    padding-top: 10px;
    margin-top: 10px;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .rolelist_mainwrp .stackPagination {
    padding: 15px;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table {
    padding: 15px 25px;
    margin-top: 25px;
    position: relative;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
    position: absolute;
    /* top: 15px; */
    top: 18px;
    right: 25px;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt .addrole_btn {
    background: var(--blue2);
    color: #fff;
    text-decoration: none;
    font-size: 40px;
    padding: 10px;
    display: block;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    cursor: pointer;
}

.bluecoastLead_list_mainwrpr .bluecoastLead_list .listing_heading {
    display: none;
}

.bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    cursor: pointer;
    z-index: 99;
}

.bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn button {
    margin: 5px;
}

.bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn button.active {
    background: #f67d24;
}

.All_Task_List .addTask_btn {
    position: absolute;
    right: 25px;
    background: var(--blue2);
    color: #fff;
    text-decoration: none;
    font-size: 40px;
    padding: 10px;
    display: block;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    cursor: pointer;
}

.All_Task_List .MuiToolbar-regular {
    margin-right: 35px !important;
    margin-bottom: 25px !important;
}

.addedittaskform .previewfield .form_item:nth-of-type(2) textarea {
    height: 100px !important;
    resize: none;
    padding: 10px;
    font-family: "Poppins", sans-serif;
}

.addedittaskform .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
}

.addtaskListMOdal .addedittaskform .form_item {
    margin-bottom: 10px;
}

.addtaskListMOdal .addedittaskform .form_item .MuiFormLabel-root {
    margin-bottom: 5px;
}

.addtaskListMOdal .Formsubmit_button {
    margin-bottom: 20px;
}

.addtaskListMOdal .taskMOdal_wrp {
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
}

.addtaskListMOdal .targetDate .MuiFormLabel-root {
    white-space: pre-wrap;
}

.addtaskListMOdal .targetDate .MuiOutlinedInput-input {
    padding: 22px 14px;
}

.bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .All_Tasklist_main_wrpr .MuiTableContainer-root th:nth-child(7) {
    width: 8% !important;
}

.addtaskListMOdal .modalBaseBox {
    width: 700px !important;
    max-width: 95%;
}

.SR_addTask_list .dispositiontask .previewfield {
    display: flex;
    flex-wrap: wrap;
}

.SR_addTask_list .dispositiontask .form_item {
    flex: 1 0 47.5%;
    margin: 5px;
}

.SR_addTask_list .dispositiontask .previewfield .Formsubmit_button {
    width: 100%;
}

.addtaskListMOdal .modalBaseBox .dispositiontask .form_item .MuiFormControl-root {
    width: 100%;
}

.addtaskListMOdal .prospectID .MuiFormControl-root {
    width: 100%;
    margin: 0;
}

.addtaskListMOdal .taskMOdal_wrp .backendTableInline_addform {
    display: flex;
    flex-wrap: wrap;
}

.addtaskListMOdal .taskMOdal_wrp .backendTableInline_addform .form_item2 {
    flex: 1 0 47.5%;
    margin: 5px;
}

.addtaskListMOdal .taskMOdal_wrp .backendTableInline_addform .formfild4 {
    width: 100%;
}

.SR_addTask_list .modalBaseBox .disposition_task .dispositiontask .taskLabel .MuiFormControl-root .MuiInputLabel-shrink {
    display: none !important;
}

.SR_UserDetail_preview .BoxMui_modal {
    padding: 20px !important;
}

.TaskStatusChange .MuiBox-root {
    max-width: 95%;
}

.maintaskList_modal {
    overflow: hidden !important;
    overflow-y: auto !important;
    z-index: 99 !important;
}

.prospect_Details .formfild2 {
    width: 49.5% !important;
}

.addBusinessMngr .addeditbusinessmanagerform .notes textarea {
    resize: none;
    height: 100px !important;
    padding: 10px;
}

.addBusinessMngr .addeditbusinessmanagerform .notes .Mui-focused {
    color: #000;
}

.addBusinessMngr .addeditbusinessmanagerform .MuiOutlinedInput-notchedOutline {
    top: 0px !important;
}

.keywordSearch {
    width: 40%;
    position: absolute;
    z-index: 98;
}

.keywordSearch .MuiBox-root .MuiPaper-root {
    width: 75%;
}

.keywordSearch .KeywordSerach_Main .mainKeywrd_search .MuiFormLabel-root,
.keywordSearch .KeywordSerach_Main .dispositionKey_search .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px;
}

.keywordSearch .KeywordSerach_Main .mainKeywrd_search,
.keywordSearch .KeywordSerach_Main .dispositionKey_search {
    width: 40%;
    margin: 5px;
}

.keywordSearch .KeywordSerach_Main .mainKeywrd_search .MuiOutlinedInput-notchedOutline,
.keywordSearch .KeywordSerach_Main .dispositionKey_search .MuiOutlinedInput-notchedOutline {
    top: 0px !important;
}

.KeywordSerach_Main .mainKeywrd_search .MuiInputBase-input,
.KeywordSerach_Main .dispositionKey_search .MuiInputBase-root .MuiSelect-select {
    padding: 10.5px 12px;
    font-size: 13px;
}


/* .keywordSearch .KeywordSerach_Main .dispositionKey_search .MuiFormControl-root .MuiFormLabel-root, .keywordSearch .KeywordSerach_Main .dispositionKey_search .MuiFormControl-root .MuiFormLabel-root{
  top: 5px;
} */

.keywordSearch .KeywordSerach_Main .mainKeywrd_search .MuiInputLabel-shrink,
.keywordSearch .KeywordSerach_Main .dispositionKey_search .MuiInputLabel-shrink {
    top: 3px;
}

.keywordSearch .KeywordSerach_Main .mainKeywrd_search .MuiInputBase-colorPrimary .MuiOutlinedInput-input {
    min-height: 25px;
}

.KeywordSerach_Main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons_keyword {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

.buttons_keyword button {
    min-width: 40px;
    color: #757575;
}

.BusinessManager_modal .rolelist_mainwrp table {
    min-width: auto !important;
}

.BusinessManager_modal .modalBaseBox {
    width: 950px !important;
    max-width: 95%;
}

.BusinessManager_modal .modal_title {
    font-size: 30px;
    padding: 10px 80px;
}


/* .BusinessManager_modal .rolelist_mainwrp_Table .MuiTableContainer-root th:first-child, .BusinessManager_modal .rolelist_mainwrp_Table .MuiTableContainer-root td:first-child{
  display: block !important;
} */

@media only screen and (max-width: 1599px) {
    .keywordSearch .KeywordSerach_Main .mainKeywrd_search,
    .keywordSearch .KeywordSerach_Main .dispositionKey_search {
        width: 42%;
    }
    .KeywordSerach_Main .mainKeywrd_search .MuiInputBase-input,
    .KeywordSerach_Main .dispositionKey_search .MuiInputBase-root .MuiSelect-select {
        padding: 10.5px 5px;
    }
    .keywordSearch {
        width: 44%;
    }
}

@media only screen and (max-width: 1400px) {
    .dispositionKey_search .MuiInputBase-formControl .MuiSelect-icon {
        right: 0px;
    }
    .keywordSearch {
        width: 43%;
    }
}

@media only screen and (max-width: 1368px) {
    .keywordSearch {
        display: block;
        position: inherit;
        width: 70%;
        margin: 0 auto;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        /* top: 70px; */
        top: 73px;
    }
    .KeywordSerach_Main .mainKeywrd_search .MuiInputBase-input,
    .KeywordSerach_Main .dispositionKey_search .MuiInputBase-root .MuiSelect-select {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1199px) {
    .keywordSearch {
        display: block;
        width: 90%;
        margin: 0 auto;
        position: relative;
    }
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
    top: 65px;
  } */
}

@media only screen and (max-width: 999.98px) {
    .maintaskList_modal .All_Task_List .MuiToolbar-regular {
        margin-right: 60px !important;
    }
}

@media only screen and (max-width: 900px) {
    .All_Task_List .MuiToolbar-regular {
        margin-bottom: 35px !important;
    }
    /* .bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn{
  width: 100%;
} */
}

@media only screen and (max-width: 840px) {
    .All_Tasklist_main_wrpr {
        display: flex;
        flex-direction: column;
    }
    .bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn {
        top: 65px;
    }
    .rolelist_mainwrp .All_Task_List .alltaskdata .MuiTableContainer-root {
        margin-top: 95px;
    }
}

@media only screen and (max-width: 768px) {
    .keywordSearch .KeywordSerach_Main {
        flex-wrap: wrap;
    }
    .keywordSearch .KeywordSerach_Main .mainKeywrd_search,
    .keywordSearch .KeywordSerach_Main .dispositionKey_search {
        width: 48%;
    }
    .buttons_keyword {
        width: 100%;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        top: 74px;
        /* top: 105px; */
    }
    .keywordSearch {
        width: 98%;
    }
}

@media only screen and (max-width: 700px) {
    .bluecoastLead_list_mainwrpr .All_Task_List .All_task_btn button {
        font-size: 12px;
    }
    .BusinessManager_modal .modal_title {
        font-size: 25px;
        padding: 15px;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular {
        right: 50%;
        transform: translate(50%);
    }
}

@media only screen and (max-width: 610px) {
    .keywordSearch .KeywordSerach_Main .mainKeywrd_search,
    .keywordSearch .KeywordSerach_Main .dispositionKey_search {
        width: 100%;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        top: 166px;
        top: 126px;
    }
}

@media only screen and (max-width: 500px) {
    .property_container .property_value {
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 440px) {
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        top: 166px;
    }
}

@media only screen and (max-width: 400px) {
    .bluecoastLead_list_mainwrpr .bluecoastLead_list .MuiPaper-elevation {
        margin-top: 15px;
    }
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular {
    right: 0;
  } */
    /* .rolelist_mainwrp .All_Task_List .alltaskdata .MuiTableContainer-root{
  margin-top: 65px;
} */
    .bluecoastLead_list_mainwrpr .bluecoastLead_list .All_Tasklist_main_wrpr .MuiPaper-elevation {
        margin-top: 0 !important;
    }
    .bmSearch .MuiFormLabel-root {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        top: 183px;
        /* top: 142px;
    right: 23px; */
    }
}

@media only screen and (max-width: 380px) {
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table {
        padding: 15px 10px;
    }
    .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .blue_cost_btn_mt {
        /* top: 174px; */
        /* top: 142px; */
        right: 15px;
    }
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular{
  right: 40px;
} */
    /* .bluecoastLead_list_mainwrpr .rolelist_mainwrp_Table .bluecoastdata .MuiToolbar-regular {
  right: 61%;
  transform: translate(50%);
} */
}


/* ======================Blue Coast Leads List Css MOdification=================================== */

.last_note_modal {
    overflow: hidden !important;
    overflow-y: auto !important;
}

.last_note_modal .modalBaseBox {
    width: 750px !important;
}

.last_note_modal .Notes_last {
    max-height: 450px !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.forEmployerThanks {
    background-color: rgb(245 245 245) !important;
    width: 100%;
    min-height: 100vh;
    transition: ease all 0.3s;
    position: relative;
    overflow: hidden;
}

.contentEmployer {
    width: 80%;
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
}

.contentEmployer .headerLogoEmployer {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentEmployer .headerLogoEmployer img {
    width: 100%;
    object-fit: contain;
}

.contentEmployer h2 {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
}

.contentEmployer h3 {
    font-size: 35px;
    text-align: center;
    font-weight: 600;
}

.contentEmployer h4 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    font-style: italic;
    color: #9f9f9f;
}

.contentEmployer p {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.forEmployerThanks .MuiGrid-root {
    padding-left: 0px !important;
    display: block;
    margin: 0 auto;
    width: 100%;
}

.forEmployerThanks .error-page h1 {
    font-size: 140px;
    /* background: rgb(25,171,255);
  background: linear-gradient(180deg, rgba(25,171,255,1) 0%, rgb(125 182 134) 36%, rgb(255 126 0) 100%); */
    background: rgb(25, 171, 255);
    background: linear-gradient(180deg, rgba(25, 171, 255, 1) 0%, rgba(139, 195, 148, 1) 25%, rgba(252, 126, 3, 1) 48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 4px 6px black);
}

@media only screen and (max-width: 900px) {
    .contentEmployer .headerLogoEmployer {
        width: auto;
    }
}

@media only screen and (max-width: 450px) {
    .contentEmployer h2 {
        font-size: 43px;
    }
    .contentEmployer h3 {
        font-size: 30px;
    }
    .contentEmployer h4 {
        font-size: 22px;
    }
    .contentEmployer p {
        font-size: 15px;
    }
    .forEmployerThanks .MuiGrid-root {
        padding-top: 0px !important;
    }
    .noLinks .contentEmployer h2 {
        font-size: 33px;
    }
    .noLinks .contentEmployer h3 {
        font-size: 20px;
    }
}

.contractFormMOdal {
    overflow: hidden;
    overflow-y: auto;
}

.contractFormMOdal .modalBaseBox {
    width: 906px;
    max-width: 95%;
}


/* .contractFormMOdal.SentMail_mainModal .rolelist_mainwrp_Table .MuiTableContainer-root th:first-child, .contractFormMOdal.SentMail_mainModal .rolelist_mainwrp_Table .MuiTableContainer-root td:first-child {
  display: block !important;
} */

.contractFormMOdal .blucoastList_specialbtn {
    width: auto;
    margin: 15px 0 0;
}

.contractFormMOdal .NDA5thSec-right .property_value span {
    /* border-bottom: 1ps solid #000; */
    padding: 0;
    padding-bottom: 1px;
    padding-left: 3px;
    font-Family: "Mr Dafoe", cursive !important;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 18px;
    outline: none;
}

.contractFormMOdal .NDA5thSectionForm {
    align-items: center;
}

.contractFormMOdal .NDA5thSec-right .nameEMployer {
    padding-left: 3px;
}

.contractFormMOdal .NDAForm_prefields {
    margin: 0 0 0 auto;
    width: 50%;
}

.NDAForm_prefields .property_container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.NDAForm_prefields .property_container .property_label {
    line-height: 18px;
    font-weight: 500;
    /* margin-bottom: 5px; */
    margin-right: 10px;
    width: auto;
}

.NDAForm_prefields .property_container .property_value {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 16px;
    outline: none;
    padding: 0;
    padding-bottom: 1px;
    padding-left: 3px;
}

.bluecoastLead_list_mainwrpr .skeleton_container {
    margin-top: 60px;
}

.SR_UserDetail_preview .listflexblock .contractForm_icon span button {
    margin-left: -20px;
}

.SR_UserDetail_preview .notes_main_header .listflexblock li:nth-child(2) a,
.SR_UserDetail_preview .notes_main_header .listflexblock li:nth-child(3) a {
    text-decoration: none;
    color: #000;
}

.SR_UserDetail_preview .disabled .blucoastList_specialbtn {
    color: #ffffff40 !important;
}

.contractFormMOdal .rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {
    width: auto;
}

.contractFormMOdal.SentMail_mainModal .rolelist_mainwrp_Table {
    height: 500px;
}

.contractFormMOdal .NDAForm_checking {
    margin-top: 40px;
}

.contractFormMOdal .NDAForm_checking p {
    text-align: center;
    font-size: 25px;
    line-height: 25px;
}

.contractFormMOdal .NDAForm_checking .confirmationBtns {
    display: flex;
    justify-content: center;
}

.contractFormMOdal .NDAForm_checking .blucoastList_specialbtn {
    margin: 15px 5px 0;
}

@media only screen and (max-width: 580px) {
    .contractFormMOdal .NDAForm_prefields {
        margin: 0 auto;
        width: 98%;
    }
    .NDAContract_mainwrpr .NDAContract_header h3 {
        font-size: 26px;
    }
    .NDAContract_mainwrpr p {
        font-size: 14px;
        line-height: 16px;
    }
    .NDAContract_mainwrpr .NDA5thSec-left h {
        font-size: 16px;
    }
}


/* ===================Employer DashBoard================= */

.Add_Location_modal {
    overflow: hidden;
    overflow-y: auto;
}

.Add_Location_modal .MuiFormControl-root {
    width: 100% !important;
}

.Add_Location_modal .modalBaseBox .MuiInputLabel-shrink {
    display: none !important;
}


/* .add_Employee_Modal .addEmployeeTable .formfild4 .MuiFormControl-root{
  width: 48.5% !important;
} */

.add_Employee_Modal {
    overflow: hidden;
    overflow-y: auto;
}

.add_Employee_Modal.addtaskListMOdal .modalBaseBox {
    width: 950px !important;
    max-width: 95%;
}

.employee_Count .rolelist_mainwrp_Table .MuiPaper-root {
    box-shadow: none;
}

.employee_Count .rolelist_mainwrp_Table .MuiToolbar-root {
    padding-left: 0px;
    padding-right: 0px;
}

@media only screen and (max-width: 580px) {
    .add_Employee_Modal .dataform2_wrapper {
        padding: 0;
    }
}

.Modal_for_Prospect .rolelist_mainwrp table,
.Modal_for_Prospect .rolelist_mainwrp tbody,
.Modal_for_Prospect .rolelist_mainwrp td,
.Modal_for_Prospect .rolelist_mainwrp tr {
    display: block !important;
    width: 100% !important;
    min-width: inherit!important;
}

.Modal_for_Prospect .rolelist_mainwrp tr {
    margin: 10px 0;
    border: solid 1px #ccc;
}

.Modal_for_Prospect .rolelist_mainwrp_Table .MuiTableContainer-root td {
    font-size: 16px !important;
}

.Modal_for_Prospect .rolelist_mainwrp tr:first-of-type {
    margin-top: 0px;
}

.Modal_for_Prospect .rolelist_mainwrp td {
    position: relative;
    padding-left: 10px!important;
}

.Modal_for_Prospect .rolelist_mainwrp thead {
    display: none;
}

.Modal_for_Prospect .rolelist_mainwrp td:before {
    content: attr(title);
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-weight: bold;
}

.Modal_for_Prospect .rolelist_mainwrp td:before {
    position: inherit;
    padding-left: 0px;
    padding-bottom: 5px;
    display: block;
}

.totalCost_table .total_bill_mt {
    margin-top: 20px;
}

.previewSubLogin {
    overflow: hidden !important;
    overflow-y: auto !important;
}

.previewSubLogin .modalview_group .data_block .property_container:nth-of-type(5) .property_value:nth-child(2) {
    overflow: hidden;
    overflow-y: auto;
    max-height: 100px;
    /* padding: 5px; */
}

.previewSubLogin .modalview_group .data_block .property_container:nth-of-type(5) .property_value:nth-child(2) .MuiChip-root {
    margin: 5px;
}

.addlContactDtls_mainwrpr {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.addlContactDtls_mainwrpr .MuiCardContent-root {
    background: #f8f7ed;
    height: 100%;
}

.addlContactDtls_mainwrpr .MuiCardContent-root p {
    font-size: 16px;
}

.addlContactDtls_mainwrpr button:nth-child(2) {
    margin-top: 35px;
}

.addAdditional_contact .modal_title {
    padding: 0 10px;
}

.addlContactDtls {
    flex: 1 0 25%;
    margin: 10px;
    overflow: unset !important;
}

.statusCheck {
    display: block !important;
}

.SubmitEmploy {
    display: block !important;
    width: 100% !important;
}

.locationDataEmployer .MuiFormControl-root .MuiInputBase-root .MuiBox-root {
    overflow: hidden;
    overflow-y: auto;
    max-height: 70px;
    margin-right: 15px;
}

.formCls .statusCheck .MuiFormControl-root .MuiFormControlLabel {
    max-width: 10% !important;
}

.package_fields_details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.package_fields_details .MuiFormControl-root {
    margin: 5px;
}

.package_modal_commission {
    overflow: hidden;
    overflow-y: auto;
}

.package_modal_commission .modalBaseBox {
    width: 850px;
}

.confirmationModal_trfc {
    overflow: hidden;
    overflow-y: auto;
}

.confirmationModal_trfc .modalBaseBox {
    width: 550px;
    max-width: 95%;
    /* padding: 0px !important; */
}

.confirmationModal_trfc .modalBaseBox .dataform2_wrapper {
    margin-bottom: 0px;
}

.confirmationModal_trfc .confirmationBtns {
    display: flex;
    justify-content: center;
}

.confirmationModal_trfc .otherForm_mainWrpr {
    text-align: center;
}

.highlighted_guides span {
    font-size: 15px;
    color: black;
    background: yellow;
}

.LandingPage9Body .highlighted_guides span {
    color: #ffec00;
    background: none;
}

.EmployeeManage_pakcage .listing_btn_class .modal_icon_button {
    background-color: #0569ad!important;
    border-radius: 50%!important;
    height: 30px;
    opacity: 1;
    padding: 5px!important;
    width: 30px;
    position: inherit !important;
}

.EmployeeManage_pakcage .listing_btn_class .modal_icon_button {
    background-color: #ffffff!important;
}

.EmployeeManage_pakcage .listing_btn_class .modal_icon_button svg {
    fill: #757575 !important
}

@media only screen and (max-width: 800px) {
    .addAdditional_contact .modal_title h4 {
        font-size: 25px;
    }
    .LandingPage9Body .highlighted_guides span {
        font-size: 12px;
        line-height: 18px;
    }
}

.otp_veriifcation form .MuiFormControl-root .MuiOutlinedInput-input {
    text-align: center !important;
}

.otp_veriifcation form p {
    margin-top: 10px;
}

.otp_veriifcation form .MuiFormControl-root {
    margin-top: 5px !important;
}

.userMnageMnetBcl .MuiPaper-root {
    box-shadow: none !important;
}

.userMnageMnetBcl .MuiToolbar-regular {
    padding-left: 0px !important;
}

.userMnageMnetBcl .MuiToolbar-regular .MuiTypography-h6 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.notesNotificationSpecial {
    display: block;
    width: 100%;
}

.show_percentage {
    /* background-color: #121212; */
    /* padding: 15px;
    margin: 18px 0; */
}

.show_percentage .trainingCenter_Progressbar {
    background-color: #e9eaec;
    border-radius: 10px;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.show_percentage .trainingCenter_Progressbar .container1 {
    padding: 5px;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.show_percentage .trainingCenter_Progressbar .container1 .progressbar_wrapper {
    width: auto;
    padding: 0;
    padding-bottom: 15px;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.show_percentage .trainingCenter_Progressbar .container1 .progressbar_wrapper mat-progress-bar {
    width: calc( 100% - 80px);
}

.show_percentage .trainingCenter_Progressbar .container1 .progressbar_wrapper p {
    display: block;
    width: 60px;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}

.show_percentage .trainingCenter_Progressbar .container1 .loderBodyCon .loderBody {
    height: 20px;
}

.show_percentage .trainingCenter_Progressbar .container1 .totalcountshow {
    display: block;
    padding: 0px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
}

.show_percentage .trainingCenter_Progressbar .container1 .totalcountshow p {
    font-size: 18px;
    font-weight: 700;
}

.show_percentage .trainingCenter_Progressbar .container2 {
    width: calc(100% - 50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.show_percentage .trainingCenter_Progressbar .container2 .lessonactivity {
    padding: 10px 0;
    width: 33.3%!important;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.show_percentage .trainingCenter_Progressbar .container2 .completed {
    /* background: #76ae4b; */
    background: linear-gradient(180deg, rgba(68, 209, 61, 1) 0%, rgba(25, 148, 52, 1) 100%)
}

.show_percentage .trainingCenter_Progressbar .container2 .ongoing {
    /* background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%); */
    background: linear-gradient(180deg, rgb(255 239 20) 0%, rgb(221 186 15) 100%);
}

.show_percentage .trainingCenter_Progressbar .container2 .incomplete {
    /* background: #01A1FE; */
    background: linear-gradient(180deg, rgb(89 192 255) 0%, rgb(25 117 211) 100%);
}

.show_percentage .trainingCenter_Progressbar .container2 span {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #fdfdfd!important;
    background-size: 70%!important;
    border-radius: 100%;
    border: solid 1px #cbb7b7;
    margin-left: 10px;
}

.show_percentage .trainingCenter_Progressbar .container2 label {
    padding: 0 0 0 10px;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
}

.show_percentage .trainingCenter_Progressbar .container2 .ongoing label {
    color: #333;
}

.show_percentage .trainingCenter_Progressbar .container2 .completed span {
    background: url('https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/backend/bookIcon.png') no-repeat center center;
}

.show_percentage .trainingCenter_Progressbar .container2 .ongoing span {
    background: url('https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/backend/openBookIcon.png') no-repeat center center;
}

.show_percentage .trainingCenter_Progressbar .container2 .incomplete span {
    background: url('https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/backend/IncompleteLesson.png') no-repeat center center;
}

.common-modalbox .mat-dialog-container,
.ConfirmationForNextLessonDialog .mat-dialog-container {
    border: 10px solid #0569ad;
    padding: 0px;
    overflow: visible;
}

.ConfirmationForNextLessonDialog .closeIcon {
    border-radius: 50%;
    height: 30px;
    opacity: 1;
    padding: 4px;
    position: absolute;
    right: -14px;
    top: -17px;
    width: 30px;
    line-height: 30px;
    background: #1b75b2;
    color: #fff;
    border: none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.ConfirmationForNextLessonDialog .closeIcon mat-icon {
    font-size: 22px;
    width: auto;
    height: auto;
}

.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson {
    padding: 0px;
    box-shadow: none !important;
}

.quiz-container {
    min-width: 400px;
    padding-bottom: 15px;
}

.quiz-container .heading,
.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson .header {
    padding: 0px 80px;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    background: #003166;
}

.quiz-container .heading {
    text-shadow: none;
    font-size: 30px;
    margin-bottom: 10px;
}

.quiz-container form {
    padding: 10px;
}

.quiz-container form .option-wrap {
    padding-left: 10px;
}

.quiz-container .scr_msg {
    padding: 0px 15px;
}

.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson h2 {
    text-align: center;
    font-size: 23px;
    line-height: 28px;
    padding: 0 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    width: 80%;
    margin: 10px auto;
}

.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson .buttonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto!important;
}

.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson .buttonDiv button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f !important;
    color: #fff !important;
    font-size: 20px;
    line-height: 26px;
    margin-right: 8px;
    padding: 6px 20px;
    text-transform: capitalize;
    border: none;
    background: #1b75b2;
}

.ConfirmationForNextLessonDialog .confirmationdialogfornextlesson .buttonDiv .reject {}

.betoClass {
    background-color: #ADD8E6;
}

.jeannetteClass {
    background-color: #90EE90;
}

.chipWrprs_next {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chipWrprs_next .MuiChip-root {
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.access_dealWrpr .other_info,
.access_dealWrpr .next_steps_view,
.access_dealMOdal .why {
    overflow-wrap: anywhere;
}

.access_dealWrpr .MuiTableCell-root {
    position: relative;
}

/* .access_dealWrpr .MuiTableCell-root tooltip {
    position: absolute;
    bottom: 0;
    right: 0;
} */

.access_dealWrpr .MuiTableCell-root tooltip i {
    font-size: 12px;
}

.access_dealMOdal .modal_title {
    font-size: 25px !important;
}

.disposition_color_for_dropdown{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.access_dealWrpr .listing_btn_class .MuiButtonBase-root {
    padding: 5px !important;
}
.access_dealWrpr .disposition_show{
    font-size: 14px !important;
}
.access_dealWrpr .disposition_show .disposition_status_block_color {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 0 auto 0 0;
}
.access_dealWrpr .disposition_show .disposition_status_block_color p:nth-child(1) {
    font-weight: 500 !important;
    width: 65%;
}
.access_dealWrpr .disposition_show .disposition_status_block_color p:nth-child(2), .access_dealWrpr .disposition_color_for_dropdown {
    border: 1px solid rgb(126, 126, 126);
}


@media only screen and (max-width: 1310px) {
    .access_dealWrpr .disposition_show .disposition_status_block_color {
        flex-direction: column;
    }
.access_dealWrpr .disposition_show .disposition_status_block_color p:nth-child(2) {
    margin-left: 0px;
    align-self: flex-start;
    margin-left: 0px !important;
}

    .access_dealWrpr .disposition_show .disposition_status_block_color p:nth-child(1) {
        width: auto;
    }
}
@media only screen and (max-width: 1199px) {
    .access_dealWrpr .disposition_show .disposition_status_block_color {
        flex-direction: row;
    }
    .access_dealWrpr .disposition_show{
        font-size: 14px !important;
    }
    /* .access_dealWrpr .disposition_show .disposition_status_block_color p:nth-child(1) {
        width: auto;
    } */
}