/* ------------------------------banner---------------------------------------- */

.landingpage_new_bnnr_block .landing_page5_bannerblock {background-image:
     /* url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Landingpage18/access_banner_men_img_bg.webp), */
     url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/access_banner_men_img_bg.webp),
      /* url(https://all-frontend-assets.s3.amazonaws.com/aspire-landingpages/Landingpage18/pharmacy_main_banner_bg1.webp); */
      url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/pharmacy_main_banner_bg1.webp);
    
    
    }

.landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_textWrapper h1{font-size: 58px; line-height: 68px;display: inline;
    background: rgb(10,34,87);
    background: linear-gradient(90deg, rgba(10,34,87,1) 0%, rgba(17,73,191,1) 35%, rgba(10,34,87,1) 92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}


 
.landingpage_new_bnnr_block .lpage_textWrapper {width: 980px; margin-bottom: 6px;}

.landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper p{font-size: 25px; font-weight: 500; line-height: 31px; color:#000;}
.landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper{width: 800px; margin-bottom: 20px;}
.lpage_textWrapper_main .lpage_colorBox {background: rgb(13,44,101);
    background: linear-gradient(332deg, rgba(13,44,101,1) 0%, rgba(23,68,129,1) 50%, rgba(13,44,101,1) 100%); width: 800px; border-radius: 8px;}

    .lpage_textWrapper_main .lpage_colorBox_innr{padding: 20px;}  
    
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_innr h2 {font-size: 43px; line-height: 49px; color: #fff; margin-bottom: 18px;}
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_innr h2 span {color: #00f0ff; text-transform: uppercase;    }

    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_p_wrapper {background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/access_colorBox_skyBlue_color_bg.webp); background-repeat: no-repeat; background-position: center center; background-size: cover; padding: 20px; border-radius: 15pxpx;}

    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_p_wrapper p{font-size: 23px; line-height: 28px; color: #fff;font-weight: 600;}
    .lnding_P18 .form_style .formWrapperClass{
        padding: 0px 15px 5px 15px;
    }
    .lnding_P18 .l2submitbtnsection{
        margin-top: 20px;
    }

 @media only screen and (max-width:1840px){


    .landingpage_new_bnnr_block .landing_page5_bannerblock{
        background-position: right 370px top 30px, center top 0;
    }
 }

 @media only screen and (max-width: 1770px){
.landingpage_new_bnnr_block .landing_page5_bannerblock {
    background-position: right 300px top 30px, center top 0;
}}

@media only screen and (max-width: 1690px){
.landingpage_new_bnnr_block .landing_page5_bannerblock {
    background-position: right 250px top 30px, center top 0;
}
}

@media only screen and (max-width: 1640px){
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_textWrapper h1 {
        font-size: 51px;
        line-height: 62px;}

    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper p {
            font-size: 23px;
            font-weight: 500;
            line-height: 29px;}

    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_innr h2 {
                font-size: 39px;
                line-height: 45px;}   
                
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_p_wrapper p {
                    font-size: 21px;
                    line-height: 27px;  }  
                    
    .landingpage_new_bnnr_block .landing_page5_bannerblock .form_title_wrapper h2 span{
        font-size: 22px;
        line-height: 28px;
                    } 
     .landingpage_new_bnnr_block .landing_page5_bannerblock .landing-banner-right {
                        margin-top: 40px;
                    }               
     .landingpage_new_bnnr_block .lpage_arrow_wrapper {margin-top: 35px; }

     .landing_page5_bannerblock .l2submitbtnsection button {    font-size: 21px;
        line-height: 26px;}
    }

   @media only screen and (max-width:1550px){
    .landingpage_new_bnnr_block .landing_page5_bannerblock {
        background-position: right 150px top 30px, center top 0;
    }
    .landingpage_new_bnnr_block .landing_page5_bannerblock .form_title_wrapper h2 span {
        font-size: 20px;
        line-height: 26px;
    }
   }

   @media only screen and (max-width: 1440px){
     .landingpage_new_bnnr_block .landing_page5_bannerblock {
       background-position: right 93px top 30px, center top 0;
        }
     .landingpage_new_bnnr_block .l2submitbtnsection {
            margin-top: 30px;
            margin-bottom: 10px;
        }
   }

   @media only screen and (max-width: 1380px){
    .lpage_textWrapper_main .lpage_colorBox{width: auto;}
    .landingpage_new_bnnr_block .lpage_arrow_wrapper {
        margin-top: 0;
    }
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_p_wrapper p {
        font-size: 16px;
        line-height: 24px;
    }
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper {
        width: auto;}
    .landingpage_new_bnnr_block .lpage_textWrapper{width: auto;}    
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_textWrapper h1 {
        font-size: 44px;
        line-height: 54px;
    }
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper p {
        font-size: 22px;
        
        line-height: 28px;
    }
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_innr h2 {
        font-size: 36px;
        line-height: 42px;
    }
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_p_wrapper p {
        font-size: 21px;
        line-height: 27px;
    }
  }
  @media only screen and (max-width: 1340px){
    .landingpage_new_bnnr_block .landing_page5_bannerblock .landing-banner-right {
        margin-top: 98px;
    }
    .landingpage_new_bnnr_block .landing_page5_bannerblock .l2submitbtnsection button {
        font-size: 19px;
        line-height: 24px;
    }
}
@media only screen and (max-width: 1310px){
    .landing_page5_bannerblock .landing-banner-right {
        width: calc(32% - 50px);
    }
    
  }

  @media only screen and (max-width: 1280px){
    .landingpage_new_bnnr_block .lpage_arrow_wrapper {
        margin-top: 0px;
    }
  }

  @media only screen and (max-width: 1240px){
.landingpage_new_bnnr_block .landing_page5_bannerblock .l2submitbtnsection button {
    font-size: 16px;
    line-height: 23px;
    padding: 10px;
}
  }

  @media only screen and (max-width: 1199px){
    .landingpage_new_bnnr_block .landing_page5_bannerblock {
        background-image: none, url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/pharmacy_main_banner_bg1.webp);
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 20px;
    }

    .landingpage_new_bnnr_block .landing_page5_bannerblock .landing-banner-right {
        margin-top: 0;
    }
    .lpage_textWrapper_main .lpage_colorBox {
        
        margin-bottom: 20px;
    }
    .landing_page5_bannerblock .landing-banner-right{
        width: 100%;
    }
  }
  @media only screen and (max-width: 699px){
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_textWrapper h1 {
        font-size: 36px;
        line-height: 45px;
    }

    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper p {
        font-size: 19px;
        line-height: 25px;
    }
  }

  @media only screen and (max-width: 420px){
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_textWrapper h1 {
        font-size: 32px;
        line-height: 38px;
    }
    .landingpage_new_bnnr_block .lpage_textWrapper_main .lpage_pWrapper p {
        font-size: 19px;
        line-height: 25px;
    }
    .lpage_textWrapper_main .lpage_colorBox .lpage_colorBox_innr h2 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 15px;
    }
  }
/* ------------------------------banner---------------------------------------- */

/* ////////////////////////////////video_block/////////////////////////////////////// */



/* ////////////////////////////////video_block/////////////////////////////////////// */


/* ////////////////////////////////////////block1////////////////////////////////////////// */








/* ////////////////////////////////////////block1////////////////////////////////////////// */













