.analysis_main_wrap{
    
    background-image: url("../../../../../assets/image/analyimg.png");
    background-repeat: no-repeat;
    background-position: right bottom ;

    
}
.analysis_text_heading{
    font-size: 30px;
    font-weight: 600;
    padding-left: 30px;
    margin-top: 20px;
}
.analysis_text_para{
    font-size: 23px;
    line-height: 27px;
    font-weight: 600px;
    margin-right: 700px;
    padding-left: 30px;
}
.analysis_back_wrap{
  
   
}
.analysis_text_wrap{
    
}

.analysis_text_list{
    margin-right: 810px;

}
.analysis_text_list h3{
    margin-left: 30px;
    margin-top: 10px;
    white-space: nowrap;
}
.analysis_text_list ul{
    list-style: none;
    font-size: 23px;
    line-height: 26px;
    font-weight: 400;
}
.analysis_text_list ul li{
    align-items: center;
    margin-left: 30px;
    margin-top: 12px;
    background-image: url("../../../../../assets//image/circle.png");
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 43px;

    
}



.analysis_text_list .analysis_list_title{
    font-weight: 600;
    font-size: 23px;
}
.text_org{
    color: rgb(252,130,45);
    font-weight: 500;
}
.analysis_text_list h3{
    color: rgb(19,95,181);
    font-size: 36px;
    font-weight: 600;
}
.analysis_btn_wrap button{
    width: 600px;
    height: 80px;
    color: white;
    font-size: 41px;
    font-weight: bolder;
    background: linear-gradient(rgb(254,130,6),rgb(255,53,3) 90%);
    border-radius: 8px;
    margin-top: 20px;
    margin-left: 30px;
    border: none;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0,0.4);
}


@media screen and (max-width:1699px) {

    .analysis_text_heading{
        font-size: 28px;
        
    }

    .analysis_btn_wrap button{
        width: 560px;
       
    }
    
}

@media screen and (max-width:1599px) {

    .analysis_text_heading{
        font-size: 26px;
        
    }
    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin-right: 500px;
        
        
    }

    .analysis_btn_wrap button{
        width: 530px;
       
    }
    
}

@media screen and (max-width:1499px) {

    .analysis_text_heading{
        font-size: 24px;
        
    }

    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin-right: 500px;
        
        
    }

    .analysis_text_list{
        margin-right: 700px;
    
    }

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 32px;
    }
    .analysis_text_list ul{
        list-style: none;
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
    }
   
    
    
    
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 22px;
    }

    .analysis_btn_wrap button{
        width: 530px;
       
    }
    
}

@media screen and (max-width:1399px) {

    .analysis_main_wrap{
        background-position: right -100px bottom ;
    
        
    }

    .analysis_text_heading{
        font-size: 24px;
        
    }

    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin-right: 400px;
        
    }

    .analysis_text_list{
        margin-right: 650px;
    
    }

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 32px;
    }
    .analysis_text_list ul{
        list-style: none;
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
    }
   
    
    
    
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 22px;
    }

    .analysis_btn_wrap button{
        width: 500px;
        font-size: 28px;
       
    }
    
}

@media screen and (max-width:1199px) {
    .analysis_main_wrap{
   
        background-position: top center ;
        padding-top: 710px;
        padding-right: 20px;
    
        
    }

    .analysis_text_heading{
        font-size: 28px;
       white-space: nowrap;
        
    }

    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin: 0;
        
    }
    .analysis_text_list{
        margin: 0;
    
    }

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 38px;
    }
    .analysis_text_list ul{
        list-style: none;
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
    }
   
    
    
    
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 22px;
    }

    .analysis_btn_wrap button{
        width: 500px;
        font-size: 28px;
       
    }
    
}


@media screen and (max-width:1099px) {
    .analysis_main_wrap{
   
        background-position: top center ;
        padding-top: 710px;
    
        
    }

    .analysis_text_heading{
        font-size: 24px;
        
        
    }

    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin: 0;
        
    }
    .analysis_text_list{
        margin: 0;
    
    }

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 36px;
    }
    
}


@media screen and (max-width:899px) {
    .analysis_main_wrap{
   
        background-position: top center -100px ;
       
    
        
    }

    .analysis_text_heading{
        font-size: 22px;
        
        
    }

    .analysis_text_para{
        font-size: 22px;
        line-height: 27px;
        margin: 0;
        
    }
    .analysis_text_list{
        margin: 0;
    
    }

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 36px;
    }
    
}

@media screen and (max-width:810px) {
    .analysis_main_wrap{
   
        background-position: top center -100px ;
       
    
        
    }

    .analysis_text_heading{
        font-size: 19px;
        
        
    }

    .analysis_text_para{
        font-size: 20px;
        line-height: 27px;
        margin: 0;
        
    }
  

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 34px;
    }

    .analysis_text_list ul{
        font-size: 20px;
    
    }
    
}





@media screen and (max-width:710px) {
    .analysis_main_wrap{
   
        background-position: top center -100px ;
        background-image: none;
        padding-top: 0;
       
    
        
    }

    .analysis_text_heading{
        font-size: 16px;
        
        
    }

    .analysis_text_para{
        font-size: 17px;
        line-height: 27px;
        margin: 0;
        
    }
  

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 26px;
    }

    .analysis_text_list ul{
        font-size: 18px;
    
    }
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 18px;
    }
    .analysis_btn_wrap button{
        width: 400px;
        margin-left: 50px;
    }
}

@media screen and (max-width:610px) {
    

    .analysis_text_heading{
        font-size: 14px;
        
        
    }

    .analysis_text_para{
        font-size: 16px;
        line-height: 27px;
        margin: 0;
        
    }
  

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 22px;
    }

    .analysis_text_list ul{
        font-size: 16px;
    
    }
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 16px;
    }
    .analysis_btn_wrap button{
        width: 300px;
        margin-left: 70px;
    }
}


@media screen and (max-width:550px) {
    

    .analysis_text_heading{
        font-size: 13px;
        
        
    }

    .analysis_text_para{
        font-size: 15px;
        line-height: 27px;
        margin: 0;
        
    }
  

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: nowrap;
        font-size: 22px;
    }

    .analysis_text_list ul{
        font-size: 15px;
    
    }
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 15px;
    }
    .analysis_btn_wrap button{
        width: 300px;
        margin-left: 70px;
    }
}

@media screen and (max-width:499px) {

    .analysis_main_wrap{
    
        padding: 0;
    
        
    }
    

    .analysis_text_heading{
        font-size: 13px;
        padding: 0;
        margin: 0;
        white-space: normal;
        
    }

    .analysis_text_para{
        font-size: 15px;
        line-height: 27px;
        margin: 0;
        padding: 0;
        
    }
  

    .analysis_text_list h3{
        margin-left: 30px;
        margin-top: 10px;
        white-space: normal;
        font-size: 21px;
        margin: 0;
        padding: 0;
    }

    .analysis_text_list ul{
        font-size: 15px;
        margin: 0;
        padding: 0;
    
    }

    .analysis_text_list ul li{
        font-size: 15px;
        margin: 0;
        padding: 0;
        padding-left: 25px;
    
    }
    .analysis_text_list .analysis_list_title{
        font-weight: 600;
        font-size: 15px;
    }
    .analysis_btn_wrap button{
        width: 300px;
        margin-left: 40px;
    }
}

@media screen and (max-width:380px){
    .analysis_btn_wrap button{
        width: 300px;
        margin-left: 25px;
    }

}

@media screen and (max-width:350px){
    .analysis_btn_wrap button{
        width: 300px;
        margin-left: 10px;
    }

}