.aslandingpageblock10_colcontainer{display:flex;flex-wrap:wrap}
.aslandingpageblock10_col8{width:calc(52%);padding:30px 50px;background:#707070;display:flex;align-items:center}
.aslandingpageblock10_col2{width:48%}
.aslandingpageblock10_imgwrapper{overflow:hidden;width:100%;height:100%}
.aslandingpageblock10_imgwrapper img{width:100%;height:100%;object-fit:cover}
.aslandingpageblock10_textwrapper h1{font-size:45px;line-height:48px;text-transform:uppercase;color:#fff;font-weight:700;margin-bottom:30px}
.aslandingpageblock10_textwrapper p{font-size:25px;line-height:32px;color:#fff;font-weight:400}

.aslandingpageblock11_container{width:1795px;display:block;margin:0 auto}
.aslandingpageblock11_mainwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/aslandingpageblock11_image1.webp);background-repeat:no-repeat;background-size:cover;padding:100px 0;background-position:right top}
.aslandingpageblock11_mainwrapper .aslandingpageblock10_textwrapper h1{margin-bottom:20px}
.aslandingpageblock11_mainwrapper .aslandingpageblock10_textwrapper:nth-child(1){padding-bottom:35px;position:relative;margin-bottom:35px}
.aslandingpageblock11_mainwrapper .aslandingpageblock10_textwrapper:nth-child(1)::after{content:'';width:100%;height:11px;background-color:#c50823;position:absolute;left:0;bottom:0}
.aslandingpageblock11_subwrapper{padding-right:690px}

@media only screen and (max-width: 1840px) {
.aslandingpageblock11_container{width:96%}
.aslandingpageblock11_mainwrapper{padding:30px 0}
}
@media only screen and (max-width: 1540px) {
.aslandingpageblock10_textwrapper h1{font-size:36px;line-height:39px}
.aslandingpageblock10_textwrapper p{font-size:20px;line-height:26px}
.aslandingpageblock11_subwrapper{padding-right:580px}
}
@media only screen and (max-width: 1300px) {
.aslandingpageblock11_mainwrapper{background-position:right -90px top}
}
@media only screen and (max-width: 1199px) {
.aslandingpageblock10_col2{width:100%}
.aslandingpageblock10_col8{width:100%}
.aslandingpageblock11_mainwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/landingpage13/aslandingpageblock11_image01.webp);background-size:100%;background-position:left top;padding-top:90%;background-color:#2a83a8}
.aslandingpageblock11_subwrapper{padding-right:0}
}
@media only screen and (max-width: 767px) {
.aslandingpageblock10_col8{padding:20px}
.aslandingpageblock10_textwrapper h1{font-size:24px;line-height:28px;margin-bottom:15px}
.aslandingpageblock11_mainwrapper .aslandingpageblock10_textwrapper h1{margin-bottom:15px}
.aslandingpageblock10_textwrapper p{font-size:18px;line-height:24px}
}
@media only screen and (max-width: 450px) {
    .aslandingpageblock11_subwrapper{padding: 10px;}
    .Landingpage13block9_main_wrapper p{text-align: left;padding: 0 10px;}
    .Landingpage13block9_main_wrapper h2{text-align: left;padding: 0 10px;font-size: 24px; line-height: 30px;}
}