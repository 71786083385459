.back_icon {margin-left: 30px;}
.back_icon svg {font-size: 50px;margin-top: 20px;cursor: pointer;}
.w9_top {display: flex;    justify-content: space-between;    padding-top: 25px;border-bottom: 4px solid #000;margin-top: 30px;}
.w9_common_width {width: 1700px;margin: 0 auto;}
.w9_top_left h4 {font-size: 20px;font-weight: 400;line-height: 26px;}
.w9_top_left h4 span {font-size: 50px;font-weight: 800;color: #000;}
.w9_top_middle h1 {    font-size: 33px;
    line-height: 38px;
    color: #000;
    text-align: center;
    font-weight: 700;}
.w9_top_middle h3 {    font-size: 20px;
    line-height: 26px;
    color: #000;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;font-style: italic;}
.w9_top_right h2 {font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: #000;}
.w9_mainwrp .MuiInputBase-input {font-size: 20px;}
.w9_top_right {    width: 15%;}
.w9_top_middle {    width: 68%;border: 4px solid #000;
    border-top: 0;
    border-bottom: 0;}
.w9_top_left {width: 16%;}
.w9_top_left, .w9_top_middle, .w9_top_right {padding-bottom: 10px;}
.w9_common_flex {display: flex;justify-content: space-between;margin: 35px 0;}
.w9_left_wrpper, .w9_right_wrpper {width: 48%;}
.w9_common_flex h2 {    font-size: 30px;
    line-height: 35px;
    color: #000;
    margin-bottom: 10px;}
.w9_common_flex p, .w9_common_flex li, .block2_common p, .w9_flex-wrapper2 p, .w9_flex-wrapper2 .MuiTypography-root, .w9_flex4 p, .font20_w9 p, .flex5 p, .w9_para p {font-size: 20px;
    line-height: 25px;
    color: #000;
    margin: 10px 0;}
    .w9_common_flex li {margin-left: 15px;}
.w9_common_flex .w9_italict_text {font-style: italic;}
.w9_bold_text {font-weight: 600;}
.w9_right_wrpper table td {padding: 10px;border: 2px solid #000;}
.w9_right_wrpper table {border-spacing: 0;border: 1px solid #000;border-collapse: collapse;}
.w9_right_wrpper table th {border: 2px solid #000;padding: 5px 10px;}
.table2 table {border: 2px solid #000;border-spacing: 0;border-collapse: collapse;border-left: 0;border-right: 0;}
.table2 table td {padding: 10px;border: 2px solid #000;}
.table2 table th {border: 2px solid #000;text-align: left;padding: 5px 10px;}
.table3 {border: 4px solid #000;border-spacing: 0;border-left: 0;border-right: 0;}
.table3 th {border-bottom: 4px solid #000;border-spacing: 0;}
.table3 td {border-spacing: 0;padding: 0px 10px;}
.table3 td:first-child, .table3 th:first-child {border-right: 4px solid #000;}
.table3 th:first-child  {width: 50%;}
.bottom_table {border-top: 0;}
.block2_common {    padding: 5px;
    border-bottom: 2px solid #000;}
.block2_common .MuiFormControl-root {width: 100%;}
.w9_flex-wrapper2 {display: flex;}
.w9_flex-wrapper2_left {    padding: 10px;
    border-right: 2px solid #000;    width: 75%;}
    .w9_flex-wrapper2_right {padding: 10px; width: 25%;}
.inline_input .MuiFormControl-root {    width: 150px;
    margin-left: 10px;   }
.full_width {width: auto;}
.w9_flex-wrapper3 {display: flex;}
.flex3_left {width: 70%;padding: 10px;border-right: 2px solid #000;padding-right: 0;}
.flex3_right {width: 30%;padding: 10px;}
.flex3_right textarea {resize: none;width: 100%;height: 205px !important;    padding: 5px 10px;font-size: 16px;
}
.bordercls {border-bottom: 2px solid #000;}
.w9_flex-wrapper3, .w9_flex-wrapper2 {border-bottom: 2px solid #000;}
.border_nne {border: none;}
/* .w9_flex-wrapper {display: flex;} */
.part_cmn {font-size: 25px;font-weight: 600;border: 2px solid #000;border-left: 0;border-right: 0;line-height: 32px;}
.black_bg {background: #000;color: #fff;padding: 0 10px;}
.w9_flex4 {display: flex;justify-content: space-between;padding-bottom: 10px;}
.w9_flex4_right p {border: 2px solid #000;padding: 5px;}
.w9_flex4_left {width: 75%;}
.w9_flex4_right {width: 20%;}
.flx_spn {display: flex;
    align-items: center;
    justify-content: space-between;}
.width70 {width: 70px;}
.width50 {width: 50px;}
.width150 {width: 150px;}

.flex5 {display: flex;align-items: center;    border: 2px solid #000;border-right: 0;border-left: 0;padding: 10px 0;}
.flex5 h3 {font-size: 28px;width: 160px;border-right: 2px solid #000;}
.flex6 {display: flex;justify-content: space-between;width: 90%;align-items: center;margin-left: 20px;}
.w9_agents_block2 p {font-size: 26px;line-height: 31px;}
.flex6 .flex6_right, .flex6 .flex6_left {width: auto;display: flex;}
.flex5 .MuiFormControl-root {margin-left: 10px;}
.pageno_flex {display: flex;justify-content: space-between;padding: 5px 0;border-bottom: 2px solid #000;}
.pageno_flex span {font-size: 30px;font-weight: 600;    line-height: 25px;}
.w9_flex-wrapper {display: flex;}
.w9_block1_left {width: 120px;
    display: flex;
    align-items: center;justify-content: center;}
    .w9_block1_left  div {    transform: rotate(271deg);
        text-align: center;}
.w9_block1_left  div h3 {    white-space: nowrap;font-size: 25px;
    line-height: 32px;}
.w9_block1_right {    border-left: 2px solid #000;}
.w9_btn_wrp .w9_btn {display: block;width: 180px;font-size: 35px;font-weight: 600;background: rgb(10, 51, 118);color: #fff;padding: 10px;margin: 15px auto;line-height: 40px;}
.w9_btn_wrp .w9_btn:hover {background-color: rgb(25 91 157);}
.flx_spn input {text-align: center;
    font-size: 22px;}
.linked_tle {text-decoration: none;}

.red_alert_text {color: red !important;border: none !important;}
.w9_logo_wrp img {display: block;margin: 0 auto;max-width: 100%;}
.w9_new_blk p, .w9_agents_block p {font-size: 25px;line-height: 30px;text-align: center;margin-bottom: 20px;}
.w9_agents_block .MuiFormControl-root {margin-left: 15px;width: 30%;}
.w9_agents_block {margin-bottom: 20px;border-bottom: 0;}
.w9_agents_block span {margin-left: 10px;}
.w9_signature_block p {font-size: 25px;line-height: 30px;margin: 10px 0;}
.w9_signature_block .MuiFormControl-root {margin-left: 0;width: 35%;}
.banking_block {margin: 20px 0;}
.banking_block .MuiFormControl-root {width: 40%;}
.right_input {width: 300px;margin-left: auto !important;display: block !important;}
.cheque_flex1 {display: flex;align-items: flex-end;}
.wdth90 {width: 90%;}
.wdth96 {width: 96%;}
.cheque_flex1 .MuiInputBase-input {padding: 7px 10px;}
.cheque_flex1 .MuiFormControl-root {margin: 0 5px;}
.cheque_flex1 {font-size: 15px;margin: 15px 0;}
.pay_text p {font-size: 25px;line-height: 25px;font-style: italic;}
.pay_text {display: block;}
.cheque_flex {display: flex;justify-content: space-between;}
.cheque_flex .MuiFormControl-root {width: 40%;}
.w9_cheque_sub_block {background: #e6ffe2;padding: 50px;border: 10px solid #9dc6a1;outline: 4px solid #7fac85;}
.w9_cheque_block {padding: 10px;background: #a2c8a5;margin: 25px 0;}
.w9_signature_block {width: 48%;}
.w9_signature_block .MuiFormControl-root {width: 100%;}
.w9_cheque_block .MuiFilledInput-root:hover,.w9_cheque_block .MuiFilledInput-root.Mui-focused, .w9_cheque_block .MuiFilledInput-root  {background: transparent;    border-bottom: 1px solid rgba(0, 0, 0, 0.42);}
.w9_cheque_block .MuiInputBase-root:before {display: none;}

.w9_cheque_block .MuiFilledInput-root:focus-visible {outline: none;}
.w9_cheque_block .MuiOutlinedInput-notchedOutline {border: none;border-bottom: 1px solid #000;border-radius: 0;}
.w9_logo_wrp {margin-bottom: 20px;}
.w9_new_blk {margin-top: -50px;}


.acceptTerms_wrapper .w9_modal_heading h2 {font-size: 30px;line-height: 35px;font-weight: 600;background: #0569ad;padding: 10px;text-align: center;color: #fff;margin-bottom: 20px;text-transform: uppercase;}
.accept_btn_wrp {display: flex;justify-content: space-between;margin-top: 40px;}
.SD_w9_agree_modal .configure-role {width: 1000px;margin: 20px auto;}
.acceptTerms_wrapper {width: 1700px; margin: 40px auto;}
.acceptTerms_sub_wrapper p {font-size: 18px;line-height: 25px;color: #000;font-weight: 500;margin: 10px 0;}
.acceptTerms_sub_wrapper {background: #ddd;padding: 20px;border: 10px solid #0569ad;box-shadow: 1px 16px 36px 11px #888888;outline: 4px solid #edebeb;}
.accept_button button {font-size: 26px;background-color: #0569ad;font-weight: 600;padding: 4px 20px;width: fit-content;color: #fff;border: 1px solid #fff;}
.accept_button button:hover {background-color: #044b7b;}
.accept_input_wrp p {margin-top: 5px;}
.acceptTerms_sub_wrapper .MuiFormHelperText-root {margin: 0 !important;}
.accept_input_wrp {width: 40%;margin-right: 10px;}
.accept_input_wrp .MuiFormControl-root {width: 100%;}
.acceptTerms_sub_wrapper p span {font-weight: 700;}


@media only screen and (max-width: 1780px) {
    .acceptTerms_wrapper {width: 96%;}

}


@media only screen and (max-width: 1750px) {
    .w9_common_width {
        width: 94%;}
        .flex6 .flex6_right, .flex6 .flex6_left {margin: 0 10px;}

}
@media only screen and (max-width: 1398px) {
    .w9_top_middle h1 {
        font-size: 25px;
        line-height: 30px;}
        .w9_top_left h4 span {
            font-size: 30px;}
            .w9_top_left h4 {
                font-size: 16px;    line-height: 21px;}.w9_top_right h2 {
                    font-size: 18px;    line-height: 23px;}
                    .w9_top_middle {padding: 10px;}
}
@media only screen and (max-width: 1199px) {
.w9_common_flex h2 {font-size: 25px;line-height: 30px;}
.w9_common_flex p, .w9_common_flex li, .block2_common p, .w9_flex-wrapper2 p, .w9_flex-wrapper2 .MuiTypography-root, .w9_flex4 p, .font20_w9 p, .flex5 p, .w9_common_flex li, .w9_mainwrp .MuiInputBase-input, .w9_para p, .w9_signature_block p {
    font-size: 17px;
    line-height: 22px;}
    .w9_block1_left {
        width: auto;
        display: block;}
        .w9_flex-wrapper {flex-direction: column;}
        .w9_block1_left div {transform: none;}
        .w9_block1_right {border: 2px solid #000;}
}

@media only screen and (max-width: 991px) {
    .w9_common_flex p, .w9_common_flex li, .block2_common p, .w9_flex-wrapper2 p, .w9_flex-wrapper2 .MuiTypography-root, .w9_flex4 p, .font20_w9 p, .flex5 p, .w9_common_flex li, .w9_mainwrp .MuiInputBase-input {
        font-size: 15px;
        line-height: 20px;
    }
    .w9_common_flex h2 {
        font-size: 22px;
        line-height: 27px;
    }
    .w9_top {flex-direction: column;}
    .w9_top_left, .w9_top_middle, .w9_top_right {width: auto;text-align: center;border: none;padding: 0;}
    .w9_flex4 {flex-direction: column;}
    .w9_flex4_left, .w9_flex4_right {width: auto;}
    .flx_spn {justify-content: flex-start;}
    .flex5 {flex-direction: column;}
    .flex5 h3 {border: none;margin-bottom: 10px;text-align: center;}
    .flex6 {width: 100%;}
    .cheque_flex {flex-direction: column;}
    .w9_cheque_sub_block .cheque_flex {flex-direction: row;}
    .w9_signature_block {width: 100%;}
}
@media only screen and (max-width: 799px) {
    .flex6 {flex-direction: column;}
.flex6_left {width: 96% !important;margin-bottom: 10px !important;}
.flex6_right {width: 50% !important;}
.accept_button button {font-size: 18px;}
.accept_input_wrp {width: 56%;}

}
@media only screen and (max-width: 767px) {
.flex3_right {width: auto;}
.w9_cheque_sub_block {padding: 10px;}
.w9_flex-wrapper2_right {width: auto;}
.w9_agents_block {flex-direction: row !important;}
.w9_agents_block .MuiFormControl-root {width: 60%;}
.w9_common_flex {flex-direction: column;margin: 10px 0;}
.w9_left_wrpper, .w9_left_wrpper, .w9_left_wrpper, .w9_right_wrpper, .w9_flex-wrapper2_left {width: auto;border: none;}
.w9_flex-wrapper3, .w9_flex-wrapper2, .flex5 {flex-direction: column;}
.flex3_left {width: auto;border: none;} 
.flex5 h3 {border: none;}
.back_icon svg {font-size: 36px;margin-top: 18px;}
.back_icon {margin-left: 5px;}
.acceptTerms_wrapper .w9_modal_heading h2 {font-size: 23px;line-height: 29px;}
.acceptTerms_sub_wrapper p {font-size: 15px;line-height: 21px;}
.accept_btn_wrp {margin-top: 20px;}
.acceptTerms_sub_wrapper {padding: 15px;}
}
@media only screen and (max-width: 599px) {
    .accept_btn_wrp {flex-direction: column;}
    .accept_input_wrp {width: auto;margin: 10px 0;}
    .accept_button button {display: block;margin: 0 auto;}
}


@media only screen and (max-width: 499px) {
    .part_cmn {
        font-size: 21px;line-height: 27px;}
        .flex6 {flex-direction: column;}
        .flex6 .flex6_right, .flex6 .flex6_left {width: auto;}
        .w9_block1_left div h3 {white-space: pre-wrap;}
        .w9_agents_block {flex-direction: column !important;}
        .w9_agents_block p {text-align: left;margin: 5px 0;}
        .w9_agents_block .MuiFormControl-root {margin-left: 0;width: 100%;}
        .w9_signature_block .MuiFormControl-root {width: 100%;}
        .right_input {width: auto;margin: 0 auto !important;}
} 




@media print {
    /* if modal-body is your scrollable class */
    #w9_mainwrp {
        width: 100%;
        visibility: visible !important;
        overflow: visible !important;
    }

    #w9_mainwrp .w9_common_width{
        width: 100%;
        padding: 5px;
        margin: 250px auto;
        visibility: visible !important;
        overflow: visible !important;
    }

    #w9_mainwrp .downloadBtn{
        display: none;
    }


 }








