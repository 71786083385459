.audting_frim_back_wrap{
    border: 1px solid rgb(207,209,209);
    background: linear-gradient(to bottom right,rgb(231,245,246),rgb(247,251,252),rgb(200, 248, 251));
    
}
.audting_frim_wrapper{
    padding: 30px;
    background-image: url("../../../../../assets/image/audit2.png");
    background-repeat: no-repeat;
    background-position: left -265px bottom;
}

.audting_frim_header{
    background-image: url("../../../../../assets/image/audit3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0;
    text-align: center;
    border-radius: 8px;
    
}

.audting_frim_header h1{
    font-size: 54px;
    background: linear-gradient(#fbed00 20%, #f9bb0a 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
   
    
}

.audting_frim_para_wrap{
    margin-left: 800px;
}
.audting_frim_para_wrap p{
    margin-top: 20px;
    font-weight: 600;
     font-size: 25px;
}

.audting_text_org{
    color: rgb(248,131,54);
}
.audting_text_blu{
    color: rgb(33,94,166);
} 

@media screen and (max-width:1899px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 24.90px;
    }
    
}

@media screen and (max-width:1890px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 24.50px;
    }
    
}

@media screen and (max-width:1881px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 24.50px;
    }
    
}

@media screen and (max-width:1876px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 24.42px;
    }
    
}



@media screen and (max-width:1782px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 24px;
    }
    
}

@media screen and (max-width:1790px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 23.40px;
    }
    
}

@media screen and (max-width:1710px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 22.5px;
    }
    
}

@media screen and (max-width:1699px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 22.3px;
    }
    
}
@media screen and (max-width:1670px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 22px;
    }
    
}

@media screen and (max-width:1643px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 21.5px;
    }
    
}

@media screen and (max-width:1620px) {

    /* .audting_frim_para_wrap{
        margin-left: 800px;
    } */
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 21px;
    }
    
}

@media screen and (max-width:1599px) {

    .audting_frim_para_wrap{
        margin-left: 750px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 21px;
    }
    
}

@media screen and (max-width:1499px) {

    .audting_frim_para_wrap{
        margin-left: 700px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 20.5px;
    }
    
}

@media screen and (max-width:1399px) {

    .audting_frim_para_wrap{
        margin-left: 680px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 20px;
    }
    
}

@media screen and (max-width:1360px) {

    .audting_frim_para_wrap{
        margin-left: 670px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 19px;
    }
    
}

@media screen and (max-width:1299px) {
    

    .audting_frim_para_wrap{
        margin-left: 670px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 18px;
    }
    
}

@media screen and (max-width:1250px) {
    .audting_frim_wrapper{
        background-position: left -320px bottom;
    }

    .audting_frim_para_wrap{
        margin-left: 650px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 18px;
    }
    
}

@media screen and (max-width:1230px) {
    .audting_frim_wrapper{
        background-position: left -320px bottom;
    }

    .audting_frim_para_wrap{
        margin-left: 650px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 17px;
    }
    
}



@media screen and (max-width:1199px) {

    .audting_frim_wrapper{
     
        background-image: url("../../../../../assets/image/audit22.png");
         background-position: center top;
         padding-top: 720px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 25px;
    } 
}

@media screen and (max-width:999px) {

    .audting_frim_header h1{
        font-size: 48px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 22px;
    } 
}

@media screen and (max-width:899px) {
    .audting_frim_wrapper{
        background-size: 1100px;
        background-position: -100px 0;
        padding-top: 610px;
    }

    .audting_frim_header h1{
        font-size: 45px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 20px;
    } 
}

@media screen and (max-width:850px) {
    .audting_frim_wrapper{
        
        background-position: -110px 0;
    }

    .audting_frim_header h1{
        font-size: 43px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 19px;
    } 
}

@media screen and (max-width:799px) {
    .audting_frim_wrapper{
        background-size: 1100px;
        background-position: -150px 0;
        padding-top: 610px;
    }

    .audting_frim_header h1{
        font-size: 40px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 18px;
    } 
}

@media screen and (max-width:750px) {
    .audting_frim_wrapper{
       
        background-position: -170px 0;
    }

    .audting_frim_header h1{
        font-size: 35px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 17px;
    } 
}

@media screen and (max-width:699px) {
    .audting_frim_wrapper{
        background-size: 1000px;
        background-position: -130px 0;
        padding-top: 550px;
    }

    .audting_frim_header h1{
        font-size: 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}

@media screen and (max-width:650px) {
    .audting_frim_wrapper{
        background-size: 1000px;
        background-position: -150px 0;
        padding-top: 550px;
    }

    .audting_frim_header h1{
        font-size: 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}

@media screen and (max-width:620px) {
    .audting_frim_wrapper{
        background-size: 900px;
        background-position: -150px 0;
        padding-top: 500px;
    }

    .audting_frim_header h1{
        font-size: 28px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}


@media screen and (max-width:599px) {
    .audting_frim_wrapper{
        background-size: 800px;
        background-position: -110px 0;
        padding-top: 445px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}

@media screen and (max-width:525px) {
    .audting_frim_wrapper{
        background-size: 800px;
        background-position: -125px 0;
        padding-top: 445px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}



@media screen and (max-width:499px) {
    .audting_frim_wrapper{
        background-size: 700px;
        background-position: -90px 0;
        padding-top: 390px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}
    

@media screen and (max-width:450px) {
    .audting_frim_wrapper{
        background-size: 700px;
        background-position: -115px 0;
        padding-top: 390px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}
   
@media screen and (max-width:420px) {
    .audting_frim_wrapper{
        background-size: 700px;
        background-position: -125px 0;
        padding-top: 390px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}

@media screen and (max-width:399px) {
    .audting_frim_wrapper{
        background-size: 650px;
        background-position: -120px 0;
        padding-top: 360px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}

@media screen and (max-width:370px) {
    .audting_frim_wrapper{
        background-size: 550px;
        background-position: -100px 0;
        padding-top: 310px;
    }

    .audting_frim_header h1{
        font-size: 28px;
        padding: 0 30px 0 30px;
    }
        
    .audting_frim_para_wrap{
        margin-left: 0px;
    }
    .audting_frim_para_wrap p{
        margin-top: 20px;
        font-weight: 600;
         font-size: 15px;
    } 
}
  