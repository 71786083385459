/* .mainContentWrapper {
    background: #e5fafd;
    min-height: 100vh;
    overflow: hidden;
} */

.connectedemail {
    background-color: #052958;
    padding: 10px 20px;
    font-size: 24px;
    box-shadow: 2px 2px 8px #bac1c5;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.connectedemail h2 {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
}

.calenderinfo {
    font-size: 18px;
    line-height: 24px;
}

.calenderinfo label {
    font-size: 26px;
    color: #198bd0;
    font-weight: 700;
}

.calenderinfo p strong {
    padding-right: 10px;
    display: inline-block;
    color: #198bd0;
}

.buttonsetToolbar {
    /* margin: 15px; */
    background: #fff;
    padding: 1%;
    box-shadow: 0 0 2px #e9e9e9;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 25px;
    border-radius: 8px;
}

.buttonsetToolbar .MuiButtonBase-root {
    width: 23%;
    padding: 12px 0;
    /* background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%); */
    /* background: #35baf3; */
    background: rgb(39, 198, 245);
    background: linear-gradient(180deg, rgba(39, 198, 245, 1) 0%, rgba(7, 136, 217, 1) 100%);
    /* border: solid 8px #1b75b2; */
    border-radius: 50px;
    box-shadow: none;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    margin: 0 0.5% 0.5%;
}


/* .buttonsetToolbar .MuiButtonBase-root a {
    color: #ff6a01;
    text-decoration: none;
} */

.buttonsetToolbar .MuiButtonBase-root {
    transform-style: preserve-3d;
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.buttonsetToolbar .MuiButtonBase-root::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 85%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--blue2);
    border-radius: inherit;
    /* box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA); */
    transform: translate3d(0, 0.75em, -1em);
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.buttonsetToolbar .MuiButtonBase-root:hover {
    /* background: rgb(250 151 13); */
    transform: translate(0, 0.375em);
    color: #f8f7ed;
}


/* .buttonsetToolbar .MuiButtonBase-root:hover a{
      color: #f8f7ed;
  } */

.buttonsetToolbar .MuiButtonBase-root:hover::before {
    transform: translate3d(0, 0.75em, -1em);
}

.buttonsetToolbar .MuiButtonBase-root:active {
    transform: translate(0em, 0.75em);
}

.buttonsetToolbar .MuiButtonBase-root:active::before {
    transform: translate3d(0, 0, -1em);
    box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}


/* .form_wrapper {
    margin: 15px;
    background: #2baae4;
    background: linear-gradient( 180deg, rgba(43, 170, 228, 1) 0%, rgba(2, 119, 175, 1) 100%);
    padding: 15px;
    box-shadow: 2px 2px 8px #bac1c5;
    border-radius: 4px;
    border: solid 15px #baeefb;
} */

.form_wrapper_con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 999;
    position: relative;
}

.form_wrapper_con .MuiInputBase-input {
    padding: 12px 14px;
}

.form_wrapper_con .MuiSelect-select {
    padding: 12px 14px;
}

.form_wrapper_con .MuiFormControl-root .MuiInputLabel-root {
    top: -4px;
    display: none;
}

.form_wrapper_con .MuiFormControl-root {
    width: 100%;
    margin-bottom: 0;
}

.form_wrapper_con .MuiOutlinedInput-root {
    border-radius: 0;
}

.form_wrapper_con .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.form_wrapper_con .MuiOutlinedInput-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc;
}

.form_wrapper_con .form_group {
    width: 400px;
    margin-right: 10px;
}

.form_wrapper_con label {
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}

.form_wrapper_con .MuiBox-root {
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
}

.subBTNWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: end;
}

.subBTNWrapper .subBTN {
    width: 50px;
    height: 50px;
    margin: 0 2px;
}

.subBTNWrapper .subBTN button {
    background: var(--blue2);
    color: #fff;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    padding: 0;
    min-width: inherit;
}

.subBTNWrapper .subBTN button svg {
    width: 35px;
    height: 35px;
}

.listCounttext {
    text-align: center;
    font-size: 21px;
    color: #ffffff;
    font-weight: 600;
    padding: 15px;
    /* background: rgb(254,190,127);
background: linear-gradient(180deg, rgba(254,190,127,1) 0%, rgba(248,127,7,1) 100%); */
    background: rgb(8, 59, 199);
    background: linear-gradient(0deg, rgba(8, 59, 199, 1) 0%, rgba(1, 192, 255, 1) 100%);
    margin: 15px 0;
    border-radius: 4px;
    margin-bottom: 0;
}

.calender_body {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--gray1);
}

.loadMoreSection {
    margin: 0 15px 30px;
}

.loadMoreSection button {
    /* background-color: #00baf7 !important; */
    background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    min-width: 140px;
    font-weight: 700;
    margin: 5px auto;
    display: block;
}


/*************/

.No_slot_div {
    text-align: center!important;
    color: #ffffff!important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%); */
    background: rgb(149 164 167);
    background: linear-gradient(180deg, rgb(223 223 223) 0%, rgb(121 121 121) 100%);
    padding: 30px;
    font-size: 30px;
    font-weight: 600;
    flex-direction: column;
}

.No_slot_div img {
    width: 190px;
    height: auto;
}

.calcelSlot_box .calender_block {
    box-shadow: 1px 1px 13px #00000091;
    background-image: linear-gradient( 174.2deg, rgb(255 255 255 / 72%) 7.1%, rgb(255 255 255 / 68%) 67.4%);
    border: none;
}

.calcelSlot_box .calender_block p strong {
    color: #3c3c3c;
}

.calcelSlot_box .calender_block p {
    color: #0087ae;
    text-shadow: none;
}

.calender_block {
    margin: 0.5%;
    border-radius: 0px;
    /* box-shadow: 1px 1px 9px #00000017; */
    width: 30%;
    transition: transform 0.2s;
    /* background: #f9efcb; */
    /* background: #f87f07; */
    padding: 0.6%;
    border: 1px solid #e3e3e3;
    /* background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_training_BG.webp) no-repeat 0 0; */
    /* background-size: cover; border-radius: 5px; */
    /* background: rgb(0,220,236);
background: linear-gradient(180deg, rgba(0,220,236,1) 0%, rgba(1,184,198,1) 100%); */
    background: rgb(8, 22, 74);
    background: linear-gradient(135deg, rgba(8, 22, 74, 1) 0%, rgba(1, 157, 255, 1) 100%);
    color: #fff;
}

.calender_block:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.calender_block_con {
    height: 100%;
}

.calender_block_con_body {
    padding: 15px;
}


/* .calender_block h2 {
    font-size: 24px;
    color: #148ec7;
    font-weight: 700;
}

.calender_block h2::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #0070af;
    margin: 10px 0;
} */

.calender_block p {
    margin: 0;
    padding: 5px 0;
    font-size: 18px;
    /* color: #333; */
    color: #fff;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
}

.calender_block p span {
    display: inline-block;
    padding-right: 5px;
    font-weight: 700;
}

.calender_block.bookNowModal {
    width: 100% !important;
    background: none;
    box-shadow: none;
    border: none !important;
    word-break: normal;
}

.calender_block.bookNowModal:hover {
    transform: none !important;
}

.addemailbox {
    position: relative;
}

.addemailbox input {
    margin: 5px 0;
}

.addemail {
    padding-right: 10% !important;
}

.addemailBTN {
    width: 10%;
    height: 34px;
    position: absolute;
    right: 0;
    bottom: 5px;
    border: none;
    /* background: #08507f; */
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    background: rgb(8, 22, 74);
    background: linear-gradient(0deg, rgba(8, 22, 74, 1) 0%, rgba(1, 157, 255, 1) 100%);
    box-shadow: none;
}


/* .modalblock .MuiBox-root input {
    width: 98%;
    height: 34px;
    background: #ffffff;
    border: solid 1px #d1c0c0;
    box-shadow: none;
    padding: 0px 0px 0px 4px;
    color: #000;
    padding-right: 0px !important;
} */

.bookingModal .MuiBox-root input {
    width: 98%;
    height: 34px;
    background: #ffffff;
    border: solid 1px #d1c0c0;
    box-shadow: none;
    padding: 0px 0px 0px 4px;
    color: #000;
    padding-right: 0px !important;
}

.slotModal.modalblock .MuiBox-root {
    padding-top: 35px;
    position: relative;
}

.modalblock .MuiBox-root h3 {
    text-align: center;
    margin-top: 10px;
}

.bookingModal.modalblock .MuiBox-root {
    padding: 0px !important;
}

.bookingModal.modalblock .modalBox {
    max-height: 82vh;
    overflow-y: auto;
    border: 0px !important;
    height: 100% !important;
    padding: 15px !important;
    background-color: var(--gray1);
}

.bookingModal .css-1ehqt7t {
    width: 600px;
    max-width: 88%;
}

.bookingModal.modalblock .closeModalBtn,
.deleteModal.modalblock .closeModalBtn,
.custommodal.modalblock .CloseModal {
    position: absolute;
    background: #2076d2 !important;
    top: -20px;
    width: 30px;
    color: #fff;
    height: 30px;
    right: -20px;
    min-width: auto;
    border-radius: 50%;
    z-index: 9;
}

.modalSUBbtn_wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.modalSUBbtn_wrapper .modalSUBbtn {
    /* box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
    color: #fff;
    margin: 2px;
    background: rgb(0, 209, 255);
    background: linear-gradient(180deg, rgba(0, 209, 255, 1) 0%, rgba(1, 176, 255, 1) 100%);
    /* background: rgb(248,154,28);
    background: linear-gradient(180deg, rgba(248,154,28,1) 0%, rgba(241,90,35,1) 100%); */
    box-shadow: none !important;
}


/* .mainContentWrapper .buttonsetToolbar button a {
    background: rgb(22,51,59);
    background: linear-gradient(180deg, rgba(22,51,59,1) 0%, rgba(0,0,0,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.bookingModal h2 {
    color: #000000;
    display: block;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
}

.bookingModal .bookNowModal {
    /* background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(4, 5, 6, 1) 100%); */
    border: solid 1px rgb(0, 0, 0)!important;
    margin: 10px 0!important;
    width: auto!important;
    max-width: inherit!important;
    padding: 15px!important;
    /* background: rgb(0,220,236);
    background: linear-gradient(180deg, rgba(0,220,236,1) 0%, rgba(1,184,198,1) 100%); */
    background: rgb(8, 22, 74);
    background: linear-gradient(135deg, rgba(8, 22, 74, 1) 0%, rgba(1, 157, 255, 1) 100%);
}

.bookingModal textarea {
    resize: none;
    padding: 1%;
    width: 98%!important;
    border: solid 1px #d1c0c0 !important;
    border-radius: 0px!important;
}

.calender_block button {
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 5px 0;
    min-width: 80px;
    padding: 5px 15px;
    /* background: rgb(248,154,28);
    background: linear-gradient(180deg, rgba(248,154,28,1) 0%, rgba(241,90,35,1) 100%); */
    background: rgb(0, 209, 255);
    background: linear-gradient(180deg, rgba(0, 209, 255, 1) 0%, rgba(1, 176, 255, 1) 100%);
    box-shadow: none;
}

.calender_body .calender_block_con_body h2 {
    text-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
}

.form_wrapperSlot {
    /* margin: 0 15px; */
    border-radius: 0px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    background: rgb(0, 87, 194);
    background: linear-gradient(0deg, rgba(0, 87, 194, 1) 0%, rgba(1, 39, 85, 1) 100%);
}


/* .form_wrapperSlot::after{
    content: '';
    display: block;
    width: 100%;
    height: 0%;
    top: -1px;
    border-radius: 0 0 50% 50%;
    background: #daeaf3;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.8s;
} */

.form_wrapperSlot:hover.form_wrapperSlot::after {
    height: 180%;
}

.blank_Heading {
    flex: 28% 1!important;
}

.loaderProgress .MuiLinearProgress-root {
    background-color: #ffffff;
}

.loaderProgress .MuiLinearProgress-bar1 {
    background-color: #18a9e8;
}

.thankYou_wrpr {
    width: 100%;
    min-height: 100vh;
    transition: ease all 0.3s;
    position: relative;
    /* padding: 0 25px 25px 85px; */
    /* background-color: var(--gray1); */
    /* background: rgb(252,243,153);
    background: linear-gradient(137deg, rgb(205, 197, 127) 50%, rgb(214 206 125) 100%); */
    background: rgb(211, 215, 219);
    background: linear-gradient(135deg, rgba(211, 215, 219, 1) 0%, rgba(134, 141, 151, 1) 100%);
    overflow: hidden;
}

.thankyou-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    position: relative;
    z-index: 99;
}

.thankYou_bgdesgn {
    width: 100%;
}

.thankyou-block {
    overflow: hidden;
    width: 100%;
}

.thankYou_wrpr .content {
    /* position: absolute;
top: 50%;
    left: 50%;
transform: translate(-50%, -50%); */
    /* background: rgb(131,203,168);
background: linear-gradient(141deg, rgba(131,203,168,1) 0%, rgba(0,179,210,1) 100%); */
    margin: 25px auto;
    border-radius: 10px;
    padding: 30px;
    color: #ffffffdb;
    width: 62%;
    z-index: 999;
    /* background: #f8f7ed;
background: linear-gradient(90deg, #f8f7ed 0%, #f1eed5 100%); */
    background: rgb(8, 24, 79);
    background: linear-gradient(180deg, rgba(8, 24, 79, 1) 0%, rgba(0, 55, 123, 1) 100%);
    border: 1px solid #ffffff5c;
    border-radius: 0;
    box-shadow: 0 7px 8px -4px #95959573, 0 12px 17px 2px #95959573, 0 5px 22px 4px #95959573;
    /* text-shadow: 1px 1px 6px rgb(0 0 0 / 30%); */
}

.thankYou_wrpr .content h1 {
    font-size: 26px;
    color: #ffffffe8;
    padding: 15px 20px;
    /* background: rgb(251,207,75);
    background: linear-gradient(180deg, rgba(251,207,75,1) 0%, rgba(251,164,31,1) 100%); */
    background: rgb(0, 209, 255);
    background: linear-gradient(180deg, rgba(0, 209, 255, 1) 0%, rgba(1, 176, 255, 1) 100%);
    text-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
    margin-bottom: 25px;
    text-align: center;
}

.thankYou_wrpr .content p {
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;
    color: #00b3fe;
    text-align: center;
}

.thankYou_wrpr .forAspire span p{
    font-size: 26px;
    color: #ffffffe8;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
    text-align: center !important;
    text-transform: initial !important;
}

.thankYou_wrpr .content p strong {
    /* color: #393939; */
    color: #e2e2e2;
}

.thankYou_wrpr .content h2 {
    color: #e2e2e2;
    text-align: center;
    font-size: 30px;
}

.cancelSlotBtnSection button {
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 5px 0;
    min-width: 80px;
    padding: 5px 15px;
    /* background: rgb(248,154,28);
    background: linear-gradient(180deg, rgba(248,154,28,1) 0%, rgba(241,90,35,1) 100%); */
    background: rgb(64, 64, 64);
    background: linear-gradient(180deg, rgba(64, 64, 64, 1) 0%, rgba(7, 7, 7, 1) 100%);
    box-shadow: none;
    margin-right: 10px;
}

.mainContentWrapper {
    min-height: 100vh;
}

.bgdesgn1 {
    position: absolute;
    /* background-color: hsl(54deg 95% 75%); */
    height: 330px;
    width: 330px;
    bottom: 0;
    right: -150px;
    z-index: 9;
    border-radius: 50%;
    /* background: radial-gradient(circle at 50px 50px, #f8f7ed, #ddd155); */
    background: radial-gradient(circle at 101px 47px, #d0d6d8, #ffffff);
    /* box-shadow: 4px 8px 10px rgb(149 149 149 / 45%); */
}

.bgdesgn2 {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    /* background: #f8f7ed; */
    right: 110px;
    bottom: 20%;
    /* background: radial-gradient(circle at 50px 50px, #f8f7ed, #ddd155); */
    background: radial-gradient(circle at 50px 50px, #fffafa, #b4b4b4);
    z-index: 99;
    box-shadow: 4px 8px 10px rgb(149 149 149 / 45%);
}

.bgdesgn3 {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    /* background: #f8f7ed; */
    left: 120px;
    top: 20%;
    /* background: radial-gradient(circle at 47px 57px, #f8f7ed, #ddd155); */
    background: radial-gradient(circle at 47px 57px, #fffafa, #b4b4b4);
    z-index: 99;
    box-shadow: 4px 8px 10px rgb(149 149 149 / 45%);
}


/* .bgdesgn4{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: radial-gradient(circle at 47px 57px, #f8f7ed, #ddd155);
    z-index: 99;

} */

.bgdesgn8 {
    position: absolute;
    /* background-color: hsl(54deg 95% 75%); */
    /* background: radial-gradient(circle at 101px 47px, #f8f7ed, #ddd155); */
    background: radial-gradient(circle at 101px 47px, #d0d6d8, #ffffff);
    height: 330px;
    width: 330px;
    top: 0;
    left: -150px;
    z-index: 9;
    border-radius: 50%;
}


/*************/

.modalblock .BoxMui_modal {
    outline: none!important;
}



.landingpage9success {
    text-align: left;
    position: relative;
    border-top: solid 1px #3f5576;
}

.landingpage9success p {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 20px;
    border-bottom: solid 1px #3f5576;
    padding: 10px 5px;
}

.landingpage9success p strong {
    width: 49%;
    font-weight: normal;
}

.landingpage9success p strong span {
    display: block;
    width: 100%;
    font-size: 16px;
}

.landingpage9success p span {
    width: 49%;
}

.landingpage9successBody {
    position: relative;
}

.landingpage9successBody .editiconinsucccess {
    background: rgb(38, 244, 254);
    background: linear-gradient(180deg, rgba(38, 244, 254, 1) 0%, rgba(75, 219, 254, 1) 50%, rgba(41, 206, 254, 1) 51%, rgba(74, 177, 254, 1) 100%);
    border: solid 1px #fff;
    cursor: pointer;
    border-radius: 10px;
    font-size: 35px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    width: 180px;
    text-align: center;
    line-height: 60px;
    margin: 15px;
    margin-bottom: 0;
    border-radius: 4px;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}
.bttnWrpr{display: flex; justify-content: center;}
.blueCoast_Success .thankyou-container .content h1 span:nth-child(1){display: block;}

.successmodal9 {
    padding: 0!important;
    width: 800px!important;
    max-width: 90%;
}

.successmodal9 .formWrapperClass {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    padding: 15px;
}

.successmodal9 .closeModalBtn {
    min-width: 35px!important;
}

.successmodal9 h2 {
    margin-bottom: 0!important;
    border-bottom: solid 1px #ccc;
}

.successmodal9 .MuiFormLabel-filled,
.successmodal9 label.Mui-focused,
.successmodal9 legend {
    display: none;
}

.successmodal9 .MuiInputLabel-root div {
    text-align: left;
    font-size: 16px;
    white-space: normal;
    margin-bottom: 10px;
    font-weight: bold;
    color: #111;
    word-wrap: break-word;
}

.successmodal9 .formfild {
    margin-bottom: 15px;
}

.successmodal9 .form_radio {
    text-align: left;
}

.successmodal9 fieldset {
    border: solid 1px #ccc!important;
    border-radius: 0px;
    outline: none!important;
    top: 0 !important;
}

.successmodal9 .textareafild{ position: relative; font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;}
.successmodal9 .textareafild .MuiFormControl-root label{ display: none;}
.successmodal9 .textareafild textarea{  border: solid 1px #ccc!important;   border-radius: 0px; outline: none!important; resize: none; height: 80px!important;     padding: 16.5px 14px;  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;     font-size: 1rem; width: 100%;}

    .successmodal9 .submitbtnsection9{    display: block;  text-align: center;   width: 100%;}
.successmodal9 .submitbtnsection9 button{ background: rgb(38,244,254)!important;
    background: linear-gradient(180deg, rgba(38,244,254,1) 0%, rgba(75,219,254,1) 50%, rgba(41,206,254,1) 51%, rgba(74,177,254,1) 100%)!important;
border: solid 1px #fff; cursor: pointer; border-radius: 10px; font-size: 35px; color: #fff; text-transform: uppercase; font-weight: bold; width: 200px;
line-height: 50px; border-radius: 4px;     text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}

 
.successmodal9  .formfild .MuiInputBase-formControl{ display: block;}
 
.successmodal9 .MuiFormControl-root {
    display: block!important; 
}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    display: block !important;
    color: #000 !important;
    visibility: visible !important;
} */

.successmodal9 .textareafild {
    position: relative;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

.successmodal9 .textareafild .MuiFormControl-root label {
    display: none;
}

.successmodal9 .textareafild textarea {
    border: solid 1px #ccc!important;
    border-radius: 0px;
    outline: none!important;
    resize: none;
    height: 80px!important;
    padding: 16.5px 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

.successmodal9 .submitbtnsection9 {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 15px;
}

.successmodal9 .submitbtnsection9 button {
    background: rgb(38, 244, 254)!important;
    background: linear-gradient(180deg, rgba(38, 244, 254, 1) 0%, rgba(75, 219, 254, 1) 50%, rgba(41, 206, 254, 1) 51%, rgba(74, 177, 254, 1) 100%)!important;
    border: solid 1px #fff;
    cursor: pointer;
    border-radius: 10px;
    font-size: 35px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    width: 200px;
    line-height: 50px;
    border-radius: 4px;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}
.associatuserModal .property_container_main .property_container{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}
.associatuserModal .property_container_main .property_container:nth-child(odd){
    background: #efefef;
}
.associatuserModal .BoxMui_modal h4 .modal_title{
    font-size: 25px;
    padding: 15px;
}
.calcelSlot_box .cancelSlotBtnSection{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 1460.98px) {
    .blank_Heading {
        flex: 10% 1!important;
    }
}

@media only screen and (max-width: 1199.98px) {
    .calender_block {
        width: 46%!important;
        padding: 1%!important;
    }
    .miscList .rolelist_mainwrp_Table .MuiTableContainer-root td:last-of-type {
        padding-left: 50% !important;
    }
    .thankYou_wrpr .content {
        width: 95%;
    }
}

@media only screen and (max-width: 1060.98px) {
    .form_wrapper_con {
        width: 100%;
        margin: 0 auto;
    }
    .form_wrapper_con .form_group {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .form_wrapper {
        padding: 15px;
    }
}

@media only screen and (max-width: 991.98px) {
    .connectedemail {
        display: block;
        text-align: center;
    }
    .calenderinfo {
        margin-top: 15px;
    }
    .buttonsetToolbar .MuiButtonBase-root {
        width: 49%;
        margin-bottom: 10px;
    }
    .thankYou_wrpr .content h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 15px;
    }
    .thankYou_wrpr .content p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767.98px) {
    .calender_body {
        display: block;
    }
    .calender_block {
        margin: 0 auto!important;
        width: 90%!important;
        margin-top: 15px!important;
        padding: 1.5%!important;
    }
    .calender_block:first-of-type {
        margin-top: 0;
    }
}

@media only screen and (max-width: 640.98px) {
    .buttonsetToolbar .MuiButtonBase-root {
        width: 98%;
        margin: 5px auto;
    }
    /* .buttonsetToolbar{
        padding: 1% 1% 2%;
    } */
    .buttonsetToolbar .MuiButtonBase-root::after {
        content: none;
    }
    .thankYou_wrpr .content {
        padding: 15px;
    }
    .thankYou_wrpr .content p {
        line-height: 27px;
    }
}

@media only screen and (max-width: 600.98px) {
    /* .buttonsetToolbar{
margin: 0;
} */
}

@media only screen and (max-width: 480px) {
    .thankYou_wrpr .innerSpan {
        word-break: break-all;
    }
    .thankYou_wrpr .content h1 span{
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 400.98px) {
    .form_wrapper_con .MuiDateRangePickerInput-root {
        display: block;
    }
    .form_wrapper_con .MuiBox-root {
        text-align: center;
        margin: 10px 0;
    }
    .calender_block {
        width: 88%;
        padding: 2%;
    }
}

.sun-editor-editable {
    height: 450px!important
}

.createEventadd {
    /* margin: 10px 20px; */
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin-bottom: 25px;
    border-radius: 8px;
}

.createEventadd .formInline {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap
}

.createEventadd .formInline label {
    color: #000
}

.createEventadd .formInline .inputBlock4line {
    flex: 1 0 24%;
    margin: 10px 0.3%
}

.createEventadd .formInline .inputBlock3line {
    flex: 1 0 32%;
    margin: 10px 0.3%
}

.createEventadd .formInline .inputBlock2line {
    flex: 1 0 48%;
    margin: 10px 0.3%
}

.createEventadd .formInline .inputBlock1line {
    flex: 100%;
    margin: 10px 0.3%
}

.createEventadd .formInline .inputBlock .MuiFormControl-root.MuiTextField-root {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    border: solid 1px #ccc;
    height: 48px
}

.createEventadd .formInline .inputBlock .MuiFormControl-root.MuiTextField-root input,
.createEventadd .formInline .inputBlock .MuiSelect-select {
    padding: 12px 14px
}

.createEventadd .formInline .inputBlock .MuiFormControl-root .MuiInputLabel-root {
    top: -4px;
    display: none
}

.createEventadd .formInline .inputBlock .MuiFormControl-root {
    width: 100%;
    margin-bottom: 0
}

.createEventadd .formInline .inputBlock .MuiOutlinedInput-root {
    border-radius: 0
}

.createEventadd .formInline .inputBlock .MuiOutlinedInput-notchedOutline {
    border: 0!important
}

.createEventadd .formInline .inputBlock .MuiInputLabel-shrink {
    display: none!important
}

.createEventadd .formInline .usertypebox .MuiSelect-select {
    padding: 8px 14px;
    height: 31px
}

.createEventadd .formInline .usertypebox .MuiFormControl-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc
}

.createEventadd .formInline .passwordinput .MuiFormControl-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc
}

.createEventadd .formInline .passwordinput .MuiFormControl-root input {
    padding: 12px 14px
}

.createEventadd .formInline .addressbox {
    flex: 0 0 99.4%;
    margin: 10px 0.3%
}

.createEventadd .formInline .addressbox textarea {
    background-color: #fff;
    width: 97.5%;
    margin-bottom: 0;
    border: solid 1px #ccc!important;
    resize: none;
    min-height: 500px!important;
    color: black;
    padding: 10px 1%;
    outline: none!important
}


/* .Formsubmit_button {
    display: block;
    overflow: hidden;
    margin-top: 10px
} */


/* .Formsubmit_button button {
    background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    color: #d5d6d6;
    font-size: 20px;
    padding: 10px 20px;
    min-width: 140px;
    font-weight: 700;
    margin: 5px
} */


/* .Formsubmit_button button:first-of-type {
    margin-right: 0
} */

.createEventadd input:-webkit-autofill,
.createEventadd input:-webkit-autofill:hover,
.createEventadd input:-webkit-autofill:focus,
.createEventadd textarea:-webkit-autofill,
.createEventadd textarea:-webkit-autofill:hover,
.createEventadd textarea:-webkit-autofill:focus,
.createEventadd select:-webkit-autofill,
.createEventadd select:-webkit-autofill:hover,
.createEventadd select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0
}

.createEventadd .css-1wc848c-MuiFormHelperText-root .Mui-error {
    color: #d32f2f;
    bottom: -2px;
    font-size: 11px;
    position: absolute
}

.createEventadd .MuiFormHelperText-root {
    color: #ffffff;
    background: #a90000;
    font-size: 11px;
    line-height: 15px;
    margin: 4px 0 0;
    text-align: center;
    /* position: absolute */
}

.addedittext {
    font-size: 28px;
    width: 80%;
    background-color: #002236;
    padding: 15px 5px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
    border-radius: 0 0 25px 25px
}

.searchlabel {
    width: 100%;
    background-color: #002236;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 5px
}

.btnabel {
    width: 100%;
    background-color: #002236;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 5px
}

.Image_Box #list h1 {
    font-size: 18px!important;
}

.Image_Box .blog_img_wrpr .upload-container .MuiButtonBase-root {
    width: 36px!important;
    height: 36px!important;
}

.inputBlockday {
    flex: 6%!important;
    margin: 0 0.3%!important;
    margin-bottom: 16px!important;
    background: #fff;
    padding: 0 0.5%;
    border: solid 1px #ccc;
}

@media only screen and (max-width: 1199.98px) {
    .createEventadd .formInline {
        display: block
    }
    .createEventadd .inputBlockday {
        padding: 0 5px;
        width: 130px;
        margin-bottom: 4px!important;
        display: inline-block;
    }
    .bookingModal .css-1ehqt7t {
        max-width: 80%!important;
    }
}

.formInline input {
    width: 100%;
}

@media only screen and (max-width: 1199.98px) {
    .landingpage9success p {
        display: block;
    }
    .landingpage9success p strong {
        width: auto;
        display: block;
    }
    .landingpage9success p span {
        width: auto;
    }
    .landingpage9success p strong span {
        width: auto;
        display: inline-block;
    }
}
.daterangecls .datePicker_wrpr .MuiFormControl-root .MuiInputLabel-shrink{display: none !important;}

.datePicker_wrpr .MuiFormControl-root .MuiInputLabel-root{display: block !important; color: #000 !important;font-weight: 400;}
.attendeeModal{

}
.attendeeModal .MuiBox-root {
    width: min(100%, 780px);
    border: 10px solid #08507f;
}
.attendeeModal #modal-modal-title {
    background-color: #5293a3;
    color: #ffffff;
    padding: 10px;
    font-size: 20px !important;
    text-align: center;
}
.attendeeModal .addEmail_main {
    margin-top: 20px;
}
.attendeeModal .addemailbox {
    display: flex;
    flex-direction: column;
}
.attendeeModal .addemailbox .addemail {
    width: 100%;
    margin: 5px 0;
}
.attendeeModal .MuiBox-root input {
    border: unset !important;
    width: 100% ;
    margin: 0px ;
    font-size: 14px;
}

@media only screen and (max-width: 780px) {
    .attendeeModal .MuiBox-root {
        width: 95%;
        margin: 0 auto;
    }
}

/* MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root */

/* MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root */

/* MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root

MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root */