.LandingPage9FormCon .previewfield .formfild6 {
  flex: 1 0 11.33%;
  margin: 5px 0.3%;
}
.hmSelection .MuiAutocomplete-root {
  min-width: 100%;
}
.LandingPage9FormCon .previewfield .textareafild {
  margin-top: 25px;
}
.LandingPage9FormCon .previewfield .formfild4 {
  width: 24.4% !important;
}
.LandingPage10Body .LandingPage9FormCon .previewfield .form_item {
  margin: 5px 0.3%;
  margin-bottom: 8px;
}
.LandingPage10Body .LandingPage9FormCon .submitbtnsection9{
  margin-top: 13px;
}
.LandingPage10Body .LandingPage9Body_TopRight h2{
  line-height: 41px;
}
.LandingBlCst_New .LandingPage9Body_TopRight h2{
  font-size: 36px;
  line-height: 38px;
}
.LandingBlCst_New .LandingPage10Body .passwordCls p{color: rgb(255, 255, 255);}
@media only screen and (max-width: 1360.98px) {
  .LandingPage9FormCon .previewfield .formfild4 {
    width: 49.4% !important;
  }
  .LandingPage9FormCon .previewfield .formfild6 {
    flex: 1 0 30.5%;
  }
}
@media only screen and (max-width: 680.98px) {
  .LandingPage9FormCon .previewfield .formfild4 {
    width: 100% !important;
  }
  .LandingPage9FormCon .previewfield .formfild6 {
    flex: 1 0 100%;
  }
}
