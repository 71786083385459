/* ------------------------------abhranil----------------------------------------------------------- */

.lp18_video_block_main_wrapper {
    background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/access_video_block_girl_bg1.webp),
        url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/access_video_block_main_bg.webp);
    background-repeat: no-repeat;
    background-size: auto, cover;
    background-position: left bottom, center center;
    padding: 30px;
}

.lp18_blue_heading_wrapper {
    background: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/access_video_block2_text_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 15px;
    background-position: top right;
}

.lp18_blue_heading_wrapper h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.lp18_blue_heading_wrapper span {
    font-size: 33px;
    font-weight: 700;
    color: #00f0ff;
}

.lp18_video_sec .landpage_videowrapper .landpage_video_subwrapper .page5_react_player {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
}

/* .lp18_video_sec .landpage_videowrapper .landpage_video_subwrapper {  
    padding-top: 0;
} */
/* .lp18_video_sec .landpage_videowrapper .landpage_video_subwrapper .react-player__preview {
     padding-top: 56.25%;
    background-size: 100% 100% !important;
    position: relative;
} */

.lp18_video_sec .landpage_videowrapper {
    width: auto;
    margin: 0;
}

.lp18_video_sec .react-player__shadow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #00000099 !important;
    width: 75px;
    height: 75px;
}

.lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp {
    margin-top: 36px;
    text-align: left;
}

.lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
    font-size: 54px;
    font-weight: 700;
    line-height: 59px;
    color: #fff;
    margin-bottom: 0;
}

.lp18_video_sec p {
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    color: #00ebff;
}

.lp18_video_sec {
    margin-left: 25%;
}

.lp18_video_sec .l5_videowrapper {
    width: 950px;
}


/* //////////////////////////////////responsive////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1699px) {
    .lp18_blue_heading_wrapper h1 {
        font-size: 28px;
        line-height: 35px;
    }

    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 50px;
        line-height: 55px;
    }

    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp {
        padding: 10px 15px;
        margin-top: 25px;
    }

    .lp18_video_sec p {
        font-size: 22px;
        line-height: 28px;
    }

    .lp18_video_block_main_wrapper {
        padding: 25px;
        background-position: left -50px bottom, center center;
    }

}

@media only screen and (max-width: 1499px) {
    .lp18_blue_heading_wrapper h1 {
        font-size: 26px;
        line-height: 31px;
    }

    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 45px;
        line-height: 50px;
    }

    .lp18_video_block_main_wrapper {
        padding: 20px;
        background-position: left -68px bottom, center center;
    }

    .lp18_video_sec .l5_videowrapper {
        margin-bottom: 15px;
    }

}

@media only screen and (max-width: 1399px) {
    .lp18_video_block_main_wrapper {
        padding: 10px;
        background-position: left -110px bottom, center center;
    }

    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp {
        margin-top: 20px;
    }

    .lp18_blue_heading_wrapper h1 {
        font-size: 22px;
        line-height: 28px;
    }

    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 40px;
        line-height: 46px;
    }

    .lp18_video_sec .l5_videowrapper {
        width: 96%;
    }
}

@media only screen and (max-width: 1299px) {
    .lp18_video_block_main_wrapper {
        background-position: left -130px bottom -54px, center center;
    }

    .lp18_blue_heading_wrapper h1 {
        font-size: 20px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1199px) {
    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp {
        width: auto;
        border-radius: 15px 15px 0 0;
        margin: 0;
        text-align: center;
    }

    .lp18_video_sec .l5_videowrapper {
        width: 100%;
    }

    .lp18_video_sec {
        margin-left: 0;
    }

    .lp18_blue_heading_wrapper {
        margin-bottom: 15px;
    }

    .lp18_video_block_main_wrapper {
        background: none;
        background-color: #c6e2ec;
    }

    .lp18_blue_heading_wrapper h1 {
        font-size: 17px;
        line-height: 23px;
    }

    .lp18_video_sec .l5_videowrapper {
        margin-bottom: 10px;
    }

    .lp18_blue_heading_wrapper span {
        font-size: 22px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 799px) {
    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 35px;
        line-height: 40px;
    }

    .lp18_video_sec p {
        font-size: 17px;
        line-height: 22px;
    }

    .lp18_blue_heading_wrapper span {
        font-size: 20px;
        line-height: 25px;
    }

    .lp18_video_sec .l5_videowrapper {
        margin-bottom: 5px;
    }

    .lp18_blue_heading_wrapper {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 699px) {
    .lp18_video_sec .l5_videowrapper .l5_vdo_txt_wrp h2 {
        font-size: 30px;
        line-height: 36px;
    }
}


/* ------------------------------abhranil----------------------------------------------------------- */










/* -------------------------------------biswarup------------------------------------------------------- */
.lp18_block3_main_wrapper {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspire-landingpages/Landingpage18/lp18_block3_blue_bac_img.webp);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
    margin-bottom: 70px;

}


.lp18_block3_sub_width {
    padding: 30px;
}

.lp18_block3_text_section h1 {
    font-size: 50px;
    line-height: 56px;
    background: rgb(223, 242, 254);
    background: linear-gradient(180deg, rgba(223, 242, 254, 1) 41%, rgba(111, 171, 217, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    text-align: center;
    margin-bottom: 50px;
}

.lp18_block3_box_section {
    width: 1470px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.lp18_block3_square_box {
    width: 400px;
    background: linear-gradient(180deg, rgba(252, 252, 252, 1) 16%, rgba(237, 237, 237, 1) 85%);
    border-radius: 8px;
    padding: 0 10px;
    position: relative;
    padding-bottom: 50px;
}

.lp18_block3_square_box::after {
    content: '';
    background-color: #ffa31f;
    position: absolute;
    width: 300px;
    height: 15px;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
}

.lp18_block3_box_img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    top: -25px;

}

.lp18_block3_box_img img {
    border-top: 8px solid #ffa31f;
}

/* .lp18_block3_box_img::after {
    position: absolute;
    content: '';
    background-color: #ffa31f;
    width: 168px;
    height: 10px;
} */

.lp18_block3_box_text h2 {
    font-size: 38px;
    line-height: 44px;
    color: #144b72;
    text-align: center;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
}

.lp18_block3_box_text h1 {
    font-size: 40px;
    line-height: 45px;
    color: #060809;
    text-align: center;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
}

.lp18_block3_box_text p {
    font-size: 22px;
    line-height: 28px;
    color: #1f1f1f;
    text-align: center;
    font-weight: 600;
}

.img_part_2 img {
    border-top: 8px solid #e2ca0b;
}

.img_part_3 img {
    border-top: 8px solid #ef5577;
}

.lp18_block3_green_button {
    position: relative;
    bottom: -55px;
}

.lp18_block3_green_button .gradiant_fontBW.gradiant_black_darkBG {
    margin: 0 auto;
}

/* .img_part_2::after {
    background-color: #e2ca0b;
}

.img_part_3::after {
    background-color: #ef5577;
} */

.box_part_2::after {
    background-color: #e2ca0b;
}

.box_part_3::after {
    background-color: #ef5577;
}

.lp18_block3_green_button .gradiant_fontBW h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    background: rgb(244, 244, 244);
    background: linear-gradient(180deg, rgba(244, 244, 244, 1) 32%, rgba(178, 180, 180, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lp18_block3_green_button.gradiant_black_darkBG {
    padding: 30px 40px;
}



/* ///////////////////////////////////////////////block3-responsive///////////////////////////////////////////////////////// */
@media only screen and (max-width: 1799px) {
    .lp18_block3_box_section {
        width: 90%;
    }

    .lp18_block3_square_box::after {
        width: 75%;
    }

    .lp18_block3_box_img::after {
        width: 36.5%;
    }

    .lp18_block3_square_box {
        width: 32%;
    }
}

@media only screen and (max-width: 1540px) {
    .lp18_block3_box_section {
        width: 96%;
    }
}

@media only screen and (max-width: 1199px) {
    .lp18_block3_green_button .gradiant_black_darkBG {
        padding: 20px 0;
        width: 100%;
    }

    .lp18_block3_green_button .gradiant_fontBW h2 {
        font-size: 30px;
        line-height: 35px;
    }

    .lp18_block3_green_button {
        bottom: 0;
    }

    .lp18_block3_box_section {
        margin-bottom: 25px;
    }
    .lp18_block3_main_wrapper {
        margin-top: 30px;
        margin-bottom: 30px;
        background-position: center center;
    }    
}

@media only screen and (max-width: 991px) {
    .lp18_block3_box_section {
        flex-direction: column;
        margin-bottom: 0;
    }

    .lp18_block3_square_box {
        width: 100%;
        margin-bottom: 40px;
    }

    .box_part_3 {
        margin-bottom: 20px;
    }
}


@media only screen and (max-width: 699px) {
    .lp18_block3_box_img img {
        width: 25%;
    }

    .lp18_block3_text_section h1 {
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 40px;
    }

    .lp18_block3_box_text h2 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 15px;
    }

    .lp18_block3_box_text h1 {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 15px;
    }

    .lp18_block3_box_text p {
        font-size: 20px;
        line-height: 26px;
    }

    .lp18_block3_square_box {
        padding-bottom: 40px;
    }

    .lp18_block3_green_button .gradiant_fontBW h2 {
        font-size: 28px;
        line-height: 33px;
    }

    .lp18_block3_green_button.gradiant_black_darkBG {
        padding: 15px 40px;
    }

    .lp18_block3_box_img {
        top: -20px;
    }
    .lp18_block3_sub_width {
        padding: 20px 10px;
    }
    .lp18_block3_main_wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .lp18_block3_text_section h1 {
        font-size: 42px;
        line-height: 48px;
        margin-bottom: 35px;
    }

    .lp18_block3_box_text h2 {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .lp18_block3_box_text h1 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 10px;
    }

    .lp18_block3_box_text p {
        font-size: 18px;
        line-height: 24px;
    }

    .lp18_block3_green_button .gradiant_fontBW h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .lp18_block3_green_button .gradiant_black_darkBG {
        padding: 10px;
    }

    .lp18_block3_box_img {
        top: -15px;
    }
}


/* ///////////////////////////////////////////////block3-responsive///////////////////////////////////////////////////////// */

/* -------------------------------------biswarup------------------------------------------------------- */