.title_sub_wrp label {white-space: pre-wrap;}
.title_sub_wrp textarea {height: 58px !important;border: 1px solid #ccc !important;border-radius: 5px;resize: none;padding: 10px;}
.title_sub_wrp .MuiFormGroup-root {width: fit-content;}
.interview_wrp .heading h1 {font-size: 25px;}
.interview_wrp .Formsubmit_button button {display: block;margin: 0 auto;}
.interview_wrp .Formsubmit_button {margin-top: 20px;}
.inline3 {flex: 1 0 30%;margin-right: 10px;}
.interview_wrp .previewfield {display: flex;flex-wrap: wrap;}
.inputBlock1line {flex: 1 0 98%;}
.information_template{
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    margin: 0 -1%;
}
.information_template_each{
    width: 31% !important;
    margin: 17px 1% !important;
}


@media only screen and (max-width: 1399px) {
.interview_wrp .heading h1 {font-size: 20px;line-height: 25px;margin: 5px 0;word-break: break-word;}
.inline3 {flex: 1 0 45%;}
}
@media only screen and (max-width: 1399px) {
.inline3 {flex: 1 0 30%;}
}
@media only screen and (max-width: 799px) {
 .interview_wrp .title_sub_wrp label {font-size: 14px;}
.interview_wrp .title_sub_wrp .MuiFormControl-root .MuiFormControlLabel-root span {font-size: 14px;}
}
@media only screen and (max-width: 570px){
.inline3 {flex: 1 0 45%;}
}
@media only screen and (max-width: 499px) {
    .inline3 {flex: 1 0 98%;margin-right: 0;}
    .interview_wrp .title_sub_wrp label {font-size: 18px;}
    .interview_wrp .title_sub_wrp .MuiFormControl-root .MuiFormControlLabel-root span {font-size: 16px;}
}
@media only screen and (max-width: 399px) {
    .interview_wrp .heading h1 {font-size: 18px;line-height: 22px;}
}

