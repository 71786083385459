.profile_block_left{

}
.profile_block_left img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}


.replyblock .folder_view_left{
    width: 420px;
}
.profile_block {
    display: flex;
    margin-top: 10px;
    background: #edece2;
    padding: 10px;
    border: 1px solid #e7e6e1;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}
.profile_block.active .profile_block_right h4{color: #2a1602;}
.profile_block.active{
    background: #f1cf6e;
    border: 1px solid #ad8a29;
    position: relative;
}
/* .profile_block.active::after{
    content: '';
    height: 40px;
    width: 40px;
    background-color: #ddd;
    position: absolute;
    right: 20px;
    top: 20px;
} */
.profile_block_left {
        width: 140px;
    margin-right: 15px;
    overflow: hidden;
    background: #fbf7ec;
    padding: 6px;
    border: 1px solid #ddd;
}
.profile_block_right {
    width: calc(100% - 155px);
}
.profile_block_right h4{
        font-size: 18px;
        line-height: 24px;
        font-weight: var(--main-font-SemiBold);
        color: #fa9427;
        margin-bottom: 15px;
}
.profile_block_right h4:last-child{margin-bottom: 0;}
.profile_block_right h4 span{
    display: block;
    font-weight: var(--main-font-Medium);
    color: #000;
    font-size: 15px;
}
.profile_textblock h2 {
    display: flex;
    margin: 7px 0;
    align-items: center;
}
.profile_textblock h2 strong{
    background: #2176b0;
    color: #fff;
    padding: 5px;
    padding-right: 18px;
    clip-path: polygon(0 0, 90% 0%, 100% 50%, 90% 100%, 0 100%);
    display: inline-block;
    margin-right: 5px;
    font-size: 18px;
    font-weight: var(--main-font-Medium);  
    width: 180px;  
    margin-right: 10px;
}
.profile_textblock h2 span{
    font-size: 18px;
    font-weight: var(--main-font-Medium);
    color: #000;
    width: calc(100% - 190px);
}

.replyblock .replyblock_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: var(--main-font-Medium);
}

.replyblock .replyblock_title span:nth-child(2){
    color: #fa9427;
}
.replyblock .folder_view_con {
    width: calc(100% - 440px);
    margin-left: 20px;
}

.replyblock .folder_view_con h4 strong {
    color: var(--orange);
    font-weight: 600;
    width: 150px;
}
.replyblock .folder_view_con h4 span {
    width: calc(100% - 150px);
}

.replyblock .folder_view_con h4 {
    margin: 0;
    border-bottom: solid 1px #ebebeb;
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    padding: 10px;
    color: #333;
    font-weight: var(--main-font-Medium);
}
.replyblock .folder_view_con p{background: none;border: 0;padding: 0;display: block;margin-bottom: 10px;}
.replyblock .folder_view_con p:last-child{margin: 0;}
.replybtnwrapper button{
    margin: 20px 0;
    margin-right: 8px;
}

.chat_mainwrapper{
    display: flex;
    padding-top: 20px;
}
.chat_user{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    border: 4px solid #ffecb6;
}
.chat_user img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.chat_content{
    width: calc(100% - 70px);
    background-color: #fffbef;
    border-radius: 0px 8px 8px 8px;
    box-shadow: 7px 4px 15px rgb(135 100 2 / 23%);
    padding: 10px;
    border: 1px solid #ffe8a5;
}


.replyfiles img{width: 100%;display: block;}


.replay_imgandvideomodal .BoxMui_modal{
outline: none;width: 800px;
}
.replay_imgandvideomodal img, .replay_imgandvideomodal video, .replay_imgandvideomodal audio{width: 100%;display: block;}

.replay_imagewrapper {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border: 1px solid #06314e;
    border-radius: 5px;
    padding: 2px;
    box-shadow: 0 0 8px rgba(2, 33, 63, 0.3);
    cursor: pointer;
    background: #2176b0;
}
.replay_imagewrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.8;
    transition: all 0.4s;
}
.replay_imagewrapper:hover.replay_imagewrapper img {opacity: 1;}
.filewrap {
    margin-right: 14px;
    position: relative;
}
.filewrap::after{
    content: '';
    height: 100%;
    width: 1px;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(139,119,63,1) 50%, rgba(83,170,173,0) 100%);
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
}
.filewrap:last-child:after{display: none;}
.fileswrapper {
    display: flex;
    flex-wrap: wrap;
}
.reply_text{
    margin-bottom: 15px;
}


.folder_view_con_title.replyrighttitle{
display: flex;
justify-content: space-between;
}

.righttitlecon {
    min-width: 300px;
}
.lefttitlecon {
    width: calc(80% - 320px);
    margin-right: 20px;
}
.righttitlecon fieldset {border-color: rgba(0, 0, 0, 0.23) !important;}
.folder_view_con h3 .righttitlecon legend{display: none;}
.folder_view_con h3 .righttitlecon svg{
        top: 11px;
    right: 2px;
}
.jobticket_blog_list {
    width: calc(20% - 10px);
    margin: 5px;
    background: #fff;
    padding: 8px;
    cursor: pointer;
}
.jobticket_blog_listwrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.jobticket_blog_list_image img {
    width: 100%;
}
.jobticket_blog_list_image {
    background: #fff;
    padding: 8px;
}


@media only screen and (max-width: 1199px) {
  .replyblock .folder_view_left {
    width: auto;
}
.replyblock .folder_view_con {
    width: auto;
    margin-left: 0;
}
}
@media only screen and (max-width: 540px) {
.profile_block_left {
    width: auto;
    margin-right: 0;
    margin-bottom: 10px;
}
.profile_block_right {
    width: 100%;
    text-align: center;
}
.profile_block{flex-wrap: wrap;}
.profile_textblock h2{
    flex-wrap: wrap;
    border: 1px solid #cbc8af;
    padding: 5px;
}
.profile_textblock h2 strong{
        width: 100%;
    margin-right: 0;
    display: block;
    clip-path:inherit;
        margin-bottom: 5px;
}
.profile_textblock h2 span {
    width: 100%;
}

}
