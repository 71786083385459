.commercial_banner_BG{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_bannerMainBG.webp') ; 
 background-repeat: no-repeat; background-position: right top;}



 .new_commercial_banner_BG{ background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/landingpage4_newBanner_bg1.webp); background-size: cover;}
 .new_commercial_banner_img1{ background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/landingpage4_newBanner_img1one.webp);  background-position:810px top; background-repeat: no-repeat;}
 .new_commercial_wrapper{width: 1796px; margin: 0 auto;  display: flex; flex-wrap: wrap; justify-content: space-between;}
 
 .new_commercial_banner_right{ width: 478px;}
 .new_commercial_banner_left_con{ width: 942px;}
 .new_commercial_banner_left h2{color: #09407f; font-size: 80px; font-weight: 800; margin: 0; padding: 0; text-transform: uppercase;  line-height: 79px;}
 .new_commercial_banner_left h3{color: #13b2ff; font-size: 58px; font-weight: 800; margin: 0; padding: 0; text-transform: uppercase;  line-height: 48px;}
 .new_commercial_banner_left h3 span{color: #042359;}
 .new_commercial_banner_left h4{color: white; background-color: #04134d; width: 853px; font-size: 24px; padding: 17px 20px; border: 10px solid white; font-weight: 600; margin-top: 22px; }
 .new_commercial_banner_left_blocktext {   width: 800px; height: 410px; overflow: hidden; background-repeat: no-repeat; margin-top: -25px; background-size: 100%;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon p{font-size: 16px;  color: #fff;  line-height: 20px;  text-align: center;  width: 220px;  margin: 0 auto;  margin-top: 40px;}
 .new_commercial_banner_left_blocktext{width: 788px; display: flex; flex-wrap: wrap; justify-content: space-between; overflow: hidden;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon{ background: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/new_commrcial_banner_textBG3.png);     margin: 20px 0;     width: 260px;  height: 378px;   overflow: hidden;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(1) h5{color: #5e59a7;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(2) h5{ color: #0278c7;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(3) h5{ color: #14956f;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(1){  background-position: left top;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(1) img{display: none;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(2) img{display: none;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(3) img{display: none;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(2){  background-position: 534px top;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(3){  background-position: 263px top;}
 .new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon h5 {  font-size: 22px;  text-transform: uppercase;  text-align: center;  width: 200px;   margin: 0 auto;  margin-top: 126px;   line-height: 24px;}
 .new_l4form_box {    background: linear-gradient(180deg, rgba(31,48,55,1) 0%, rgba(1,1,1,1) 100%); padding: 15px;  border: solid 1px #fff;  border-radius: 10px;   margin-top: 96px;}
 .l4form_box .l4form_style_heading{  background: rgb(7,165,224); background: linear-gradient(180deg, rgba(7,165,224,1) 0%, rgba(3,86,168,1) 100%);  border: solid 1px #fff; border-radius: 2px;   text-align: center;}
.l4form_box .l4form_style_heading h2{ position: relative; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_formBGBG1.webp');  background-size: cover; margin: 0; padding: 28px 10px; font-size: 32px;   line-height: 33px; text-transform: uppercase; color: #fff; text-shadow: 0 0 1px #082c4e;}
.l4form_box .l4form_style_heading h2::before{ content: ''; display: block; width: 36px; height: 36px; background: #fa1717; clip-path: polygon(100% 0, 0 0, 0 100%); position: absolute; left: 0; top: 0;}
.new_l4form_style .MuiFormControl-root{width: 100%;}
.new_l4form_style .formfild{margin-bottom: 12px;}
.new_l4form_style .MuiInputBase-root{border-radius: 0;}
.new_l4form_style .MuiOutlinedInput-notchedOutline{border: 1px solid #414e50 !important;}
/* .new_l4form_style .formfild input{background-color: #000000;     color: #8b9093;     padding: 5px 14px; height: 44px;} */
.new_l4form_style .MuiFormLabel-root{font-weight: var(--main-font-Bold);color: #787e81 !important;     top: 0px;}
.new_l4form_style .MuiFormLabel-filled{display: none;}
.new_l4form_style label.Mui-focused{display: none;}
.new_l4form_style legend{display: none;}
.new_l4form_style fieldset{top: 0 !important;}
.new_l4form_style .form_radio .MuiFormGroup-root{display:flex;flex-direction:row;align-items:center}
.new_l4form_style .form_radio .MuiFormControlLabel-root{color: #fff;}
.new_l4form_style .form_radio .MuiFormControlLabel-root .MuiSvgIcon-root path{display:none}
 .new_l4form_style .formfild input { background-color: #e2e5e6; color: #787e81;  padding: 5px 15px; height: 44px;     }
 .css-1nrlq1o-MuiFormControl-root{width: 100%;}
 .l4submitbtnsection{margin-top: 8px;}
 .new_l4form_style_heading{margin-bottom: 8px;}
 .new_l4form_box .new_l4form_style_heading h2 { position: relative; background: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_formBGBG1.webp); background-size: cover; margin: 0; padding: 28px 10px; font-size: 32px;
    line-height: 33px; text-transform: uppercase; color: #fff; text-shadow: 0 0 1px #082c4e;}
 .new_l4form_box .new_l4form_style_heading h2::before { content: ''; display: block;  width: 36px; height: 36px;  background: #fa1717; clip-path: polygon(100% 0, 0 0, 0 100%);  position: absolute; left: 0; top: 0;}
 .new_l4form_box .new_l4form_style_heading {background: rgb(7,165,224); background: linear-gradient(180deg, rgba(7,165,224,1) 0%, rgba(3,86,168,1) 100%);  border: solid 1px #fff; border-radius: 2px; text-align: center;}
 .form_style .MuiFormHelperText-root.Mui-error,  .l2form_style .MuiFormHelperText-root.Mui-error, .new_l4form_style .MuiFormHelperText-root.Mui-error {
    position: absolute;left: -209px; top: 50%; transform: translateY(-50%);background: #ffffff; padding: 9px 17px; font-size: 14px; border-radius: 8px; color: #f00!important;  border: 1px solid #eb0404;}
.new_l4form_style .MuiFormHelperText-root.Mui-error::after{ content: '';  position: absolute; right: -12px; top: 50%; transform: translateY(-50%); border-width: 36px; width: 0; height: 0;
    border-top: 13px solid transparent; border-bottom: 13px solid transparent; border-left: 13px solid white;}
 .new_commercial_banner_arrow_text{ background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/commrcial_banner_textBG5.png);  width: 1238px; height: 189px;  margin: -34px 0 10px 0;    overflow: hidden; background-repeat: no-repeat;  }
 .new_commercial_banner_arrow_text label{margin: 60px 0 0 35px; text-shadow: 0 0 2px #963a10;  padding: 0; font-size: 30px; color: #ffffff; text-transform: uppercase; font-weight: bold; display: block;}

 .form_style .zipCode_for .MuiFormHelperText-filled{left: -242px}






 .commercialBody{background: rgb(240,248,213);  background: linear-gradient(180deg, rgba(240,248,213,1) 0%, rgba(255,255,255,1) 100%);}
.commercial_wrapper{ width: 1796px; margin: 0 auto;  display: flex; flex-wrap: wrap; justify-content: space-between;}
.commercial_banner_left{ width: calc(100% - 500px);}
.commercial_banner_right{ width: 478px;}
.commercial_banner_left_con{ width: 942px;}
.commercialLogo{ display: block; width: auto; max-width: 90%; margin: 32px 0;}
.commercial_banner_left h2{ margin: 0; padding: 0; font-size: 66px;    line-height: 66px;   background: rgb(19,114,212); background: linear-gradient(180deg, rgba(19,114,212,1) 0%, rgba(1,38,112,1) 100%);  -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  text-shadow: 2px 2px 3px rgb(1 38 112 / 30%), -1px -1px #2b579b; text-transform: uppercase;}
.commercial_banner_left h3{ margin: 0; padding: 0; font-size: 66px; line-height: 66px;    text-transform: uppercase;}   
.commercial_banner_left h3 strong{ background: rgb(13,183,255);  background: linear-gradient(180deg, rgba(13,183,255,1) 0%, rgba(71,96,255,1) 100%);  -webkit-text-fill-color: transparent; -webkit-background-clip: text;  }      
.commercial_banner_left h3 span{ background: rgb(254,36,55);
    background: linear-gradient(180deg, rgba(254,36,55,1) 0%, rgba(185,25,39,1) 100%);  -webkit-text-fill-color: transparent; -webkit-background-clip: text;  }   
.commercial_banner_left h4{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_banner_textBG.webp');
 background-size: cover; margin: 20px 0 0 0; padding: 22px; border: solid 8px #fff; box-shadow: 0 0 10px #a6ccc7; font-size: 28px; color: #ffffff; font-weight: 500; line-height: 30px;}      
.commercial_banner_left h4 span{ color: #00fff0;}
.commercial_banner_left_blocktext{ width: 788px; display: flex; flex-wrap: wrap; justify-content: space-between;  overflow: hidden;  }
.commercial_banner_left_blocktext .commercial_left_blocktextcon{  margin: 20px 0; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_banner_textBG2.webp');  width: 260px; height: 378px; overflow: hidden;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(1){  background-position: left top;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(2){  background-position: 530px top;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(3){  background-position: 263px top;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon h5 {  font-size: 22px;  text-transform: uppercase;  text-align: center;  width: 200px;   margin: 0 auto;  margin-top: 126px;   line-height: 24px;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(1) h5{ color: #5e59a7;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(2) h5{ color: #0278c7;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(3) h5{ color: #ff7f00;}
.commercial_banner_left_blocktext .commercial_left_blocktextcon p{ font-size: 16px; color: #fff; line-height: 20px; text-align: center; width: 220px; margin: 0 auto;     margin-top:40px;}
.commercial_banner_arrow_text{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_banner_textBG3.webp') no-repeat; width: 1238px; height: 189px;  margin: -52px 0 10px 0;    overflow: hidden; }
.commercial_banner_arrow_text label{ margin: 68px 0 0 35px; text-shadow: 0 0 2px #963a10; padding: 0; font-size: 32px; color: #ffffff; text-transform: uppercase; font-weight: bold; display: block;}
.commercial_banner_right .l4form_style{  margin-top: 80px;  }
.l4form_box{ background: rgb(31,48,55); background: linear-gradient(180deg, rgba(31,48,55,1) 0%, rgba(1,1,1,1) 100%); padding: 15px;  border: solid 1px #fff;  border-radius: 10px;}
.l4form_box .l4form_style_heading{  background: rgb(7,165,224); background: linear-gradient(180deg, rgba(7,165,224,1) 0%, rgba(3,86,168,1) 100%);  border: solid 1px #fff; border-radius: 2px;   text-align: center;}
.l4form_box .l4form_style_heading h2{ position: relative; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_formBGBG1.webp');  background-size: cover; margin: 0; padding: 28px 10px; font-size: 32px;   line-height: 33px; text-transform: uppercase; color: #fff; text-shadow: 0 0 1px #082c4e;}
.l4form_box .l4form_style_heading h2::before{ content: ''; display: block; width: 36px; height: 36px; background: #fa1717; clip-path: polygon(100% 0, 0 0, 0 100%); position: absolute; left: 0; top: 0;}
.l4form_style .MuiFormControl-root{width: 100%;}
.l4form_style .formfild{margin-bottom: 12px;}
.l4form_style .MuiInputBase-root{border-radius: 0;}
.l4form_style .MuiOutlinedInput-notchedOutline{border: 1px solid #414e50 !important;}
.l4form_style .formfild input{background-color: #000000;     color: #8b9093;     padding: 5px 14px; height: 44px;}
.l4form_style .MuiFormLabel-root{font-weight: var(--main-font-Bold);color: #8b9093 !important;     top: 0px;}
.l4form_style .MuiFormLabel-filled{display: none;}
.l4form_style label.Mui-focused{display: none;}
.l4form_style legend{display: none;}
.l4form_style fieldset{top: 0 !important;}
.l4form_style .form_radio .MuiFormGroup-root{display:flex;flex-direction:row;align-items:center}
.l4form_style .form_radio .MuiFormControlLabel-root{color: #fff;}
.l4form_style .form_radio .MuiFormControlLabel-root .MuiSvgIcon-root path{display:none}
.l4submitbtnsection button{background: rgb(1,190,238);  background: linear-gradient(180deg, rgba(1,190,238,1) 0%, rgba(3,132,226,1) 100%); border:none;border-radius:5px;font-size:40px;
    line-height:40px;color:#fff;font-weight:var(--main-font-SemiBold);text-transform:uppercase;padding:20px;width:calc(100%); text-shadow: 0 0 4px #0f3a58;}
.l4form_style .form_radio, .l4form_style .form_text{margin-bottom: 15px;}
.l4form_style .form_text .MuiTypography-root{font-size: 18px;}
.l4form_style  .form_text p{color:#fff;font-size:18px;line-height:24px;font-weight:var(--main-font-SemiBold);text-shadow:1px 1px 1px #76838a}
.l4form_style .formWrapperClass{padding: 15px 5px 5px 5px;}
 


.commercial_block2{ margin-top: 0px;}
.commercial_block2_con1{
    background:#00d2ff url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_block1BG.webp') no-repeat; background-position: left top; background-size: cover;  
}
.commercial_block2_con1_bg{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_block1BG_sub.webp') no-repeat; background-position: left bottom;  padding-left: 614px; padding-bottom: 17px; width: 100%; overflow: hidden; }   
.commercial_block2_con1Div1{padding: 50px 50px 40px 258px; min-height: 300px; position: relative;}
.commercial_block2_con1Div1::after{content: ''; width: 219px; height: 219px; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_img1.webp') no-repeat; background-position: left bottom;  position: absolute; left: 0; bottom: 18px;}
.commercial_block2_con1Div1::before{ content: ''; width: 100px; height: 100px; background-color: #baeaf2; opacity: 0.7; border-radius: 100px; position: absolute; left: 80px; bottom: 190px;}
.commercial_block2_con1Div1H1{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_block1_text_shadow.webp') no-repeat left bottom; padding-bottom: 14px; position: relative;  }
.commercial_block2_con1Div1H1 h1{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_block1_text_Bg.webp') no-repeat right top; background-size: cover; margin: 0; padding: 10px 15px; font-size: 43px; line-height: 43px; color: #ffffff;}
.commercial_block2_con1Div1 p{ margin: 14px 0; padding: 0; font-size: 20px; color: #fff; font-weight: 600;     text-shadow: 0 0 2px #0d88bd;}
.commercial_block2_con2{ padding:15px 30px; display: flex; flex-wrap: wrap; justify-content: center; width: 100%; background: rgb(238,250,246); background: linear-gradient(180deg, rgba(238,250,246,1) 0%, rgba(243,249,235,1) 100%); box-shadow: 0 0 5px #ddd; border-radius: 10px; margin-top: -8px;}
.commercial_block2_con2_con{ width: calc(25% - 36px);  position: relative;   background: #fff; border-radius: 25px; margin: 25px 18px; }
.commercial_block2_con2_con_wrapper{ position: relative;}
.commercial2_arrow1{ background:  url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_line1.webp') no-repeat left top;     width: 3px;  height: 200px;  position: absolute; left: -14px; top:14px;}
.commercial2_arrow2{ background:  url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_line2.webp') no-repeat left top;     position: absolute; left: -13px;  top: -20px; width: 44px; height: 38px;}
.commercial2_arrow3 {  background: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_line3.webp) no-repeat left top; width: calc(100% - 18%);   height: 4px; position: absolute;  left: 40px; top: -21px; }
.commercial2_arrow4{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_line4.webp) no-repeat left top;width:37px;height:33px;position:absolute;right:-14px;top:-22px}
.commercial2_arrow5{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_line5.webp) no-repeat left top;width:22px;height:306px;position:absolute;top:18px;right:-20px}
.commercial_block2_con2_con:nth-child(even) .commercial2_arrow1{top:inherit;bottom:23px}
.commercial_block2_con2_con:nth-child(even) .commercial2_arrow2{transform:rotate(-101deg);left:-13px;top:inherit;bottom:-22px}
.commercial_block2_con2_con:nth-child(even) .commercial2_arrow3{left:40px;top:inherit;bottom:-20px}
.commercial_block2_con2_con:nth-child(even) .commercial2_arrow4{transform:rotate(80deg);top:inherit;bottom:-14px;right:-13px}
.commercial_block2_con2_con:nth-child(even) .commercial2_arrow5{transform:rotate(180deg);top:inherit;bottom:18px}
.commercial_block2_con2_con_body{background:#011f63;background:linear-gradient(180deg,rgba(1,31,99,1) 0%,rgba(4,10,62,1) 100%);border-radius:24px;margin:7px;height:calc(100% - 14px);text-align:center}
.commercial_block2_con2_conTop{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block2_blockBG2.webp) no-repeat center top;border-radius:24px 24px 0 0;padding:0 24px;text-align:center;overflow:hidden}
.commercial_block2_con2_conTop img{display:block;margin:65px auto}
.commercial_block2_con2_conTop h1{margin:0;text-transform:uppercase;font-size:39px;line-height:40px;color:#fff}
.commercial_block2_con2_conBottom{padding:24px}
.commercial_block2_con2_conBottom h2{font-size:23px;color:#fff;font-weight:500;line-height:30px;height:200px;padding:20px;border-radius:15px;display:flex;align-items:center;text-shadow:0 0 2px #888}
.commercial_block2_con2_con:nth-of-type(1) .commercial_block2_con2_conBottom h2{background: rgb(73,53,149);
    background: linear-gradient(90deg, rgba(73,53,149,1) 0%, rgba(89,67,172,1) 100%); border: solid 1px #696ac2;}
.commercial_block2_con2_con:nth-of-type(2) .commercial_block2_con2_conBottom h2{background: rgb(85,70,176);
    background: linear-gradient(90deg, rgba(85,70,176,1) 0%, rgba(63,91,199,1) 100%); border: solid 1px #696ac2;}
.commercial_block2_con2_con:nth-of-type(3) .commercial_block2_con2_conBottom h2{background: rgb(56,90,181);
    background: linear-gradient(90deg, rgba(56,90,181,1) 0%, rgba(62,105,243,1) 100%); border: solid 1px #696ac2;}
.commercial_block2_con2_con:nth-of-type(4) .commercial_block2_con2_conBottom h2{background: rgb(55,116,245);
    background: linear-gradient(90deg, rgba(55,116,245,1) 0%, rgba(21,172,253,1) 100%); border: solid 1px #12bdfa;}
.commercial_block3{margin-top:30px}
.commercial_block3_body{display:flex;flex-wrap:wrap;justify-content:space-between;padding:25px;align-items:center;background:#011345 url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block3_BG.webp);background-position:center bottom;background-repeat:no-repeat;width:100%}
.commercial_block3_left{width:828px}
.commercial_block3_right{width:calc(100% - 880px)}
.commercial_block3_right img{width:100%;border:solid 20px #184286}
.commercial_block3_left h2{background:#1a82e9;background:linear-gradient(180deg,rgba(26,130,233,1) 0%,rgba(6,85,164,1) 100%);border:solid 9px #18386f;padding:14px 15px;font-size:46px;line-height:50px;color:#fff;margin-bottom:32px}
.commercial_block3_left p{margin:0;padding:0;font-size:25px;color:#fff}
.commercial_block3_left p span{color:#00cbff}
.commercial_block4{background: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4BG3.webp) no-repeat right bottom; padding-bottom: 20px;}
.commercial_block4_con1{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4BG1.webp) no-repeat left bottom;padding:30px 0 30px 840px;position:relative}
.commercial_block4_con1 h2::before{content:'';background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4_img1.webp) no-repeat left bottom;position:absolute;left:590px;top:170px;width:223px;height:311px}
.commercial_block4_con1 h2{background:#1a82e9;background:linear-gradient(180deg,rgba(26,130,233,1) 0%,rgba(6,85,164,1) 100%);border:solid 9px #18386f;padding:14px 15px;font-size:46px;line-height:50px;color:#fff;margin-bottom:18px;display:inline-block;padding-right:100px}
.commercial_block4_con1 ul{margin:0;padding:0}
.commercial_block4_con1 ul li{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4_icon.webp) no-repeat left 7px;font-size:24px;color:#000;list-style:none;margin:8px 0 0;padding:0 0 0 42px;font-weight:500}
.commercial_block4_con1 span{color: #0080c9;}
.commercial_block4_con1 h3{ background:#0093b3 url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4BG2.webp') no-repeat left top; background-size: cover; border: solid 1px #00ffe3; padding: 20px; font-size: 24px; color: #fff; line-height: 30px; font-weight: 500; margin-top: 20px;  }
.commercial_block4_con1 h3 span{ color: #00cbff;}
.commercial_block4_con1 .commercial_block4BTN1{background: rgb(0,193,239);  background: linear-gradient(180deg, rgba(0,193,239,1) 0%, rgba(3,127,225,1) 100%); border-radius: 5px; font-size: 42px; color: #fff; text-transform: uppercase; text-shadow: 0 0 10px #1186b1; border: none; cursor: pointer; font-weight: bold;  line-height: 76px; min-width: 450px; border-radius: 4px; text-align: center; margin-top: 25px;}
.commercial_block4_con2{ padding: 30px; width: 1110px;} 
.commercial_block4_con2 h2{background: rgb(26,130,233);  background: linear-gradient(180deg, rgba(26,130,233,1) 0%, rgba(6,85,164,1) 100%); border: solid 9px #18386f; padding: 14px 15px; font-size: 42px; line-height: 50px; color: #fff; margin-bottom: 18px; display: inline-block;  }
.commercial_block4_con2 ul{ margin: 0; padding: 0; display: flex;  flex-wrap: wrap;}
.commercial_block4_con2 ul li{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4_icon.webp') no-repeat left 7px; font-size: 24px; color: #0f2c5e; list-style: none; margin: 8px 0 0 0; padding: 0 0 0 42px; font-weight: 500; width: 33%; }
.commercial_block4_con2 h3{ background:#061f5b url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block4BG4.webp') no-repeat left top; background-size: cover;  position: relative;  padding: 20px; padding-right: 25px; font-size: 24px; color: #fff; line-height: 30px; font-weight: 500; margin-top: 30px; }
.commercial_block4_con2 h3 span{ color: #00cbff;}
.commercial_block4_con2 h3::after{content: ''; display: block; width: 10px; background: #febb02;     height: calc(100% - 40px); position: absolute; top: 20px; right: 0;}
.commercial_block5 .commercial_block5_con{  background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5BG.webp') no-repeat; background-size: cover; width: 100%; border-radius: 10px; position: relative; min-height: 300px;}
.commercial_block5 .commercial_block5_con::before{ content: ''; display: block; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5_BG.webp') no-repeat; width: 744px; height: 332px; position: absolute; left: 0; bottom: 0px; border-radius: 0 0 0 10px; } 
.commercial_block5_conBG{ padding: 30px 30px 30px 650px; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5_img1.webp') no-repeat 450px center; height: 100%; position: relative; z-index: 9;}
.commercial_block5_conBG::after{ content: ''; display: block; width: 60px; height: 60px; position: absolute; right: 0; top: 0; border-radius: 0 10px 0 0; background: rgb(248,83,29);
    background: linear-gradient(180deg, rgba(248,83,29,1) 0%, rgba(243,45,74,1) 100%); clip-path: polygon(100% 0, 0 0, 100% 100%);}
.commercial_block5_con_text_BG{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5_img3_shadow.webp') no-repeat left bottom; padding-bottom: 20px;}
.commercial_block5_con_text_BG h2{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5_img3.webp') no-repeat left bottom; background-size: cover; margin: 0; padding: 18px; font-size: 43px; line-height: 40px; color: #fff; text-transform: uppercase;}
.commercial_block5_con_text ul{ margin: 0; padding: 0 20px}
.commercial_block5_con_text ul li{ list-style: none;   background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block5_icon.webp') no-repeat left 7px; padding: 0 0 0 46px; font-size: 25px;  color: #fff; font-weight: 600; text-shadow: 0 0 2px #1e4f9f;}
.commercial_block5_con_text ul li span{ color: #fff000;}
.commercial_block6_conBG{background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(244,246,247,1) 100%);}
.commercial_block6_con{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block6_BG.webp') no-repeat right bottom; width: 100%; padding: 30px 680px 30px 30px;}
.commercial_block6_con h2 {  background: rgb(26,130,233);  background: linear-gradient(180deg, rgba(26,130,233,1) 0%, rgba(6,85,164,1) 100%);  border: solid 9px #18386f;  padding: 10px 15px; font-size: 42px;  line-height: 50px;  color: #fff;  margin-bottom: 20px;   display: inline-block; min-width: 490px;}
.commercial_block6_con h3{ margin: 0; padding: 0; font-size:35px; line-height: 42px; color: #000; font-weight: 600;}

.commercial_block6_text1{background: rgb(39,27,112);  background: linear-gradient(90deg, rgba(39,27,112,1) 0%, rgba(74,76,207,1) 100%); width: 90%; position: relative; margin-top: 18px; border: solid 1px #4341bc;}
 .commercial_block6_text1_sub{ position: relative; padding: 25px; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block6_textBG.webp') no-repeat right top; background-size: cover; }

.commercial_block6_text1_sub ::after {  content: ''; display: block;   width: 10px;  background: #febb02;   height: calc(100% - 40px);  position: absolute;  top: 20px;  right: 0;}
.commercial_block6_text1_sub h1{ margin: 0; padding: 0; font-size: 53px; line-height: 54px; color: #00fffc;}
.commercial_block6_text1_sub p{ margin: 0; padding: 0; font-size: 25px; color: #fff; line-height: 36px; margin-top: 15px; font-weight: bold;}
.commercial_block6_con ul{ margin: 20px 0; padding: 0;}
.commercial_block6_con ul li{ list-style: none;   background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block6Icon.webp') no-repeat left 7px; padding: 0 0 0 46px; font-size:30px;  color: #7e02d1; font-weight: 600; }
.commercial_block6_con .commercial_block6BTN{background: rgb(0,196,240);
    background: linear-gradient(180deg, rgba(0,196,240,1) 0%, rgba(3,126,225,1) 100%); border: none; cursor: pointer; border-radius: 5px; font-size: 40px; color: #fff; text-transform: uppercase; font-weight: bold; padding: 15px 20px; text-shadow: 0 0 5px #1584ae;} 
.commercial_block7_bg{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block7BG.webp') no-repeat left top; background-size: cover;}    
.commercial_block7_con{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block7_img1.webp') no-repeat 0 bottom; padding: 30px 30px 30px 750px; }    
.commercial_block7_con h2{ margin: 0; padding: 0; font-size: 42px; line-height: 55px; font-weight: 600;}
.commercial_block7_con h2 span{ color: #2c5ae9; display: inline-block;}

.commercial_block7_con h3{ margin: 0; padding: 20px 0; font-size: 28px; color: #00283f; line-height: 35px; font-weight: 500;}
.commercial_block7_con .commercial_block7BTN{background: rgb(0,196,240);
    background: linear-gradient(180deg, rgba(0,196,240,1) 0%, rgba(3,126,225,1) 100%); border: none; cursor: pointer; border-radius: 5px; font-size: 40px; color: #fff; text-transform: uppercase; font-weight: bold; padding: 15px 20px; text-shadow: 0 0 5px #1584ae;} 

.commercial_block7Text{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block7_textBG.webp') no-repeat left top; background-size: cover; margin-bottom: 30px;}
.commercial_block7Text_sub{ border-left: solid 14px #f6472b; position: relative; padding: 30px;}
.commercial_block7Text_sub::after{ content: ''; display: block; width: 28px; height: 28px; position: absolute; right: 0; top: 0;   background: rgb(248,83,29);
    background: linear-gradient(180deg, rgba(248,83,29,1) 0%, rgba(243,45,74,1) 100%); clip-path: polygon(100% 0, 0 0, 100% 100%);}

.commercial_block7Text_sub h4{ margin: 0; padding: 0 0 10px 0; font-size: 35px; line-height: 35px; color: #00fcff;}  
.commercial_block7Text_sub p{ margin: 0; padding: 0; font-size: 25px; line-height: 32px; color: #ffffff;}  
.commercial_block7Text_sub p:first-of-type{padding: 0 0 14px 0;}

.commercial_block8_bg{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block8_bg3.webp') no-repeat right center; width: 100%;background-size: auto 100%;}
.commercial_block8_con{background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block8_bg2.webp') no-repeat right bottom; padding:44px 30px  30px 0; padding-left: 14px; padding-right: 665px; clear: both; overflow: hidden; }

.commercial_block8_div{ width: 33.3%; margin: -14px 0 0 -14px; border-radius: 15px; float: left; min-height: 482px;  -webkit-box-shadow: -10px 0px 15px 0px rgba(0,0,0,0.52);
    -moz-box-shadow: -10px 0px 15px 0px rgba(0,0,0,0.52);
    box-shadow: -10px 0px 15px 0px rgba(0,0,0,0.52);}

.commercial_block8_div .commercial_block8_divSub{ background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block8_bg.webp') no-repeat right top; padding: 25px; text-align: center;}
.commercial_block8_icon{ width: 100%; height: 150px; display: flex; align-items: center; justify-content: center;}
.commercial_block8_divSub h2{ margin: 0; padding: 0; font-size: 34px; color: #ffffff; font-weight: 600; line-height: 35px;}

.commercial_block8_text{ min-height: 210px; font-size: 25px; color: #fff; line-height: 32px; display: flex; flex-wrap: wrap; align-items: center;}

.commercial_block8_div:nth-of-type(1){background: rgb(21,81,254);
    background: linear-gradient(180deg, rgba(21,81,254,1) 0%, rgba(12,44,194,1) 100%);} 

.commercial_block8_div:nth-of-type(2){background: rgb(93,93,234);
    background: linear-gradient(180deg, rgba(93,93,234,1) 0%, rgba(75,55,182,1) 100%); border: solid 1px #6b6bf3;}

.commercial_block8_div:nth-of-type(3){background: rgb(0,205,201);
    background: linear-gradient(180deg, rgba(0,205,201,1) 0%, rgba(1,138,226,1) 100%); border: solid 1px #00cbc9;}  


.commercial_block8_div:nth-of-type(4){background: rgb(5,154,138);
    background: linear-gradient(180deg, rgba(5,154,138,1) 0%, rgba(1,81,124,1) 100%); border: solid 1px #00cbc9;}   
    
    
.commercial_block8_div:nth-of-type(5){background: rgb(73,74,205) ;
    background: linear-gradient(180deg, rgba(73,74,205,1) 0%, rgba(40,28,113,1) 100%); border: solid 1px #6b6bf3;}  

 .commercial_block8_div:nth-of-type(6){background: rgb(166,33,118);
    background: linear-gradient(180deg, rgba(166,33,118,1) 0%, rgba(74,6,44,1) 100%); border: solid 1px #df4efe;}    


.commercial_block9{ margin:0 0 30px 0;}
.commercial_block9_bg{ padding: 30px; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block9_bg.webp') no-repeat right top; background-size: cover; border: solid 18px #daefff; position: relative; }

.commercial_block9Heading{ display: inline-block; padding: 20px 20px 20px 0; background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block9_text1.webp') no-repeat right top; background-size: cover; border-radius: 10px; position: absolute; top: -20px;  left: 30px;}
.commercial_block9Heading h2{ border-left: solid 10px #febb02; margin: 0; padding: 0 80px 0 15px; font-size: 36px; line-height: 36px; color: #fff; text-transform: uppercase; text-shadow: 0 0 8px #0e578c; }

.commercial_block9_bg h3{ display: block; width: 100%; overflow: hidden; font-size: 36px; line-height: 40px; color: #ffffff; text-shadow: 0 0 8px #0e578c; margin: 50px 0 0 0; padding: 0; font-weight: 500;  }

.commercial_block9Body{ display: flex; flex-wrap: wrap; justify-content: space-between; padding: 66px 0 30px 0;}
.commercial_block9Body_sub{ width: 24%;}
 .commercial_block9BTN{background: rgb(0,196,240);
    background: linear-gradient(180deg, rgba(0,196,240,1) 0%, rgba(3,126,225,1) 100%); border: none; cursor: pointer; border-radius: 5px; font-size: 40px; color: #fff; text-transform: uppercase; font-weight: bold; padding: 15px 20px; text-shadow: 0 0 5px #1584ae; margin: 0 auto; display: block;} 

 .commercial_block9Body_sub:nth-of-type(1){background: rgb(109,48,175);
    background: linear-gradient(180deg, rgba(109,48,175,1) 0%, rgba(59,17,110,1) 100%);} 

.commercial_block9Body_sub:nth-of-type(2){background: rgb(7,26,89);
    background: linear-gradient(180deg, rgba(7,26,89,1) 0%, rgba(63,21,178,1) 100%);} 

.commercial_block9Body_sub:nth-of-type(3){background: rgb(14,154,193);
    background: linear-gradient(180deg, rgba(14,154,193,1) 0%, rgba(5,105,196,1) 100%);} 

.commercial_block9Body_sub:nth-of-type(4){background: rgb(9,181,193);
    background: linear-gradient(180deg, rgba(9,181,193,1) 0%, rgba(23,125,197,1) 100%);
} 

.commercial_block9Body_sub:nth-of-type(1) .commercial_block9Body_subCon{background: rgb(140,89,194);
    background: linear-gradient(180deg, rgba(140,89,194,1) 0%, rgba(66,14,116,1) 100%);}  

.commercial_block9Body_sub:nth-of-type(2) .commercial_block9Body_subCon{background: rgb(24,118,253);
    background: linear-gradient(180deg, rgba(24,118,253,1) 0%, rgba(73,19,197,1) 100%);}  

.commercial_block9Body_sub:nth-of-type(3) .commercial_block9Body_subCon{background: rgb(14,150,190);
    background: linear-gradient(180deg, rgba(14,150,190,1) 0%, rgba(2,128,245,1) 100%);}  

.commercial_block9Body_sub:nth-of-type(4) .commercial_block9Body_subCon{background: rgb(10,193,205);
    background: linear-gradient(180deg, rgba(10,193,205,1) 0%, rgba(24,140,211,1) 100%);}  

.commercial_block9Body_sub:nth-of-type(1) label{background: rgb(109,48,175);
        background: linear-gradient(180deg, rgba(109,48,175,1) 0%, rgba(59,17,110,1) 100%); color: #663398;} 
    
.commercial_block9Body_sub:nth-of-type(2) label{background: rgb(7,26,89);
        background: linear-gradient(180deg, rgba(7,26,89,1) 0%, rgba(63,21,178,1) 100%); color: #1f61ed;} 
    
.commercial_block9Body_sub:nth-of-type(3) label{background: rgb(14,154,193);
        background: linear-gradient(180deg, rgba(14,154,193,1) 0%, rgba(5,105,196,1) 100%); color: #0a92d2;} 
    
.commercial_block9Body_sub:nth-of-type(4) label{background: rgb(10,193,205);
    background: linear-gradient(180deg, rgba(10,193,205,1) 0%, rgba(24,140,211,1) 100%); color: #09abaf;} 


.commercial_block9Body_sub { border-radius: 10px; padding: 10px; display: flex; flex-direction: row; }

.commercial_block9Body_subCon{ width: 100%; border-radius: 10px; position: relative;}

.commercial_block9Body_subCon label{ display: block; margin: 0 auto; position: absolute;
    top: -60px;
    transform: translateX(-50%);
    left: 50%;  width: 164px; height: 164px; border-radius: 100px;  padding: 12px;}

.commercial_block9Body_subCon label div{ display: block; width: 100%; height: 100%; background: #fff; border-radius: 100px; -webkit-box-shadow: -5px 15px 21px -3px rgba(0,0,0,0.45);
    -moz-box-shadow: -5px 15px 21px -3px rgba(0,0,0,0.45);
    box-shadow: -5px 15px 21px -3px rgba(0,0,0,0.45);}

 .commercial_block9Body_subCon label div span{ display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;  border-radius: 100px; -webkit-box-shadow: inset 1px -9px 21px -3px rgba(0,0,0,0.45);
        -moz-box-shadow: inset 1px -9px 21px -3px rgba(0,0,0,0.45);
        box-shadow: inset 1px -9px 21px -3px rgba(0,0,0,0.45); text-align: center;   font-size: 87px; font-weight: 600;}

.commercial_block9Body_subBG {background: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage4/COMMERCIAL_block9_text2.webp') no-repeat right top; border-radius: 10px; position: relative; }

.commercial_block9BodyTextCon{  display: flex; flex-direction: column; justify-content: space-between;  padding: 135px 20px 26px 20px; text-shadow: 0 0 2px #333;}

.commercial_block9BodyTextCon h4{ padding: 0 0 25px 0; margin: 0; text-align: center; font-size: 30px; line-height: 33px;  color: #fff; text-transform: uppercase;     min-height: 90px; }

.commercial_block9BodyTextCon h5{ padding:0 6px; margin: 0; text-align: center; font-size: 22px; line-height: 28px;  font-weight: 600; color: #fff;   }

.landingFour .l4form_style .state .MuiInputBase-root .MuiInputBase-input{
    background-color: #000000;
    color: #8b9093;
    padding: 5px 14px;
    height: 44px;
    display: flex;
    align-items: center;
}
.new_l4form_style .state .MuiInputBase-root .MuiInputBase-input{
    background-color: #e2e5e6;
    color: #8b9093;
    padding: 5px 14px;
    height: 44px;
    display: flex;
    align-items: center;
}
.landingFour .state svg {
    color: #8b9093;
}



@media only screen and (max-width: 1829px){
    .new_commercial_wrapper{width:96%}
    .new_commercial_banner_BG{background-position:-70px top}
    .new_commercial_block9BodyTextCon h4{ font-size: 26px; line-height: 30px;}
   
    
 }
 @media only screen and (max-width: 1829px){
    .new_commercial_banner_img1{background-position: 769px top;}
 }
 @media only screen and (max-width: 1789px){
    .new_commercial_banner_arrow_text {
        width: 1161px;
    height: 172px;
    background-size: 100% 100%;
    }
    .new_commercial_banner_arrow_text label {
        font-size: 29px;
    }
 }

@media only screen and (max-width: 1780.98px){
    
    .new_commercial_banner_img1{background-position: 710px top;}
    
    .new_commercial_banner_arrow_text{width:1100px;height:168px;background-size:100% 100%;}
    .new_commercial_banner_arrow_text label{margin:49px 96px 0 35px;font-size:32px;line-height:32px}
    .new_commercial_banner_left h3{ font-size: 50px;}
}

@media only screen and (max-width: 1760px){
    .new_commercial_banner_img1 {
    background-position: 660px top;
}
}
@media only screen and (max-width: 1643px){
    .new_commercial_banner_arrow_text{width:920px;height:140px}
    .new_commercial_banner_arrow_text label{margin:39px 96px 0 35px;font-size:28px;line-height:30px}

    .new_commercial_banner_right {
        width: 400px;
    }

    .new_l4form_box .new_l4form_style_heading h2{ font-size: 26px;     padding: 8px 10px; line-height: 30px;}

    .new_commercial_banner_img1 {
        background-position: 640px top;
    }
  
}
@media only screen and (max-width: 1517px){
    .new_l4form_box{
        background: linear-gradient(180deg, rgba(31,48,55,1) 0%, rgba(1,1,1,1) 100%);
    padding: 15px;
    border: solid 1px #fff;
    border-radius: 10px;
 
    margin-top: 45px;
    width: 85%;
    margin-left: 65px;
}
    
}
@media only screen and (max-width: 1488px){

    


    .new_commercial_banner_left h3{
        font-size: 50px;
    }
    
    
    .new_commercial_banner_left h4{
        width: 83%;
        font-size: 20px;
        
    }
    /* .new_commercial_banner_left_blocktext{
        width: 779px;
    }
     */
    .new_commercial_banner_img1 {
        background-position: 694px top ;
        background-size: 36%;
    }
    .new_l4form_box .new_l4form_style_heading h2{
        font-size: 30px;
    }
    .new_l4form_box .new_l4form_style_heading h2{
        font-size: 23px;
        width: 100%;
    }
    .new_commercial_banner_left_con {
        width: 932px;
    }
}
@media only screen and (max-width: 1464px){

    /* .commercial_block4_con1{ background-position: -110px bottom;} */
    .new_commercial_banner_arrow_text{    width: 818px;
        height: 140px;
    }
    .new_commercial_banner_arrow_text label{
        font-size: 27px;
    }
}
@media only screen and (max-width: 1420px){
    .new_commercial_banner_left_con{
        width: 860px;
    }
}
@media only screen and (max-width: 1399px){

    .new_commercial_banner_left h4 { 
        font-size: 18px;
    }

    .new_commercial_banner_left h3 {
        font-size: 40px;
    }
    .new_commercial_banner_left_con{
        width: 789px;
    }
    .new_commercial_banner_right {
        width: 419px;
    }
    .new_l4form_box {
        padding: 8px;
        margin-top: 0px;
    }
    .new_commercial_banner_img1 {    background-position: 573px top;
        background-size: 43%;}
        .new_l4form_box{
            margin-top: 50px;
        }

        .new_commercial_banner_img1{ margin-bottom: 20px;}
}
@media only screen and (max-width: 1320.98px){
.new_commercial_banner_arrow_text{width:840px;height:120px; margin: -11px 0 10px 0;}
.new_commercial_banner_arrow_text label{margin:36px 96px 0 35px;font-size:24px;line-height:26px}
}
@media only screen and (max-width: 1309px){
    .new_commercial_banner_arrow_text{
        margin: -6px 0 10px 0;
    }
    .new_commercial_banner_right{
        width: 411px;
    }
    .new_commercial_banner_arrow_text{
        width: 751px;
        height: 120px;
    }
    .new_commercial_banner_right{
        width: 396px;
    }
}
@media only screen and (max-width: 1298px){
    .new_commercial_banner_arrow_text{
        margin: -7px 0 10px 0;
    }
 
    .new_commercial_banner_left h2{
        font-size: 53px; line-height: 58px;
    }
}
@media only screen and (max-width: 1256px){
    .new_commercial_banner_arrow_text{
    margin: -8px 0 10px 0;}
    .commercial_banner_left_con {
        width: 467px;
    }
   
    
    .new_commercial_banner_left h4{
        font-size: 17px;
    }
    .new_l4form_box .new_l4form_style_heading h2{
        font-size: 20px;
        width: 100%;
        padding: 5px;
    }

    .new_commercial_banner_img1{
        background-position: 594px top;
    }
    
    
  
    .new_commercial_banner_left_con {
        width: 738px;
    }
    .new_commercial_banner_img1 {
        background-position: 81% top;
        background-size: 43%;
    }
    
    .new_commercial_banner_left_blocktext {
        width: 788px;
        flex-wrap: nowrap;
    }

}
    

@media only screen and (max-width: 1199px){

    .commercial_block2_con1{ background: #0668be;}
    .new_commercial_banner_left h2{
        font-size: 50px;
    }
    .new_commercial_banner_left h4{text-align: center;}
    .new_commercial_banner_right{
        width: 100%;
    }
    .new_commercial_banner_left h4{
        width: 100%;
    }
    
    .new_commercial_banner_left_blocktext{
        margin: 0 auto;
    }
    .new_commercial_banner_img1{
        background-image: none;
    }
    .new_commercial_banner_left h4{
        width: 100%;
        margin-top: 10px;
    }
    .new_commercial_banner_left_con{
        width: 100%;
    }
    .new_commercial_banner_left h3{
        text-align: center;
    }
    .new_commercial_banner_left h2{
        text-align: center;
    }
    
    .MuiFormControl-root{
        width: 100%;
    }
    .new_l4form_box{
        width: 100%;
        margin: 0 auto;
        margin-top: 14px;
    }
    .new_commercial_banner_arrow_text{width:auto;margin:0;border-radius:10px;height:auto;background: #2cb2ff;background:linear-gradient(#2cb2ff),rgba(#2cb2ff) 50%,rgba(#2cb2ff) }
    .new_commercial_banner_arrow_text label{margin:0;font-size:28px;line-height:30px;padding:15px;text-align: center;}

}
@media only screen and (max-width: 900px){
    .new_commercial_banner_left_blocktext{display:block;width:100%;margin-bottom:0px;height: unset;}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon{background:none;width:auto;height:auto;padding:10px}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(1){background:#5b5ca9;background:linear-gradient(180deg,rgb(91 92 169 / 82%) 0%,rgb(94 79 161 / 85%) 100%);}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(1) img{ display: block; margin: 10px auto;}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(2){background:#17b6dd;background:linear-gradient(180deg,rgba(23,182,221,1) 0%,rgba(14,144,226,1) 100%)}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(2) img{ display: block; margin: 10px auto;}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(3){background: #14956f;background:linear-gradient(180deg,rgb#14956f,rgba#14956f)}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon:nth-of-type(3) img{ display: block; margin: 10px auto;}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon h5{margin:0;width:auto;padding:10px;background:#fff;border-radius:10px}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon h5 br{display:none}
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon p{width:auto;margin:0;padding:10px 0 0}

.new_l4form_box {
    margin-top: 13px;
}
 
}
@media only screen and (max-width: 756px){
    
    }

@media only screen and (max-width: 724px){
    .new_commercial_banner_left h3 {
        font-size: 30px; line-height: 32px;
    }
}

    

@media only screen and (max-width: 713px){
    .new_commercial_banner_left_blocktext{
        width: 100%;
    }
    
}
@media only screen and (max-width: 663px){
    
    .new_commercial_banner_left h4{
        text-align: center;
    }
}
@media only screen and (max-width: 663px){
    
    .new_l4form_box{
        margin-top: -43px;
    }
}
@media only screen and (max-width: 541px){
    
}

@media only screen and (max-width: 405px){
   
    .new_l4form_box{
        margin-top: -58px;
    }
    .new_commercial_banner_left h3{
        font-size: 35px;
        line-height: 40px;
    }
}
@media only screen and (max-width: 686px){
    .new_l4form_box {
        margin-top: 19px;
    }
    .new_commercial_banner_left_blocktext{
        height: auto;
    }
    
    }










@media only screen and (max-width: 1820.98px) {
.commercial_wrapper{width:96%}
.commercial_banner_BG{background-position:-70px top}
.commercial_block9BodyTextCon h4{ font-size: 26px; line-height: 30px;}
.commercial_block9BodyTextCon h5{ font-size: 20px; line-height: 26px;}
}
@media only screen and (max-width: 1780.98px) {
.commercial_banner_arrow_text{width:1100px;height:168px;background-size:cover}
.commercial_banner_arrow_text label{margin:49px 96px 0 35px;font-size:32px;line-height:32px}
.commercial_block6_con{background-position: 120% bottom;}
}
@media only screen and (max-width: 1760.98px) {
.commercial_banner_BG{background-position:-125px top}
.commercial_block2_con2_conBottom h2{height:250px}
.commercial_block4_con1 h2::before{left:560px;top:30px;left:560px;top:30px}
.commercial_block4_con1 h2{padding:10px}
.commercial_block4{background-position:122% bottom}
}
@media only screen and (max-width: 1700.98px) {
.commercial_block2_con1Div1H1{width:140%;margin-left:-40%}
.commercial_banner_BG{background-position:-35px top;background-size:100%}
.commercial_banner_left h4{padding:15px;font-size:25px;line-height:28px}
.commercial_banner_right .l4form_style{margin-top:30px}
.commercial_block6_con h3{ width: 120%;}
.commercial_block8_text{ font-size: 22px; line-height: 26px;}
.commercial_block8_divSub h2{ font-size: 30px;}
 }
@media only screen and (max-width: 1640.98px) {
.commercial_banner_arrow_text{width:920px;height:140px}
.commercial_banner_arrow_text label{margin:39px 96px 0 35px;font-size:28px;line-height:30px}
.l4form_style .formfild input{height:40px}
.commercial_block4_con1 h2::before{left:570px;top:180px}
.commercial_block4_con1 h2{margin-left:-100%;width:200%}
.commercial_block6_con h3{ width: 160%;}
.commercial_block7_con{ padding-left: 600px;}
.commercial_block7_con{ background-position: -90px bottom;}
.commercial_block9BodyTextCon{  padding: 135px 9px 15px 9px;}
}
@media only screen and (max-width: 1600.98px) {
.commercial_block2_con1Div1H1{width:170%;margin-left:-49%}
.commercial_block2_con1Div1H1 h1{font-size: 37px;}
.commercial_block2_con2_conBottom h2{font-size:18px;line-height:24px}
.commercial2_arrow3{width:calc(100% - 22%)}
.commercial_block4{background-position:134% bottom}
.commercial_block8_con{ padding-right: 470px; background-size: 500px;}
 }
@media only screen and (max-width: 1570.98px) {
.commercial_block2_con1Div1H1{width:170%;margin-left:-60%}
.commercial_block3_left{width:50%}
.commercial_block3_right{width:calc(100% - 52%)}
.commercial_block4{background-position:110% bottom;background-size:740px}
.commercial_block6_text1{ width: 100%;}
}
@media only screen and (max-width: 1520.98px) {
.commercial_banner_right{width:398px}
.l4form_box .l4form_style_heading h2{padding:22px 10px;font-size:28px;line-height:30px}
.commercial_block2_con2_conTop img{margin:40px auto}
.commercial_block6_con {  background-position: 180% bottom;  }
.commercial_block6_con{ padding-right: 580px;}
.commercial_block9BodyTextCon h4{ min-height: inherit; font-size: 28px; line-height: 30px;     padding: 0 0 15px 0;}
.commercial_block9BodyTextCon h5{ font-size: 18px; line-height: 24px; padding: 0;}
.commercial_block9BodyTextCon{  padding: 125px 15px 15px 15px;}
}
@media only screen and (max-width: 1480.98px) {
.commercial_block2_con1Div1H1{width:270%;margin-left:-170%}
.commercial_block4_con1 h2::before{left:0;top:20px}
.commercial_block4_con1 ul{width:140%;margin-left:-40%}
}
@media only screen and (max-width: 1460.98px) {
.commercial_banner_left h2{font-size:56px;line-height:56px}
.commercial_banner_left h3{font-size:56px;line-height:56px}
.commercial_banner_left_con{width:790px}
.commercial_banner_left h4{padding:12px;font-size:22px;line-height:24px}
.l4form_box .l4form_style_heading h2{padding:20px 10px;font-size:26px;line-height:28px}
.commercial_block2_con1Div1H1{width:auto;margin-left:0}
.commercial_block2_con1_bg{padding-left:300px;background-position:-337px bottom}
.commercial_block4_con2{padding:30px;width:930px}
.commercial_block4_con2 ul li{width:45%}
.commercial_block4_con2{width:auto}
.commercial_block4_con2 ul{width:850px}
.commercial_block4{background-position:110% bottom;background-size:850px}
.commercial_block4_con2 h3{width:60%}
.commercial_block5_con_text_BG h2{ font-size: 32px; line-height:36px;}
.commercial_block6_con {  background-position: 280% bottom;  }
.commercial_block6_con .commercial_block6BTN{ font-size: 36px;}
.commercial_block7_con .commercial_block7BTN{ font-size: 36px;}
.commercial_block8_text { font-size: 18px;  line-height: 24px;  }
.commercial_block8_divSub h2 {  font-size: 24px;    line-height: 28px; }
.commercial_block9BTN{ font-size: 36px;}
}
 @media only screen and (max-width: 1380.98px) {
.commercial_block4_con1 ul{width:200%;margin-left:-100%}
.commercial_block3_left h2{font-size:38px;line-height:40px}
.commercial_block2_con2_conTop h1{font-size:28px;line-height:30px}
.l4form_box{padding:8px}
.commercial_banner_right{width:340px}
.l4form_box .l4form_style_heading h2{font-size:23px}
.commercial_block5_conBG{ background-position: 450px 80%;}
.commercial_block5_con_text_BG {  width: 125%; margin-left: -25%;}
.commercial_block6_con .commercial_block6BTN{ font-size: 30px;}
.commercial_block6_con {  background-position: 280% bottom;  }
.commercial_block6_text1_sub h1{ font-size: 48px; line-height: 52px;}
.commercial_block7_con h2 br{ display: none; }
.commercial_block7_con h2 { width: 150%; margin-left: -50%;}
}
 @media only screen and (max-width: 1360.98px) {
.commercial_block2_con1Div1H1{width:150%;margin-left:-40%}
.commercial_block6_con {  background-position: 350% bottom;  }
}
 @media only screen and (max-width: 1340.98px) {
.commercial_block4_con1{padding-left:710px}
.commercial_block4_con1 h2{margin-left:-50%;width:150%}
.commercial_block4_con1 ul{margin-left:-10%;width:115%}
.commercial_block6_con {  background-position: 480% bottom;  }

.commercial_block8_con{ background-size: 400px; padding-right: 360px;} 
}
 @media only screen and (max-width: 1320.98px) {
.commercial_banner_left h2{font-size:50px;line-height:50px}
.commercial_banner_left h3{font-size:50px;line-height:50px}
.commercial_banner_left h4{padding:10px;font-size:18px;line-height:20px}
.commercial_banner_arrow_text{width:840px;height:120px}
.commercial_banner_arrow_text label{margin:36px 96px 0 35px;font-size:24px;line-height:26px}
.l4form_box .l4form_style_heading h2{padding:15px 10px;font-size:20px;line-height:22px}
.commercial2_arrow3{width:calc(100% - 28%)}
.commercial_block6_con {  background-position: 630% bottom;  }
 
}
 @media only screen and (max-width: 1300.98px) {
.commercial_block4_con1{padding-left:680px}
.commercial_block4{background-size:780px}
.commercial_block5_con_text_BG {  width: 150%; margin-left: -50%;}
.commercial_block6_con {  background-position: right bottom; background-size: 700px;  }
.commercial_block6_con h3 {  width: 200%; }
.commercial_block6_text1 {    width: 170%; }

/* .commercial_block7_con h3{ width: 120%; margin-left: -20%;} */
.commercial_block7_con {  padding-left: 520px;     background-position: -130px bottom; }
}
 @media only screen and (max-width: 1260.98px) {
.commercial_block4{background-size:700px}
/* .commercial_block4_con1 ul{margin-left:-28%;width:128%} */
.commercial_block4_con1 h2::before{left:140px;top:20px}

.commercial_block7_con h3{width: 117%;
    margin-left: -110px;}
}
 @media only screen and (max-width: 1240.98px) {
.commercial_banner_right{width:310px}
}
 @media only screen and (max-width: 1199.98px) {
.new_commercial_banner_left_blocktext .new_commercial_left_blocktextcon{margin: 8px 0;}
.commercial_block8_text{ min-height: inherit; padding-top: 15px;}
.commercial_block8_con { padding: 15px; padding-top: 0; background: none; display: flex; flex-wrap: wrap; }  
.commercial_block8_div{ margin: 0; box-shadow: none; min-height: inherit; float: none;}
.commercial_block7_con{ background: none; padding:17px 15px;} 
.commercial_block7_con h2 { width: auto;  margin-left: 0; font-size: 28px; line-height: 40px;  }
.commercial_block7_con h3 { width: auto; padding: 20px 0;  margin-left: 0;   font-size: 22px; line-height: 30px;  }
.commercial_block7Text_sub{ padding: 15px;}
.commercial_block7Text_sub h4{ font-size: 28px; line-height: 30px;}
.commercial_block7Text_sub p{ font-size: 18px; line-height: 22px;}
.commercial_block7_con .commercial_block7BTN{ font-size: 20px;     margin-bottom: 10px;}
.commercial_block9BTN{ font-size: 20px;}
.commercial_block7_bg{ background: none;}
 .commercial_block6_con h2{ font-size: 28px; line-height: 30px; display: inline-block;  min-width: inherit;    width: 100%;}
 .commercial_block6_con h3{ width: auto;}
 .commercial_block6_con h3{ width: auto; font-size: 28px; line-height: 30px;}
 .commercial_block6_text1{ width: auto;}
 .commercial_block6_text1_sub h1{ font-size: 28px; line-height: 30px;}
 .commercial_block6_text1_sub p{ font-size: 18px; line-height: 24px;}
 .commercial_block6_text1_sub{ padding: 15px;}
.commercial_block6_con {  background: none;    padding: 30px 15px 15px 15px;  }
.commercial_block6_con ul li{ font-size: 18px; line-height: 26px;  padding-top: 5px; padding-bottom: 5px;    background-position: left 4px;}
.commercial_block6_con .commercial_block6BTN{ font-size: 20px;}
.commercial_block4_con2 h3{font-size:18px;line-height:22px;padding:10px;padding-right:25px;width:auto;margin-top:20px}
.commercial_block4{background-image:none;padding-bottom:25px}
.commercial_block4_con2{padding:0}
.commercial_block4_con2 h2{margin-left:0;width:100%;font-size:28px;line-height:30px}
.commercial_block4_con2 ul{width:auto}
.commercial_block4_con2 ul li{font-size:17
    px;width:33%;background-position:left 4px}
.commercial_block4_con1 h2::before{display:none}
.commercial_block3{margin-top:0px}
.commercial_block4_con1 h2{margin-left:0;width:auto}
.commercial_block4_con1 ul{margin-left:0;width:auto}
.commercial_block4_con1{background-image:none;padding-left:0}
.commercial_block4_con1 h2{margin-left:0;width:100%;font-size:28px;line-height:30px}
.commercial_block4_con1 h3{font-size:18px;line-height:22px;padding:10px}
.commercial_block4_con1 ul li{font-size:18px;background-position:left 4px}
.commercial_block4_con1 .commercial_block4BTN1{min-width:inherit;font-size:20px;line-height:46px;margin-top:20px;padding:5px 15px}
.commercial_block3_left h2{font-size:28px;line-height:30px;margin-bottom:15px}
.commercial_block3_left p{font-size:18px;line-height:22px}
.commercial_block3_body{padding:15px;display:block;text-align:center}
.commercial_block3_left{width:auto;margin-bottom:15px}
.commercial_block3_right{width:auto}
.commercial2_arrow_block{display:none}
.commercial_block2_con2{padding:15px;display:block}
.commercial_block2_con2_con{width:auto;border-radius:25px;margin:0}
.commercial_block2_con2_conBottom h2{height:auto;text-align:center;display:block;padding:10px}
.commercial_block2_con2_conTop img{margin:15px auto}
.commercial_block2_con2_conTop{background:none;padding:0 15px}
.commercial_block2_con2_conBottom{padding:15px}
.commercial_block2_con1Div1::after{display:none}
.commercial_block2_con1Div1::before{display:none}
.commercial_block2_con1Div1H1{width:auto;margin-left:0}
.commercial_block2_con1_bg{background-position:center bottom;background-size:100%;padding:10px 10px 52%;text-align:center}
.commercial_block2_con1Div1H1 h1{background-image:none;background-color:#071923;font-size:28px;line-height:30px}
.commercial_block2_con1Div1 p{margin:0;font-size:18px;line-height:22px}
.commercial_block2_con1Div1{padding:0;min-height:inherit;position:relative}
.commercial_wrapper{width:96%;display:block;overflow:hidden}
.commercial_banner_BG{background-image:none;background:#d5eeda;background:linear-gradient(180deg,rgba(213,238,218,1) 0%,rgba(221,244,225,1) 25%,rgba(226,245,229,1) 50%,rgba(226,241,240,1) 75%,rgba(251,250,245,1) 100%);padding-bottom:25px}
.commercial_banner_left_con{width:auto}
.commercial_banner_left{width:auto;text-align:center}
.commercialLogo{margin:28px auto;}
.commercial_banner_left h4{font-size:22px;line-height:30px}
.commercial_banner_left_blocktext{margin:0 auto}
.commercial_banner_arrow_text{width:auto;margin:0;border-radius:10px;height:auto;background:#f92801;background:linear-gradient(180deg,rgba(249,40,1,1) 0%,rgba(254,114,1,1) 50%,rgba(249,40,1,1) 100%)}
.commercial_banner_arrow_text label{margin:0;font-size:28px;line-height:30px;padding:15px}
.commercial_banner_right{width:auto}
.l4form_box .l4form_style_heading h2{background:none;font-size:26px;line-height:30px}
.l4form_box .l4form_style_heading h2::before{display:none}
.commercial_banner_left h3{margin-top:10px}
.commercial_block5_con_text_BG {  width: auto; margin-left: 0;}
.commercial_block5 .commercial_block5_con::before{ display: none;}
.commercial_block5_conBG{ background: none;}
.commercial_block5_conBG{ padding: 25px;}
.commercial_block5_con_text ul{ padding: 0;}
.commercial_block5_con_text ul li{ font-size: 18px; margin-bottom: 10px; line-height: 26px; background-position: left 4px;     padding: 0 0 0 36px}
.commercial_block5 .commercial_block5_con{ min-height: inherit;}
.commercial_block5_con_text_BG h2{ font-size: 28px; line-height: 30px;}
.commercial_block9Body_sub{width: 49%;}
.commercial_block9Body_sub:nth-of-type(1),.commercial_block9Body_sub:nth-of-type(2){ margin-bottom: 70px;}
.commercial_block9_bg h3{ font-size: 28px; line-height: 32px; }
}
 @media only screen and (max-width: 900.98px) {
.commercial_banner_left_blocktext{display:block;width:100%;margin-bottom:10px}
.commercial_banner_left_blocktext .commercial_left_blocktextcon{background:none;width:auto;height:auto;padding:10px}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(1){background:#5b5ca9;background:linear-gradient(180deg,rgba(91,92,169,1) 0%,rgba(94,79,161,1) 100%)}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(2){background:#17b6dd;background:linear-gradient(180deg,rgba(23,182,221,1) 0%,rgba(14,144,226,1) 100%)}
.commercial_banner_left_blocktext .commercial_left_blocktextcon:nth-of-type(3){background:#e69400;background:linear-gradient(180deg,rgba(230,148,0,1) 0%,rgba(252,104,9,1) 100%)}
.commercial_banner_left_blocktext .commercial_left_blocktextcon h5{margin:0;width:auto;padding:10px;background:#fff;border-radius:10px}
.commercial_banner_left_blocktext .commercial_left_blocktextcon h5 br{display:none}
.commercial_banner_left_blocktext .commercial_left_blocktextcon p{width:auto;margin:0;padding:10px 0 0}
.commercial_block3_right img{border:solid 10px #184286}
.commercial_block4_con2 ul li{width:50%}
.commercial_block5_conBG {  padding: 15px;  }
.commercial_block8_div{ width: 50%;}
.commercial_block9Body_sub{ width: auto;}
.commercial_block9Body_sub:nth-of-type(1),.commercial_block9Body_sub:nth-of-type(2),.commercial_block9Body_sub:nth-of-type(3){ margin-bottom: 70px;}
.commercial_block9_bg{padding: 15px;}
.commercial_block9Heading{ position: inherit; top: 0; left: 0;}
.commercial_block9_bg h3{   margin: 20px 0 0 0;  text-align: center; }
.commercial_block9Body{padding-bottom: 15px;}
}

 @media only screen and (max-width: 560.98px) {
.commercial_block8_div{ width: 100%;}
.commercial_block8_text{ width: 100%; text-align: center; display: block;}
.commercial_block9Heading h2{padding-right: 15px;}
}
    
 @media only screen and (max-width: 480.98px) {
.commercial_block4_con2 ul li{width:100%}
.commercial_banner_left h2{font-size:40px;line-height:44px}
.commercial_banner_left h3{font-size:40px;line-height:44px}
}